/**
 * Validates the total price against tonnage price
 * 
 * @param {string|number} newTotalPrice - New total price to validate
 * @param {number} tonnagePrice - Tonnage price to compare against
 * @param {boolean} isNoteRequired - Flag to indicate if a note is required
 * @param {object} swal - SweetAlert2 instance for showing alerts
 * @returns {boolean} Validation result
 */
export const validateTotalPrice = (newTotalPrice, tonnagePrice, isNoteRequired, swal) => {
    if(isNoteRequired === true) {
      return true;
    }

    if (newTotalPrice == 0 || newTotalPrice === "" || newTotalPrice === undefined) {
      swal.fire({
        icon: "warning",
        title: "UYARI!",
        text: "Ton maliyetinin altında bir Satış Fiyatı girildi. Lütfen açıklamanızı Not alanına ekleyin.",
        showConfirmButton: true,
        confirmButtonText: "TAMAM",
      });
      return;
    }
    
    // Format the price correctly (handle decimal points)
    const formattedPrice = typeof newTotalPrice === 'string' && newTotalPrice.includes(".")
      ? newTotalPrice.replace(".", "")
      : newTotalPrice;
      
    // Convert to numeric values for comparison
    const numericTotalPrice = parseFloat(typeof formattedPrice === 'string' ? formattedPrice.replace(",", ".") : formattedPrice);
    const numericTonnagePrice = parseFloat(tonnagePrice);
    
    // Check if total price is less than tonnage price
    const isLessThanTonnage = numericTotalPrice < numericTonnagePrice;

    if (isLessThanTonnage) {
      // Show warning message
      swal.fire({
        icon: "warning",
        title: "UYARI!",
        text: "Ton maliyetinin altında bir Satış Fiyatı girildi. Lütfen açıklamanızı Not alanına ekleyin.",
        showConfirmButton: true,
        confirmButtonText: "TAMAM",
      });
      
      return false;
    }
    
    return true;
  };
  
  /**
   * Formats numbers according to Turkish locale
   * 
   * @param {number} val - Value to format
   * @returns {string} Formatted value
   */
  export const numberFormatter = (val) => {
    if (val === undefined || val === null) return "0.00";
    
    const formattedValue = new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(val);
    
    return formattedValue;
  };