<!-- components/MapDisplay.vue -->
<template>
  <div>
    <GmapMap
      ref="gmap"
      :center="position"
      :zoom="zoomLevel"
      :map-type-id="mapType"
      style="width: 100%; height: 80vh !important"
      :options="mapOptions"
    >
      <!-- Araç Marker'ları -->
      <GmapMarker
        v-for="m in markers"
        :key="m.id"
        :title="m.title"
        :label="getMarkerLabel(m.title)"
        :position="m.position"
        :icon="m.icon"
      >
      </GmapMarker>

      <!-- Ara Noktalar -->
      <GmapMarker
        v-show="stateId == 5"
        v-for="m in detail.waypoints"
        :key="m.id + '_way'"
        :title="m.no"
        :label="getWaypointLabel(m.no)"
        :position="m.position"
        :icon="m.icon"
      >
      </GmapMarker>

      <!-- Hedef Noktası -->
      <GmapMarker
        v-if="shouldShowTargetMarker"
        :title="getTargetTitle"
        :label="getTargetLabel"
        :position="detail.target"
        :icon="require('@/assets/marker/flag.png')"
      >
      </GmapMarker>

      <!-- Rota Çizgisi -->
      <GmapPolyline
        v-if="taskDetail.polyline && detail"
        v-bind:path.sync="taskDetail.polyline"
        v-bind:options="{ strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>
    </GmapMap>
  </div>
</template>
  
  <script>
export default {
  name: "map-display",
  props: {
    position: {
      type: Object,
      required: true,
    },
    zoomLevel: {
      type: Number,
      default: 5,
    },
    mapType: {
      type: String,
      default: "roadmap",
    },
    markers: {
      type: Array,
      default: () => [],
    },
    stateId: {
      type: Number,
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    taskDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mapOptions() {
      return {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
    },
    shouldShowTargetMarker() {
      return this.detail.planning && this.detail.planning.state < 8;
    },
    getTargetTitle() {
      if (this.detail.planning.state < 4) {
        return "Yükleme Noktası";
      } else if (
        this.detail.planning.state != 4 &&
        this.detail.planning.state < 8
      ) {
        return "Teslim Noktası";
      }
      return "";
    },
    getTargetLabel() {
      return {
        text: this.detail.planning.adress,
        color: "white",
        className:
          "absolute bottom-4 -right-8 text-xs px-2 text-xs font-bold bg-blue-700 rounded tracking-widest",
        fontSize: "8.5px",
      };
    },
  },
  methods: {
    getMarkerLabel(title) {
      return {
        text: title,
        color: "white",
        className:
          "absolute bottom-4 -right-8 text-xs px-2 text-xs font-bold bg-blue-700 rounded tracking-widest",
        fontSize: "8.5px",
      };
    },
    getWaypointLabel(no) {
      return {
        text: no + ". Numaralı Ara Nokta",
        color: "white",
        className:
          "absolute bottom-4 -right-8 text-xs px-2 text-xs font-bold bg-blue-700 rounded tracking-widest",
        fontSize: "8.5px",
      };
    },
  },
};
</script>