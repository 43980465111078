<!-- VehicleConflictModal.vue -->
<template>
  <div class="vehicle-conflict-modal" v-if="visible">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-container">
      <div class="modal-header warning">
        <div class="header-icon">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <div class="header-title">
          <h3>Araç Ataması Yapılamadı</h3>
        </div>
        <div class="header-close">
          <button @click="close">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="conflict-description">
          <p>
            Bu araç başka bir işe atanmış durumda ve mevcut işinizle uyumlu
            değil.
          </p>
        </div>

        <div class="info-container">
          <div class="info-section order-info">
            <div class="section-header">
              <i class="fas fa-file-alt"></i>
              <h4>Sipariş Bilgileri</h4>
            </div>
            <div class="section-content">
              <table>
                <tr>
                  <td>Sipariş No:</td>
                  <td>{{ orderInfo.orderNo }}</td>
                </tr>
                <tr>
                  <td>Sipariş Adı:</td>
                  <td>{{ orderInfo.orderName }}</td>
                </tr>
                <tr>
                  <td>Müşteri:</td>
                  <td>{{ orderInfo.customer }}</td>
                </tr>
                <tr>
                  <td>Tonaj:</td>
                  <td>{{ orderInfo.tonnage }} {{ orderInfo.unitType }}</td>
                </tr>
                <tr>
                  <td>Ürün:</td>
                  <td>{{ orderInfo.productType }}</td>
                </tr>
                <tr>
                  <td>Teslim Tarihi:</td>
                  <td>{{ orderInfo.deliveryDate }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="info-section vehicle-info">
            <div class="section-header">
              <i class="fas fa-truck"></i>
              <h4>Araç Bilgileri ({{ vehicleInfo.plate }})</h4>
            </div>
            <div class="section-content">
              <table>
                <tr>
                  <td>Mevcut Görev:</td>
                  <td>{{ vehicleInfo.planning.customer }}</td>
                </tr>
                <tr>
                  <td>Araç Kapasitesi:</td>
                  <td>{{ vehicleInfo.capacity/1000 }} TON</td>
                </tr>
                <tr>
                  <td>Mevcut İşin Teslim Süresi:</td>
                  <td>{{ vehicleInfo.planning.deliveryDate }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="primary-button" @click="close">{{ buttonText }}</button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "VehicleConflictModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orderInfo: {
      type: Object,
      required: true,
    },
    vehicleInfo: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      default: "Anladım",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
  
  <style scoped>
.vehicle-conflict-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: relative;
  width: 90%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: modal-appear 0.3s ease;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.modal-header.warning {
  background-color: #fff5f5;
  color: #e53e3e;
}

.header-icon {
  font-size: 24px;
  margin-right: 12px;
}

.header-title {
  flex: 1;
}

.header-title h3 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
}

.header-close button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #888;
  transition: color 0.2s;
}

.header-close button:hover {
  color: #333;
}

.modal-body {
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.conflict-description {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  color: #444;
  font-size: 16px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-section {
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
}

.section-header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}

.section-header i {
  margin-right: 8px;
  color: #555;
}

.section-header h4 {
  margin: 0;
  font-weight: 600;
  color: #333;
  font-size: 16px;
}

.section-content {
  padding: 16px;
}

.section-content table {
  width: 100%;
  border-collapse: collapse;
}

.section-content table tr:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.section-content table td {
  padding: 8px 4px;
}

.section-content table td:first-child {
  font-weight: 500;
  width: 40%;
  color: #555;
}

.section-content table td:last-child {
  color: #333;
}

.modal-footer {
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.primary-button {
  padding: 10px 20px;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.primary-button:hover {
  background-color: #2b6cb0;
}

.order-info .section-header {
  background-color: #ebf8ff;
}

.vehicle-info .section-header {
  background-color: #f0fff4;
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .modal-container {
    width: 95%;
    max-height: 90vh;
  }

  .info-container {
    flex-direction: column;
  }

  .info-section {
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>