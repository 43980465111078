<template>
  <modal name="combined-order-modal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '90%'"
    @opened="getAll()">
    <div class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200">
      <div class="md:flex items-center justify-center w-full mt-4">
        <div class="w-full text-left">
          <div class="w-full md:flex items-center justify-between">
            <h4 class="inline-block w-6/12">BAĞLI SİPARİŞLER</h4>
          </div>
        </div>
        <div class="flex gap-4">
          <button class="p-2 w-1/12" @click="$modal.hide('combined-order-modal')">
            <i class="fas fa-times float-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
        <div class="w-full">
          <LyrausTable :data="getList()" :columns="columns" :innerTable="innerTableColumns" :enablePagination="true"
            :isLoading="load" :alternateRowBg="['white', 'gray-100']" :rightExtraSlot="true"
            :buttonsColumn="{ label: 'İşlem' }" :paginationButtonColor="'red-600'" :paginationHoverColor="'red-700'"
            :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
            :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
            :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'">
            <template v-slot:colButtons="{ item }">
              <div class="flex items-center justify-end space-x-2 px-2">
                <button :class="{
    'opacity-30':
      item.state == 'Tamamlandı' ||
      item.state == 'Başlamadı' ||
      item.state == 'Kontrol Ediliyor' ||
      item.state == 'Son Yükte',
  }" @click="
    item.state !== 'Tamamlandı' &&
    item.state !== 'Kontrol Ediliyor' &&
    item.state !== 'Başlamadı' &&
    item.state !== 'Son Yükte' &&
    completePlanning(item)
    ">
                  <i class="fas fa-check text-gray-700"></i>
                </button>
              </div>

              <div class="flex items-center justify-end space-x-2 px-2">
                <button class="mx-2" @click="showDetailModal(item)">
                  <i class="fas fa-search text-gray-700"></i>
                </button>
              </div>
              <div class="flex items-center justify-end space-x-2 px-2">
                <button class="" @click="deleteItem(item)">
                  <i class="fas fa-trash text-gray-700"></i>
                </button>
              </div>
            </template>
            <template v-slot:batchOperations>
              <asyncBtn icon="fas fa-plus " :text="$t('operations.planning.newBtnTitle')" class="w-36 btn"
                @click="() => $modal.show('add-plan-modal')" />
            </template>
            <template v-slot:rowButtons="{ item, columnKey }">
              <button v-if="columnKey === 'vehicleCount'" @click="showVehicleList(item)">
                <div class="flex items-center justify-center border rounded-lg w-8 h-6" :class="item.rejectStatus > 0
    ? 'animate-pulse border-red-400 bg-red-400 text-gray-100'
    : 'border-gray-400'
    ">
                  {{ item.vehicleCount }}
                </div>
              </button>
            </template>
            <template v-slot:rightExtra>
              <div class="flex justify-between items-center">
                <userFilterModal ref="modalActive" :selectedItems="selectedItems" :load="load"
                  @selected-items-changed="handleSelectedItemsChanged" />
              </div>
            </template>
            <template v-slot:sortButton="{ columnKey }">
              <button v-if="columnKey === 'orderNo'" class="pr-1" @click="toggleSort(columnKey)">
                <i v-if="sortColumn !== 'orderNo'" class="fas fa-sort"></i>
                <i v-if="sortState === 1 && sortColumn === 'orderNo'" class="fas fa-sort-alpha-up"></i>
                <i v-if="sortState === 2 && sortColumn === 'orderNo'" class="fas fa-sort-alpha-down"></i>
              </button>
              <button v-if="columnKey === 'customer'" class="pr-1" @click="toggleSort(columnKey)">
                <i v-if="sortColumn !== 'customer'" class="fas fa-sort"></i>
                <i v-if="sortState === 1 && sortColumn === 'customer'" class="fas fa-sort-alpha-up"></i>
                <i v-if="sortState === 2 && sortColumn === 'customer'" class="fas fa-sort-alpha-down"></i>
              </button>
              <button v-if="columnKey === 'receivedName'" class="pr-1" @click="toggleSort(columnKey)">
                <i v-if="sortColumn !== 'receivedName'" class="fas fa-sort"></i>
                <i v-if="sortState === 1 && sortColumn === 'receivedName'" class="fas fa-sort-alpha-up"></i>
                <i v-if="sortState === 2 && sortColumn === 'receivedName'" class="fas fa-sort-alpha-down"></i>
              </button>
              <button v-if="columnKey === 'deliveredName'" class="pr-1" @click="toggleSort(columnKey)">
                <i v-if="sortColumn !== 'deliveredName'" class="fas fa-sort"></i>
                <i v-if="sortState === 1 && sortColumn === 'deliveredName'" class="fas fa-sort-alpha-up"></i>
                <i v-if="sortState === 2 && sortColumn === 'deliveredName'" class="fas fa-sort-alpha-down"></i>
              </button>
              <button v-if="columnKey === 'productType'" class="pr-1" @click="toggleSort(columnKey)">
                <i v-if="sortColumn !== 'productType'" class="fas fa-sort"></i>
                <i v-if="sortState === 1 && sortColumn === 'productType'" class="fas fa-sort-alpha-up"></i>
                <i v-if="sortState === 2 && sortColumn === 'productType'" class="fas fa-sort-alpha-down"></i>
              </button>
              <button v-if="columnKey === 'estimatedComplateDate'" class="pr-1" @click="toggleSort(columnKey)">
                <i v-if="sortColumn !== 'estimatedComplateDate'" class="fas fa-sort"></i>
                <i v-if="sortState === 1 && sortColumn === 'estimatedComplateDate'
    " class="fas fa-sort-alpha-up"></i>
                <i v-if="sortState === 2 && sortColumn === 'estimatedComplateDate'
    " class="fas fa-sort-alpha-down"></i>
              </button>
            </template>
            <template v-slot:filterButton="{ columnKey }">
              <statusPanel v-if="columnKey === 'state'" :statusList="bulkStatus" :initialStatus="filterType"
                @setStatusFilter="(val) => (filterType = val)" />
              <!-- getStatusController -->
            </template>
          </LyrausTable>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
//Npm
import { LyrausTable } from "lyraus-ui";

//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";
import userFilterModal from "@/components/filters/userFiltering/index.vue";
import statusPanel from "@/components/filters/filterTableStatus/index.vue";

//Networking
import { bulkPlanning } from "@/networking/urlmanager";
import axios from "axios";

export default {
  props: ["bulkId"],
  components: {
    asyncBtn,
    userFilterModal,
    statusPanel,
  },
  data() {
    return {
      selectedItems: [],
      List: [],
      columns: [
        {
          key: "orderNo",
          label: "#",
        },
        {
          key: "customer",
          label: "Müşteri",
          maxLength: 16,
        },
        {
          key: "receivedName",
          label: "Dolum",
          maxLength: 16,
        },
        {
          key: "deliveredName",
          label: "Teslim",
          maxLength: 16,
        },
        {
          key: "productType",
          label: "Ürün",
        },
        {
          key: "status",
          label: "Toplam / Kalan",
        },
        {
          key: "vehicleCount",
          label: "Araçlar",
          dontDisplay: true,
          center: true,
        },
        {
          key: "estimatedComplateDate",
          label: "Teslim Süresi",
          isDate: true,
        },
        {
          key: "state",
          label: "Durum",
          width: 160,
        },
      ],

      innerTableColumns: [
        { key: "orderName", label: "Kaynak" },
        { key: "fillingAdress", label: "Dolum Adresi" },
        { key: "deliveryAdress", label: "Teslim Adresi" },
        { key: "invoicedCompany", label: "Fatura Adresi" },
        { key: "estimatedComplateDate", label: "Tahmini Teslim Tarihi" },
      ],
      dblClickableColumns: [
        "customer",
        "receivedName",
        "deliveredName",
        "productType",
      ],
      load: false,
      search: "",
      sortState: 0,
      sortColumn: "",
      searchColumn: "",
      filterArr: [],
      currentPage: 0,
      selected: [],
      filterType: 5,
      bulkStatus: [
        { id: 5, name: "Hepsi" },
        { id: 0, name: "Devam Ediyor" },
        { id: 1, name: "Araç Ekleme" },
        { id: 2, name: "Araç Çıkarma" },
        { id: 3, name: "Kriz" },
        { id: -1, name: "Tamamlandı" },
      ],
      needInterval: true, // app.vue da setInterval için lazım silme
    };
  },
  methods: {
    convertTonnage(data) {
      const tonnage = Math.max(0, data.tonnage);
      const remainingTonnage = Math.max(
        0,
        data.tonnage - data.receivedTonnage
      ).toFixed(2);
      return `${tonnage} / ${remainingTonnage} TON`;
    },
    getList() {
      return (
        this.filterType === 5
          ? this.List
          : this.filterType != 0
            ? this.List.filter((r) => r.stateId == this.filterType)
            : this.List.filter((r) => r.stateId > -1)
      ).map((data) => {
        data.status = this.convertTonnage(data);

        return data;
      });
    },
    // userFilter() {
    //   this.$refs.modalActive.show();
    // },
    showDetailModal(item) {
      this.$emit("reportModal", item);
    },
    showVehicleList(item) {
      this.$emit("vehicle", item);
    },

    toggleSort(column) {
      this.currentPage = 0;
      if (this.sortColumn !== column) {
        this.sortState = 0;
      }
      this.sortColumn = column;
      // Toggle the sort state between 0, 1, and 2
      this.sortState = (this.sortState + 1) % 3;

      // Determine the filter based on the sort state
      let filter = [];
      if (this.sortState === 1) {
        filter = [{ sort: 1, column }];
      } else if (this.sortState === 2) {
        filter = [{ sort: 2, column }];
      } else {
        this.sortColumn = "";
      }

      this.filterArr = [JSON.stringify(filter)];
      this.getAll();
    },
    getAll(load = true) {
      if (load) {
        this.List = [];
        this.load = load;
      }

      const params = {
        page: this.currentPage,
        search: this.search,
        userList: this.selectedItems,
      };

      if (this.sortState) {
        params.filter = this.filterArr;
      }

      if (this.searchColumn) {
        params.column = this.searchColumn;
      }

      axios
        .get(bulkPlanning.getSubOrders, {
          params: {
            ...params,
            bulkId: this.bulkId,
          },
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(async (res) => {
          this.List = res.data.data.map((item) => {
            return {
              ...item,
              state: item.actionState[0].title,
              stateId: 0,
              action: null,
            };
          });
          if (this.selected.id) {
            this.selected = this.List.find((r) => r.id === this.selected.id);
          }
          // await this.getStatusController();
          this.load = false;
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.delete,
              {
                mainOrderId: item.mainOrderId,
                rowId: item.id.toString(),
                orderNo: item.orderNo,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    completePlanning(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Sevkiyatı bitirmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.completePlanning,
              {
                rowId: item.id,
                tonnage: item.tonnage - item.receivedTonnage,
                orderNo: item.orderNo,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    async getStatusController() {
      try {
        const result = await axios.get(bulkPlanning.getActionController, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        });

        const list = this.List.map((item) => {
          const findItem = result.data.data?.find((r) => r.id == item.id);
          if (findItem) {
            item.state = findItem.title;
            item.stateId = findItem.type;
            item.action = findItem;
          }
          return item;
        });
        this.List = list;
      } catch (error) {
        this.swalBox(
          "warning",
          "UYARI!",
          "Durum Kontrol Sistemi başlatılamadı,Lütfen daha sonra tekrar deneyiniz!"
        );
      }
    },

    handleSelectedItemsChanged(newSelectedItems) {
      this.selectedItems = newSelectedItems;
    },
  },
  watch: {
    selectedItems() {
      this.getAll();
    },
  },
};
</script>
