<template>
  <div class="bg-gray-200 dark:bg-gray-900 p-2">
    <!-- Modals -->
    <add-modal @refresh="getAll()" />
    <edit-modal @refresh="getAll()" :rowId="selectedOrder.id" />
    <product-add-modal
      :defaultName="productName"
      @refresh="getAll(true, true)"
    />

    <vehicle-modal :detail="selectedOrder" />
    <pirate-driver-detail-modal :detail="selectedOrder" />
    <reallocation-needed-modal
      :show="showReallocationModal"
      :order="selectedOrder"
      :reallocation-message="reallocationMessage"
      :planned-orders="activeOrdersWithVehicles"
      @close="showReallocationModal = false"
      @reallocate="handleReallocateVehicles"
    />
    <confirmation-modal
      :show="showConfirmationModal"
      :order="confirmationOrder"
      @close="showConfirmationModal = false"
      @confirm="confirmRemoveOrder"
    />

    <ConfirmationSaveModal
      :show="showSaveConfirmation"
      :orders="selectedList"
      :orderCount="selectedList.length"
      @confirm="handleSaveOrders"
      @close="showSaveConfirmation = false"
      :loading="saveLoad"
    />
    <pirate-driver-modal :item="pirateDriverplanProp" @refresh="getAll()" />

    <add-customer-modal
      @formSubmitted="handleCustomerFormSubmitted"
      :customerName="selectedOrder.customer"
    />

    <product-edit-modal :detail="defaultProductDataProp" />

    <!-- Context Menu -->
    <vue-context ref="menu" v-if="activeState != 2">
      <li><a href="#" @click.prevent="customPlan(1)">Kiralık Araç Öner</a></li>
      <li><a href="#" @click.prevent="customPlan(2)">Özmal Araç Öner</a></li>
      <li><a href="#" @click.prevent="customPlan(4)">Korsan ile planla</a></li>
    </vue-context>

    <div class="p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <div
        :class="
          isFullScreen && 'fixed top-0 w-full z-50 left-0 h-full bg-white'
        "
      >
        <!-- Main Content -->
        <div class="w-full mt-2">
          <!-- Header with buttons and filters -->

          <OrderHeader
            :isFullScreen="isFullScreen"
            :planningLoad="planningLoad"
            :saveLoad="saveLoad"
            :orderCount="orderCounts"
            :activeState="activeState"
            :selectedCount="selectedList.length"
            :search="search"
            :loading="loading"
            @save-orders="saveOrders"
            @change-tab="changeTab"
            @search="(val) => (search = val)"
            @refresh="() => getAll(true, true)"
            @clear-orders="() => clearOrders()"
          />

          <!-- Orders Table -->
          <OrdersTable
            :isFullScreen="isFullScreen"
            :activeState="activeState"
            :orders="displayedOrders()"
            :planningLoad="planningLoad"
            :loading="loading"
            :products="products"
            :vehicleList="vehicleList"
            :customers="customers"
            @select-order="handleOrderSelect"
            @view-order="viewOrderDetail"
            @remove-order="removeFromPlanned"
            @change-vehicle="changeVehicle"
            @change-driver="changeDriver"
            @open-context="openContextMenu"
            @handle-personel-change="
              (driver, item) => handlePersonelChange(driver, item)
            "
          />

          <!-- Pagination -->
          <div
            :class="
              !isFullScreen
                ? 'flex justify-between items-center'
                : 'absolute bottom-0 w-full bg-white flex justify-between items-center pr-2'
            "
          >
            <PaginationComponent
              v-if="!loading"
              :pageCount="pageCount"
              v-model="currentPage"
              :totalRowCount="
                activeState == 2 ? selectedList.length : orderList.length
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import components
import OrderHeader from "./components/OrderHeader.vue";
import OrdersTable from "./components/OrdersTable.vue";
import PaginationComponent from "@/components/general/pagination.vue";
import AddModal from "./modals/add.modal.vue";
import EditModal from "./modals/edit.modal.vue";

import VehicleModal from "@/components/modals/vehicle.detail.modal/index.vue";
import pirateDriverDetailModal from "@/components/modals/vehicle.detail.modal/pirateDriverModal.vue";


import ProductAddModal from "../../control-panel/products/components/add.modal.vue";
import ProductEditModal from "../../control-panel/products/components/edit.modal.vue";
import AddCustomerModal from "../../control-panel/customers/components/add.modal.vue";

import ConfirmationModal from "./modals/ConfirmationModal.vue";
import PirateDriverModal from "./modals/piratedriver.modal.vue";
import ConfirmationSaveModal from "./modals/orderSaveConfirmation.vue";
import ReallocationNeededModal from "./modals/reallocation.needed.modal.vue";

import VueContext from "vue-context";

// Import services & tools
import { fetchOrders, saveOrdersWithVehicles } from "./tools/orderService";
import { getLocationAll, getCustomers, getProducts } from "@/utils/detailData";
import {
  processOrderSelection,
  validateOrder,
  isProductValid,
  calculateCustomerPriority,
} from "./tools/orderTools/index";

import {
  calculateDistancePriority,
  isVehicleSuitableForOrderDetail,
  isWashStatus,
} from "@/utils/recomendation/index.js";
import { calculator } from "../../../utils/cost";
import moment from "moment";

export default {
  name: "orders-module",
  components: {
    OrderHeader,
    OrdersTable,
    PaginationComponent,
    AddModal,
    EditModal,
    ProductAddModal,
    VehicleModal,
    pirateDriverDetailModal,
    VueContext,
    ConfirmationModal,
    PirateDriverModal,
    ProductEditModal,
    AddCustomerModal,
    ConfirmationSaveModal,
    ReallocationNeededModal,
  },
  data() {
    return {
      planningLoad: false,
      saveLoad: false,
      currentPage: 0,
      pageCount: 1,
      totalCount: 0,
      isFullScreen: false,
      activeState: 1,
      orderList: [],
      selectedList: [],
      errorCount: 0,
      successCount: 0,
      vehicleList: [],
      inProgress: false,
      selectedVehicles: [],
      products: [],
      customers: [],
      loading: false,
      search: "",
      productName: "",
      tripTonnage: 26,
      selectedOrder: { id: "", plaque: "", vehicleId: "" },
      selectedContextItem: null,
      currentHaveType: null,
      showConfirmationModal: false,
      confirmationOrder: null,
      defaultProductDataProp: null, // Eklendi
      pirateDriverplanProp: null, // Eklendi

      showSaveConfirmation: false,

      showReallocationModal: false,
      reallocationMessage: "",
      maxDistance: 300, // varsayılan mesafe sınırı
      activeOrdersWithVehicles: [], // aktif siparişler ve araçları
    };
  },
  computed: {
    orderCounts() {
      return {
        unplanned: this.successCount - this.selectedList.length,
        planned: this.selectedList.length,
        error: this.errorCount,
      };
    },
  },
  methods: {
    saveOrders() {
      if (this.selectedList.length === 0) {
        this.$swal({
          title: "Bir Sipariş Seçin",
          text: "Lütfen en az bir sipariş seçerek devam edin. planlanmayanlar sekmesinden siparişleri işaretleyerek tekrar deneyin.",
          icon: "warning",
          confirmButtonText: "Tamam, Seçim Yap",
          showCancelButton: true, // Alternatif seçenek sunmak
          cancelButtonText: "İptal",
        });
        return;
      } else if (this.selectedList.length > 15) {
        this.$swal({
          title: "Sınırlama Aşıldı",
          text: "En fazla 15 sipariş seçebilirsiniz. Daha fazla sipariş eklemek isterseniz, mevcut seçimlerinizi gözden geçirebilirsiniz.",
          icon: "warning",
          confirmButtonText: "Tamam, Gözden Geçir",
          showCancelButton: true,
          cancelButtonText: "İptal",
        });
        return;
      }

      const invalidItems = this.selectedList.filter(
        (item) =>
          !(item.driver && item.driverTC) && // Sürücü ve TC bilgisi eksik
          !item.isPirateDriver && // Korsan sürücü değilse kontrol et
          (item.unitType === "TON" ? item.tonnage <= 30 : item.tonnage === 1) // Küçük sipariş
      );

      if (invalidItems.length) {
        const vehicles = invalidItems
          .map((item) => item.vehicleName)
          .join(", ");
        this.$swal({
          title: "Uyarı!",
          text: `Planlama yapılamaz. Aşağıdaki ${
            invalidItems.length > 1 ? "araçlar" : "araç"
          } için sürücü bilgisi veya TC kimlik bilgisi eksik: ${vehicles} Plakalı ${
            invalidItems.length > 1 ? "araçlara" : "araca"
          } Lütfen sürücü atayınız veya mevcut sürücünün TC kimlik bilgisini giriniz.`,
          icon: "warning",
        });
        return;
      }

      this.showSaveConfirmation = true;
    },
    normalizePlate(plate) {
      if (plate === null || plate === "" || plate == undefined) {
        return "";
      }
      return plate.toUpperCase().replace(/\s/g, "");
    },
    handleCustomerFormSubmitted(data) {
      const targetItem = this.selectedList.find(
        (item) => item.orderNo === this.selectedOrder.orderNo
      );
      if (targetItem) {
        targetItem.customerId = data.customerId;
        if (targetItem.recomendation) {
          targetItem.recomendation.customerPriority = data.priorty;
        }
      }
    },
    handlePersonelChange(val, item) {
      const targetItem = this.selectedList.find(
        (sItem) => sItem.orderNo == item.orderNo
      );
      if (targetItem) {
        targetItem.recomendation.srcEndTime = val.srcEndTime;
      }

      const isProductRequireSrc =
        this.products.find((p) => p.id == item.productId).typeId == 2;
      let warningMessage = [];
      if (isProductRequireSrc) {
        if (val.srcEndTime == null) {
          warningMessage.push("Sürücünün SRC-5 Belgesi bilgisi bulunmuyor.");
        }
        if (
          moment(val.srcEndTime).diff(moment(), "days") <= 0 ||
          moment(val.srcEndTime).diff(moment(item?.deliveryDate), "days") <= 0
        ) {
          warningMessage.push("Sürücünün SRC-5 belgesi süresi dolmuş.");
        }
        warningMessage.length > 0 &&
          this.$swal.fire({
            icon: "warning",
            title: "UYARI !",
            showConfirmButton: true,
            width: "max-content",
            html: `
                <div>
                  ${warningMessage.map(
                    (m) =>
                      `<p style='width: max-content;  margin:auto; margin-top:4px;'>${m}</p>`
                  )}
                </div>
          `,
          });
      }
    },
    customPlan(haveType) {
      this.currentHaveType = haveType;
      // Seçilen siparişin zaten planlanmış olup olmadığını kontrol et
      const isAlreadyPlanned = this.activeState === 2;

      // Eğer sipariş halihazırda planlanmışsa, önce onu kaldır
      if (isAlreadyPlanned) {
        // Mevcut planlanmış siparişi bul
        const existingOrderIndex = this.selectedList.findIndex(
          (item) =>
            parseInt(item.orderNo) ===
            parseInt(this.selectedContextItem.orderNo)
        );

        if (existingOrderIndex !== -1) {
          // Eski planlamayı kaldır
          this.selectedList.splice(existingOrderIndex, 1);
        }
      }

      // Korsan sürücü özel durumu
      if (haveType === 4) {
        // Korsan sürücü modalı için sipariş ata
        this.pirateDriverplanProp = this.selectedContextItem;

        // Root event listener ekle - korsan sürücü planı oluşturulduğunda çağrılacak
        this.$root.$once("add-pirate-driver-plan", (pirateDriverPlan) => {
          // Korsan sürücü planını selectedList'e ekle
          this.selectedList.push(pirateDriverPlan);
        });

        this.$modal.show("pirate-driver-modal");
        return;
      }

      // Diğer durumlarda normal akış devam etsin
      this.handleOrderSelect(this.selectedContextItem, haveType);
    },
    displayedOrders() {
      // Memoize the filtered results based on key inputs
      // This ensures the filter only runs when these values change
      const activeState = this.activeState;
      const searchTerm = this.search.toLowerCase().trim();
      const selectedOrderNos = this.selectedList.map((order) =>
        parseInt(order.orderNo)
      );

      if (activeState === 2) {
        // For planned orders, filter by search
        if (!searchTerm) return this.selectedList;

        return this.selectedList.filter((order) => {
          // Use some simple checks first (cheaper operations)
          const orderNo = order.orderNo.toString();
          if (orderNo.includes(searchTerm)) return true;

          // Only do more expensive checks if the simple ones fail
          return (
            order.customer.toLowerCase().includes(searchTerm) ||
            order.deliveredName.toLowerCase().includes(searchTerm) ||
            order.invoicedCompany.toLowerCase().includes(searchTerm) ||
            order.orderName.toLowerCase().includes(searchTerm) ||
            order.receivedName.toLowerCase().includes(searchTerm) ||
            order.tonnage.toString().includes(searchTerm) ||
            order.outpointAdress.toLowerCase().includes(searchTerm) ||
            order.productType.toLowerCase().includes(searchTerm)
          );
        });
      } else {
        // For other tabs, filter by selection status
        // Use Set for O(1) lookups instead of Array.some() which is O(n)
        const selectedOrderSet = new Set(selectedOrderNos);
        return this.orderList.filter(
          (order) => !selectedOrderSet.has(parseInt(order.orderNo))
        );
      }
    },
    async getAll(showLoading = true, resetProducts = false) {
      this.loading = showLoading;
      try {
        // Eğer resetProducts true ise, her durumda önbelleği temizle ve yeni veri yükle
        if (resetProducts === true) {
          this.orderList = [];
          this.$store.commit("orders/clearPage");
        } else {
          // resetProducts false ise, önbellekten veri çekmeyi dene
          const cachedOrders = this.$store.state.orders.List;
          if (cachedOrders.length > 0) {
            const detail = cachedOrders.find(
              (r) =>
                r.tab == this.activeState &&
                r.page == this.currentPage &&
                r.filterCenter == this.$store.state.orders.activeFilter
            );

            if (detail) {
              const { data } = detail;
              this.pageCount = data.pageCount;
              this.totalCount = data.totalCount;
              this.errorCount = data.errorCount;
              this.successCount = data.successCount;
              this.orderList = data.orderList;
              this.loading = false;
              return;
            }
          }
        }

        // Önbellekte veri bulunamazsa veya resetProducts=true ise yeni veri yükle
        const pageIndex = Math.max(0, this.currentPage - 1); // Negatif sayfa indeksi olmadığından emin ol
        const response = await fetchOrders(
          this.$store.state.userData.token,
          this.search,
          pageIndex,
          this.activeState === 3 ? 0 : 1,
          this.$store.state.orders.activeFilter
        );

        this.pageCount = response.pageCount;
        this.totalCount = response.totalCount;
        this.errorCount = response.errorCount;
        this.successCount = response.successCount;
        this.orderList = response.detail.map((item) => ({
          ...item,
          isCustom: 2,
          driverTC: "",
        }));

        this.$store.commit("orders/setFilterList", response.filterList);

        // Önbelleğe yeni verileri kaydet
        this.$store.commit("orders/addOrder", {
          tab: this.activeState,
          page: this.currentPage,
          filterCenter: this.$store.state.orders.activeFilter,
          data: {
            pageCount: this.pageCount,
            totalCount: this.totalCount,
            errorCount: this.errorCount,
            successCount: this.successCount,
            orderList: this.orderList,
          },
        });

        // Ürünleri yenileme işlemi
        if (resetProducts) {
          this.products = await getProducts(this.$store.state.userData.token);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        this.loading = false;
      }
    },
    async handleSaveOrders() {
      this.saveLoad = true;

      try {
        await saveOrdersWithVehicles(
          this.$store.state.userData.token,
          this.selectedList
        );

        this.showSaveConfirmation = false;
        this.saveLoad = false;
        this.selectedList = [];
        this.activeState = 1;
        this.$store.commit("orders/clearPage");
        await this.getAll();
      } catch (error) {
        this.$swal({
          title: "UYARI",
          text: error?.response?.data?.message || "İşlem gerçekleştirilemedi!",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      } finally {
        this.showSaveConfirmation = false;
        this.saveLoad = false;
      }
    },
    async handleOrderSelect(order, haveType = null) {
      if (this.inProgress) return;

      if (this.selectedList.length == 15) {
        this.$swal({
          title: "Sınırlama Aşıldı",
          text: "En fazla 15 sipariş seçebilirsiniz. Daha fazla sipariş eklemek isterseniz, mevcut seçimlerinizi gözden geçirebilirsiniz.",
          icon: "warning",
          confirmButtonText: "Tamam, Gözden Geçir",
          showCancelButton: true,
          cancelButtonText: "İptal",
        }).then((acc) => {
          if (acc.isConfirmed) this.activeState = 2;
        });
        return;
      }

      this.inProgress = true;
      this.currentHaveType = null;

      const isAlreadySelected = this.selectedList.some(
        (item) => parseInt(item.orderNo) === parseInt(order.orderNo)
      );

      if (isAlreadySelected) {
        this.removeFromPlanned(order);
        this.inProgress = false;
        return;
      }

      // eğer korsan atama seçildiyse
      if (haveType === 4) {
        this.pirateDriverplanProp = order;
        this.$modal.show("pirate-driver-modal");
        this.inProgress = false;
        return;
      }

      // Process the order selection through a dedicated service
      try {
        const processedOrder = await processOrderSelection(
          order,
          this.vehicleList,
          this.products,
          this.customers,
          this.selectedVehicles,
          haveType,
          this.$store,
          this.$swal
        );

        if (processedOrder && processedOrder.REALLOCATION_NEEDED) {

          console.log(processedOrder)
          // Uzak mesafedeki sipariş için araç çekme modalını göster
          this.reallocationMessage =
            processedOrder.message ||
            `${processedOrder.maxDistance} km mesafede uygun araç bulunamadı. Aktif siparişlerden araç çekilebilir.`;
          this.maxDistance = processedOrder.maxDistance || 300;

          // Aktif siparişleri ve araçları getir
          this.activeOrdersWithVehicles=processedOrder.vehicles;

          // Seçilen siparişi ve modalı ayarla
          this.selectedOrder = order;
          this.showReallocationModal = true;
          this.$modal.show("reallocation-needed-modal");
        } else if (processedOrder?.needCustomerAdd) {
          this.selectedOrder = order;
          this.$modal.show("add-customer-modal");
        } else {
          if (processedOrder) {
            this.selectedList.push({ ...processedOrder, haveType });
          }
        }
      } catch (error) {
        console.error("Error processing order selection:", error);
      } finally {
        this.inProgress = false;
      }
    },


    // 7. Belirli bir araçla özel planlama yapan yeni metot

    /**
     * Belirli bir araçla manuel planlama yapar
     * @param {Object} order - Sipariş
     * @param {Object} vehicle - Araç
     */
    async customPlanWithVehicle(order, vehicle) {
      // Araç bilgisini kullarak sipariş oluşturma
      const distance = this.calculateDistance(order, vehicle);
      const cost = await calculator(
        this.$store.state.userData.token,
        1,
        1,
        vehicle,
        order,
        distance,
        0,
        true,
        true,
        1,
        distance / 50
      );

      // Müşteri önceliğini hesapla
      const customerPriority =
        this.customers.find((c) => c.name === order.customer)?.priority || 1;

      // Yeni sipariş objesi oluştur
      const newPlannedOrder = {
        ...order,
        vehicleName: vehicle.vehicle || vehicle.plate,
        washCost: cost.totalWashPriceCost,
        washPointName: cost.washPointName,
        recomendationTotalCost: Number(cost.total),
        allCost: Number(cost.total) + Number(cost.totalWashPriceCost),
        vehicle: {
          id: vehicle.id,
          name: vehicle.vehicle || vehicle.plate,
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
        },
        orderAmount: order.tonnage,
        recomendation: {
          vehicleInspectionEndDate: vehicle.vehicleInspectionEndDate,
          inspectionEndDate: vehicle.inspectionEndDate,
          vehicleAdrEndDate: vehicle.vehicleAdrEndDate,
          dorseAdrEndDate: vehicle.dorseAdrEndDate,
          srcEndTime: vehicle.srcEndTime,
          name: vehicle.vehicle || vehicle.plate,
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
          customerPriority,
          capacity: vehicle.capacity / 1000,
          isWashing: isWashStatus(this.customers, this.products, vehicle, order)
            .status,
          isLiveAssignment: true, // Canlı atama bayrağı
        },
        isMultiple: false,
        driver: vehicle.driverName || "",
        dorse: vehicle.dorse || "",
        distance: distance || 0,
      };

      // Planlanmış siparişler listesine ekle
      this.selectedList.push(newPlannedOrder);

      // Araç kullanım durumunu güncelle
      if (!vehicle.orderNos) {
        vehicle.orderNos = [];
      }
      if (!vehicle.orderNos.includes(order.orderNo)) {
        vehicle.orderNos.push(order.orderNo);
      }
    },

    /**
     * Seçilen araçları hedef siparişe yeniden atar
     * @param {Object} param0 - Yeniden atama parametreleri
     */
    handleReallocateVehicles({ orderNo, selectedVehicles }) {
      // Seçilen araçların plakalarını ve etkileşimde bulunacak siparişleri bul
      const vehiclePlates = new Set();
      const affectedOrders = new Set();

      // Seçilen her araç ID'si için
      selectedVehicles.forEach((vehicleId) => {
        // Bu aracın hangi siparişe ait olduğunu bul
        this.activeOrdersWithVehicles.forEach((order) => {
          const vehicle = order.vehicles.find((v) => v.id === vehicleId);
          if (vehicle) {
            vehiclePlates.add(vehicle.plate);
            affectedOrders.add(order.orderNo);
          }
        });
      });

      // Etkilenen siparişleri planlama listesinden kaldır
      affectedOrders.forEach((ordNo) => {
        const index = this.selectedList.findIndex(
          (item) => item.orderNo === ordNo
        );
        if (index !== -1) {
          this.selectedList.splice(index, 1);
        }
      });

      // Hedef siparişi bul
      const targetOrder = this.orderList.find((o) => o.orderNo === orderNo);
      if (!targetOrder) return;

      // Modal'ı kapat
      this.$modal.hide("reallocation-needed-modal");
      this.showReallocationModal = false;

      // Seçilen her araç için işlem yap
      vehiclePlates.forEach((plate) => {
        // Vehicle list içinde bu plakaya sahip aracı bul
        const vehicle = this.vehicleList.find(
          (v) => (v.vehicle || v.plate) === plate
        );

        if (vehicle) {
          // İşlem başarılı mesajı
          this.$swal.fire({
            icon: "success",
            title: "Araç Yeniden Atandı",
            text: `${plate} plakalı araç ${orderNo} numaralı siparişe başarıyla atandı.`,
            confirmButtonText: "Tamam",
          });

          // Seçilen araçla manuel olarak sipariş işleme
          this.customPlanWithVehicle(targetOrder, vehicle);
        }
      });
    },

    removeFromPlanned(order) {
      // Onay modalını göstermek için hazırlık
      this.confirmationOrder = order;
      this.showConfirmationModal = true;
    },
    confirmRemoveOrder() {
      if (!this.confirmationOrder) return;

      const order = this.confirmationOrder;
      const index = this.selectedList.findIndex(
        (item) => parseInt(item.orderNo) === parseInt(order.orderNo)
      );

      if (index !== -1) {
        this.selectedList.splice(index, 1);

        // Update vehicle order references
        this.vehicleList.forEach((vehicle) => {
          if (
            !this.selectedList.some((item) => item.vehicle.id === vehicle.id)
          ) {
            // Check if orderNos exists before trying to access pop()
            if (vehicle.orderNos && Array.isArray(vehicle.orderNos)) {
              vehicle.orderNos.pop();
            }
          }
        });
      }

      // Modal'ı kapat
      this.showConfirmationModal = false;
      this.confirmationOrder = null;
    },
    openContextMenu(event, item) {
      if (item.tonnage < 30 && this.activeState != 2) {
        this.selectedContextItem = item;
        this.$refs.menu.open(event);
      }
    },
    viewOrderDetail(order) {
      this.selectedOrder = order;
      if(order.driverTC.includes('KORSAN')){
        this.$modal.show("pirate-vehicle-detail-modal");
      }else{
        this.$modal.show("vehicle-modal");
      }
    },
    async changeVehicle(val, item) {
      let vehicle = this.vehicleList.find(
        (vehicle) =>
          this.normalizePlate(vehicle.plate) == this.normalizePlate(val)
      );
      const isRent = vehicle.haveType ? vehicle.haveType : vehicle.isRent;

      const distancePriority = calculateDistancePriority(item, vehicle);
      const distance = parseFloat(distancePriority).toFixed(2);
      // Vehicle with Wash Status
      vehicle = {
        ...vehicle,
        recomendation: {
          isWashing: isWashStatus(this.customers, this.products, vehicle, item)
            .status,
        },
      };

      const cost = await calculator(
        this.$store.state.userData.token,
        1,
        1,
        vehicle,
        item,
        distance,
        0,
        true,
        true,
        1,
        distance / 50
      );

      if (item.vehicle.name != vehicle.vehicle) {
        const oldVehicleIndex = this.vehicleList.findIndex(
          (vehicle) => vehicle.plate == item.vehicle.name
        );
        this.vehicleList[oldVehicleIndex].orderNos = [];
        item.vehicle = {
          id: vehicle.id,
          name: vehicle.vehicle,
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
        };
        item.isRent = isRent;
        item.isCustom = 1;
        item.vehicleName = vehicle.vehicle;
        item.orderAmount = item.tonnage;
        item.washPointName = cost.washPointName;
        item.recomendationTotalCost = Number(cost.total);
        item.washCost = Number(cost.totalWashPriceCost);
        item.allCost = Number(cost.total) + Number(cost.totalWashPriceCost);
        item.recomendation = {
          polygonName: vehicle.polygonName,
          polygonScore: vehicle.polygonScore,
          vehicleInspectionEndDate: vehicle.vehicleInspectionEndDate,
          inspectionEndDate: vehicle.inspectionEndDate,
          vehicleAdrEndDate: vehicle.vehicleAdrEndDate,
          dorseAdrEndDate: vehicle.dorseAdrEndDate,
          srcEndTime: vehicle.srcEndTime,
          name: vehicle.vehicle,
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
          customerPriority: calculateCustomerPriority(item, this.customers),
          distancePriority: calculateDistancePriority(
            { startLat: item.outpointLat, startLng: item.outpointLng },
            vehicle
          ).toFixed(2),
          ...isVehicleSuitableForOrderDetail(
            item,
            vehicle,
            this.products,
            false,
            false
          ),
          capacity: (vehicle.capacity / 1000).toFixed(2),
          isWashing: isWashStatus(this.customers, this.products, vehicle, item)
            .status,
        };
      }
    },
    clearOrders() {
      this.$swal
        .fire({
          title: "UYARI",
          text: "Planlanan siparişleri temizlemek istediğinize emin misiniz?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Evet",
        })
        .then((acc) => {
          if (acc.isConfirmed) this.selectedList = [];
        });
    },

    changeTab(tabIndex, resetPagination = true) {
      if (this.activeState != tabIndex) {
        // Sekme değiştiğinde arama metnini sıfırla
        this.search = "";

        // // Eğer planlanmış siparişler sekmesi değilse, selectedList'i temizle
        // if (tabIndex !== 2) {
        //   this.selectedList = [];
        // }

        // Pagination'ı sıfırla
        if (resetPagination) {
          this.currentPage = 0;
        }

        // Aktif sekme durumunu güncelle
        this.activeState = tabIndex;

        // Tab 2 için pageCount hesaplaması
        if (tabIndex === 2) {
          const itemsPerPage = 40;
          this.pageCount = Math.ceil(this.selectedList.length / itemsPerPage);
          if (this.pageCount === 0) {
            this.pageCount = 1;
          }
        } else {
          // Diğer sekmeler için veriyi yeniden yükle
          this.orderList = [];
          this.getAll(true, false);
        }
      }
    },

    changeDriver(driver, item) {
      item.driver = driver;
    },
    handlePageChange(newPage) {
      if (this.currentPage !== newPage) {
        this.currentPage = newPage;

        if (this.activeState !== 2) {
          this.orderList = [];
          this.getAll(true, true);
        }
      }
    },
    filterBySearch(list) {
      if (!this.search) return list;

      const searchTerm = this.search.toLowerCase().trim();
      return list.filter((order) => {
        return (
          order.orderNo.toString().toLowerCase().includes(searchTerm) ||
          order.customer.toLowerCase().includes(searchTerm) ||
          order.deliveredName.toLowerCase().includes(searchTerm) ||
          order.invoicedCompany.toLowerCase().includes(searchTerm) ||
          order.orderName.toLowerCase().includes(searchTerm) ||
          order.receivedName.toLowerCase().includes(searchTerm) ||
          order.tonnage.toString().toLowerCase().includes(searchTerm) ||
          order.outpointAdress.toLowerCase().includes(searchTerm) ||
          order.productType.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  async created() {
    try {
      // Initialize data
      this.vehicleList = await getLocationAll(this.$store.state.userData.token);

      // Ensure orderNos array is initialized for each vehicle
      this.vehicleList = this.vehicleList.map((vehicle) => ({
        ...vehicle,
        orderNos: vehicle.orderNos || [],
      }));

      this.customers = await getCustomers(this.$store.state.userData.token);
      this.products = await getProducts(this.$store.state.userData.token);

      this.getAll();
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  },
  watch: {
    // '$store.state.orders.activeFilter'(){
    //   this.orderList=[];
    //   this.getAll();
    // },
    search() {
      this.getAll(true, true);
    },
    activeState(val) {
      if (val !== 2) this.getAll();
    },
    currentPage() {
      this.orderList = [];
      this.getAll();
    },
    selectedList(val) {
      if (val.length == 0) this.activeState = 1;

      this.selectedVehicles = val.map((item) => ({ plate: item.vehicleName }));
    },
  },
};
</script>

<style>
@import "~vue-context/dist/css/vue-context.css";
</style>
