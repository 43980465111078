<template>
  <div>
    <!-- Date selection fields -->
    <div class="md:flex mt-4">
      <div class="w-full md:w-6/12">
        <diffInput
          type="datetime-local"
          :min="getMinFillingDate"
          v-model="fillingDateModel"
          :max="deliveryDate"
          :required="true"
          :disabled="isEdit"
          :title="$t('operations.planning.modal.fillingTime')"
        />
      </div>
      <div class="w-full md:w-6/12 md:pl-2">
        <diffInput
          :min="fillingDate"
          type="datetime-local"
          v-model="deliveryDateModel"
          :required="true"
          :disabled="true"
          :title="$t('operations.planning.modal.deliveryTime')"
        />
      </div>
    </div>

    <!-- Waypoints list component -->
    <waypoints
      ref="waypointComponent"
      :minDate="fillingDate"
      :maxDate="deliveryDate"
      v-model="waypointsListModel"
      :maxTonnage="maxTonnage"
      @update:waypoints="updateWaypoints"
      :isEdit="isEdit"
    />

    <!-- Map and route visualization -->  
    <div class="w-full mt-4">
      <div
        v-if="!canShowRoute"
        class="route-error-container"
      >
        <i class="fas fa-map-marker-alt text-red-500 text-3xl"></i>
        <p class="text-gray-500 dark:text-gray-400 text-center mt-2">
          {{ getRouteErrorMessage }}
        </p>
        <button 
        type="button"
          v-if="canRetry"
          @click="retryRoute"
          class="retry-button"
        >
          Tekrar Dene
        </button>
      </div>
      <routeViewiver
        v-else
        ref="routerView"
        :vehicle="vehicle"
        :customer="customer"
        :outpoint="outpoint"
        :center.sync="mapCenter"
        :startPosition="startPosition"
        :endPosition="endPosition"
        :height="'250px'"
        :waypointsList="waypointsList"
        @changeStartPosition="handleStartPositionChange"
        @changeEndPosition="handleEndPositionChange"
        @changeOutpointId="handleOutpointIdChange"
        @changeCustomerId="handleCustomerIdChange"
        @changePolyline="handlePolylineChange"
        @change="handleRouteChange"
      />
    </div>
  </div>
</template>
  
  <script>
import diffInput from "@/components/general/diffInput.vue";
import routeViewiver from "@/components/devItem/route-generator/index.vue";
import waypoints from "./waypoints.vue";
import moment from "moment";

export default {
  name: "LocationFields",
  components: {
    diffInput,
    routeViewiver,
    waypoints,
  },
  props: {
    fillingDate: {
      type: String,
      default: "",
    },
    deliveryDate: {
      type: String,
      default: "",
    },
    fillingPoint: {
      type: Object,
      default: () => ({ lat: 0, lng: 0, adress: "" }),
    },
    deliveryPoint: {
      type: Object,
      default: () => ({ lat: 0, lng: 0, adress: "" }),
    },
    waypointsList: {
      type: Array,
      default: () => [],
    },
    vehicle: {
      type: [String, Object],
      default: "",
    },
    maxTonnage: {
      type: [String, Number],
      default: 0,
    },
    customer: {
      type: String,
      default: "",
    },
    outpoint: {
      type: String,
      default: "",
    },
    orderDate: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLocationDetails: false,
      routeStatus: "pending",
      routeError: null,
      selectedWaypoints: [],
    };
  },
  computed: {
    getRouteErrorMessage() {
      if (!this.order) {
        return "Lütfen önce bir sipariş seçin.";
      }
      if (!this.fillingPoint.adress || !this.deliveryPoint.adress) {
        return "Seçili siparişin dolum ve teslim adreslerini kontrol edin.";
      }
      return "Rota üretilemedi, tekrar deneyin.";
    },
    canRetry() {
      return this.order && this.fillingPoint.adress && this.deliveryPoint.adress;
    },
    fillingDateModel: {
      get() {
        return this.fillingDate;
      },
      set(value) {
        this.$emit("update:fillingDate", value);
      },
    },
    deliveryDateModel: {
      get() {
        return this.deliveryDate;
      },
      set(value) {
        this.$emit("update:deliveryDate", value);
      },
    },
    waypointsListModel: {
      get() {
        return this.waypointsList;
      },
      set(value) {
        this.$emit("update:waypointsList", value);
        this.selectedWaypoints = [...value];
      },
    },
    canShowRoute() {
      return (
        this.fillingPoint.lat > 0 &&
        this.fillingPoint.lng > 0 &&
        this.deliveryPoint.lat > 0 &&
        this.deliveryPoint.lng > 0
      );
    },
    getMinFillingDate() {
      return this.orderDate
        ? moment(this.orderDate).format("YYYY-MM-DD HH:mm")
        : moment().format("YYYY-MM-DD HH:mm");
    },
    // Computed map properties for cleaner template
    mapCenter() {
      return {
        lat: Number(this.fillingPoint.lat),
        lng: Number(this.fillingPoint.lng),
      };
    },
    startPosition() {
      return {
        adress: this.fillingPoint.adress,
        lat: Number(this.fillingPoint.lat),
        lng: Number(this.fillingPoint.lng),
      };
    },
    endPosition() {
      return {
        adress: this.deliveryPoint.adress,
        lat: Number(this.deliveryPoint.lat),
        lng: Number(this.deliveryPoint.lng),
      };
    },
  },
  methods: {
    handleStartPositionChange(val) {
      this.$emit("changeStartPosition", val);
      this.updateRoute();
    },

    handleEndPositionChange(val) {
      this.$emit("changeEndPosition", val);
      this.updateRoute();
    },

    handleOutpointIdChange(val) {
      this.$emit("changeOutpointId", val);
    },

    handleCustomerIdChange(val) {
      this.$emit("changeCustomerId", val);
    },

    handlePolylineChange(val) {
      this.$emit("changePolyline", val);
      // Set route status to success when polyline is updated
      this.routeStatus = "success";
    },

    handleRouteChange(val) {
      this.$emit("change", val);
      // Show location details after route calculation
      this.showLocationDetails = true;
    },

    // Update waypoints when they change
    updateWaypoints(waypoints) {
      this.selectedWaypoints = [...waypoints];
      this.updateRoute();
    },

    // Trigger route calculation
    updateRoute() {
      if (this.canShowRoute && this.$refs.routerView) {
        this.routeStatus = "loading";
        this.$nextTick(() => {
          try {
            this.$refs.routerView.getRoute();
          } catch (error) {
            console.error("Error updating route:", error);
            this.routeStatus = "error";
            this.routeError = error.message;
          }
        });
      }
    },

    // Calculate estimated travel time between points
    calculateEstimatedTime() {
      if (!this.canShowRoute) return null;

      const distance = this.calculateDistance(
        { lat: this.fillingPoint.lat, lng: this.fillingPoint.lng },
        { lat: this.deliveryPoint.lat, lng: this.deliveryPoint.lng }
      );

      // Estimate: 45km/h average speed + 20min buffer
      const minutesTotal = Math.round((distance / 45) * 60 + 20);
      const hours = Math.floor(minutesTotal / 60);
      const minutes = minutesTotal % 60;

      return { hours, minutes, minutesTotal };
    },

    // Calculate direct distance between two points (haversine)
    calculateDistance(point1, point2) {
      // This assumes there's a haversineDistance function in the app
      // If not available, implement a simple version
      if (typeof this.$haversineDistance === "function") {
        return this.$haversineDistance(point1, point2);
      }

      // Simple version (placeholder)
      const lat1 = parseFloat(point1.lat);
      const lng1 = parseFloat(point1.lng);
      const lat2 = parseFloat(point2.lat);
      const lng2 = parseFloat(point2.lng);

      // Direct distance calculation logic would go here
      // For now, return a rough estimate based on coordinates
      const latDiff = Math.abs(lat1 - lat2);
      const lngDiff = Math.abs(lng1 - lng2);

      return Math.sqrt(latDiff * latDiff + lngDiff * lngDiff) * 111; // 111km per degree
    },

    // Toggle location details visibility
    toggleLocationDetails() {
      this.showLocationDetails = !this.showLocationDetails;
    },
  },
  watch: {
    // Watch for location changes and update route
    fillingPoint: {
      handler() {
        this.updateRoute();
      },
      deep: true,
    },

    deliveryPoint: {
      handler() {
        this.updateRoute();
      },
      deep: true,
    },

    // When locations are valid, ensure route is drawn
    canShowRoute(newValue) {
      if (newValue && this.routeStatus !== "success") {
        this.updateRoute();
      }
    },
  },
  mounted() {
    // Initial route check
    if (this.canShowRoute) {
      this.$nextTick(() => {
        this.updateRoute();
      });
    }
  },
};
</script>
  
  <style scoped>
.route-status-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
}

.loading {
  background-color: rgba(59, 130, 246, 0.8);
  color: white;
}

.error {
  background-color: rgba(239, 68, 68, 0.8);
  color: white;
}

.success {
  background-color: rgba(34, 197, 94, 0.8);
  color: white;
}
.route-error-container {
  background-color: #fee2e2;
  color: #b91c1c;
  border: 1px solid #f87171;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
}

.retry-button {
  margin-top: 8px;
  background-color: #f87171;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.retry-button:hover {
  background-color: #ef4444;
}
</style>