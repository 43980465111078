<template>
  <diffInput
    :disabled="disabled"
    :loading="load"
    type="select"
    :title="title ? '' : 'Planlamalar'"
    label="filterName"
    :optList="List"
    v-model="selected"
    :lightDark="lightDark"
    :hideContainer="hideContainer"
  />
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";

// Networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";
import { calculateDistancePriority } from "@/utils/recomendation/index";

export default {
  name: "order-input",
  props: [
    "value",
    "title",
    "lightDark",
    "isBulk",
    "hideContainer",
    "fillingAndDelivery",
    "disabled",
  ],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      bulkList: [],
      selected: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(
          bulkPlanning.getNotPaginationAll +
            "?multiple=" +
            (this.isBulk ? "1" : "2"),
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          this.List = result.data.data
            .filter((item) => item.receivedTonnage < item.tonnage)
            .filter(x=>x.actionStateId!=9)//Statusu Tamamlandı olan siparişler listede olmasın.
            .map((item) => {
              return {
                ...item,
                filterName: item.orderNo + " / " + item.orderName,
              };
            });
          this.bulkList = this.List;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    filterAndSortList(val, distanceThreshold) {
      return this.bulkList
        .reduce((acc, r) => {
          const fillingDistance = calculateDistancePriority(
            {
              startLat: val.fillingPoint.lat,
              startLng: val.fillingPoint.lng,
            },
            {
              latitude: r.deliveryLatitude,
              longitude: r.deliveryLongitude,
            }
          );

          const deliveryDistance = calculateDistancePriority(
            {
              startLat: val.deliveryPoint.lat,
              startLng: val.deliveryPoint.lng,
            },
            {
              latitude: r.fillingLatitude,
              longitude: r.fillingLongitude,
            }
          );

          const isFillingMatch = fillingDistance < distanceThreshold;
          const isDeliveryMatch = deliveryDistance < distanceThreshold;

          if (isFillingMatch || isDeliveryMatch) {
            r.fillingDistance = fillingDistance;
            r.deliveryDistance = deliveryDistance;
            r.totalDistance = fillingDistance + deliveryDistance;
            r.matchType =
              isFillingMatch && isDeliveryMatch
                ? "both"
                : isFillingMatch
                ? "filling"
                : "delivery";
            acc.push(r);
          }
          return acc;
        }, [])
        .sort((a, b) => {
          // Önce eşleşme tipine göre sırala
          if (a.matchType !== b.matchType) {
            return a.matchType === "both" ? -1 : b.matchType === "both" ? 1 : 0;
          }
          // Sonra toplam mesafeye göre sırala
          return a.totalDistance - b.totalDistance;
        });
    },
  },
  created() {
    this.selected = this.value;
    this.getAll();
  },
  watch: {
    fillingAndDelivery(val) {
      this.load = true;
      const distanceThreshold = 200;
      this.List = this.filterAndSortList(val, distanceThreshold);
      this.$emit("resetSelect", true);
      this.load = false;
    },
    selected(val) {
      this.$emit("change", val);

      const findItem = this.List.find((item) => item.id == val);

      if (findItem) {
        this.$emit("changeObject", findItem);
        this.$emit("updateFlag", true);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
