var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.isVisible)?_c('div',{staticClass:"fixed bottom-4 right-4 z-50 max-w-sm"},[_c('div',{class:[
        'rounded-lg shadow-lg p-4 flex items-center gap-3 transition-all duration-300',
        _vm.connectionQuality === 'offline'
          ? 'bg-red-600 text-white'
          : 'bg-yellow-400 text-yellow-900',
      ]},[_c('div',{class:{ 'pulse-animation': true }},[(_vm.connectionQuality === 'offline')?_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"}})]):_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"}})])]),_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.connectionQuality === "offline" ? "İnternet bağlantısı yok!" : "Zayıf internet bağlantısı!")+" ")]),_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.connectionQuality === "offline" ? "Lütfen internet bağlantınızı kontrol edin." : "Bazı özellikler düzgün çalışmayabilir.")+" ")])]),_c('button',{class:[
          'p-1 rounded-full',
          _vm.connectionQuality === 'offline'
            ? 'hover:bg-red-500'
            : 'hover:bg-amber-200',
        ],on:{"click":_vm.handleDismiss}},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }