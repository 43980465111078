<template>
  <thead
    class="bg-white border-black border-dotted border-b-2 border-opacity-20"
  >
    <tr>
      <th v-if="isPlanning" scope="col" class="table-th text-center">
        <input v-if="false" disabled type="checkbox" />
      </th>
      <th
        scope="col"
        class="table-th text-left border-r border-opacity-20"
        style="min-width: 120px !important"
      >
        #
      </th>
      <th
        scope="col"
        class="table-th text-left border-r border-opacity-20"
        style="min-width: 215px !important"
      >
        Dolum
      </th>
      <th
        scope="col"
        class="table-th text-left border-r border-opacity-20"
        style="min-width: 218px !important"
      >
        Teslim
      </th>
      <th
        scope="col"
        class="table-th text-left border-r border-opacity-20"
        style="min-width: 180px !important"
      >
        Müşteri
      </th>
      <th
        scope="col"
        class="table-th text-left border-r border-opacity-20"
        style="min-width: 110px !important; z-index: 99 !important"
      >
        Ürün
        <i class="fas fa-arrow-down"></i>
      </th>
      <th
        scope="col"
        class="table-th text-left border-r border-opacity-20 z-30"
        style="min-width: 70px !important"
      >
        Miktar
        <TableResizeBtn v-model="isFullScreen" v-if="activeState == 3" />
      </th>

      <!-- Columns only visible in planned orders (activeState === 2) -->
      <th
        v-if="activeState === 2"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 120px !important"
      >
        Yıkama Noktası
      </th>
      <th
        v-if="activeState === 2"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 120px !important"
      >
        Yıkama Maliyeti
      </th>
      <th
        v-if="activeState === 2"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 120px !important"
      >
        Sefer Maliyeti
      </th>
      <th
        v-if="activeState === 2"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 120px !important"
      >
        Toplam Maliyet
      </th>

      <th
        v-if="activeState < 3"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 200px !important"
      >
        Araç
        <TableResizeBtn v-model="isFullScreen" v-if="activeState == 1" />
      </th>
      <th
        v-if="activeState === 2"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 200px !important"
      >
        Sürücü
      </th>
      <th
        v-if="activeState === 2"
        scope="col"
        class="table-th text-left z-20 border-r border-opacity-20"
        style="min-width: 100px !important"
      >
        #
        <TableResizeBtn v-model="isFullScreen" v-if="activeState === 2" />
      </th>
    </tr>
  </thead>
</template>
  
  <script>
import TableResizeBtn from "@/components/general/table.resize.btn.vue";

export default {
  name: "TableHeader",
  components: {
    TableResizeBtn,
  },
  props: {
    activeState: {
      type: Number,
      required: true,
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>