<!-- components/VehicleDetailsPanel.vue -->
<template>
  <div
    class="absolute top-20 right-5 w-80 bg-white rounded-xl shadow-lg overflow-hidden z-50 transition-all duration-300"
  >
    <!-- Panel Başlığı -->
    <div
      class="bg-red-600 text-white px-4 py-3 flex justify-between items-center"
    >
      <h3 class="font-semibold text-base flex items-center">
        <i class="fas fa-truck-moving mr-2"></i>
        Araç Detayları
      </h3>
      <button
        class="text-white hover:text-gray-200 transition-colors"
        @click="$emit('close')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Panel İçeriği -->
    <div class="p-4">
      <!-- Araç ve Sürücü Bilgileri -->
      <div class="flex items-center mb-4">
        <div
          class="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mr-3 flex-shrink-0"
        >
          <i class="fas fa-truck text-red-600 text-lg"></i>
        </div>
        <div>
          <h4 class="font-semibold text-gray-800">{{ vehicleData.vehicle }}</h4>
          <p class="text-sm text-gray-500">
            {{ vehicleData.driverName || "Sürücü bilgisi yok" }}
          </p>
        </div>
      </div>

      <!-- Hız ve Durum Bilgileri -->
      <div
        class="flex items-center justify-between bg-gray-50 rounded-lg p-3 mb-4"
      >
        <div class="text-center">
          <div class="text-xl font-bold text-red-600">
            {{ vehicleData.speed || 0 }}
          </div>
          <div class="text-xs text-gray-500">km/s</div>
        </div>

        <div class="h-10 border-l border-gray-300"></div>

        <div class="text-center">
          <span
            class="text-xs font-semibold px-2 py-1 rounded-full text-white"
            :class="getStatusBgClass"
          >
            {{ getStatusText }}
          </span>
          <div class="text-xs text-gray-500 mt-1">Durum</div>
        </div>
      </div>

      <!-- Detay Bilgiler -->
      <div class="mb-4">
        <h4 class="text-sm font-medium text-gray-600 mb-2 px-1">
          Araç Bilgileri
        </h4>

        <div
          class="bg-gray-50 rounded-lg overflow-hidden divide-y divide-gray-100"
        >
          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-weight text-gray-400 w-5 mr-2"></i>
              Dorse
            </div>
            <div class="text-sm font-medium text-gray-700 w-1/2 text-right">
              {{ vehicleData.dorse || "Belirtilmemiş" }}
            </div>
          </div>

          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-map-marker-alt text-gray-400 w-5 mr-2"></i>
              Bölge
            </div>
            <div class="text-sm font-medium text-gray-700 w-1/2 text-right">
              {{ vehicleData.region || "Belirtilmemiş" }}
            </div>
          </div>

          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-clock text-gray-400 w-5 mr-2"></i>
              Son Güncelleme
            </div>
            <div class="text-sm text-right w-1/2">
              <div class="font-medium text-gray-700">
                {{ formattedLastUpdate }}
              </div>
              <div v-if="timeAgo" class="text-xs text-gray-500">
                {{ timeAgo }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Planlama Bilgileri -->
      <div class="mb-5" v-if="vehicleData.planning">
        <h4 class="text-sm font-medium text-gray-600 mb-2 px-1">
          Planlama Bilgileri
        </h4>

        <div
          class="bg-gray-50 rounded-lg overflow-hidden divide-y divide-gray-100"
        >
          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-box text-gray-400 w-5 mr-2"></i>
              Ürün
            </div>
            <div class="text-sm font-medium text-gray-700 w-1/2 text-right">
              {{ vehicleData.planning.productType || "Belirtilmemiş" }}
            </div>
          </div>

          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-user text-gray-400 w-5 mr-2"></i>
              Müşteri
            </div>
            <div
              class="text-sm font-medium text-gray-700 w-1/2 text-right truncate"
            >
              {{ vehicleData.planning.customer || "Belirtilmemiş" }}
            </div>
          </div>

          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-calendar-alt text-gray-400 w-5 mr-2"></i>
              Teslim Tarihi
            </div>
            <div class="text-sm font-medium text-gray-700 w-1/2 text-right">
              {{
                formatDate(vehicleData.planning.targetDate) || "Belirtilmemiş"
              }}
            </div>
          </div>

          <div class="flex items-center px-3 py-2 hover:bg-gray-100">
            <div class="text-sm text-gray-500 flex items-center w-1/2">
              <i class="fas fa-file-invoice text-gray-400 w-5 mr-2"></i>
              Sipariş No
            </div>
            <div class="text-sm font-semibold text-red-600 w-1/2 text-right">
              {{ vehicleData.planning.orderNo || "Belirtilmemiş" }}
            </div>
          </div>
        </div>
      </div>

      <!-- Adres Bilgisi -->
      <div class="mb-4" v-if="vehicleData.adress">
        <h4
          class="text-sm font-medium text-gray-600 mb-2 px-1 flex items-center"
        >
          <i class="fas fa-map-marked-alt text-red-600 mr-2"></i>
          Güncel Konum
        </h4>

        <div class="bg-gray-50 rounded-lg p-3">
          <p class="text-sm text-gray-700">{{ vehicleData.adress }}</p>
        </div>
      </div>

      <!-- Eylem Butonları -->
      <div class="flex gap-2">
        <button
          class="flex-1 flex items-center justify-center bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg text-sm font-medium transition-colors"
          @click="callDriver"
        >
          <i class="fas fa-phone mr-2"></i>
          Sürücüyü Ara
        </button>

        <button
          class="flex-1 flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-700 py-2 rounded-lg text-sm font-medium transition-colors"
          @click="viewHistory"
        >
          <i class="fas fa-history mr-2"></i>
          Geçmiş
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";

export default {
  name: "vehicle-details-panel",
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getStatusText() {
      const states = {
        1: "Onay Bekliyor",
        2: "Onaylandı",
        3: "Yüklemeye Gidiyor",
        4: "Yüklemede",
        5: "Yola Çıktı",
        6: "Teslimata Ulaştı",
        7: "Tahliyede",
        8: "Tamamlandı",
        9: "İptal Edildi",
        10: "Onaylanmadı",
      };
      return states[this.vehicleData.planning?.state] || "Bilinmiyor";
    },
    getStatusBgClass() {
      const statusClasses = {
        1: "bg-yellow-500",
        2: "bg-green-500",
        3: "bg-blue-500",
        4: "bg-purple-500",
        5: "bg-blue-600",
        6: "bg-indigo-500",
        7: "bg-indigo-600",
        8: "bg-green-600",
        9: "bg-red-500",
        10: "bg-red-600",
      };
      return statusClasses[this.vehicleData.planning?.state] || "bg-gray-500";
    },
    formattedLastUpdate() {
      if (!this.vehicleData.lastUpdateTime) return "Bilgi yok";
      return moment(this.vehicleData.lastUpdateTime).format("DD.MM.YYYY HH:mm");
    },
    timeAgo() {
      if (!this.vehicleData.lastUpdateTime) return "";
      return moment(this.vehicleData.lastUpdateTime).fromNow();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD.MM.YYYY HH:mm");
    },

    callDriver() {
      // Sürücüyü arama fonksiyonu
      this.$emit("call-driver", this.vehicleData);

      // Toast mesajı gösterimi (eğer bir toast kütüphanesi kullanılıyorsa)
      if (this.$toast) {
        this.$toast.info("Sürücü arama başlatılıyor...", {
          position: "top-right",
          duration: 3000,
        });
      }
    },

    viewHistory() {
      // Araç geçmişini görüntüleme
      this.$emit("view-history", this.vehicleData);
    },
  },
};
</script>