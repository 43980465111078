<template>
  <modal
    name="add-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '55%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.planning.modal.newTitle") }}
      </h4>

      <button class="p-2 w-1/12" @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full mb-2">
        <orderInput @changeObject="(item) => getDetail(item)" />
      </div>

      <!-- Core form fields component -->
      <FormFields
        :orderDate="orderDate"
        :complateDate.sync="complateDate"
        :customer="customer"
        :invoicedCompany="invoicedCompany"
        :receivedName="receivedName"
        :deliveredName="deliveredName"
        :productType="productType"
        :amount="amount"
        :unitType="unitType"
      />

      <!-- Date and location related fields -->
      <LocationFields
        :fillingDate.sync="fillingDate"
        :deliveryDate="deliveryDate"
        :fillingPoint="fillingPoint"
        :deliveryPoint="deliveryPoint"
        :waypointsList="waypointsList"
        :vehicle="vehicle"
        @changeStartPosition="(val) => (fillingPoint = val)"
        @changeEndPosition="(val) => (deliveryPoint = val)"
        @changeOutpointId="(r) => (receivedName = r)"
        @changeCustomerId="(r) => (deliveredName = r)"
        @changePolyline="(r) => changePolyline(r)"
        @change="(r) => (costDistribution = r)"
      />

      <!-- Vehicle information -->
      <VehicleFields :vehicle="vehicle" :dorse="dorse" :personnel="personnel" />

      <!-- Cost calculation fields -->
      <CostCalculation
        :hakedisKilometer.sync="hakedisKilometer"
        :transitionCount.sync="transitionCount"
        :totalFuelPrice.sync="totalFuelPrice"
        :otherExpensePrice.sync="otherExpensePrice"
        :tonnagePrice.sync="tonnagePrice"
        :costDistribution.sync="costDistribution"
        :expensivePrice.sync="expensivePrice"
        :totalPrice.sync="totalPrice"
        :isOneWay.sync="isOneWay"
      />

      <!-- Additional options -->
      <OptionFields
        :isAmortisman="isAmortisman"
        :isOneWay="isOneWay"
        :dayCount="dayCount"
      />

      <!-- Notes field -->
      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          v-model="explanation"
          :title="$t('general.note')"
          maxlength="500"
          :required="isNoteRequired"
        />
      </div>

      <!-- Save button -->
      <div class="w-full mt-4 text-right">
  <template v-if="isPastDeliveryDate">
    <p class="text-red-500 text-sm">
      Siparişin teslim tarihi geçtiği için siparişi kayıt edemezsiniz.
    </p>
  </template>
  <template v-else>
    <asyncBtn
      icon="fas fa-check"
      :text="$t('general.saveButtonTitle')"
      :loadTitle="$t('general.saveButtonLoadTitle')"
      :loadState="load"
      :disabled="load"
      class="w-full md:w-56"
    />
  </template>
</div>
    </form>
  </modal>
</template>
  
  <script>
// NPM
import _ from "lodash";

// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import orderInput from "@/components/devItem/orderInput.vue";

// Local Components
import FormFields from "./components/FormFields.vue";
import LocationFields from "./components/LocationFields.vue";
import VehicleFields from "./components/VehicleFields.vue";
import CostCalculation from "./components/CostCalculation.vue";
import OptionFields from "./components/OptionFields.vue";

// Utils
import { saveFormData } from "./api/planningService";
import { calculatePrice, changePolyline } from "./utils/calculations";
import { validateTotalPrice } from "./utils/validation";
import { checkWorkingHours } from "./utils/timeUtils";
import moment from "moment";

export default {
  name: "add-plan-modal",
  components: {
    diffInput,
    asyncBtn,
    orderInput,
    FormFields,
    LocationFields,
    VehicleFields,
    CostCalculation,
    OptionFields,
  },
  data() {
    return {
      isPirateDriver: "",
      phone: "",
      orderId: "",
      isNoteRequired: false,
      orderNo: "",
      orderCost: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",
      transitionCount: 1,
      personnel: "",
      personnelTC: "",
      vehicle: "",
      dorse: "",
      driver: "",
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      order: "",

      outpointLat: "",
      outpointLng: "",
      targetPointLat: "",
      targetPointLng: "",
      fillingCustomerId: "",
      deliveryCustomerId: "",

      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",

      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 1,
      isWashing: 0,

      expenseDetail: {},
      waypointsList: [],
      changeLocationState: false,
      load: false,
      vehicleArrivalTime: "",
    };
  },
  methods: {
    close() {
      this.resetAll();
      this.$modal.hide("add-plan-modal");
    },

    resetAll() {
      // Reset all form data
      Object.assign(this.$data, this.$options.data.call(this));
    },

    getDetail(item) {
      // Handle order data received from orderInput component
      if (item.orderCost === 1) {
        this.$swal.fire({
          icon: "warning",
          title: "UYARI!",
          text: "Gelen satış fiyatı geçerli değildir.",
          showConfirmButton: true,
          confirmButtonText: "TAMAM",
        });
      } else {
        this.totalPrice = item.orderCost * Number(item.amount);
      }

      // Set form data based on item details
      // Transfer all fields from item to the component data
      this.mapItemDataToForm(item);
      this.changeLocation();
    },

    mapItemDataToForm(item) {
      // Map all the item properties to the component data
      const fieldsToMap = [
        "outpointLat",
        "outpointLng",
        "targetPointLat",
        "targetPointLng",
        "fillingCustomerId",
        "deliveryCustomerId",
        "id",
        "isPirateDriver",
        "phone",
        "orderNo",
        "orderCost",
        "isWashing",
        "name",
        "customer",
        "invoicedCompany",
        "receivedName",
        "deliveredName",
        "productType",
        "amount",
        "unitType",
        "vehicle",
        "dorse",
        "driverName",
        "driverTC",
        "deliveryDate",
      ];

      fieldsToMap.forEach((field) => {
        if (field in item) {
          const targetField = this.getTargetFieldName(field);
          this[targetField] = item[field];
        }
      });

      // Special cases
      this.orderId = item.id;
      this.personnel = item.driverName;
      this.personnelTC = item.driverTC;
      this.orderName = item.name;
      this.orderDate = moment(item.orderDate).format("YYYY-MM-DD");

      // Set complex objects
      this.fillingPoint = {
        lat: item.outpointLat,
        lng: item.outpointLng,
        adress: item.outpointAdress,
      };

      this.deliveryPoint = {
        lat: item.targetPointLat,
        lng: item.targetPointLng,
        adress: item.targetAdress,
      };

      this.deliveryDate = item.deliveryDate
        ? item.deliveryDate
        : moment().format("YYYY-MM-DDT:HH:mm");

      this.order = item; // Store full item for calculations
    },

    getTargetFieldName(sourceField) {
      // Map specific field names that differ
      const fieldMap = {
        id: "orderId",
        name: "orderName",
        driverName: "personnel",
        driverTC: "personnelTC",
      };

      return fieldMap[sourceField] || sourceField;
    },

    async save() {
      if (!this.validateForm()) return;

      this.load = true; // Butonu devre dışı bırak
      try {
        const [deliveryWillArrive, fillingWillArrive] = await Promise.all([
          this.checkDeliveryAvailability(),
          this.checkFillingAvailability(),
        ]);

        if (!deliveryWillArrive || !fillingWillArrive) {
          this.showScheduleWarning(deliveryWillArrive, fillingWillArrive);
        } else {
          await this.saveCall();
        }
      } catch (error) {
        console.error("Hata oluştu:", error);
        this.$swal
          .fire({
            icon: "error",
            title: "Hata",
            text: "Bir hata oluştu, lütfen tekrar deneyin.",
            showConfirmButton: true,
            confirmButtonText: "Tamam",
          })
          .then(() => {
            this.load = false;
          });
      }
    },

    validateForm() {
      return validateTotalPrice(
        this.totalPrice,
        this.tonnagePrice,
        this.isNoteRequired,
        this.$swal
      );
    },

    async checkDeliveryAvailability() {
      if (!this.deliveryCustomerId) return true;
      return await checkWorkingHours(
        this.outpointLat,
        this.outpointLng,
        this.targetPointLat,
        this.targetPointLng,
        this.vehicleArrivalTime,
        this.fillingDate,
        this.deliveryCustomerId,
        this.$store.state.userData.token
      );
    },

    async checkFillingAvailability() {
      if (!this.fillingCustomerId) return true;
      const vehicleData =
        this.vehicle == null
          ? {
              latitude: this.fillingPoint.lat,
              longitude: this.fillingPoint.lng,
            }
          : this.vehicle;

      return await checkWorkingHours(
        vehicleData.latitude,
        vehicleData.longitude,
        this.outpointLat,
        this.outpointLng,
        null,
        null,
        this.fillingCustomerId,
        this.$store.state.userData.token
      );
    },

    showScheduleWarning(deliveryWillArrive, fillingWillArrive) {
      const errText =
        !deliveryWillArrive && !fillingWillArrive
          ? "Araç doldurmaya ve boşaltmaya yetişemeyecek"
          : !deliveryWillArrive
          ? "Araç boşaltmaya yetişemeyecek"
          : !fillingWillArrive
          ? "Araç doldurmaya yetişemeyecek"
          : "";

      this.$swal
        .fire({
          icon: "warning",
          title: "UYARI!",
          text: errText,
          showConfirmButton: true,
          confirmButtonText: "TAMAM",
        })
        .then((acc) => {
          if (acc.isConfirmed) {
            this.saveCall();
          } else this.load = false;
        });
    },

    async saveCall() {
      this.load = true;

      const formData = this.prepareFormData();
      try {
        const result = await saveFormData(
          formData,
          this.$store.state.userData.token
        );
        const detail = result.data.data;

        if (!detail.status) {
          this.showResponseMessage("warning", result.data.message);
        } else {
          this.showResponseMessage("success");
        }
      } catch (error) {
        console.error("API Hatası:", error);
        this.$swal
          .fire({
            icon: "error",
            title: "Hata",
            text: "Sipariş kaydedilirken bir hata oluştu.",
            showConfirmButton: true,
            confirmButtonText: "Tamam",
          })
          .then(() => {
            this.load = false; // Her durumda load'u kapat
          });
      }
    },

    prepareFormData() {
      return {
        isPirateDriver: this.isPirateDriver,
        phone: this.phone,
        orderId: this.orderId,
        orderNo: this.orderNo,
        orderCost: this.orderCost,
        isWashing: this.isPirateDriver == 1 ? 2 : this.isWashing,
        orderName: this.orderName,
        orderDate: this.orderDate,
        complateDate: this.complateDate,
        customer: this.customer,
        invoicedCompany: this.invoicedCompany,
        receivedName: this.receivedName,
        deliveredName: this.deliveredName,
        productType: this.productType,
        amount: this.amount,
        unitType: this.unitType,
        fillingDate: this.fillingDate,
        deliveryDate: this.deliveryDate,
        vehicle: this.vehicle ? this.vehicle : "34ABC222",
        dorse: this.dorse,
        driver: this.personnel,
        driverTC: this.personnelTC,
        kilometer: this.hakedisKilometer,
        expensePrice: this.expensivePrice,
        totalPrice: this.totalPrice,
        explanation: this.explanation,
        polyline: this.polyline,
        outpointAdress: this.fillingPoint.adress,
        outpointLat: this.fillingPoint.lat,
        outpointLng: this.fillingPoint.lng,
        targetpointAdress: this.deliveryPoint.adress,
        targetpointLat: this.deliveryPoint.lat,
        targetpointLng: this.deliveryPoint.lng,
        // Expense details
        tollsPrice: (
          this.costDistribution.cost.cash *
          this.transitionCount *
          2
        ).toFixed(2),
        // Add all the expense detail fields here
        isAmortisman: this.isAmortisman,
        isOneWay: this.isOneWay,
        dayCount: this.dayCount,
        transitionCount: this.transitionCount,
        waypoint: this.waypointsList,
      };
    },

    async showResponseMessage(type, message = "") {
      await this.$swal.fire({
        icon: type === "success" ? "success" : "warning",
        title: this.$t(
          type === "success" ? "general.successTitle" : "general.warningTitle"
        ),
        text: message,
        showConfirmButton: true,
        confirmButtonText: this.$t("general.OkayTitle"),
      });

      this.resetAll();
      this.$emit("refresh", true);
      this.$modal.hide("add-plan-modal");
      this.load = false; // Kullanıcı aksiyonu tamamlanınca load sıfırlanıyor
    },

    changeLocation() {
      this.changeLocationState = false;
      if (
        (this.fillingPoint.lat != 0 && this.fillingPoint.lng != 0) ||
        (this.deliveryPoint.lat != 0 && this.deliveryPoint.lng != 0)
      ) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev?.getRoute();
        }, 200);
      }
    },

    changePolyline(val) {
      this.polyLine = changePolyline(val);
    },

    async calculatePrice() {
      const result = await calculatePrice(
        this.$store.state.userData.token,
        this.hakedisKilometer,
        this.transitionCount,
        this.vehicle,
        this.order,
        this.costDistribution,
        this.isAmortisman,
        this.isOneWay,
        this.dayCount
      );

      this.expenseDetail = result.expenseDetail;
      this.totalFuelPrice = result.totalFuelPrice;
      this.otherExpensePrice = result.otherExpensePrice;
      this.tonnagePrice = result.tonnagePrice;
      this.expensivePrice = result.expensivePrice;
    },
  },
  computed: {
    isPastDeliveryDate() {
    return this.deliveryDate && moment(this.deliveryDate).isBefore(moment());
  },
    getMinFillingDate() {
      return moment(this.orderDate).format("YYYY-MM-DD HH:mm");
    },

    isMobile() {
      // Example mobile detection - you may want to enhance this based on your app's needs
      return window.innerWidth < 768;
    },
  },
  watch: {
    explanation(newExplanation) {
      this.isNoteRequired = newExplanation !== "";
    },

    async transitionCount() {
      await this.calculatePrice();
    },

    async costDistribution(val) {
      this.hakedisKilometer = val.totalKilometer;
      await this.calculatePrice();
    },

    async isAmortisman() {
      await this.calculatePrice();
    },

    isOneWay() {
      this.calculatePrice();
    },

    dayCount() {
      this.calculatePrice();
    },
  },
};
</script>