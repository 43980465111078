<template>
  <div class="block px-2 text-black mt-2 relative z-50" ref="menu" style="z-index: 999999 !important">
    <button 
      @click="showModal()" 
      class="flex py-2 px-1 items-center text-sm z-50 rounded-lg transition-all duration-200 hover:bg-gray-100"
      :class="activeState && !show ? 'text-red-600 font-medium' : 'text-gray-700'"
    >
      <!-- Modern CSS icon with gradient effects -->
      <div v-if="!show" class="icon-wrapper mr-2" :class="{'active-icon': activeState}">
        <div :class="['modern-icon', getIconClass]"></div>
      </div>
      <div v-if="show" class="icon-wrapper mr-2 active-icon">
        <div class="modern-icon icon-minus"></div>
      </div>
      <span>{{ title }}</span>
    </button>
    
    <!-- Modern dropdown menu with animation -->
    <div
      v-if="show && opened == id"
      class="absolute left-0 top-12 bg-white shadow-xl rounded-xl overflow-hidden transition-all duration-200 dropdown-panel"
      style="width: 240px; transform-origin: top center; box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -5px rgba(0, 0, 0, 0.04); z-index: 999999 !important"
    >
      <div class="py-2">
        <div class="px-3 py-2 border-b border-gray-100 mb-1">
          <h3 class="text-xs font-semibold text-gray-500 uppercase tracking-wider">{{ title }}</h3>
        </div>
        <div v-for="(item, index) in list" :key="index" class="px-1">
          <button
            @click="() => ($router.push(item.url), (show = false))"
            class="w-full flex items-center px-4 py-2 text-left transition-colors rounded-lg my-1 group"
            :class="$route.path == item.url ? 'bg-red-50 text-red-600 font-medium' : 'text-gray-700 hover:bg-gray-50'"
          >
            <!-- No icon for submenu items -->
            <span class="text-xs">{{ item.title }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar-item",
  components: {},
  props: ["list", "title", "icon", "isMenu", "routeName", "id", "opened"],
  data() {
    return {
      show: false,
      activeState: false,
    };
  },
  computed: {
    // Map the icon prop to our CSS icon classes
    getIconClass() {
      const iconMap = {
        'fa-map-signs': 'icon-location',
        'fa-calculator': 'icon-calculator',
        'fa-list': 'icon-list',
        'fa-exchange-alt': 'icon-swap',
        'fa-cogs': 'icon-settings',
        'fa-question-circle': 'icon-help'
      };
      
      return iconMap[this.icon] || 'icon-default';
    }
  },
  methods: {
    showModal() {
      if (this.isMenu) {
        this.show = !this.show;
        this.$emit("changeOpened", this.id);
      } else {
        this.$emit("changeOpened", this.id);
        this.$router.push(this.routeName);
      }
    },
    showSubItem() {
      this.$store.commit(
        "addTab",
        this.list?.find((r) => r.url == this.$route.path)
      );
    },
    hideModal() {
      this.show = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.menu.contains(event.target)) {
        this.show = false;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    this.activeState = this.list?.find((r) => r.url == this.$route.path)
      ? true
      : this.$route.path == this.routeName;
  },
  watch: {
    opened(val) {
      if (val != this.id) {
        this.show = false;
      }
    },
    "$route.path"() {
      this.activeState = this.list?.find((r) => r.url == this.$route.path)
        ? true
        : this.$route.path == this.routeName;
    },
  },
};
</script>

<style scoped>
.active {
  border-left: 3px solid #dc2626 !important;
  color: rgb(255, 255, 255) !important;
}

/* Icon wrapper for enhanced visuals */
.icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: linear-gradient(135deg, #f3f4f6 0%, #e5e7eb 100%);
  transition: all 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.icon-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

.active-icon {
  background: linear-gradient(135deg, #fecaca 0%, #ef4444 100%);
  box-shadow: 0 2px 4px rgba(239, 68, 68, 0.2);
}

.active-icon .modern-icon {
  color: white;
}

/* Base icon styles */
.modern-icon {
  position: relative;
  width: 14px;
  height: 14px;
  display: inline-block;
  color: #4b5563;
  z-index: 2;
}

/* Default icon */
.icon-default {
  position: relative;
}

.icon-default:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  background-color: currentColor;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

/* Minus icon */
.icon-minus:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  transform: translateY(-50%);
  border-radius: 2px;
}

/* Location icon */
.icon-location {
  position: relative;
}

.icon-location:before {
  content: '';
  position: absolute;
  top: 0;
  left: 3px;
  width: 8px;
  height: 8px;
  border: 2px solid currentColor;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
}

.icon-location:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 6px;
  width: 2px;
  height: 2px;
  background-color: currentColor;
  border-radius: 50%;
  transform: rotate(-45deg);
  opacity: 0.7;
}

/* List icon - modern 3D-like design */
.icon-list {
  position: relative;
}

.icon-list:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 1px;
  width: 8px;
  height: 2px;
  background-color: currentColor;
  border-radius: 1px;
}

.icon-list:after {
  content: '';
  position: absolute;
  top: 7px;
  left: 1px;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  border-radius: 1px;
}

.icon-list::before {
  box-shadow: 
    0 5px 0 0 currentColor,
    3px 0 0 0 currentColor, 
    3px 5px 0 0 currentColor,
    3px 10px 0 0 currentColor;
}

.icon-list::after {
  box-shadow: 0 5px 0 0 currentColor;
}

/* Calculator icon */
.icon-calculator {
  position: relative;
}

.icon-calculator:before {
  content: '';
  position: absolute;
  top: 0;
  left: 1px;
  width: 12px;
  height: 14px;
  border: 1px solid currentColor;
  border-radius: 2px;
}

.icon-calculator:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 2px;
  background-color: currentColor;
}

/* Settings icon */
.icon-settings {
  position: relative;
}

.icon-settings:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid currentColor;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.icon-settings:after {
  content: '';
  position: absolute;
  top: 0;
  left: 6px;
  width: 2px;
  height: 14px;
  background-color: currentColor;
  transform-origin: center;
  transform: rotate(0deg);
}

.icon-settings::after {
  box-shadow: 
    0 0 0 4px transparent,
    0 0 0 0 transparent,
    0 0 0 0 transparent,
    7px 7px 0 -3px currentColor,
    -7px 7px 0 -3px currentColor,
    -7px -7px 0 -3px currentColor,
    7px -7px 0 -3px currentColor;
}

/* Swap/Exchange icon */
.icon-swap {
  position: relative;
}

.icon-swap:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  border-radius: 1px;
}

.icon-swap:after {
  content: '';
  position: absolute;
  bottom: 3px;
  right: 0;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  border-radius: 1px;
}

.icon-swap::before {
  box-shadow: 4px -2px 0 -1px currentColor, 4px 0px 0 -1px currentColor, 2px -1px 0 -1px currentColor;
  transform: rotate(45deg);
  transform-origin: right;
}

.icon-swap::after {
  box-shadow: -4px 2px 0 -1px currentColor, -4px 0px 0 -1px currentColor, -2px 1px 0 -1px currentColor;
  transform: rotate(45deg);
  transform-origin: left;
}

/* Help icon */
.icon-help {
  position: relative;
}

.icon-help:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1.5px solid currentColor;
  border-radius: 50%;
}

.icon-help:after {
  content: '?';
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
}

/* Add hover effects */
button:hover .icon-wrapper {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Dropdown animation */
.dropdown-panel {
  animation: dropdownAnim 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes dropdownAnim {
  0% {
    opacity: 0;
    transform: translateY(-8px) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>