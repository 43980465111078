<!-- components/MapControlPanel.vue -->
<template>
  <div class="map-controls absolute left-3 top-28 z-50 flex flex-col">


    <button
      @click="$emit('zoom-in')"
      class="control-btn mb-2"
      :class="isMapView ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'"
      title="Yakınlaştır"
    >
      <i class="fas fa-plus"></i>
    </button>

    <button
      @click="$emit('zoom-out')"
      class="control-btn mb-2"
      :class="isMapView ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'"
      title="Uzaklaştır"
    >
      <i class="fas fa-minus"></i>
    </button>

    <button
      @click="$emit('center-map')"
      class="control-btn mb-2"
      :class="isMapView ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'"
      title="Aracı Merkeze Al"
    >
      <i class="fas fa-crosshairs"></i>
    </button>

    <button
      @click="$emit('refresh')"
      class="control-btn"
      :class="isMapView ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'"
      title="Yenile"
    >
      <i class="fas fa-sync-alt"></i>
    </button>
  </div>
</template>
  
  <script>
export default {
  name: "map-control-panel",
  props: {
    isMapView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
  
  <style scoped>
.control-btn {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

.control-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.control-btn:active {
  transform: translateY(0);
}
</style>