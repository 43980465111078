<template>
  <div class="mt-4 md:flex items-center">
    <div
      class="w-full mt-4 md:mt-0"
      :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12'"
    >
      <diffInput
      :disabled="isEdit"
        type="select"
        :optList="amortismanOptions"
        v-model="isAmortismanModel"
        title="Amortisman Maliyeti"
      />
    </div>
    <div
      class="w-full mt-4 md:mt-0 md:px-2"
      :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12 md:pl-2'"
    >
      <diffInput
        :disabled="isEdit"
        type="select"
        :optList="oneWayOptions"
        v-model="isOneWayModel"
        title="Tek Yön"
      />
    </div>
    <div class="w-full md:w-4/12 mt-4 md:mt-0" v-if="isOneWay == 1">
      <diffInput
      :disabled="isEdit"
        type="number"
        :valCount="99"
        v-model="dayCountModel"
        :required="true"
        title="Gün Sayısı"
        :max="99"
        :min="1"
        :step="1"
      />
    </div>
  </div>
</template>
  
  <script>
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "OptionFields",
  components: {
    diffInput,
  },
  props: {
    isAmortisman: {
      type: [String, Number],
      default: 1,
    },
    isOneWay: {
      type: [String, Number],
      default: 1,
    },
    dayCount: {
      type: [String, Number],
      default: 1,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amortismanOptions: [
        {
          id: 1,
          name: "Eklensin",
        },
        {
          id: 2,
          name: "Eklenmesin",
        },
      ],
      oneWayOptions: [
        {
          id: 1,
          name: "Evet",
        },
        {
          id: 2,
          name: "Hayır",
        },
      ],
    };
  },
  computed: {
    isAmortismanModel: {
      get() {
        return this.isAmortisman;
      },
      set(value) {
        this.$emit("update:isAmortisman", value);
      },
    },
    isOneWayModel: {
      get() {
        return this.isOneWay;
      },
      set(value) {
        this.$emit("update:isOneWay", value);
      },
    },
    dayCountModel: {
      get() {
        return this.dayCount;
      },
      set(value) {
        this.$emit("update:dayCount", value);
      },
    },
  },
};
</script>