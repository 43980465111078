/**
 * Planlı siparişleri ve sipariş-araç ilişkilerini getirir
 * @param {Array} selectedList - Planlı siparişler listesi
 * @param {Array} busyVehicles - İşi olan araçlar
 * @param {Object} targetOrder - Hedef sipariş
 * @returns {Array} Siparişler ve araçları içeren liste
 */
function getOrdersWithVehicles(selectedList, busyVehicles, targetOrder) {
    // İşi olan araçlar listesi verilmişse, bunları kullan
    if (busyVehicles && busyVehicles.length > 0) {
      // Araçların sipariş numaralarını topla
      const orderNoSet = new Set();
      busyVehicles.forEach(vehicle => {
        if (Array.isArray(vehicle.orderNos)) {
          vehicle.orderNos.forEach(orderNo => {
            orderNoSet.add(orderNo);
          });
        }
      });
      
      const orderNumbers = Array.from(orderNoSet);
      
      // Bu sipariş numaralarına sahip siparişleri selectedList'ten bul
      const relevantOrders = selectedList.filter(order => 
        orderNumbers.includes(order.orderNo)
      );
      
      // Eğer ilgili siparişler selectedList'te yoksa, direkt araçları kullan
      if (relevantOrders.length === 0) {
        // Araçları sipariş numaralarına göre grupla
        const groupedVehicles = {};
        
        busyVehicles.forEach(vehicle => {
          if (Array.isArray(vehicle.orderNos) && vehicle.orderNos.length > 0) {
            vehicle.orderNos.forEach(orderNo => {
              if (!groupedVehicles[orderNo]) {
                groupedVehicles[orderNo] = {
                  orderNo,
                  customer: vehicle.planningInfo?.customer || "Bilinmeyen Müşteri",
                  productType: vehicle.planningInfo?.product || "Bilinmeyen Ürün",
                  tonnage: "?",
                  unitType: "TON",
                  deliveryDate: vehicle.planningInfo?.deliveryDate || "Bilinmeyen Tarih",
                  vehicles: []
                };
              }
              
              groupedVehicles[orderNo].vehicles.push({
                id: vehicle.id,
                plate: vehicle.vehicle || vehicle.plate,
                capacity: ((vehicle.capacity || 0) / 1000).toFixed(1)
              });
            });
          }
        });
        
        return Object.values(groupedVehicles);
      } else {
        // Siparişleri ve bu siparişlere atanmış araçları grupla
        return relevantOrders.map(order => {
          // Bu siparişe atanmış araçları bul
          const assignedVehicles = busyVehicles.filter(vehicle => 
            Array.isArray(vehicle.orderNos) && vehicle.orderNos.includes(order.orderNo)
          );
          
          return {
            orderNo: order.orderNo,
            customer: order.customer,
            productType: order.productType,
            tonnage: order.tonnage,
            unitType: order.unitType,
            deliveryDate: order.deliveryDate,
            vehicles: assignedVehicles.map(vehicle => ({
              id: vehicle.id,
              plate: vehicle.vehicle || vehicle.plate,
              capacity: ((vehicle.capacity || 0) / 1000).toFixed(1)
            }))
          };
        });
      }
    }
    
    // Eğer busyVehicles listesi verilmemişse, planlanan siparişlerden veri al
    // Planlanan siparişleri kullan
    const plannedOrders = selectedList.filter(order => 
      // Hedef siparişin kendisini hariç tut ve en az bir araç atanmış olanları al
      parseInt(order.orderNo) !== parseInt(targetOrder.orderNo) && 
      order.vehicle && order.vehicle.name
    );
    
    // Formatlanmış planlı siparişleri oluştur
    return plannedOrders.map(order => ({
      orderNo: order.orderNo,
      customer: order.customer,
      productType: order.productType,
      tonnage: order.tonnage,
      unitType: order.unitType,
      deliveryDate: order.deliveryDate,
      vehicles: [{
        id: order.vehicle.id,
        plate: order.vehicle.name,
        capacity: (order.recomendation?.capacity || "0").toString()
      }]
    }));
  }
  


  module.exports={
    getOrdersWithVehicles
  }