
/**
 * Calculate customer priority
 * @param {Object} order - The order
 * @param {Array} customers - Customers array
 * @returns {number} - Priority value (1 is low)
 */
export const calculateCustomerPriority = (order, customers) => {
    if (!order || !customers || !Array.isArray(customers)) {
      return 1;
    }
    
    const findItem = customers.find(
      (customer) => customer.name?.toLowerCase() === order.customer?.toLowerCase()
    );
  
    if (!findItem) return 1;
    return findItem.priority || 1;
  };
  
  /**
   * Müşterinin şirket bilgilerini bulur
   * @param {string} customerName - Müşteri adı
   * @param {Array} customers - Müşteriler dizisi
   * @returns {Object|null} - Müşteri bilgileri veya null
   */
  export const findCustomerByName = (customerName, customers) => {
    if (!customerName || !customers || !Array.isArray(customers)) {
      return null;
    }
    
    return customers.find(
      (customer) => customer.name?.toLowerCase() === customerName?.toLowerCase()
    ) || null;
  };
  
  /**
   * Müşterinin filoya bağlı araçlarını kontrol eder
   * @param {Object} customer - Müşteri bilgileri
   * @param {Array} vehicleList - Araç listesi
   * @returns {Array} - Müşteriye atanmış araçlar
   */
  export const getCustomerFleetVehicles = (customer, vehicleList) => {
    if (!customer || !vehicleList || !Array.isArray(vehicleList)) {
      return [];
    }
    
    return vehicleList.filter(vehicle => 
      vehicle.fleetCustomers && 
      vehicle.fleetCustomers.some(c => c.customerId === customer.id)
    );
  };
  
  /**
   * Siparişe göre tercih edilen müşteri sıralamasını yapar
   * @param {Object} order - Sipariş
   * @param {Array} customers - Müşteriler listesi
   * @returns {Array} - Önceliğe göre sıralanmış müşteri listesi
   */
  export const getPrioritizedCustomers = (order, customers) => {
    if (!order || !customers || !Array.isArray(customers)) {
      return [];
    }
    
    // Önce müşteriyi bul
    const currentCustomer = findCustomerByName(order.customer, customers);
    
    // Müşteri bulunamadıysa tüm listeyi döndür
    if (!currentCustomer) {
      return customers;
    }
    
    // Müşterileri önceliğe göre sırala
    return [...customers].sort((a, b) => {
      // Mevcut müşteri en önde
      if (a.id === currentCustomer.id) return -1;
      if (b.id === currentCustomer.id) return 1;
      
      // Sonra önceliğe göre sırala
      return (b.priority || 1) - (a.priority || 1);
    });
  };