<template>
  <div
    class="shadow-sm overflow-x-auto border border-gray-200 bg-white dark:bg-gray-800 sm:rounded-lg my-2"
    style="height:85vh !important"
  >
    <!-- Filtreleme ve Arama Başlık -->
    <div class="py-2 px-3 bg-gray-50 dark:bg-gray-700 flex flex-wrap justify-between items-center gap-2 border-b border-gray-200">
      <!-- Filtreleme Butonları -->
      <div class="flex items-center">
        <div class="flex space-x-1">
          <button 
            @click="setFilter('all')" 
            class="px-2 py-1 text-xs rounded-sm border"
            :class="activeFilter === 'all' ? 'bg-gray-200 border-gray-300' : 'bg-white border-gray-200'"
          >
            Tümü
          </button>
          <button 
            @click="setFilter('matched')" 
            class="px-2 py-1 text-xs rounded-sm border flex items-center"
            :class="activeFilter === 'matched' ? 'bg-gray-200 border-gray-300' : 'bg-white border-gray-200'"
          >
            <svg class="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            Uyumlu
          </button>
          <button 
            @click="setFilter('mismatched')" 
            class="px-2 py-1 text-xs rounded-sm border flex items-center"
            :class="activeFilter === 'mismatched' ? 'bg-gray-200 border-gray-300' : 'bg-white border-gray-200'"
          >
            <svg class="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            Uyumsuz
          </button>
        </div>
      </div>

      <!-- Sıralama -->
      <div class="flex items-center">
        <select v-model="sortBy" id="sortBy" class="text-xs p-1 rounded-sm bg-white border border-gray-200 text-gray-700 mr-1">
          <option value="orderNo">Sefer No</option>
          <option value="date">Tarih</option>
          <option value="status">Durum</option>
        </select>
        <button @click="toggleSortDirection" class="p-1 rounded-sm bg-white border border-gray-200 text-gray-700">
          <svg class="w-3 h-3" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M12 5v14M19 12l-7 7-7-7" v-if="sortDirection === 'asc'"></path>
            <path d="M12 19V5M5 12l7-7 7 7" v-else></path>
          </svg>
        </button>
      </div>

      <!-- Arama Kutusu -->
      <div class="relative">
        <input 
          v-model="searchQuery" 
          type="text" 
          placeholder="Ara..." 
          class="pl-6 p-1 text-xs rounded-sm bg-white border border-gray-200 w-36"
        />
      </div>
    </div>

    <!-- Tablo -->
    <table class="min-w-full divide-y divide-gray-100">
      <thead class="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
        <tr>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 40px !important; width: 40px">
            #
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 100px !important; width: 70px">
            Sefer No
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 90px !important; width: 90px">
            Araç
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 120px !important">
            Dolum
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 120px !important">
            Teslim
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 100px !important">
            Durum
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider border-r border-gray-100" style="min-width: 100px !important">
            Oto. Takip
          </th>
          <th scope="col" class="px-2 py-1 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider" style="min-width: 80px !important; width: 80px">
            Tarih
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-100">
        <tr 
          v-for="(item, index) in getFilteredList" 
          :key="index" 
          :class="{'bg-gray-50': index % 2 === 0}"
          class="hover:bg-gray-100 dark:hover:bg-gray-700 text-xs"
        >
          <!-- Durum İkonu Sütunu -->
          <td class="px-2 py-1 border-r border-gray-100">
            <div class="flex justify-center">
              <div v-if="hasMismatchedStatus(item)" class="text-red-500" title="Uyumsuz durumlar">
                <svg class="w-3 h-3" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <div v-else class="text-green-500" title="Uyumlu durumlar">
                <svg class="w-3 h-3" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
          </td>
          
          <td class="px-2 py-1 whitespace-nowrap text-gray-900 dark:text-white border-r border-gray-100">
            {{ item.orderNo }}
          </td>
          <td class="px-2 py-1 whitespace-nowrap text-gray-700 dark:text-gray-300 border-r border-gray-100">
            {{ item.plate }}
          </td>
          <td class="px-2 py-1 whitespace-nowrap text-gray-700 dark:text-gray-300 border-r border-gray-100">
            {{ item.planning.receivedName }}
          </td>
          <td class="px-2 py-1 whitespace-nowrap text-gray-700 dark:text-gray-300 border-r border-gray-100">
            {{ item.planning.deliveredName }}
          </td>
          <td class="px-2 py-1 whitespace-nowrap text-gray-700 dark:text-gray-300 border-r border-gray-100">
            {{ item.planning.stateName }}
          </td>
          <td class="px-2 py-1 whitespace-nowrap text-gray-700 dark:text-gray-300 border-r border-gray-100">
            {{ getAutoStatusName(item.planning.trackStateId) }}
          </td>
          <td class="px-2 py-1 whitespace-nowrap text-gray-700 dark:text-gray-300">
            {{ formatDate(getItemDate(item)) }}
          </td>
        </tr>
      </tbody>
    </table>
    
    <div v-if="getFilteredList.length === 0" class="flex justify-center items-center p-4 text-gray-500 dark:text-gray-400 text-sm">
      Sonuç bulunamadı
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getPlanningStatusName } from "@/utils/statusName";

export default {
  name: "detail-components",
  props: ["list", "search"],
  data() {
    return {
      sortBy: "orderNo",
      sortDirection: 'asc',
      searchQuery: '',
      activeFilter: 'all', // 'all', 'matched', 'mismatched'
    }
  },
  created() {
    this.searchQuery = this.search || '';
    moment.locale('tr');
  },
  watch: {
    search(newVal) {
      this.searchQuery = newVal;
    }
  },
  methods: {
    getAutoStatusName(id) {
      return getPlanningStatusName(id);
    },
    formatDate(date) {
      return moment(date).format("DD.MM HH:mm");
    },
    getItemDate(item) {
      return (this.isValidDate(item.planning.lastStatusChangeDate)) 
              ? item.planning.lastStatusChangeDate 
              : item.planning.addDate;
    },
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setFilter(filter) {
      this.activeFilter = filter;
    },
    isValidDate(date) {
      return date && date !== 'Invalid date' && moment(date).isValid();
    },
    isValidOrderNo(orderNo) {
      return orderNo !== null && orderNo !== undefined && orderNo !== '';
    },
    isValidState(state) {
      return state !== null && state !== undefined && state !== '' && state != 0;
    },
    hasMismatchedStatus(item) {
      return this.isValidState(item.planning.state) && 
             this.isValidState(item.planning.trackStateId) && 
             item.planning.state !== item.planning.trackStateId;
    }
  },
  computed: {
    getDetailList() {
      return this.list.filter((item) => {
        if (!this.searchQuery) return true;
        
        const searchTerm = this.searchQuery.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        
        const plate = (item.plate || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const receivedName = (item.planning.receivedName || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const deliveredName = (item.planning.deliveredName || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const orderNo = (item.orderNo || "").toString().toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const stateName = (item.planning.stateName || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");

        return plate.includes(searchTerm) || 
               receivedName.includes(searchTerm) || 
               deliveredName.includes(searchTerm) || 
               orderNo.includes(searchTerm) ||
               stateName.includes(searchTerm);
      });
    },
    getFilteredList() {
      let filteredItems = this.getDetailList;
      
      if (this.activeFilter === 'matched') {
        filteredItems = filteredItems.filter(item => !this.hasMismatchedStatus(item));
      } else if (this.activeFilter === 'mismatched') {
        filteredItems = filteredItems.filter(item => this.hasMismatchedStatus(item));
      }
      
      return filteredItems.sort((a, b) => {
        let comparison = 0;
        
        switch (this.sortBy) {
          case 'date':
            const dateA = this.getItemDate(a);
            const dateB = this.getItemDate(b);
            
            if (this.isValidDate(dateA) && this.isValidDate(dateB)) {
              comparison = moment(dateA).diff(moment(dateB));
            } else if (this.isValidDate(dateA)) {
              return -1;
            } else if (this.isValidDate(dateB)) {
              return 1;
            }
            break;
          case 'status':
            if (this.isValidState(a.planning.state) && this.isValidState(b.planning.state)) {
              comparison = a.planning.state - b.planning.state;
            } else if (this.isValidState(a.planning.state)) {
              return -1;
            } else if (this.isValidState(b.planning.state)) {
              return 1;
            }
            break;
          case 'orderNo':
          default:
            if (this.isValidOrderNo(a.orderNo) && this.isValidOrderNo(b.orderNo)) {
              comparison = a.orderNo - b.orderNo;
            } else if (this.isValidOrderNo(a.orderNo)) {
              return -1;
            } else if (this.isValidOrderNo(b.orderNo)) {
              return 1;
            }
            break;
        }
        
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
</style>