<template>
  <div class="fixed bottom-5 left-5 z-50">
    <button
      @click="$emit('toggle')"
      class="px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center"
      :class="
        mapView ? 'bg-gray-800 bg-opacity-80' : 'bg-white bg-opacity-80 blur-md'
      "
    >
      <img
        class="w-6 h-6"
        :src="
          mapView == false
            ? require('@/assets/marker/mapstatus.svg')
            : require('@/assets/marker/mapstatus_white.svg')
        "
      />
    </button>
  </div>
</template>
  
  <script>
export default {
  name: "MapViewToggle",
  props: {
    mapView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle"],
};
</script>