<template>
  <modal
    name="zone-vehicles-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '70%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">Araç Listesi</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('zone-vehicles-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="w-full h-96 flex items-center justify-center">
      <i class="fas fa-spinner fa-3x text-gray-400 fa-spin"></i>
    </div>

    <!-- Modal Content -->
    <div v-if="!isLoading" class="pb-4 px-4 pt-2">
      <div >
        <LyrausTable
          :searchBar="true"
          :searchProp="search"
          @update-search-value="(val) => handleSearchValue(val)"
          :data="filteredList"
          :columns="columns"
          :alternateRowBg="['white', 'gray-100']"
          :containerClass="'px-2 p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm w-[50%]'"
          :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
          :innerTrClass="'px-2 whitespace-nowrap text-xxs bg-red-200 py-2'"
          :innerTdClass="'px-2 whitespace-nowrap text-xxs bg-red-200 py-2'"
          :tdClass="'text-sm p-1'"
          :totalCount="10" 
          :batchOperations="true"
        >
          <template v-slot:batchOperations>
            <div class="w-full ml-2">
              <async-btn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </template>

          <template v-slot:rowButtons="{ item, columnKey }">
            <div v-if="columnKey === 'capacity'">
              <span v-if="item.capacity>=18000 && item.capacity <=22000" class="fas fa-check text-green-400">Kırkayak</span>
              <span v-else>Çekici</span>
            </div>
          </template>
        </LyrausTable>
      </div>
    </div>
  </modal>
</template>

<script>
import AsyncBtn from '../../../../components/general/asyncBtn.vue';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
export default {
  name: "ZoneCreationModal",
  props:["List"],
  components: {
    AsyncBtn

  },
  data() {
    return {
      isLoading: false,
      loading: false,
      originalList: [...this.List], // Orijinal Listeyi Saklıyoruz
      filteredList: [...this.List], // Filtrelenmiş Listeyi Tutuyoruz
      columns:[
        {
          key:"vehicle",
          label:"Araç Plakası",
        },
        {
          key:"polygonScore",
          label:"Bekleme Skoru",
        },
        {
          key:"entryTime",
          label:"Giriş Tarihi",
        },
        {
          key:"capacity",
          label:"Araç Türü",
        },
       
        {
          key:"haveType",
          label:"Varlık Türü",
        },
        {
          key:"lastProduct",
          label:"Son Yük"
        },
        {
          key:"dorseType",
          label:"Dorse Türü",

        },
      ]
    };
  },
  watch: {
    List(newVal) {
      this.originalList = [...newVal]; // List güncellendiğinde orijinal listeyi de güncelle
      this.filteredList = [...newVal];
    }
  },
  methods: {
    handleSearchValue(val) {
       if (!val) {
        // Eğer arama kutusu boşsa, orijinal listeye geri dön
        this.filteredList = [...this.originalList];
      } else {
        // Eğer bir değer girilmişse, filtreleme yap
        this.filteredList = this.originalList.filter((item) =>
          item.vehicle.toLowerCase().includes(val.toLowerCase())
        );
      }
    },
    async excelExport() {
      try {
        const workbook = XLSX.utils.book_new();

        const headers = {
          vehicle: "Araç Plakası",
          polygonScore: "Bekleme Skoru",
          entryTime: "Giriş Tarihi",
          capacity: "Araç Türü",
          dorseType: "Dorse Türü",
          haveType: "Varlık Türü",
          lastProduct: "Son Yük"
        };

        const excelData = this.filteredList.map((item) => ({
          vehicle: item.vehicle,
          polygonScore: item.polygonScore,
          entryTime: item.entryTime,
          capacity: item.capacity,
          dorseType: item.dorseType,
          haveType: item.haveType,
          lastProduct: item.lastProduct,

        }));

        if (!excelData || !Array.isArray(excelData)) {
          throw new Error("Invalid data received");
        }

        // Create worksheet data with headers and rows
        const worksheet = XLSX.utils.aoa_to_sheet([
          Object.values(headers), // Headers row
          ...excelData.map((item) => [
            item.vehicle || "",
            item.polygonScore || "",
            item.entryTime || "",
            (item.capacity>=18000 && item.capacity <=22000)?"Kırkayak":"Çekici" || "",
            item.dorseType,
            item.haveType,
           item.lastProduct,
          ]),
        ]);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "vehicleScoresRaports");

        // Create and save the file
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(data, "vehicle-scores.xlsx");
      } catch (error) {
        console.error("Excel export failed:", error);
        // Handle error appropriately (maybe show an error message to user)
        if (this.errorBox) {
          this.errorBox(error);
        }
      }
    },
  }
};
</script>
