<template>
  <div class="flex flex-col items-center justify-center py-10">
    <div class="w-10 h-10 text-center">
      <i class="fas fa-spinner fa-spin text-red-500 text-2xl"></i>
    </div>
    <span class="text-sm text-gray-500 mt-2">Yükleniyor</span>
  </div>
</template>
  
  <script>
export default {
  name: "LoadingSpinner",
};
</script>