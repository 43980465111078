import { bulkPlanning } from "../../../../networking/urlmanager";
import axios from 'axios'
/**
 * Save planned orders with vehicle assignments
 * @param {string} token - Authentication token
 * @param {Object} bulkAndVehicleData - {bulkId , plaque , driverTC , driverName , dorse , stateId , isActive , rowId}
 * @returns {Promise<Object>} - The response data
 */
export const addVehicleExtraTour = async (token, bulkAndVehicleData) => {
    try {
        const response = await axios.post(
            bulkPlanning.addExtraTourVehicle,
            { ...bulkAndVehicleData },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error saving Vehicles Extra Tour:', error);
        throw error;
    }
};