import axios from 'axios';
import { orders } from '@/networking/urlmanager';

/**
 * Fetch orders with filtering options
 * @param {string} token - Authentication token
 * @param {string} search - Search term
 * @param {number} page - Page number
 * @param {number} isError - Error filter (0: show errors, 1: show valid)
 * @returns {Promise<Object>} - The order data
 */
export const fetchOrders = async (token, search = '', page = 0, isError = 1,filterCenter=null) => {
    try {

        const response = await axios.get(orders.getAll, {
            params: {
                search,
                page,
                isError,
                filterCenter:filterCenter.map(r=>r.name).join(',')
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data.data;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};

/**
 * Save planned orders with vehicle assignments
 * @param {string} token - Authentication token
 * @param {Array} orderList - List of orders with vehicle assignments
 * @returns {Promise<Object>} - The response data
 */
export const saveOrdersWithVehicles = async (token, orderList) => {
    try {
        const response = await axios.post(
            orders.applyVehicle,
            { orderList },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error saving orders with vehicles:', error);
        throw error;
    }
};

/**
 * Get order details by ID
 * @param {string} token - Authentication token
 * @param {string} orderId - Order ID
 * @returns {Promise<Object>} - The order details
 */
export const getOrderDetails = async (token, orderId) => {
    try {
        const response = await axios.get(`${orders.getById}/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data.data;
    } catch (error) {
        console.error('Error fetching order details:', error);
        throw error;
    }
};

/**
 * Add a new order
 * @param {string} token - Authentication token
 * @param {Object} orderData - Order data
 * @returns {Promise<Object>} - The created order
 */
export const createOrder = async (token, orderData) => {
    try {
        const response = await axios.post(
            orders.create,
            orderData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error creating order:', error);
        throw error;
    }
};

/**
 * Update an existing order
 * @param {string} token - Authentication token
 * @param {string} orderId - Order ID
 * @param {Object} orderData - Updated order data
 * @returns {Promise<Object>} - The updated order
 */
export const updateOrder = async (token, orderId, orderData) => {
    try {
        const response = await axios.put(
            `${orders.update}/${orderId}`,
            orderData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error updating order:', error);
        throw error;
    }
};

/**
 * Delete an order
 * @param {string} token - Authentication token
 * @param {string} orderId - Order ID
 * @returns {Promise<Object>} - The response data
 */
export const deleteOrder = async (token, orderId) => {
    try {
        const response = await axios.delete(`${orders.delete}/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error deleting order:', error);
        throw error;
    }
};