/**
 * recommendModules/helpers/preFilterVehicles.js
 * Araçları ön-filtreleyen yardımcı fonksiyon
 */

const { calculateDistancePriority } = require("../calculator/calculateDistancePriority");

/**
 * Araçların ön-filtrelenmesi için yardımcı fonksiyon
 * @param {Array} vehicles - Araçlar listesi
 * @param {Object} order - Sipariş detayları
 * @param {Function} isSuitableFn - Uygunluk kontrol fonksiyonu
 * @param {number} areaSensitivity - Mesafe hassasiyeti (km)
 * @returns {Array} Filtrelenmiş araçlar listesi
 */
function preFilterVehicles(vehicles, order, isSuitableFn, areaSensitivity = 200.0) {
  // Uygun araçları filtrele
  let suitableVehicles = vehicles?.filter(isSuitableFn);
  
  // 20 ton altı siparişler için ek filtreleme
  if (order.tonnage <= 20) {
    suitableVehicles = suitableVehicles.filter(vehicle => {
      const distancePriority = calculateDistancePriority(order, vehicle);
      return distancePriority < areaSensitivity;
    });
  }
  
  return suitableVehicles;
}

module.exports = preFilterVehicles;