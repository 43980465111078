// Gerekli yardımcı kütüphaneler
import { decode } from "@/utils/encoder";

/**
 * Harita ile ilgili yardımcı fonksiyonları içeren modül
 */
export const mapHelpers = {
    /**
     * Bölge merkez noktasını hesaplar
     * @param {Object} zone - Bölge nesnesi
     * @returns {Object} - {lat, lng} formatında merkez koordinatları
     */
    getZoneCenter(zone) {
        return {
            lat: parseFloat(zone.latitude),
            lng: parseFloat(zone.longitude),
        };
    },

    /**
     * Bölge için özel ikon oluşturur
     * @param {Object} zone - Bölge nesnesi 
     * @returns {Object} - Google Maps için ikon konfigürasyonu
     */
    getZoneIconWithTitle(zone) {
        // Bölge bilgilerini al
        const name = zone.name || "İsimsiz Bölge";

        // İsimden sadece ilk kelimeyi al
        const firstWord = this.getFirstWord(name);

        // Yuvarlak etiket için boyutlar
        const diameter = 45;

        // Bölge için renk al
        const color = this.getZoneColor(zone);

        // Font boyutu
        const fontSize = 10;

        // SVG çizimi - sadece ilk kelimeyi içeren basit daire
        const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${diameter}" height="${diameter}" viewBox="0 0 ${diameter} ${diameter}">
      <circle cx="${diameter / 2}" cy="${diameter / 2}" r="${diameter / 2 - 1}" fill="${color}" fill-opacity="0.85" stroke="white" stroke-width="1.5" />
      <text 
          x="${diameter / 2}" 
          y="${diameter / 2}" 
          font-family="Arial, sans-serif" 
          font-size="${fontSize}" 
          font-weight="bold" 
          fill="black" 
          text-anchor="middle"
          dominant-baseline="central"
      >${firstWord}</text>
    </svg>
    `;

        // İkon yapılandırmasını döndür
        return {
            url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
            scaledSize: { width: diameter, height: diameter },
            anchor: { x: diameter / 2, y: diameter / 2 }, // Ortalanmış
        };
    },

    /**
     * Metinden ilk kelimeyi alır
     * @param {string} fullName - Tam metin
     * @returns {string} - İlk kelime
     */
    getFirstWord(fullName) {
        if (!fullName) return "";
        const words = fullName.split(" ");
        return words[0]; // Sadece ilk kelime
    },

    /**
     * Bölge için renk döndürür
     * @param {Object} zone - Bölge nesnesi
     * @returns {string} - Renk kodu (hex)
     */
    getZoneColor(zone) {
        // Özelleştirilebilir renk dizisi
        const zoneColors = ["#00ffff"];

        // Bölge ID'sine göre renk seçimi
        return zoneColors[zone.id % zoneColors.length];
    },

    /**
     * Noktanın polygon içinde olup olmadığını kontrol eder
     * @param {Object} point - {lat, lng} formatında nokta
     * @param {Array} polygon - {lat, lng} nesnelerinden oluşan dizi
     * @returns {boolean} - Nokta polygon içindeyse true, değilse false
     */
    isPointInPolygon(point, polygon) {
        if (!polygon || polygon.length === 0) return false;

        // Ray-casting algoritması kullanarak noktanın polygon içinde olup olmadığını kontrol et
        let inside = false;

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].lng;
            const yi = polygon[i].lat;
            const xj = polygon[j].lng;
            const yj = polygon[j].lat;

            const intersect =
                yi > point.lat !== yj > point.lat &&
                point.lng < ((xj - xi) * (point.lat - yi)) / (yj - yi) + xi;

            if (intersect) inside = !inside;
        }

        return inside;
    },

    /**
     * Belirli bir bölgede bulunan araçları filtreler
     * @param {Object} zone - Bölge nesnesi
     * @param {Array} vehicles - Tüm araçlar listesi
     * @returns {Array} - Bölge içindeki araçlar
     */
    getVehiclesInZone(zone, vehicles) {
        if (!zone || !vehicles || vehicles.length === 0) return [];

        // Bölge polygon'unu çöz
        const zonePolygon = decode(zone.polyline);

        // Polygon içinde olan araçları filtrele
        return vehicles.filter((vehicle) => {
            const vehiclePos = {
                lat: parseFloat(vehicle.latitude),
                lng: parseFloat(vehicle.longitude),
            };
            return this.isPointInPolygon(vehiclePos, zonePolygon);
        });
    },

    /**
     * Zoom seviyesine göre ölçek faktörü hesaplar
     * @param {number} zoom - Zoom seviyesi
     * @returns {number} - Ölçek faktörü
     */
    getScaleFactorForZoom(zoom) {
        if (zoom >= 16) return 0.8;      // Çok yakın zoom
        if (zoom >= 14) return 0.9;      // Yakın zoom
        if (zoom >= 12) return 1;        // Normal zoom (değişim yok)
        if (zoom >= 10) return 1.2;      // Uzak zoom (biraz büyüt)
        return 1.2;                     // Çok uzak zoom (daha büyük)
    }
};