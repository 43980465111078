<template>
  <div class="w-full h-full bg-gray-200 dark:bg-gray-900">
    <!-- Yan Paneller -->
    <SideBarItem
      @selected="handleFleetSelected"
      @selectedGroup="handleGroupSelected"
      @selectedItem="setPlaqueSelected"
      @closeModal="resetItemDetail"
      @changeIntervention="updateInterventionStatus"
      @changeInterventionList="updateInterventionList"
    />
    <!-- ARAÇ DETAY -->
    <ProductSideBarItem
      @backMenu="backProduct"
      ref="prodDetail"
      :detail="detail"
      :isVehicleDetailShow="isVehicleDetailShow"
      @closeModal="()=>{this.detail={}; resetItemDetail();}"
      @selectedActiveDetail="updateActiveDetail"
    />

    <FilterPanel
      :detail="filter"
      ref="filterPanel"
      @change="updateFilters"
    />

    <GroupDetailPanel
      @close="resetItemDetail"
      :List="hoverList"
      v-if="hoverList.length"
      @selected="setPlaqueSelected"
    />
    
    <!-- Detay Modal -->
    <detailModal :detailList="markers" />
    
    <!-- Kontrol Butonları -->
    <MapControls 
      :showZones="showZones"
      :showFactories="showFactories"
      :search="search"
      @toggleZones="toggleZones"
      @toggleFactories="toggleFactories"
      @searchChanged="updateSearch"
      @searchSubmitted="performSearchOnEnter"
      @openFilterPanel="openFilterPanel"
      @openSummary="openSummary"
    />

    <!-- Harita Görünüm Değiştirme -->
    <MapViewToggle 
      :mapView="$store.state.mapView"
      @toggle="mapstatus"
    />

    <!-- Yükleniyor Göstergesi -->
    <LoadingSystem :loading="load" />

    <!-- Ana Harita Bileşeni -->
    <MapContainer
      ref="mapContainer"
      :center="position"
      :zoom="zoomLevel"
      :mapTypeId="$store.state.mapView ? 'hybrid' : 'roadmap'"
      @mapClick="handleMapClick"
    >
      <!-- Bölge Paneli -->
      <ZoneDisplay
      v-if="showZones"
      :vehicles="originalVehicleList"
      :visible="showZones"
      :mapObject="gmapObject"
      @zoneSelected="handleZoneSelected"
      @zoneLoaded="handleZoneLoaded"
    />
      <!-- @vehicleSelected="handleVehicleSelected" -->

      <!-- Araç İşaretçileri -->
      <VehicleMarkers
        v-if="!selectedItem"
        :markers="markers"
        :selectedGroup="selectedGroup"
        :selectedFleet="selectedFleet"
        :filter="filter"
        @clusterClick="handleClusterMouseOver"
        @markerClick="showItemDetail"
      />

      <!-- Fabrika/Müşteri İşaretçileri -->
      <FactoryMarkers
        v-if="!selectedItem && showFactories"
        :markers="vectorMarkers"
        @clusterClick="handleClusterMouseOver"
        @markerClick="showItemDetail"
      />

      <!-- Seçili Öğe ve Rota Gösterimi -->
      <SelectedItemDisplay
        v-if="selectedItem"
        :selectedItem="selectedItem"
        :selectedActiveDetail="selectedActiveDetail"
        @markerClick="showItemDetail"
      />
    </MapContainer>
  </div>
</template>

<script>
// Npm paketleri
import moment from "moment";

// Yerel Bileşenler

// özet modalı
import detailModal from "./components/Dialogs/summary/index.vue";

import SideBarItem from "./components/Dialogs/sidebar/index.vue";
import ProductSideBarItem from "./components/Dialogs/vehicleDetail/index.vue";
import FilterPanel from "./components/Dialogs/filter/index.vue";

import GroupDetailPanel from "./components/Dialogs/groupDetail/index.vue";
import ZoneDisplay from './components/map/plannedRegions';
import LoadingSystem from './components/spinner.vue';
import MapControls from './components/map/MapControls.vue';
import MapViewToggle from './components/map/MapViewToggle.vue';
import MapContainer from './components/map/MapContainer.vue';
import VehicleMarkers from './components/map/VehicleMarkers.vue';
import FactoryMarkers from './components/map/FactoryMarkers.vue';
import SelectedItemDisplay from './components/map/SelectedItemDisplay.vue';

// Servisler
import { trackingService } from './services/api';
import { mapHelpers } from './services/mapHelpers';

export default {
  name: "maps-page",
  components: {
    SideBarItem,
    ProductSideBarItem,
    FilterPanel,
    GroupDetailPanel,
    detailModal,
    ZoneDisplay,
    LoadingSystem,
    MapControls,
    MapViewToggle,
    MapContainer,
    VehicleMarkers,
    FactoryMarkers,
    SelectedItemDisplay
  },
  data() {
    return {
      // Harita durumu
      position: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      
      // Veriler
      markers: [],
      vectorMarkers: [],
      originalVehicleList: [],
      
      // Seçim durumu
      selectedGroup: "",
      selectedFleet: "",
      selectedItem: null,
      
      // UI durumu
      hoverListType: "vehicle",
      showFactories: false,
      showZones: false,
      hoverList: [],
      load: false,
      
      // Filtre durumu
      filter: {
        region: { key: null, id: 1 },
        workStatus: 1,
        productId: null,
        productName: null,
        deliveryDate: { start: null, end: null },
        deliveryState: 1,
      },
      
      // Detay durumu
      selectedActiveDetail: {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      },
      
      // Arama durumu
      search: "",
      isSearch: false,
      
      // Müdahale durumu
      isIntervention: false,
      interventionList: [],
      
      detail: {},
      isVehicleDetailShow:false,
      gmapObject: null,
      
      // Diğer
      sync: {
        date: moment().fromNow(),
        state: false,
      },
      filterLoad: true,
      isRequestPending: true,
      needInterval: true,
    };
  },
  
  methods: {
    // Harita etkileşimleri
    handleZoneSelected(data) {
      this.position = data.center;
      this.zoomLevel = data.zoom;
    },
    
    handleZoneLoaded(center) {
      if (this.showZones && center) {
        this.position = center;
        this.zoomLevel = 8;
      }
    },
    
    handleMapClick() {
      if (this.showZones && this.$refs.zoneMap) {
        this.$refs.zoneMap.selectedZone = null;
      }
    },
    
    // Panel kontrolleri
    backProduct() {
      this.selectedItem = null;
    },
    
    mapstatus() {
      this.$store.commit("toggleMapStatus");
    },
    
    toggleFactories() {
      this.resetItemDetail();
      this.$refs.prodDetail.hideModal();
      this.isVehicleDetailShow=false;
      this.showFactories = !this.showFactories;
    },
    
    toggleZones() {
      this.resetItemDetail();
      this.$refs.prodDetail.hideModal();
      this.isVehicleDetailShow=false;
      this.showZones = !this.showZones;
      if (this.showZones) {
        this.showFactories = false;
      }
    },
    
    // Detay işlemleri
    showItemDetail(item, type) {
      this.selectedItem = item;
      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
      this.hoverList = [];
      if ("plate" in item) {
        this.$refs.prodDetail.showModal();
        this.isVehicleDetailShow=true;
      }
      // if (type === "vehicle") {
      //   this.$refs.prodDetail.showModal();
      // }
    },
    
    resetItemDetail() {
      this.isVehicleDetailShow=false;
      this.hoverList = [];
      this.selectedItem = null;
      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
    },
    
    // Küme işlemleri
    handleClusterMouseOver(cluster, type) {
      if (typeof cluster.markers_ != "undefined") {
        this.hoverListType = type;
        this.hoverList = cluster.markers_;
      }
    },
    
    // Arama ve filtre
    performSearchOnEnter() {
      this.isSearch = true;
      this.applyFilters();
      if (this.search == "") {
        this.isSearch = false;
      }
      if (this.selectedItem) {
        this.$refs.prodDetail.hideModal();
        this.isVehicleDetailShow=false;
        this.resetItemDetail();
      }
    },
    
    updateSearch(value) {
      this.search = value;
      if (value.length == 0) this.performSearchOnEnter();
    },
    
    updateFilters(newFilters) {
      this.filter = newFilters;
      this.applyFilters();
    },
    
    // Data yükleme
    async getAll(load = true) {
      this.load = load;
      try {
        const data = await trackingService.getAll(this.$store.state.userData.token);
        this.originalVehicleList = data;
        this.applyFilters();
      } catch (error) {
        console.error("Veri yüklenirken hata:", error);
      } finally {
        this.load = false;
        this.isRequestPending = false;
        this.filterLoad = false;
      }
    },
    async getAllVectors() {
      try {
        const data = await trackingService.getVectors(this.$store.state.userData.token);
        this.vectorMarkers = data.map(vectorItem => mapHelpers.mapVectorToMarker(vectorItem));
      } catch (error) {
        console.error("Vektör verisi yüklenirken hata:", error);
      }
    },
    
    // Filtre uygulama
    async applyFilters() {
      let vehicleList = this.originalVehicleList;
      
      // Filtre ve grup seçimi
      if (this.selectedGroup === undefined) {
        this.selectedGroup = 0;
      }

      if (this.selectedFleet > 0 || this.selectedGroup > 0) {
        vehicleList = vehicleList.filter((vehicle) => {
          const hasMatchingFleet =
            this.selectedFleet === 0 || vehicle.fleetId === this.selectedFleet;

          const hasMatchingGroup =
            this.selectedGroup === 0 ||
            (Array.isArray(this.selectedGroup)
              ? this.selectedGroup.includes(vehicle.groupId)
              : vehicle.groupId === this.selectedGroup);

          return hasMatchingFleet && hasMatchingGroup;
        });
      }

      // Arama
      let list = this.isSearch
        ? await this.filterBySearch(vehicleList, this.search)
        : vehicleList;

      // Diğer filtreler ve marker'a dönüştürme
      this.markers = this.filterAndMapMarkers(list);

      // Seçili öğeyi güncelle
      if (this.markers.length > 0) {
        this.updateSelectedItem();
      } else {
        this.markers = [];
      }
    },
    
    filterBySearch(data, search) {
      const normalizedSearch = this.normalizeText(search);
      return data.filter(
        (item) =>
          this.normalizeText(item.vehicle).includes(normalizedSearch) ||
          this.normalizeText(item.driverName).includes(normalizedSearch) ||
          this.normalizeText(item.orderNo) === normalizedSearch ||
          (item.orderNos &&
            item.orderNos.some(
              (orderNo) => this.normalizeText(orderNo) === normalizedSearch
            )) ||
          (item?.planning.customer &&
            this.normalizeText(item.planning.customer).includes(
              normalizedSearch
            ))
      );
    },
    
    normalizeText(text) {
      return (
        text
          ?.toString()
          .toLocaleLowerCase("tr-TR")
          .replace(/[\u0300-\u036f\s]/g, "") || ""
      );
    },
    
    filterAndMapMarkers(list) {
      const {
        workStatus,
        productId,
        deliveryDate,
        deliveryState,
        productName,
      } = this.filter;

      return list
        .filter(
          (el) =>
            (!this.filter.region.key ||
              this.filter.region.key.toLowerCase().trim() ===
                el.region.toLowerCase().trim()) &&
            (workStatus === 1 || workStatus - 2 === Number(el.state)) &&
            (!productId || mapHelpers.productController(el.productId, productName)) &&
            (deliveryState === 1 ||
              mapHelpers.isDeliveryDateInRange(el.deliveryDate, deliveryDate))
        )
        .map((el) => mapHelpers.mapVehicleToMarker(el));
    },
    
    updateSelectedItem() {
      const selectedItem = this.markers.find(
        (item) => item.vehicle === this.selectedItem?.vehicle
      );

      this.selectedItem = selectedItem || this.selectedItem;
    },
    
    // Diğer metotlar
    getDetail(data) {
      setTimeout(() => {
        this.position = data.position;
        this.detail = data.isPirateDriver 
          ? {...data, outpointAdress: data.planning.outpointAdress, targetAdress: data.planning.targetAdress} 
          : data;
      }, 50);

      this.zoomLevel = 9;
    },
    
    setPlaqueSelected(val) {
      const markers =
      this.hoverListType === "vehicle" ? this.markers : this.vectorMarkers;
      this.selectedItem = {...markers?.find((r) => r.title.includes(val))};
      this.detail= {...markers?.find((r) => r.title.includes(val))};
      if (this.hoverListType === "vehicle") {
        this.$refs.prodDetail.showModal();
        this.isVehicleDetailShow=true;
      }
    },
    // Event handler'lar
    handleFleetSelected(value) {
      this.selectedGroup = 0;
      this.selectedFleet = value;
    },
    handleGroupSelected(value) {
      this.selectedGroup = value;
    },
    
    updateActiveDetail(value) {
      this.selectedActiveDetail = value;
    },
    
    updateInterventionStatus(value) {
      this.isIntervention = value;
    },
    
    updateInterventionList(value) {
      this.interventionList = value;
    },
    
    openFilterPanel() {
      this.$refs.filterPanel.showModal();
    },
    
    openSummary() {
      this.$modal.show('planning-state-modal');
    }
  },
  
  computed: {
    deviationMarkers() {
      return this.filterList().filter((m) => m.isDeviation);
    },
    
    nonDeviationMarkers() {
      return this.filterList().filter((m) => !m.isDeviation);
    },
    
    filterList() {
      if (this.isIntervention) return this.interventionList;
      else if (!this.selectedItem) return this.markers;
      else return [this.detail];
    }
  },
  
  created() {
    this.getAll();
    this.getAllVectors();
  },
  
  watch: {
    selectedItem(val) {
      if (!val) return;
      this.getDetail(val);
    },
    
    selectedFleet() {
      this.applyFilters();
    },
    
    selectedGroup() {
      this.applyFilters();
    }
  }
};
</script>