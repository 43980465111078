<template>
  <modal
    name="edit-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '55%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="3" :rowId="rowId" />
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 flex items-center justify-between"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.planning.modal.editTitle") }}
      </h4>

      <div class="flex justify-end w-1/12">
        <logBtn class="mr-2" />
        <button class="p-2" @click="close()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <!-- Order identifier fields -->
      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderNo"
            :disabled="true"
            title="Sipariş Numarası"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            :disabled="true"
            v-model="orderName"
            title="Sipariş Adı"
          />
        </div>
      </div>

      <!-- Core form fields component -->
      <FormFields
        :orderDate="orderDate"
        :complateDate.sync="complateDate"
        :customer="customer"
        :invoicedCompany="invoicedCompany"
        :receivedName="receivedName"
        :deliveredName="deliveredName"
        :productType="productType"
        :amount="amount"
        :unitType="unitType"
        :isEdit="true"
      />

      <!-- Date and location related fields -->
      <LocationFields
        :fillingDate.sync="fillingDate"
        :deliveryDate="deliveryDate"
        :fillingPoint="fillingPoint"
        :deliveryPoint="deliveryPoint"
        :waypointsList="waypointsList"
        :vehicle="vehicle"
        :orderDate="orderDate"
        :disabled="isDisabled"
        @changeStartPosition="(val) => (fillingPoint = val)"
        @changeEndPosition="(val) => (deliveryPoint = val)"
        @changeOutpointId="(r) => (receivedName = r)"
        @changeCustomerId="(r) => (deliveredName = r)"
        @changePolyline="(r) => changePolyline(r)"
        @change="(r) => (costDistribution = r)"
        :isEdit="true"
      />

      <!-- Vehicle information -->
      <VehicleFields :vehicle="vehicle" :dorse="dorse" :personnel="personnel" />

      <!-- Cost calculation fields -->
      <CostCalculation
        :hakedisKilometer.sync="hakedisKilometer"
        :transitionCount.sync="transitionCount"
        :totalFuelPrice.sync="totalFuelPrice"
        :otherExpensePrice.sync="otherExpensePrice"
        :tonnagePrice.sync="tonnagePrice"
        :costDistribution.sync="costDistribution"
        :expensivePrice.sync="expensivePrice"
        :totalPrice.sync="totalPrice"
        :isOneWay.sync="isOneWay"
        :disabled="isDisabled"
        :isEdit="true"
      />

      <!-- Additional options -->
      <OptionFields
        :isAmortisman.sync="isAmortisman"
        :isOneWay.sync="isOneWay"
        :dayCount.sync="dayCount"
        :disabled="isDisabled"
        :isEdit="true"
      />

      <!-- Notes field -->
      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          v-model="explanation"
          :title="$t('general.note')"
          maxlength="500"
          :disabled="isDisabled"
        />
      </div>

      <!-- Save button (only shown when not disabled) -->
      <div class="w-full mt-4 text-right" v-if="!isDisabled">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          :disabled="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>
    
  <script>
// NPM
import _ from "lodash";
import moment from "moment";

// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

// Local Components
import FormFields from "../addModal/components/FormFields.vue";
import LocationFields from "../addModal/components/LocationFields.vue";
import VehicleFields from "../addModal/components/VehicleFields.vue";
import CostCalculation from "../addModal/components/CostCalculation.vue";
import OptionFields from "../addModal/components/OptionFields.vue";

// Utils and API
import { getDetailById } from "../addModal/api/planningService";
import { calculatePrice, changePolyline } from "../addModal/utils/calculations";

export default {
  name: "edit-plan-modal",
  props: {
    rowId: {
      type: [Number, String],
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    diffInput,
    asyncBtn,
    logModule,
    logBtn,
    FormFields,
    LocationFields,
    VehicleFields,
    CostCalculation,
    OptionFields,
  },
  data() {
    return {
      isPirateDriver: "",
      phone: "",
      orderNo: "",
      orderCost: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",
      transitionCount: 1,
      personnel: "",
      personnelTC: "",
      vehicle: "",
      dorse: "",
      driver: "",
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      order: "",

      outpointLat: "",
      outpointLng: "",
      targetPointLat: "",
      targetPointLng: "",
      fillingCustomerId: "",
      deliveryCustomerId: "",

      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",

      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 1,
      isWashing: 0,

      expenseDetail: {},
      waypointsList: [],
      load: false,
      vehicleArrivalTime: "",
    };
  },
  methods: {
    close() {
      this.resetAll();
      this.$modal.hide("edit-plan-modal");
    },

    resetAll() {
      // Reset all form data
      Object.assign(this.$data, this.$options.data.call(this));
    },

    async getDetail() {
      try {
        const result = await getDetailById(
          this.rowId,
          this.$store.state.userData.token
        );

        const data = result.data.data;

        // Map all the data to our component
        this.mapDetailDataToForm(data);

        // Calculate prices based on loaded data
        await this.calculatePrice();
      } catch (error) {
        console.error("Error getting plan details:", error);
        this.$swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sipariş detayları alınırken bir hata oluştu.",
          showConfirmButton: true,
          confirmButtonText: "Tamam",
        });
      }
    },

    mapDetailDataToForm(data) {
      // Map basic fields
      const basicFields = [
        "orderNo",
        "orderName",
        "orderDate",
        "complateDate",
        "customer",
        "invoicedCompany",
        "receivedName",
        "deliveredName",
        "productType",
        "amount",
        "unitType",
        "fillingDate",
        "deliveryDate",
        "transitionCount",
        "isPirateDriver",
        "phone",
        "isWashing",
        "totalPrice",
        "explanation",
      ];

      basicFields.forEach((field) => {
        if (field in data) {
          this[field] = data[field];
        }
      });

      // Special fields
      this.hakedisKilometer = data.kilometer;
      this.personnel = data.driver;
      this.personnelTC = data.driverTC;
      this.vehicle = data.vehicle;
      this.dorse = data.dorse;
      this.expensivePrice = data.expensePrice;
      this.polyline = data.polyline;
      this.isAmortisman = data.isAmortisman || 1;
      this.isOneWay = data.isOneWay || 1;
      this.dayCount = data.dayCount || 1;

      // Set location data
      this.fillingPoint = {
        lat: parseFloat(data.outpointLat),
        lng: parseFloat(data.outpointLng),
        adress: data.outpointAdress,
      };

      this.deliveryPoint = {
        lat: parseFloat(data.targetPointLat),
        lng: parseFloat(data.targetPointLng),
        adress: data.targetAdress,
      };


      // Parse waypoints if they exist
      if (data.waypoints) {
        try {
          const waypointsList =
            typeof data.waypoints === "string"
              ? JSON.parse(data.waypoints)
              : data.waypoints;


          this.waypointsList = waypointsList.filter(r=>Number(r.no)>0).map((item) => ({
            date: item.date,
            no: item.no,
            amount: item.amount,
            position: {
              adress: item.adress,
              lat: Number(item.latitude),
              lng: Number(item.longitude),
            },
          }));
        } catch (e) {
          console.error("Error parsing waypoints:", e);
          this.waypointsList = [];
        }
      }

      // Store full data for calculations
      this.order = data;
    },


    changePolyline(val) {
      this.polyLine = changePolyline(val);
    },

    async calculatePrice() {
      const result = await calculatePrice(
        this.$store.state.userData.token,
        this.hakedisKilometer,
        this.transitionCount,
        this.vehicle,
        this.order,
        this.costDistribution,
        this.isAmortisman,
        this.isOneWay,
        this.dayCount
      );

      this.expenseDetail = result.expenseDetail;
      this.totalFuelPrice = result.totalFuelPrice;
      this.otherExpensePrice = result.otherExpensePrice;
      this.tonnagePrice = result.tonnagePrice;
      this.expensivePrice = result.expensivePrice;
    },
  },
  computed: {
    getMinFillingDate() {
      return moment(this.orderDate).format("YYYY-MM-DD HH:mm");
    },

    isMobile() {
      return window.innerWidth < 768;
    },
  },
  watch: {
    explanation(newExplanation) {
      this.isNoteRequired = newExplanation !== "";
    },

    async transitionCount() {
      await this.calculatePrice();
    },

    async costDistribution(val) {
      this.hakedisKilometer = val.totalKilometer;
      await this.calculatePrice();
    },

    async isAmortisman() {
      await this.calculatePrice();
    },

    isOneWay() {
      this.calculatePrice();
    },

    dayCount() {
      this.calculatePrice();
    },
  },
};
</script>