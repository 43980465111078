<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="fixed top-5 right-5 bg-red-600 text-white px-5 py-3 rounded-md shadow-lg flex items-center space-x-3 z-50"
    >
      <i class="fas fa-exclamation-circle fa-lg"></i>
      <span>Araç konumu bulunamadı! Lütfen takip sistemini kontrol edin.</span>
      <button @click="visible = false" class="ml-3 focus:outline-none">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: "no-vehicle-location-toast",
  data() {
    return {
      visible: true, // İlk açıldığında görünür olacak
    };
  },
  mounted() {
    // 5 saniye sonra otomatik kapanması için
    setTimeout(() => {
      this.visible = false;
    }, 7000);
  },
};
</script>

<style scoped>
/* Yumuşak fade animasyonu */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
