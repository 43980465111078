<template>
  <div v-if="item">
    <GmapMap
      :center="{ lat: item.targetPointLat, lng: item.targetPointLng }"
      ref="gmap"
      :zoom="10"
      :style="{ width: '100%', height: height ? height : '55vh' }"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
    >
      <GmapPolyline
        v-bind:path.sync="polylineList"
        v-bind:options="{ strokeWidth: 20, strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>

      <GmapMarker
        title="'Yükleme Noktası'"
        :label="{
          text: 'Yükleme Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="{ lat: item?.outpointLat, lng: item?.outpointLng }"
        :icon="require('@/assets/marker/waypoint.png')"
      />

      <GmapMarker
        title="'Teslim Noktası'"
        :label="{
          text: 'Teslim Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="{
          lat: item?.targetPointLat,
          lng: item?.targetPointLng,
        }"
        :icon="require('@/assets/marker/flag.png')"
      />
    </GmapMap>
  </div>
</template>

<script>
import { decode } from "@/utils/encoder";

export default {
  name: "preview-map",
  props: ["item", "height", "polyline"],
  data() {
    return {
      polylineList: [],
    };
  },
  methods: {
    getVehicleRoute() {
        this.polylineList = decode(this.polyline).polyline;
    },
  },
  created() {
    this.getVehicleRoute();
  },
};
</script>
