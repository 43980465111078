<template>
  <modal name="pirate-driver-plan-modal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '60%'"
    :scrollable="true" @opened="getDetail()">
    <div class="p-4">
      <div class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200">
        <h4 class="inline-block w-11/12">
          Listeye Korsan Araç Ekle
        </h4>
        <button class="p-2 w-1/12" @click="$modal.hide('pirate-driver-plan-modal')">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <form @submit.prevent="save"
        class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3">
        <div class="md:flex">
          <div class="w-full md:w-6/12 mt-4">
            <diffInput type="text" minlength="1" maxlength="50" :required="true" v-model="plate" title="Plaka" />
          </div>
          <div class="w-full md:w-6/12 mt-4 md:pl-2">
            <diffInput type="phone" minlength="1" :max="10" :required="true" v-model="driverPhone"
              title="Sürücü Telefon numarası" />
          </div>
        </div>
        <div class="md:flex">
          <div class="w-full md:w-6/12 mt-4">
            <diffInput type="text" minlength="1" maxlength="50" :required="true" v-model="dorse" title="Dorse" />
          </div>
          <div class="w-full md:w-6/12 mt-4 md:pl-2">
            <diffInput type="text" minlength="1" maxlength="50" :required="true" v-model="driverName"
              title="Sürücü İsmi" />
          </div>
        </div>
        <div class="w-full mt-4 text-right">
          <asyncBtn icon="fas fa-plus mr-1.5" :text="'Ekle'" :loadTitle="$t('general.saveButtonLoadTitle')"
            class="w-full md:w-56" />
        </div>
      </form>
      <vehicleList :List="vehicles" @deleteVehicle="deleteVehicle" />
      <div class="w-full mt-4 text-right">
        <asyncBtn icon="fas fa-check" :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')" :loadState="load" class="w-full" @click="bulkSave()" />
      </div>
    </div>
  </modal>
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";

// Networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";

// Local Components
import routeViewiver from "@/components/devItem/route-generator/index.vue";
import vehicleList from "./priatedVehicles.vue";
export default {
  name: "piratedriver-plan-modal",
  props: ["rowId", "item", "orderNo", "load"],
  components: {
    diffInput,
    asyncBtn,
    gmapInputVue,
    routeViewiver,
    vehicleList
  },
  data() {
    return {
      vehicles: [],
      plate: "",
      driverPhone: "",
      dorse: "",
      driverName: ""
    };
  },
  methods: {
    resetAll() {
      this.plate = "";
      this.driverPhone = "";
      this.dorse = "";
      this.driverName = "";
    },
    bulkSave() {
      this.$emit("submit", this.vehicles);
      this.vehicles = [];
    },
    save() {
      const isExist = this.vehicles.find(x => x.plate == this.plate);
      if (isExist) {
        this.swalBox("warning", "Uyarı", "Bu aracı zaten eklediniz.", false, this.$t("general.OkayTitle"));
        return;
      }
      if (this.driverPhone.length > 11 || this.driverPhone.length < 10) {
        this.swalBox("warning", "Uyarı", "Lütfen telefon numarasını kontrol ediniz.", false, this.$t("general.OkayTitle"));
        this.load = false;
        return;
      }

      const randomTC = "00" + Math.floor(100000000 + Math.random() * 900000000);
      const lastFourDigits = randomTC.slice(-4);
    this.normalizePlate(this.plate)
      const newVehicle = {
        vehicle: this.normalizePlate(`${this.plate}-${lastFourDigits}`),
        plate: this.plate,
        dorse: this.normalizePlate(this.dorse),
        driverName: `${this.driverName}-${lastFourDigits}`,
        isCustom: 1,
        driverTC: randomTC,
        capacity: 26,
        longitude: this.item?.fillingLongitude,
        latitude: this.item?.fillingLatitude,
        isWashing: 1,
        phone: this.driverPhone
      };

      this.vehicles.push(newVehicle);
      this.resetAll();
    },
    deleteVehicle(item, index) {
      this.vehicles.splice(index, 1);
    },
    normalizePlate(plate) {
        if (plate === null || plate === '' || plate == undefined) {
            return "";
        }
        return plate.toUpperCase().replace(/\s/g, "");
    },

  },
};
</script>
