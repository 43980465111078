<!-- VehicleLocationDetail.vue (UX İyileştirmeli) -->
<template>
  <modal
    name="location-detail-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '70%'"
    :scrollable="true"
    @opened="getAll()"
    @closed="reset()"
    :clickToClose="false"
    transition="fade"
  >
    <div class="w-full relative" style="height:70vh">
      <!-- Header Bar -->
      <header-bar
        :vehicle="vehicle"
        :driver-name="detail.driverName"
        :is-map-view="$store.state.mapView"
        @close="$modal.hide('location-detail-modal')"
      />

      <!-- Yükleniyor İndikatörü -->
      <loading-indicator v-if="load" />

      <!-- Harita Görünüm Kontrol Paneli -->
      <map-control-panel
        :is-map-view="$store.state.mapView"
        @refresh="refreshData"
        @toggle-traffic="toggleTrafficLayer"
        @zoom-in="zoomIn"
        @zoom-out="zoomOut"
        @center-map="centerToVehicle"
      />

      <!-- Harita Alanı -->
      <map-display
        :position="position"
        :zoom-level="zoomLevel"
        :map-type="$store.state.mapView ? 'hybrid' : 'roadmap'"
        :markers="markers"
        :state-id="stateId"
        :detail="detail"
        :task-detail="taskDetail"
        :show-traffic="showTrafficLayer"
        ref="mapDisplay"
        @map-clicked="handleMapClick"
        @marker-clicked="handleMarkerClick"
      />

      <!-- Yolculuk Bilgileri Kartı -->
      <journey-info-card
        v-if="!load && markers.length && isJourneyStateActive()"
        :detail="detail"
        :task-detail="taskDetail"
        class="transition-all duration-300"
        :class="isInfoCardCollapsed ? 'h-12 overflow-hidden' : ''"
        @toggle-collapse="isInfoCardCollapsed = !isInfoCardCollapsed"
      />

      <!-- Araç Konum Bulunamadı Hatası -->
      <no-vehicle-location v-if="!load && markers.length === 0" @retry="refreshData" />

      <!-- Detay Bilgiler Panel -->
      <vehicle-details-panel
        v-if="!load && markers.length && showDetailsPanel"
        :vehicle-data="detail"
        @close="showDetailsPanel = false"
        class="transition-all"
      />
    </div>
  </modal>
</template>

<script>
// Alt Bileşenler
import HeaderBar from "./components/HeaderBar.vue";
import LoadingIndicator from "./components/LoadingIndicator.vue";
import MapControlPanel from "./components/MapControlPanel.vue";
import MapDisplay from "./components/MapDisplay.vue";
import JourneyInfoCard from "./components/JourneyInfoCard.vue";
import NoVehicleLocation from "./components/NoVehicleLocation.vue";
import VehicleDetailsPanel from "./components/VehicleDetailsPanel.vue";

// NPM
import moment from "moment";

// Networking
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
import { routeEngine } from "@/networking/routeEngine";

//Utils
import { decode } from "../../../../../../utils/encoder";

export default {
  name: "vehicle-location-detail",
  components: {
    HeaderBar,
    LoadingIndicator,
    MapControlPanel,
    MapDisplay,
    JourneyInfoCard,
    NoVehicleLocation,
    VehicleDetailsPanel,
  },
  props: ["vehicle", "stateId"],
  data() {
    return {
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      markers: [],
      detail: {},
      taskDetail: {},
      load: true,
      showTrafficLayer: false,
      isInfoCardCollapsed: false,
      showDetailsPanel: false,
      refreshInterval: null,
      lastUpdateTime: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    timeElapsedSinceUpdate() {
      if (!this.lastUpdateTime) return null;
      const now = moment();
      const duration = moment.duration(now.diff(this.lastUpdateTime));
      return Math.floor(duration.asMinutes());
    },
  },
  mounted() {
    // Otomatik yenileme için interval başlat (2 dakikada bir)
    this.refreshInterval = setInterval(() => {
      if (!this.load) {
        this.refreshData(false); // Sessiz yenileme
      }
    }, 2 * 60 * 1000);

    // Harita boyutunu pencere boyutuna göre ayarla
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // Interval'i temizle
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // Pencere boyutu değiştiğinde harita bileşenini güncelle
      if (this.$refs.mapDisplay) {
        this.$refs.mapDisplay.resizeMap();
      }
    },
    isJourneyStateActive() {
      return (
        this.stateId == 1 || this.stateId == 2 || this.stateId == 3 || this.stateId == 5
      );
    },
    refreshData(showLoadingIndicator = true) {
      // Verileri yeniden yükle
      this.getAll(showLoadingIndicator);
    },
    getAll(showLoadingIndicator = true) {
      if (showLoadingIndicator) {
        this.markers = [];
        this.detail = {};
        this.taskDetail = {};
        this.load = true;
      }

      axios
        .get(tracking.getAll + "?targetVehicle=" + this.vehicle, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.load = false;
          this.lastUpdateTime = moment();

          if (Array.isArray(res.data.data)) {
            if (res.data.data.length > 0) {
              let markers = res.data.data.filter(
                (r) =>
                  r.vehicle.toLowerCase() == this.vehicle.toLowerCase().replace(/\s/g, "")
              );

              this.center = {
                lat: markers[0].latitude,
                lng: markers[0].longitude,
              };

              this.position = {
                lat: markers[0].latitude,
                lng: markers[0].longitude,
              };

              this.markers = this.processMarkers(markers);
              this.detail = this.markers[0];
              this.zoomLevel = 8;
              this.getRoute();

              // Bildirim göster (sadece sessiz yenileme ise)
              if (!showLoadingIndicator) {
                this.showUpdateNotification();
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.load = false;

          // Hata durumunda kullanıcıya bilgi ver
          if (showLoadingIndicator) {
            this.$toast.error(
              "Veriler yüklenirken bir hata oluştu. Lütfen tekrar deneyin."
            );
          }
        });
    },

    showUpdateNotification() {
      // İsteğe bağlı - veriler yenilendiğinde kullanıcıya bildirim göster
      this.$toast.info("Konum bilgileri güncellendi", {
        position: "bottom-right",
        duration: 3000,
      });
    },

    processMarkers(markers) {
      return markers.map((el) => {
        let list = JSON.parse(el.planning?.waypoints);
        if (!Array.isArray(list)) list = [];

        return {
          id: el.id,
          title: el.vehicle,
          region: el.region,
          position: {
            lat: parseFloat(el.latitude),
            lng: parseFloat(el.longitude),
          },
          adress: el.address,
          vehicle: el.vehicle,
          dorse: el.dorse,
          planning: el.planning,
          driverName: el.driverName,
          state: el.state,
          speed: el.speed,
          speedDirection: el.speedDirection,
          lastUpdateTime: el.lastUpdateTime,
          targetDate: moment(el.targetDate).format("LLLL"),
          waypoints: list.map((item) => {
            return {
              date: item.date,
              no: item.no,
              amount: item.amount,
              position: {
                adress: item.adress,
                lat: Number(item.latitude),
                lng: Number(item.longitude),
              },
              icon: require("@/assets/marker/waypoint.png"),
            };
          }),
          target:
            el.planning.state < 4
              ? {
                  adress: el.outpointAdress,
                  lat: Number(el.outpointLat),
                  lng: Number(el.outpointLng),
                }
              : {
                  adress: el.targetAdress,
                  lat: Number(el.targettLat),
                  lng: Number(el.targettLng),
                },
          isBulkOperation: el.isBulkOperation,
          icon: this.positionMarker(el.speed, el.speedDirection),
        };
      });
    },

    toggleTrafficLayer() {
      this.showTrafficLayer = !this.showTrafficLayer;
    },

    zoomIn() {
      if (this.zoomLevel < 18) {
        this.zoomLevel += 1;
      }
    },

    zoomOut() {
      if (this.zoomLevel > 3) {
        this.zoomLevel -= 1;
      }
    },

    centerToVehicle() {
      if (this.markers.length > 0) {
        this.position = this.markers[0].position;
      }
    },

    handleMapClick(event) {
      // Haritada boş bir alana tıklandığında detay panelini kapat
      this.showDetailsPanel = false;
    },

    handleMarkerClick(marker) {
      // Araç işaretçisine tıklandığında detay panelini aç/kapat
      this.showDetailsPanel = true;
    },

    getRoute() {
      if (
        !(
          this.detail.planning &&
          (this.detail.planning.state == 1 ||
            this.detail.planning.state == 2 ||
            this.detail.planning.state == 3 ||
            this.detail.planning.state == 5)
        )
      ) {
        return;
      }

      this.load = true;

      const requestData = {
        from: this.detail.position,
        to: this.detail.target,
        waypoints: this.detail.waypoints.map((item) => {
          return {
            adress: item.position.adress,
            lat: item.position.lat,
            lng: item.position.lng,
          };
        }),
        isToll: 1,
        routeType: "fast",
        shippedHazardousGoods: 1,
        isShip: 1,
        isAsphalt: 1,
        fuelRatio: 35,
        isPolyline: 1,
        vehicleTypeId: 5,
        isApi: 1,
      };

      axios
        .post(routeEngine.generate, requestData, {
          headers: {
            Authorization: "Bareer " + this.$store.state.routeEngineToken,
          },
        })
        .then((res) => {
          this.load = true;
          const detail = res.data.data;

          this.taskDetail = {
            state: this.detail.state,
            polyline: decode(detail.routePolyline).polyline,
            customer: this.detail.customer,
            totalDistance: detail.totalDistance,
            fuelConsumption: detail.fuelConsumption,
            totalMinute: moment().add(detail.totalMinute, "minutes").format("LLLL"),
            estimatedArrival: moment().add(detail.totalMinute, "minutes"),
          };
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Rota bilgileri yüklenirken bir hata oluştu.");
        })
        .finally(() => {
          this.load = false;
        });
    },

    reset() {
      this.load = true;
      this.position = { lat: 41.015137, lng: 28.97953 };
      this.zoomLevel = 5;
      this.markers = [];
      this.showDetailsPanel = false;
      this.isInfoCardCollapsed = false;
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLL");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
