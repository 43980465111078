
import { 
    isOrderContentValid, 
    getMissingFields 
  } from './validation';
  
  // İşleme işlevlerini içe aktar
  import { 
    processOrderSelection, 
    processOrderWithRecommendedVehicle 
  } from './processing';
  
  // Öneri işlevlerini içe aktar
  import { 
    handleSmallOrderRecommendation,
    getRecommendedVehicle
  } from './recommendations';
  
  // Hesaplama işlevlerini içe aktar
  import {
    calculateDistance,
    deg2rad,
    compareCosts
  } from './calculations';
  
  // Ürün yardımcı işlevlerini içe aktar
  import {
    isProductValid,
    isProductExist,
    findProductIdBySimilarity
  } from './productUtils';
  
  // Müşteri yardımcı işlevlerini içe aktar
  import {
    calculateCustomerPriority
  } from './customerUtils';
  
  // Tüm işlevleri dışa aktar
  export {
    // Validasyon işlevleri
    isOrderContentValid,
    getMissingFields,
    
    // İşleme işlevleri
    processOrderSelection,
    processOrderWithRecommendedVehicle,
    
    // Öneri işlevleri
    handleSmallOrderRecommendation,
    getRecommendedVehicle,
    
    // Hesaplama işlevleri
    calculateDistance,
    deg2rad,
    compareCosts,
    
    // Ürün yardımcı işlevleri
    isProductValid,
    isProductExist,
    findProductIdBySimilarity,
    
    // Müşteri yardımcı işlevleri
    calculateCustomerPriority
  };
  
  // Varsayılan dışa aktarma - kullanıcıların import orderTools from './orderTools' kullanabilmesi için
  export default {
    // Validasyon
    isOrderContentValid,
    getMissingFields,
    
    // İşleme
    processOrderSelection,
    processOrderWithRecommendedVehicle,
    
    // Öneriler
    handleSmallOrderRecommendation,
    getRecommendedVehicle,
    
    // Hesaplamalar
    calculateDistance,
    deg2rad,
    compareCosts,
    
    // Ürün yardımcıları
    isProductValid,
    isProductExist,
    findProductIdBySimilarity,
    
    // Müşteri yardımcıları
    calculateCustomerPriority
  };