<template>
  <div>
    <!-- Yükleniyor göstergesi -->
    <div
      v-if="loading"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white p-4 rounded-md shadow-lg">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
        <span class="ml-2">Yükleniyor...</span>
      </div>
    </div>

    <!-- Polygon ve İşaretçiler Bileşeni -->
    <ZonePolygons 
      :zones="zones"
      :selectedZone="selectedZone"
      :showIndividualPolygons="showIndividualPolygons"
      :combinedPolygon="combinedPolygon"
      @zoneSelected="handleZoneClick"
    />

    <!-- Bölge Bilgi Paneli -->
    <ZoneInfoPanel 
      v-if="selectedZone"
      :zone="selectedZone"
      :vehicles="vehiclesInSelectedZone"
      @close="selectedZone = null"
      @vehicleSelected="handleVehicleClick"
    />

    <!-- Sayfalama Kontrolü -->
    <ZonePagination 
      :currentPage="currentPage"
      :pageCount="pageCount"
      @previousPage="previousPage"
      @nextPage="nextPage"
    />

    <!-- Arama Kutusu -->
    <ZoneSearch 
      :modelValue="search"
      @update:modelValue="search = $event"
      @search="getAll"
    />
  </div>
</template>

<script>
import { zoneService } from './services/zoneService';
import { mapHelpers } from './utils/mapHelpers';
import ZonePolygons from './components/ZonePolygons.vue';
import ZoneInfoPanel from './components/ZoneInfoPanel.vue';
import ZonePagination from './components/ZonePagination.vue';
import ZoneSearch from './components/ZoneSearch.vue';

export default {
  name: "PlannedRegions",
  components: {
    ZonePolygons,
    ZoneInfoPanel,
    ZonePagination,
    ZoneSearch
  },
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    mapObject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      zones: [],
      selectedZone: null,
      loading: false,
      search: "",
      currentPage: 1,
      pageCount: 1,
      totalCount: 0,
      combinedPolygon: null,
      showIndividualPolygons: true,
      zoomListener: null,
      markers: [] // Google Maps marker nesnelerini saklamak için
    };
  },
  computed: {
    vehiclesInSelectedZone() {
      return mapHelpers.getVehiclesInZone(this.selectedZone, this.vehicles);
    }
  },
  methods: {
    /**
     * Bölge verilerini API'den çeker
     */
    async getAll() {
      if (!this.visible) return;

      this.loading = true;
      this.zones = [];

      try {
        // zoneService'i kullanarak verileri çek
        const response = await zoneService.getZones({
          page: this.currentPage - 1,
          search: this.search,
          token: this.$store.state.userData.token
        });


        this.zones = response.zones;
        this.totalCount = response.totalCount;
        this.pageCount = response.pageCount;

        // Harita merkezini güncelleme yerine, event emit
        if (this.zones.length > 0) {
          const firstZone = this.zones[0];
          this.$emit("zoneLoaded", {
            lat: parseFloat(firstZone.latitude),
            lng: parseFloat(firstZone.longitude),
          });
        }

        this.setupZoomBasedPolygons();
        this.createProfessionalZoneLabels();
      } catch (error) {
        this.errorBox(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Zoom seviyesine bağlı olarak poligon görünürlüğünü ayarlayan 
     * event listener'ları oluşturur
     */
     setupZoomBasedPolygons() {
      // Google Maps API ve harita nesnesini kontrol et
      if (!window.google || !window.google.maps) return;
      if (!this.mapObject) {
        console.warn("Harita nesnesi bulunamadı!");
        return;
      }

      // Mevcut zoom seviyesine göre ilk görünümü ayarla
      const currentZoom = this.mapObject.getZoom();
      this.updatePolygonVisibility(currentZoom);

      // Var olan listener'ı temizle
      if (this.zoomListener) {
        google.maps.event.removeListener(this.zoomListener);
      }

      // Zoom değiştiğinde polygon görünümünü güncelle
      this.zoomListener = this.mapObject.addListener("zoom_changed", () => {
        const zoom = this.mapObject.getZoom();
        this.updatePolygonVisibility(zoom);
      });
    },

    createProfessionalZoneLabels() {
      // Eski marker'ları temizle
      if (this.markers && this.markers.length) {
        console.log("Eski markerlar temizleniyor...", this.markers.length);
        this.markers.forEach((marker) => marker.setMap(null));
        this.markers = [];
      }

      // Harita nesnesi kontrolü
      if (!window.google) {
        console.error("Google API bulunamadı!");
        return;
      }
      
      // Prop veya event ile gelen harita nesnesini kullan
      const mapToUse = this.mapObject || this.localMapObject;
      
      if (!mapToUse) {
        console.error("Harita nesnesi bulunamadı! mapObject ve localMapObject null");
        return;
      }
      
      console.log("Bölge sayısı:", this.zones.length);
      
      if (this.zones.length === 0) {
        console.warn("Gösterilecek bölge yok!");
        return;
      }

      // Her bölge için özel marker oluştur
      this.markers = this.zones.map((zone, index) => {
        console.log(`Bölge #${index+1} için marker oluşturuluyor:`, zone.name || "İsimsiz Bölge");
        
        const position = mapHelpers.getZoneCenter(zone);
        console.log("Marker pozisyonu:", position);
        
        const icon = mapHelpers.getZoneIconWithTitle(zone);
        console.log("Marker ikonu oluşturuldu");
        
        try {
          const marker = new google.maps.Marker({
            position: position,
            map: mapToUse,
            icon: icon,
            optimized: false,
            zIndex: this.selectedZone && this.selectedZone.id === zone.id ? 1000 : 100,
          });

          // Marker'a tıklama olayı ekle
          marker.addListener("click", () => {
            this.handleZoneClick(zone);
          });
          
          console.log("Marker başarıyla oluşturuldu");
          return marker;
        } catch (error) {
          console.error("Marker oluşturulurken hata:", error);
          return null;
        }
      }).filter(marker => marker !== null);
      
      console.log("Toplam oluşturulan marker sayısı:", this.markers.length);
    },
       /**
     * Zoom seviyesine göre bölge görünümünü günceller
     */
     updatePolygonVisibility(zoomLevel) {
      const LOW_ZOOM_THRESHOLD = 10; // Birleşik polygon gösterilecek zoom seviyesi eşiği

      if (zoomLevel < LOW_ZOOM_THRESHOLD) {
        // Düşük zoom seviyelerinde birleşik polygon göster
        this.createCombinedPolygon();
        
        if (this.combinedPolygon && this.mapObject) {
          this.combinedPolygon.setMap(this.mapObject);
        }
        
        // Bireysel polygonları gizle
        this.showIndividualPolygons = false;
      } else {
        // Yüksek zoom seviyelerinde bireysel polygonları göster
        if (this.combinedPolygon) {
          this.combinedPolygon.setMap(null);
        }
        
        // Bireysel polygonları göster
        this.showIndividualPolygons = true;
      }
    },

    /**
     * Tüm bölgeleri kapsayan tek bir polygon oluşturur
     */
    createCombinedPolygon() {
      if (!window.google || !window.google.maps || this.zones.length === 0) return;
      if (!this.mapObject) {
        console.warn("Harita nesnesi bulunamadı!");
        return;
      }

      // Eğer polygon zaten varsa, önce kaldır
      if (this.combinedPolygon) {
        this.combinedPolygon.setMap(null);
        this.combinedPolygon = null;
      }

      // Tüm bölgeleri içine alan sınır kutusu oluştur
      const bounds = new google.maps.LatLngBounds();

      // Tüm bölgelerin noktalarını sınır kutusuna ekle
      this.zones.forEach((zone) => {
        if (zone.path && zone.path.length > 0) {
          zone.path.forEach((point) => {
            bounds.extend(new google.maps.LatLng(point.lat, point.lng));
          });
        }
      });

      // Köşe noktaları al
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // Dikdörtgen sınır oluştur
      const regionBounds = [
        { lat: ne.lat(), lng: ne.lng() }, // Northeast
        { lat: ne.lat(), lng: sw.lng() }, // Northwest
        { lat: sw.lat(), lng: sw.lng() }, // Southwest
        { lat: sw.lat(), lng: ne.lng() }, // Southeast
        { lat: ne.lat(), lng: ne.lng() }, // Close the polygon
      ];

      // Birleşik polygonu oluştur
      this.combinedPolygon = new google.maps.Polygon({
        paths: regionBounds,
        strokeColor: "#00ffff",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#00ffff",
        fillOpacity: 0.2,
        clickable: true,
        map: null // Başlangıçta gizli
      });

      // Tıklama olayı ekle - yakınlaştırma için
      this.combinedPolygon.addListener("click", (event) => {
        if (this.mapObject) {
          this.mapObject.setZoom(11); // Bireysel bölgelerin görünür olduğu zoom seviyesi
          this.mapObject.setCenter(event.latLng);
        }
      });

      // Merkez hesaplamaları için sınırları sakla
      this.combinedBounds = bounds;
    },

    /**
     * Önceki sayfaya git
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.getAll();
      }
    },

    /**
     * Sonraki sayfaya git
     */
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage += 1;
        this.getAll();
      }
    },

    /**
     * Bölge tıklama olayını işler
     */
    handleZoneClick(zone) {
      this.selectedZone = zone;
      // Harita merkezini seçili bölgeye ayarla - event emit
      const center = mapHelpers.getZoneCenter(zone);
      this.$emit("zoneSelected", {
        center,
        zoom: 12,
        zone,
      });
    },

    /**
     * Araç tıklama olayını işler
     */
    handleVehicleClick(vehicle) {
      this.$emit("vehicleSelected", vehicle);
    },

    /**
     * Hata mesajı gösterir
     */
    errorBox(error) {
      this.$swal.fire({
        icon: "error",
        title: "Hata",
        text: error?.response?.data?.message || "Bir hata oluştu.",
        confirmButtonText: "Tamam",
      });
    },

    /**
     * Bileşeni sıfırlar
     */
    reset() {
      this.selectedZone = null;
      this.search = "";
      this.currentPage = 1;
    },
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getAll();
      } else {
        this.selectedZone = null;
      }
    },
  },
  mounted() {
  console.log("PlannedRegions bileşeni mounted");
  
  if (this.visible) {
    this.getAll();
  }
  
  // Doğrudan harita nesnesini alma denemesi
  const tryGetMapObject = () => {
    // Ana uygulama içindeki harita referansını bulmaya çalış
    let mapRef = null;
    
    // İlk deneme: this.$parent.$refs.gmap
    if (this.$parent && this.$parent.$refs && this.$parent.$refs.gmap) {
      mapRef = this.$parent.$refs.gmap.$mapObject;
      console.log("Harita $parent.$refs.gmap üzerinden bulundu");
    } 
    // İkinci deneme: this.$root.$refs.gmap
    else if (this.$root && this.$root.$refs && this.$root.$refs.gmap) {
      mapRef = this.$root.$refs.gmap.$mapObject;
      console.log("Harita $root.$refs.gmap üzerinden bulundu");
    }
    // Üçüncü deneme: DOM'da arama
    else {
      // DOM'da gmap veya harita sınıfına sahip elementleri bul
      const gmapElements = document.querySelectorAll('.vue-map, .vue-map-container');
      if (gmapElements.length > 0) {
        console.log("DOM'da vue-map elementi bulundu:", gmapElements.length);
        
        // Vue bileşenlerindeki __vue__ özelliğini kullanarak harita nesnesine erişmeye çalış
        for (let i = 0; i < gmapElements.length; i++) {
          const el = gmapElements[i];
          if (el.__vue__ && el.__vue__.$mapObject) {
            mapRef = el.__vue__.$mapObject;
            console.log("DOM element.__vue__.$mapObject üzerinden harita bulundu");
            break;
          }
        }
      }
    }
    
    // Harita nesnesini bulduk mu?
    if (mapRef) {
      this.localMapObject = mapRef;
      console.log("Harita nesnesi bulundu ve localMapObject'e atandı");
      
      // Google API hazır mı kontrol et
      if (window.google && window.google.maps) {
        this.setupZoomBasedPolygons();
        this.createProfessionalZoneLabels();
      }
      return true;
    }
    
    return false;
  };
  
  // Harita nesnesini almayı dene
  if (!tryGetMapObject()) {
    // İlk denemede başarısız olduysa, 300ms sonra tekrar dene
    setTimeout(() => {
      if (!tryGetMapObject()) {
        // İkinci denemede de başarısız olduysa, 700ms sonra tekrar dene
        setTimeout(() => {
          tryGetMapObject();
        }, 700);
      }
    }, 300);
  }
  
  // Google Maps API durumunu kontrol et ve yükle
  if (!window.google || !window.google.maps) {
    this.$gmapApiPromiseLazy().then(() => {
      console.log("Google Maps API yüklendi");
      tryGetMapObject();
    });
  }
},
  beforeDestroy() {
    // Listener'ları temizle
    if (this.zoomListener && window.google) {
      google.maps.event.removeListener(this.zoomListener);
    }
    
    // Marker'ları temizle
    if (this.markers && this.markers.length) {
      this.markers.forEach(marker => {
        if (marker) marker.setMap(null);
      });
    }
    
    // Polygon'ları temizle
    if (this.combinedPolygon) {
      this.combinedPolygon.setMap(null);
    }
  }
};
</script>