// orderTools/validation.js
// Sipariş doğrulama işlevleri

import moment from 'moment';

/**
 * Validate if an order has all required fields
 * @param {Object} order - The order to validate
 * @returns {boolean} - Whether the order is valid
 */
export const isOrderContentValid = (order) => {
  const {
    customer,
    deliveredName,
    deliveryDate,
    deliveryTime,
    invoicedCompany,
    orderDate,
    orderName,
    orderNo,
    outpointAdress,
    outpointLat,
    outpointLng,
    productType,
    receivedName,
    targetAdress,
    targetPointLat,
    targetPointLng,
    tonnage,
    unitType,
  } = order;

  return !!(
    customer &&
    deliveredName &&
    deliveryDate &&
    deliveryTime &&
    invoicedCompany &&
    orderDate &&
    moment(deliveryDate).diff(moment(orderDate), "days") >= 0 &&
    orderName &&
    orderNo &&
    outpointAdress &&
    outpointLat &&
    outpointLng &&
    productType &&
    receivedName &&
    targetAdress &&
    targetPointLat &&
    targetPointLng &&
    tonnage &&
    unitType
  );
};

/**
 * Get missing fields from an order
 * @param {Object} order - The order to check
 * @returns {Array} - List of missing field names
 */
export const getMissingFields = (order) => {
  const {
    customer,
    deliveredName,
    deliveryDate,
    deliveryTime,
    invoicedCompany,
    orderDate,
    orderName,
    orderNo,
    outpointAdress,
    outpointLat,
    outpointLng,
    productType,
    receivedName,
    targetAdress,
    targetPointLat,
    targetPointLng,
    tonnage,
    unitType,
  } = order;

  return [
    !customer ? "Müşteri " : "",
    !deliveredName ? "Teslim Edilen Ad " : "",
    !deliveryDate ? "Teslim Tarihi" : "",
    !deliveryTime ? "Teslimat Saati" : "",
    !invoicedCompany ? "Faturalı Şirket" : "",
    !orderDate ? "Sipariş Tarihi" : "",
    !orderName ? "Sipariş Adı" : "",
    !orderNo ? "Sipariş No" : "",
    !outpointAdress ? "Dolum Noktası Adresi" : "",
    !outpointLat ? "Dolum Noktası Enlem" : "",
    !outpointLng ? "Dolum Noktası Boylam" : "",
    !productType ? "Ürün" : "",
    !receivedName ? "Gönderici Adı" : "",
    !targetAdress ? "Teslim Noktası Adresi" : "",
    !targetPointLat ? "Teslim Noktası Enlem" : "",
    !targetPointLng ? "Teslim Noktası Boylam" : "",
    !tonnage ? "Tonage (Miktar)" : "",
    !unitType ? "Birim" : "",
  ].filter((r) => r !== "");
};

/**
 * Teslim tarihinin sipariş tarihinden sonra olup olmadığını kontrol eder
 * @param {Object} order - Kontrol edilecek sipariş
 * @returns {boolean} - Tarih kontrolünün sonucu (true = geçerli)
 */
export const isDeliveryDateValid = (order) => {
  const { orderDate, deliveryDate } = order;
  
  if (!orderDate || !deliveryDate) return false;
  
  return moment(deliveryDate).diff(moment(orderDate), "days") >= 0;
};

/**
 * Siparişin teslim tarihinin geçmiş bir tarih olup olmadığını kontrol eder
 * @param {Object} order - Kontrol edilecek sipariş
 * @returns {boolean} - Tarih kontrolünün sonucu (true = geçerli / gelecek tarih)
 */
export const isFutureDeliveryDate = (order) => {
  const { deliveryDate } = order;
  
  if (!deliveryDate) return false;
  
  return moment(deliveryDate).isAfter(moment());
};