import axios from "axios";
import { planning, customer } from "@/networking/urlmanager";

/**
 * Gets customer details including working hours
 * 
 * @param {string} customerId - ID of the customer
 * @param {string} token - Authentication token
 * @returns {Promise<Array>} Array of working hours information
 */
export const getCustomerDetail = async (customerId, token) => {
    try {
        const response = await axios.get(
            `${customer.getAll}?customerId=${customerId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data.data.detail[0].shiftHours;
    } catch (err) {
        console.error("Error getting customer details:", err);
        throw err;
    }
};

/**
 * Retrieves a specific plan detail by ID
 * 
 * @param {string|number} rowId - The ID of the plan to retrieve
 * @param {string} token - Authentication token
 * @returns {Promise<object>} Server response with plan details
 */
export const getDetailById = async (rowId, token) => {
    try {
        const response = await axios.get(
            `${planning.getSingle}?rowId=${rowId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (err) {
        console.error("Error getting plan details:", err);
        throw err;
    }
};

/**
 * Saves planning form data to the server
 * 
 * @param {object} formData - Form data to save
 * @param {string} token - Authentication token
 * @returns {Promise<object>} Server response
 */
export const saveFormData = async (formData, token) => {
    try {
        const response = await axios.post(
            planning.add,
            formData,
            {
                headers: {
                    Authorization: "Bareer " + token,
                },
            }
        );
        return response;
    } catch (err) {
        console.error("Error saving planning data:", err);
        throw err;
    }
};

/**
 * Updates existing planning form data on the server
 * 
 * @param {object} formData - Form data to update
 * @param {string} token - Authentication token
 * @returns {Promise<object>} Server response
 */
export const updateFormData = async (formData, token) => {
    try {
        const response = await axios.post(
            planning.update,
            formData,
            {
                headers: {
                    Authorization: "Bareer " + token,
                },
            }
        );
        return response;
    } catch (err) {
        console.error("Error updating planning data:", err);
        throw err;
    }
};

/**
 * Formats the expense detail for API call
 * 
 * @param {object} expenseDetail - Expense detail object
 * @param {number} transitionCount - Number of transitions
 * @param {object} costDistribution - Cost distribution information
 * @returns {object} Formatted expense detail object
 */
export const formatExpenseDetailForAPI = (expenseDetail, transitionCount, costDistribution, isOneWay) => {
    if (!expenseDetail) return {};

    const multiplier = isOneWay == 1 ? 1 : 2;

    return {
        tollsPrice: (costDistribution.cost.cash * transitionCount * multiplier).toFixed(2),
        fuelPrice: expenseDetail.fuelTotalPrice.toFixed(2),
        adbluePrice: expenseDetail.totalAdbluePrice.toFixed(2),
        whellPrice: expenseDetail.totalWhellPrice.toFixed(2),
        engineOilPrice: expenseDetail.totalEngineOilPrice.toFixed(2),
        filtersPrice: expenseDetail.TotalfilterPrice.toFixed(2),
        brakepadPrice: expenseDetail.totalBrakePadPrice.toFixed(2),
        diskPrice: expenseDetail.totalDiskPrice.toFixed(2),
        clutchPrice: expenseDetail.totalClutchPrice.toFixed(2),
        periodMainstancePrice: expenseDetail.totalperiodicMaintenanceCost.toFixed(2),
        TrafficInsuranceAndinsurancePrice: expenseDetail.totalTrafficInsurancePriceAndinsurancePrice.toFixed(2),
        otherCost: expenseDetail.otherCost.toFixed(2)
    };
};