const { calculateDistancePriority } = require("../calculator/calculateDistancePriority");
const moment=require('moment')

/**
 * Belirtilen aracın ve siparişin ardışık olup olmadığını kontrol eder.
 * @param {Object} vehicle - Kontrol edilecek araç bilgisi.
 * @param {number} vehicle.latitude - Aracın mevcut enlemi.
 * @param {number} vehicle.longitude - Aracın mevcut boylamı.
 * @param {Object} vehicle.planning - Araç planlama bilgisi.
 * @param {number} vehicle.planning.taskCounter - Araç üzerindeki iş sayısı.
 * @param {number} vehicle.planning.state - Araç durumunu belirten kod.
 * @param {number} vehicle.targettLat - Aracın hedef enlemi.
 * @param {number} vehicle.targettLng - Aracın hedef boylamı.
 * @param {number} vehicle.outpointLat - Aracın çıkış noktası enlemi.
 * @param {number} vehicle.outpointLng - Aracın çıkış noktası boylamı.
 * @param {Array<string>} vehicle.orderNos - Araç üzerindeki sipariş numaraları listesi.
 * 
 * @param {Object} order - Kontrol edilecek sipariş bilgisi.
 * @param {number} order.startLat - Siparişin başlangıç noktası enlemi.
 * @param {number} order.startLng - Siparişin başlangıç noktası boylamı.
 * @param {number} order.endLat - Siparişin bitiş noktası enlemi.
 * @param {number} order.endLng - Siparişin bitiş noktası boylamı.
 * @param {string} order.orderNo - Sipariş numarası.
 * @param {Date} order.deliveryDate - Siparişin teslim tarihi.
 * 
 * @returns {boolean} - Aracın belirtilen siparişi ardışık olarak alıp alamayacağını döndürür.
 */
function isSequential(vehicle, order) {
  let betweenPlanningDistance = 0;
  let orderDistance = 0;
  let remainingMinuteForSecondOrder = 0;

  if (vehicle?.planning?.taskCounter == 1) {
    betweenPlanningDistance = calculateDistancePriority(
      { startLat: Number(vehicle.targettLat), startLng: Number(vehicle.targettLng) },
      { latitude: Number(order.startLat), longitude: Number(order.startLng) }
    );

    orderDistance = calculateDistancePriority(
      { startLat: Number(order.startLat), startLng: Number(order.startLng) },
      { latitude: Number(order.endLat), longitude: Number(order.endLng) }
    );
    let estimatedArrivalMinutes = 0; // Aracın mevcut konumundan 1.işini bitereceği tahmini süre

    if (vehicle?.planning.state > 4) {
      // Aracın mevcut konumundan teslimat noktasına tahmini varış süresi
      estimatedArrivalMinutes =
        (calculateDistancePriority(
          { startLat: Number(vehicle.latitude), startLng: Number(vehicle.longitude) },
          { latitude: Number(vehicle.targettLat), longitude: Number(vehicle.targettLng), }
        ) /
          60) * 60  // Ortalama hız varsayımı 60 km/saat
      estimatedArrivalMinutes + 30; //Boşaltma tahliyesi eklemesi
    } else if (vehicle?.planning.state < 5) {
      // Aracın mevcut konumundan Önce Yüklemeye oradan teslimat noktasına tahmini varış süresi
      const estimatedFillinglMinutes =
        (calculateDistancePriority(
          { startLat: Number(vehicle.latitude), startLng: Number(vehicle.longitude) },
          { latitude: Number(vehicle.outpointLat), longitude: Number(vehicle.outpointLng) }
        ) /
          60) * 60;

      // Ortalama hız varsayımı 60 km/saat

      // Aracın mevcut konumundan teslimat noktasına tahmini varış süresi
      const estimatedeliveryMinutes =
        (calculateDistancePriority(
          { startLat: Number(vehicle.latitude), startLng: Number(vehicle.longitude) },
          { latitude: Number(vehicle.targettLat), longitude: Number(vehicle.targettLng) }
        ) /
          60) * 60;
      // Ortalama hız varsayımı 60 km/saat

      estimatedArrivalMinutes = estimatedFillinglMinutes + estimatedeliveryMinutes + (30 + 30) // Yükleme ve boşaltma tahliye süreside eklendi
    }

    // 2.işin teslim süresinden 1. iş  için haracanak süre çıkarılır ve 2. iş için kalan vakit bulunur
    remainingMinuteForSecondOrder = moment(order.deliveryDate).diff(
      moment().add(estimatedArrivalMinutes, "minutes"),
      "minutes"
    );
  }

  if (!Array.isArray(vehicle?.orderNos)) {
    vehicle.orderNos = [];
  }

  if (vehicle?.orderNos?.length > 1) {
    orderCheck =
      vehicle?.orderNos?.filter((r) => r != order.orderNo).length > 0;
  }


  return (
    vehicle?.orderNos.length == 0 ||
    (
      (vehicle?.planning?.taskCounter == 1 && // araçta sadece 1 spot iş olacak
        vehicle?.orderNos?.filter((r) => r != order.orderNo).length == 1) && // siparişlerde seçilen sipariş orderNosa dahil. aracın seçilen siparişten 1 farklı işi olmalı.üstteki şarttan dolayı O işte spot iş olacaktır.
      (100 - betweenPlanningDistance >= 0 && // 100 km uzaklığa göre işi bitmek üzere olan araçlar gelebilir.
        remainingMinuteForSecondOrder > //2.sipariş için kalan süre
        ((orderDistance / 60) * 60 + 60))) //2. sipariş için gerekli olan mesafe süresi artı tahliye süresi (30+30)
  );
  // Araç üzerinde bir spot iş olabilir.

  //Siparişin teslim tarihinin diğer işin teslim tarihi farkı,
  //Bana lazım olan hedeflenen süreden küçük olmalı.
  //Ve bunu dakika cinsinden hesaplıyoruz.
}

module.exports = {
  isSequential
}