<template>
  <GmapMap
    :max-zoom="14"
    ref="gmap"
    :center="center"
    :zoom="zoom"
     :map-type-id="$store.state.mapView ? 'hybrid' : 'roadmap'"
    style="width: 100%; height: 100vh !important"
    :options="{
      minZoom: 3,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    }"
    @click="$emit('mapClick')"
  >
    <slot></slot>
  </GmapMap>
</template>
  
<script>
export default {
  name: "MapContainer",
  props: {
    center: {
      type: Object,
      default: () => ({ lat: 41.015137, lng: 28.97953 }),
    },
    zoom: {
      type: Number,
      default: 5,
    },
    mapTypeId: {
      type: String,
      default: "roadmap",
    },
  },
  data() {
    return {
      gmapObject: null,
      customMapType: null,
    }
  },
  emits: ["mapClick"],
  methods: {
    /**
     * Harita nesnesini döndürür
     * @returns {Object} Google Maps harita nesnesi
     */
    getMapObject() {
      return this.$refs.gmap.$mapObject;
    },
    
    initMap() {
      console.log("initMap çağrıldı");
      if (this.$refs.gmap && this.$refs.gmap.$mapObject) {
        this.gmapObject = this.$refs.gmap.$mapObject;
        console.log("Harita nesnesi başarıyla alındı");
        
        // Özel harita tipini başlat ve uygula
        this.initializeCustomMapType();
      } else {
        console.log("Harita nesnesi henüz oluşturulmamış");
      }
    },

    /**
     * Özel harita tipini başlatır ve haritaya uygular
     */
    initializeCustomMapType() {
      const customMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return `https://api.tomtom.com/map/1/tile/sat/main/${zoom}/${coord.x}/${coord.y}.jpg?key=QBZW0FaE7nyqWeLuLA5PIwF93jGzSQVC`;
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 19,
        minZoom: 0,
        name: "TomTom Satellite",
      });

      const mapObject = this.getMapObject();
      
      // Özel harita tipini kaydet ve doğrudan kullan
      this.customMapType = customMapType;
      
      // Method 1: Eklenti (overlay) olarak ekle
      mapObject.overlayMapTypes.clear();  // Önceki eklentileri temizle
      mapObject.overlayMapTypes.insertAt(0, customMapType);
      
      // Method 2: Alternatif olarak, özel harita tipi olarak ekle ve seç
      // mapObject.mapTypes.set('tomtom_satellite', customMapType);
      // mapObject.setMapTypeId('tomtom_satellite');
      
      console.log("TomTom harita katmanı eklendi");
    },

    /**
     * Google haritayı göster (TomTom eklentisini kaldır)
     */
    showGoogleMap() {
      if (this.gmapObject) {
        this.gmapObject.overlayMapTypes.clear();
        this.gmapObject.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      }
    },
    
    /**
     * TomTom haritayı göster
     */
    showTomTomMap() {
      this.customMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return `https://api.tomtom.com/map/1/tile/sat/main/${zoom}/${coord.x}/${coord.y}.jpg?key=QBZW0FaE7nyqWeLuLA5PIwF93jGzSQVC`;
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 19,
        minZoom: 0,
        name: "Custom Satellite Overlay",
      });

      this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);

      this.map.overlayMapTypes.insertAt(0, this.customMapType);
    },
    
    /**
     * TomTom eklentisinin görünürlüğünü değiştirir
     */
    toggleTomTomOverlay() {
      if (this.gmapObject) {
        if (this.gmapObject.overlayMapTypes.getLength() > 0) {
          this.gmapObject.overlayMapTypes.clear();
        } else if (this.customMapType) {
          this.gmapObject.overlayMapTypes.insertAt(0, this.customMapType);
        }
      }
    }
  }
};
</script>