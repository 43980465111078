
/**
 * Belirtilen tonaj ve maksimum araç sayısına göre en uygun araçları seçer.
 *
 * @param {Array} vehicles - Kullanılabilir araçlar listesi.
 * @param {number} totalTonnage - Taşınması gereken toplam tonaj.
 * @param {number} maxVehicleCount - Maksimum seçilebilecek araç sayısı.
 * @returns {Array} - Seçilen en uygun araçların listesi.
 */
function selectOptimalVehicles(vehicles, totalTonnage, maxVehicleCount) {
    let remainingTonnage = totalTonnage;
    const selectedVehicles = [];

    for (const vehicle of vehicles) {
        if (selectedVehicles.length >= maxVehicleCount || remainingTonnage <= 0)
            break;

        const vehicleCapacity = vehicle.capacity / 1000; // ton cinsinden
        if (vehicleCapacity <= remainingTonnage) {
            selectedVehicles.push(vehicle);
            remainingTonnage -= vehicleCapacity;
        } else if (selectedVehicles.length < maxVehicleCount) {
            // Kalan tonaj için en uygun aracı seç
            selectedVehicles.push(vehicle);
            remainingTonnage = 0;
        }
    }

    return selectedVehicles;
}

module.exports = {
    selectOptimalVehicles
}