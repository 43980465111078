
/**
 * Seçilen araçları yeniden atar
 * @param {string} targetOrderNo - Hedef sipariş numarası
 * @param {Array} selectedVehicleIds - Seçilen araç ID'leri
 * @param {Array} selectedList - Planlı siparişler listesi
 * @param {Array} vehicleList - Tüm araçlar listesi
 * @param {Array} activeOrdersWithVehicles - Aktif siparişler ve araçları
 * @returns {Object} Yeniden atama sonucu
 */
function reallocateVehicles(targetOrderNo, selectedVehicleIds, selectedList, vehicleList, activeOrdersWithVehicles) {
    const result = {
      success: true,
      message: '',
      reassignedVehicles: [],
      affectedOrders: []
    };
    
    try {
      // Seçilen araçların plakalarını ve etkileşimde bulunacak siparişleri bul
      const vehiclePlates = new Set();
      const affectedOrders = new Set();
      
      // Seçilen her araç ID'si için
      selectedVehicleIds.forEach(vehicleId => {
        // Bu aracın hangi siparişe ait olduğunu bul
        activeOrdersWithVehicles.forEach(order => {
          const vehicle = order.vehicles.find(v => v.id === vehicleId);
          if (vehicle) {
            vehiclePlates.add(vehicle.plate);
            affectedOrders.add(order.orderNo);
          }
        });
      });
      
      // Etkilenen siparişleri planlama listesinden kaldır
      affectedOrders.forEach(ordNo => {
        const index = selectedList.findIndex(item => item.orderNo === ordNo);
        if (index !== -1) {
          selectedList.splice(index, 1);
        }
      });
      
      // Seçilen araçların asıl nesnelerini bul
      const vehiclesToReassign = vehiclePlates.size > 0 
        ? vehicleList.filter(v => vehiclePlates.has(v.vehicle || v.plate))
        : [];
      
      if (vehiclesToReassign.length === 0) {
        result.success = false;
        result.message = 'Seçilen araçlar bulunamadı';
        return result;
      }
      
      // Hedef siparişi bul
      const targetOrder = selectedList.find(o => o.orderNo === targetOrderNo);
      if (!targetOrder) {
        result.success = false;
        result.message = 'Hedef sipariş bulunamadı';
        return result;
      }
      
      // Araçların sipariş numaralarını güncelle
      vehiclesToReassign.forEach(vehicle => {
        // Eski sipariş numaralarını kaydet
        const oldOrderNos = [...(vehicle.orderNos || [])];
        
        // Sipariş numaralarını güncelle
        if (!vehicle.orderNos) {
          vehicle.orderNos = [];
        }
        
        // Eğer zaten hedef siparişe atanmamışsa, ekle
        if (!vehicle.orderNos.includes(targetOrderNo)) {
          vehicle.orderNos.push(targetOrderNo);
        }
        
        // Sonuç bilgisine ekle
        result.reassignedVehicles.push({
          id: vehicle.id,
          plate: vehicle.vehicle || vehicle.plate,
          oldOrderNos,
          newOrderNos: [...vehicle.orderNos]
        });
      });
      
      // Etkilenen siparişleri sonuç bilgisine ekle
      result.affectedOrders = Array.from(affectedOrders).map(orderNo => {
        const order = activeOrdersWithVehicles.find(o => o.orderNo === orderNo);
        return {
          orderNo,
          orderName: order ? order.customer : `Sipariş ${orderNo}`
        };
      });
      
      result.message = `${vehiclesToReassign.length} araç başarıyla yeniden atandı`;
    } catch (error) {
      result.success = false;
      result.message = `Hata oluştu: ${error.message}`;
    }
    
    return result;
  }

  
  module.exports={
    reallocateVehicles
  }