<template>
  <div>
    <GmapMarker
      @click="() => $emit('markerClick', selectedItem)"
      :title="selectedItem.title"
      :label="{
        text: selectedItem.title,
        color: 'white',
        className:
          'absolute bottom-3 -right-8 text-xs px-2 text-xs font-bold bg-black rounded',
        fontSize: '8.5px',
      }"
      :position="selectedItem.position"
      :icon="selectedItem.icon"
    >
    </GmapMarker>

    <!-- Hedef Noktası (Eğer Varsa) -->
    <GmapMarker
      :title="
        selectedActiveDetail.state <= 3
          ? 'Yükleme Noktası'
          : selectedActiveDetail.customer
      "
      :label="{
        text:
          selectedActiveDetail.state <= 3
            ? 'Yükleme Noktası'
            : selectedActiveDetail.customer,
        color: 'white',
        className:
          'absolute top-4 px-2 rounded left-0 text-xs px-2 text-xs font-bold bg-red-500',
        fontSize: '12px',
      }"
      :position="selectedActiveDetail.targetPoint"
      v-if="
        selectedActiveDetail.targetPoint.lat &&
        selectedActiveDetail.targetPoint.lng
      "
    >
    </GmapMarker>

    <!-- Rota Çizgisi -->
    <GmapPolyline
      v-if="
        selectedActiveDetail.polyline && selectedActiveDetail.polyline.length
      "
      v-bind:path.sync="selectedActiveDetail.polyline"
      v-bind:options="{ strokeColor: '#C43A3A' }"
    >
    </GmapPolyline>
  </div>
</template>
  
  <script>
export default {
  name: "SelectedItemDisplay",
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
    selectedActiveDetail: {
      type: Object,
      default: () => ({
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      }),
    },
  },
  emits: ["markerClick"],
};
</script>