<template>
  <GmapCluster @click="handleClusterClick" :styles="clusterStyles">
    <GmapMarker
      v-for="(m, index) in markers"
      :key="index"
      :title="m.title"
      :label="{
        text: m.title,
        color: 'white',
        className:
          'absolute bottom-4 -right-8 text-xs px-2 text-xs font-bold bg-green-700 rounded tracking-widest',
        fontSize: '8.5px',
      }"
      :position="m.position"
      :icon="m.icon"
      rotation="30"
    >
    </GmapMarker>
  </GmapCluster>
</template>
  
  <script>
import { mapHelpers } from "../../services/mapHelpers";

export default {
  name: "FactoryMarkers",
  props: {
    markers: {
      type: Array,
      required: true,
    },
  },
  emits: ["markerClick", "clusterClick"],
  data() {
    return {
      clusterStyles: mapHelpers.getClusterStyles("default"),
    };
  },
  methods: {
    /**
     * Kümeleme tıklama olayını işleme
     */
    handleClusterClick(cluster) {
      this.$emit("clusterClick", cluster, "factory");
    },
  },
};
</script>