<template>
  <LyrausTable
    :data="List"
    :columns="columns"
    :innerTable="innerTableColumns"
    :enablePagination="true"
    :isLoading="load"
    :stickyLeft="['orderNo']"
    :stickyRight="['dropdownColumnDurum', 'buttonsColumn']"
    :batchOperations="true"
    :searchBar="true"
    :searchProp="$parent.search"
    @update-search-value="(val) => $parent.search=val"
    :handleSearch="$parent.handleSearch"
    :alternateRowBg="['white', 'gray-100']"
    :rightExtraSlot="true"
    :dblClickableColumns="dblClickableColumns"
    :pageCount="$parent.pageCount"
    :pageProp="$parent.currentPage"
    @update-current-page="$parent.handleCurrentPage"
    :totalCount="$parent.totalCount"
    @dbl-row-click="$parent.handleDblRowClick"
    :buttonsColumn="{ label: 'İşlem', width: 120 }"
    :dropdownColumns="[{ key: 'Durum', label: 'Durum' }]"
    :paginationButtonColor="'red-600'"
    :paginationHoverColor="'red-700'"
    :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
    :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
    :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
  >
    <template v-slot:batchOperations>
      <asyncBtn
        icon="fas fa-plus "
        :text="$t('operations.planning.newBtnTitle')"
        class="w-36 btn"
        @click="() => $parent.$modal.show('add-plan-modal')"
      />
    </template>
    <template v-slot:rightExtra>
      <div class="flex justify-between items-center">
        <userFilterModal
          ref="modalActive"
          :selectedItems="$parent.selectedItems"
          :load="load"
          @selected-items-changed="$parent.handleSelectedItemsChanged"
        />
      </div>
    </template>
    <template v-slot:colDropdown="{ item, dropdownKey }">
      <div
        v-if="dropdownKey === 'dropdownColumnDurum'"
        class="table-td w-36 border-r border-opacity-20"
      >
        <changeStatus
          :statusList="$parent.statusList"
          :plaque="item.vehicle"
          :defaultTitle="item.stateName"
          :stateId="item.stateId"
          :typeId="item.typeId"
          :planId="item.id"
          :orderNo="item.orderNo"
          :note="item.notes"
          @changeVehicle="
            () => ((selected = item), $parent.$modal.show('change-vehicle-modal'))
          "
          @refresh="() => $parent.getAll()"
          @vehicleStatu="
            (r) => (
              ($parent.$refs.vehicleStatu.modalData = {
                detail: item,
                activeStatu: r,
              }),
              $parent.$refs.vehicleStatu.show()
            )
          "
        />
      </div>
    </template>
     <!-- Sort Buttons -->
     <template v-slot:sortButton="{ columnKey }">
      <!-- Search Clear Button -->
      <div
        v-if="columnKey === $parent.searchColumn"
        @click="$parent.clearSearch()"
        class="cursor-pointer mr-1"
      >
        <i class="fas fa-times text-red-600"></i>
      </div>

      <!-- Sorting Buttons for Different Columns -->
      <sort-button
        :column-key="columnKey"
        :sort-column="$parent.sortColumn"
        :sort-state="$parent.sortState"
        @toggle-sort="(column) => $parent.toggleSort(column)"
      />
    </template>

    <template v-slot:colButtons="{ item }">
      <div class="flex items-center justify-end px-2">
        <button class="mr-2" @click="$parent.noteDetailModal(item)">
          <i class="far fa-sticky-note"></i>
        </button>
      </div>

      <div class="flex items-center justify-end px-2">
        <button class="mr-2 relative" @click="$parent.showEditModal(item)">
          <i
            :class="item.stateId >= 8 && 'text-gray-700 text-opacity-50'"
            class="fas fa-pen"
          ></i>
          <i
            v-if="item.explanation"
            class="fas fa-circle text-xxs absolute -left-2 -top-1 text-red-600 animate-pulse"
          ></i>
        </button>
      </div>

      <div class="flex items-center justify-end px-2">
        <button
          class="mr-2"
          :class="item.isError > 0 ? 'animated animate-pulse duration-200' : ''"
          @click="$parent.vehicleStatesModel(item)"
        >
          <i
            class="fas fa-search"
            :class="
              item.isError > 0
                ? 'text-red-500 animate-pulse duration-200'
                : 'text-gray-700'
            "
          ></i>
        </button>
      </div>

      <div class="flex items-center justify-end px-2">
        <button
          :disabled="item.stateId == 8 || item.stateId == 10"
          :class="item.stateId >= 8 && ' bg-opacity-70 mr-2'"
          class="mr-2"
          @click="$parent.deleteItem(item)"
        >
          <i
            :class="
              (item.stateId == 8 || item.stateId == 10) &&
              'text-gray-400 text-opacity-50 '
            "
            class="fas fa-trash text-gray-700"
          ></i>
        </button>
      </div>
    </template>
    <template v-slot:rowButtons="{ item, columnKey }">
      <div
        v-if="columnKey === 'vehicle'"
        class="flex items-center justify-between"
      >
        <p
          @click="item.vehicle ? $parent.handleItemClick(item) : ''"
          :class="
            item.stateId >= 8 ? 'opacity-40' : 'cursor-pointer select-none'
          "
          class="border rounded-lg p-1 justify-between"
        >
          {{ item.vehicle }}
          <i class="fas fa-search text-gray-700"></i>
        </p>
        <div class="ml-2 flex items-center justify-between">
          <span
            @click="swalBox('info','BİLGİ',item.isPirateDriver == 1 ? 'Firma dışından bağımsız bir sürücüye (korsan araç) atandı' : 'Operasyon personeli, önerilen araçlar yerine bu aracı manuel olarak seçti')"
            style="font-size: 11px"
            class="px-2 mr-2  rounded-xl cursor-pointer select-none text-white inline-block font-extralight"
            :class="item.isPirateDriver == 1 ? 'bg-yellow-600' : 'bg-red-600'"
            v-if="item.isCustom == 1 || item.isPirateDriver == 1"
            :title="item.isPirateDriver == 1 ? 'Firma dışından bağımsız bir sürücüye (korsan araç) atandı' : 'Operasyon personeli, önerilen araçlar yerine bu aracı manuel olarak seçti'"
          >
            <i class="fas fa-info-circle    "></i>
            {{ item.isPirateDriver == 1 ? "Korsan Atama" : "Manuel Atama" }}
          </span>
        </div>
      </div>
    </template>
    <template v-slot:filterButton="{ columnKey }">
      <statusPanel
        v-if="columnKey === 'dropdownColumnDurum'"
        @stopFiltering="() => $parent.stopFiltering()"
        :statusId="$parent.statusId"
        :initialStatus="$parent.statusId"
        :statusList="status"
        @setStatusFilter="(val) => $parent.setStatusFilter(val)"
      />
    </template>
  </LyrausTable>
</template>

<script>
import statusPanel from "@/components/filters/filterTableStatus/index.vue";
import changeStatus from "../../components/change/status.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import userFilterModal from "@/components/filters/userFiltering/index.vue";
import sortButton from './SortButton.vue'
import { planningStatus } from "@/utils/statusName";
export default {
  name: "planning-table",
  props:["List","load","pageCount"],
  components:{
    changeStatus,
    asyncBtn,
    userFilterModal,
    statusPanel,
    sortButton
  },
  data() {
    return {
      dblClickableColumns: [
        "customer",
        "receivedName",
        "deliveredName",
        "productType",
      ],
      columns: [
        {
          key: "orderNo",
          label: "#",
        },
        {
          key: "customer",
          label: "Müşteri",
          maxLength: 18,
        },
        {
          key: "receivedName",
          label: "Dolum",
          maxLength: 18,
        },
        {
          key: "deliveredName",
          label: "Teslim",
          maxLength: 18,
        },
        {
          key: "productType",
          label: "Ürün",
        },
        {
          key: "vehicle",
          label: "Araç",
          dontDisplay: true,
        }
      ],
      innerTableColumns: [
        { key: "orderName", label: "Kaynak" },
        { key: "outpointAdress", label: "Dolum Adresi" },
        { key: "targetAdress", label: "Teslim Adresi" },
        { key: "invoicedCompany", label: "Fatura Adresi" },
        { key: "deliveryDate", label: "Teslim Tarihi", isDate: true },
        { key: "driver", label: "Sürücü" },
        { key: "isTourComplatedSuccess", label: "Sefer Kaydı" },
      ],
      order: [
        {
          orderId: 1,
          orderName: "A-Z Sırala",
          active: false,
        },
        {
          orderId: 2,
          orderName: "Z-A Sırala",
          active: false,
        },
        {
          orderId: 0,
          orderName: "Varsayılan Sıralama",
          active: false,
        },
      ],
      status:planningStatus
    };
  },
};
</script>