<!-- components/HeaderBar.vue -->
<template>
  <div
    class="w-full flex items-center justify-between p-3 border-b"
    :class="isMapView ? 'bg-white text-black' : 'bg-white text-gray-800'"
  >
    <div class="flex items-center">
      <div
        class="h-10 w-10 rounded-full bg-red-600 flex items-center justify-center mr-3"
      >
        <i class="fas fa-truck text-white"></i>
      </div>
      <div>
        <h3 class="font-bold text-lg">{{ vehicle }}</h3>
        <p class="text-sm opacity-75">
          {{ driverName || "Sürücü bilgisi yok" }}
        </p>
      </div>
    </div>

    <div class="flex">
      <button
        class="mx-2 p-2 rounded hover:bg-opacity-10"
        :class="isMapView ? 'hover:bg-white' : 'hover:bg-gray-800'"
        @click="$emit('help')"
        title="Yardım"
      >
        <i class="fas fa-question-circle"></i>
      </button>

      <button
        class="mx-2 p-2 rounded hover:bg-opacity-10"
        :class="isMapView ? 'hover:bg-white' : 'hover:bg-gray-800'"
        @click="$emit('close')"
        title="Kapat"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "header-bar",
  props: {
    vehicle: {
      type: String,
      required: true,
    },
    driverName: {
      type: String,
      default: "",
    },
    isMapView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>