<template>
  <div
    class="fixed xl:top-24 top-0 h-screen xl:h-full w-full bg-white px-2 py-4 shadow xl:w-80"
    style="z-index: 99 !important"
    v-if="show"
  >
    <div
      class="w-full border-b py-2 relative flex justify-between items-center"
      v-if="detail"
    >
      <button class="text-black text-base" @click="backMenu()">
        <i class="fas fa-arrow-left"></i>
      </button>
      <span class="font-bold block">{{ detail.title }}</span>
      <button
        @click="hideModal()"
        class="text-black rounded-full w-7 h-7 flex items-center justify-center"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div
      class="w-full py-2 overflow-y-scroll"
      v-if="detail"
      style="height: 80vh !important"
    >

      <MenuItem v-model="activeMenu" :detail="detail" />
      <currentDetail :detail="detail" v-if="activeMenu == 1 && detail" />

      <taskDetail
        @selectedActiveDetail="(val) => changeDetail(val)"
        :item="detail"
        v-else-if="activeMenu == 2"
      />
      <otherProducts :vehicleId="detail.vehicle" v-else />
    </div>
  </div>
</template>

<script>
// Local components
import MenuItem from "./components/menu/menu.vue";

// Sections
import currentDetail from "./components/general/index.vue";
import taskDetail from "./components/currentTask/index.vue";
import otherProducts from "./components/pastMissions/index.vue";

export default {
  name: "vehicle-tracking",
  props: ["detail","isVehicleDetailShow"],
  components: {
    taskDetail,
    currentDetail,
    otherProducts,
    MenuItem,
  },
  data() {
    return {
      activeMenu: 1,
      show:this.isVehicleDetailShow || false,
    };
  },
  methods: {
    backMenu() {
      this.show = false;
      this.$emit("backMenu", true);
    },
    changeDetail(val) {
      this.$emit("selectedActiveDetail", val);
    },
    showModal() {
      this.show = true;
      this.activeMenu = 1;
    },
    hideModal() {
      this.show = false;
      this.$emit("closeModal", true);
    },
  },
  created(){
    setTimeout(()=>{
    this.show= this.isVehicleDetailShow
    },500) 
  },

  watch: {
    selected(val) {
      this.$emit("selected", val);
    }, 
    isVehicleDetailShow(val){
       this.show=this.isVehicleDetailShow;
    }
  },
};
</script>
