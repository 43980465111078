<template>
  <div
    class="flex items-center justify-between bg-gray-100 dark:bg-gray-800 px-4 py-2 mx-4 mt-2 rounded"
  >
    <div class="flex items-center space-x-4">
      <div class="border-r mr-2 pr-5">
        <span class="text-lg font-light border-r mr-2 pr-2 text-gray-500 dark:text-gray-400">Sipariş:</span>
        <span class="font-medium ml-1"
          >{{ orderItem.orderNo }} - {{ orderItem.orderName }}</span
        >
      </div>
      <div>
        <span class="text-lg font-light border-r mr-2 pr-2 text-gray-500 dark:text-gray-400 ">Tonaj:</span>
        <span class="font-medium ml-1"
          >{{convertTonnage(orderItem)}}</span
        >
      </div>

    </div>
    <div
      class="px-2 py-1 text-xs rounded"
      :class="getStateClass(orderItem.TransitionType)"
    >
    
      {{ orderItem.TransitionType == 1 ? "Gidiş Dönüş" : "Tek Yön" }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderItem"],
  methods: {
    getStateClass(stateId) {
      switch (Number(stateId)) {
        case 1:
          return "bg-green-700 text-white";
        case 2:
          return "bg-blue-700 text-white";
      }
    },
    convertTonnage(data) {
      let tonnage = Math.max(0, data.tonnage).toFixed(2);
      let remainingTonnage = Math.max(
        0,
        data.tonnage - data.receivedTonnage
      ).toFixed(2);

      if (data.unitType === "SEFER") {
        // Function to convert tonnage to SEFER
        const convertToSefer = (ton) => {
          if (ton < 20) return 0;
          if (ton >= 20 && ton <= 32) return 1;
          return Math.ceil(ton / 26);
        };

        tonnage = convertToSefer(tonnage);
        remainingTonnage = convertToSefer(parseFloat(remainingTonnage));
      }

      return `${tonnage} / ${remainingTonnage} ${data.unitType || "TON"}`;
    },
  },
};
</script>