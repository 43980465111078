<template>
  <modal
    name="editCalculateroute-modal"
    :height="isMobile ? '100%' : '100%'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <logModule :moduleId="2" :rowId="detail.id" />
    <div
      class="w-full text-2xl dark:text-white dark:bg-gray-900 h-full overflow-y-scroll"
    >
      <!-- Ara noktalar bu kısımda gözükmüyor -->
      <!-- Header -->
      <div class="flex items-center mb-4 w-full px-8">
        <i class="fas fa-calculator fa-2x text-xl"></i>
        <h5 class="font-bold ml-4 text-xl">HESAP DETAYI</h5>
        <div class="mt-4 w-2/12 ml-auto flex items-center">
          <div class="w-6/12">
            <logBtn />
          </div>
          <div class="w-6/12 ml-16">
            <button @click="$modal.hide('editCalculateroute-modal')" class="">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Header -->

      <!-- Body -->
      <div class="dark:bg-gray-800 px-5 shadow-sm rounded">
        <form @submit.prevent="save" class="h-full bg-white p-2">
          <div class="w-full full mt-4 md:mt-0">
            <diffInput
              type="text"
              max="255"
              min="1"
              v-model="name"
              :disabled="true"
              :required="true"
              title="Hasap Adı"
              class="text-base"
            />
          </div>
          <div class="md:flex">
            <div class="w-full md:w-6/12 mt-4">
              <gmapInputVue
                @change="(r) => changeFillingPoint(r)"
                :defaultAdress="fillingPoint.adress"
                :lat="fillingPoint.lat"
                :lng="fillingPoint.lng"
                height="150px"
                placeHolder="Başlangıç"
                :hideMap="true"
                class="text-base"
                :disabled="true"
              />
            </div>
            <div class="w-full md:w-6/12 mt-4 md:pl-2">
              <gmapInputVue
                @change="(r) => changeDeliveryPoint(r)"
                :defaultAdress="deliveryPoint.adress"
                :lat="deliveryPoint.lat"
                :lng="deliveryPoint.lng"
                height="150px"
                :hideMap="true"
                placeHolder="Bitiş"
                class="text-base"
                :disabled="true"
              />
            </div>
          </div>
          <div class="w-full my-6">
            <routeViewiver
              v-if="
                // changeLocationState &&
                fillingPoint.lat > 0 &&
                fillingPoint.lng > 0 &&
                deliveryPoint.lat > 0 &&
                deliveryPoint.lng > 0
              "
              ref="routerViev"
              :customer="receivedName"
              :outpoint="deliveredName"
              :center="{
                lat: Number(fillingPoint.lat),
                lng: Number(fillingPoint.lng),
              }"
              :startPosition="{
                adress: fillingPoint.adress,
                lat: Number(fillingPoint.lat),
                lng: Number(fillingPoint.lng),
              }"
              @changeStartPosition="(val) => (fillingPoint = val)"
              @changeEndPosition="(val) => (deliveryPoint = val)"
              @changeOutpointId="(r) => (receivedName = r)"
              @changeCustomerId="(r) => (deliveredName = r)"
              @changePolyline="(r) => changePolyline(r)"
              :endPosition="{
                adress: deliveryPoint.adress,
                lat: Number(deliveryPoint.lat),
                lng: Number(deliveryPoint.lng),
              }"
              :height="'250px'"
              @change="(r) => (costDistribution = r)"
              :waypointsList="waypointsList"
            />
          </div>

          <div class="md:flex my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="number"
                v-model="hakedisKilometer"
                :required="true"
                title="Tek yön Kilometresi"
                disabled="true"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="number"
                v-model="transitionCount"
                :required="true"
                title="Ay içi Sefer Sayısı"
                :max="99"
                :min="1"
                :step="1"
                :disabled="true"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :value="Money(hakedisKilometer * transitionCount * 2)"
                :required="true"
                :disabled="true"
                title="Toplam Kilometre"
              />
            </div>
          </div>

          <div class="md:flex items-center my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :value="numberFormatter(totalFuelPrice) + ' ₺'"
                :disabled="true"
                title="Yakıt Maliyeti"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="text"
                :value="numberFormatter(otherExpensePrice) + ' ₺'"
                :disabled="true"
                title="Diğer Masraflar"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :disabled="true"
                :value="numberFormatter(tonnagePrice) + ' ₺'"
                title="Ton Maliyeti"
              />
            </div>
          </div>

          <div class="md:flex items-center my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                @click="$modal.show('add-hgs-detail-modal')"
                :value="
                  numberFormatter(
                    costDistribution.cost.cash * transitionCount * isOneWay
                  ) + ' ₺'
                "
                :disabled="true"
                :title="$t('operations.planning.modal.tollHighwayCost')"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="text"
                :value="numberFormatter(expensivePrice) + ' ₺'"
                :disabled="true"
                title="Maliyet Fiyatı"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="price"
                v-model="totalPrice"
                :digitCount="2"
                title="Gerçek Satış Fiyatı"
                :disabled="true"
              />
            </div>
          </div>

          <div class="my-6 md:flex items-center">
            <div
              class="w-full mt-4 md:mt-0"
              :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12'"
            >
              <diffInput
                type="select"
                :optList="getYesNoList"
                v-model="isAmortisman"
                title="Amortisman Maliyeti"
                :disabled="true"
              />
            </div>
            <div
              class="w-full mt-4 md:mt-0 md:px-2"
              :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12 md:pl-2'"
            >
              <diffInput
                type="select"
                :optList="getYesNoList"
                v-model="isOneWay"
                title="Tek Yön"
                :disabled="true"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0" v-if="isOneWay == 1">
              <diffInput
                type="number"
                v-model="dayCount"
                :step="1"
                :min="0"
                :max="150"
                title="Gün Sayısı"
                :disabled="true"
              />
            </div>
          </div>
          <waypoints
            class="text-sm"
            :minDate="fillingDate"
            :maxDate="deliveryDate"
            :value="waypointsList"
            :maxTonnage="30"
            :hiddenInsert="true"
          />
          <!-- maxTonnage waypoinst'de Kullanılmıyor gerek yoksa sil -->
          <div class="w-full">
            <diffInput
              :disabled="true"
              type="textarea"
              v-model="explanation"
              :title="$t('general.note')"
              maxlength="500"
              :required="isNoteRequired"
            />
          </div>

          <div v-if="false">
            <asyncBtn
              icon="fas fa-check"
              :text="$t('general.saveButtonTitle')"
              :loadTitle="$t('general.saveButtonLoadTitle')"
              :loadState="load"
              class="w-full py-1 mt-4"
            />
          </div>
        </form>
      </div>
      <!-- Body -->
    </div>
  </modal>
</template>

<script>
//npm
import polyLine from "google-polyline";

//global Components
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import diffInput from "@/components/general/diffInput.vue";
import routeViewiver from "@/components/devItem/route-generator/index.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

//networking
import axios from "axios";
import { calculate } from "@/networking/urlmanager";
import _ from "lodash";

//local components
import waypoints from "./waypointsCalculator.vue";
import { calculator } from "@/utils/cost";

export default {
  name: "calculPage",
  props: ["detail"],
  components: {
    routeViewiver,
    diffInput,
    gmapInputVue,
    asyncBtn,
    waypoints,
    logModule,
    logBtn,
  },
  data() {
    return {
      fillingDate: "", // Gereksiz state ?
      deliveryDate: "", // Gereksiz state ?
      name: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      hakedisKilometer: "",
      transitionCount: 1,
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",
      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 1,
      expenseDetail: {},
      waypointsList: [],
      isNoteRequired: false,
      changeLocationState: false,
      load: false,
      debouncedCalculatePrice: _.debounce(this.calculatePrice, 300),
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    hide() {
      this.$modal.hide("editCalculateroute-modal");
    },
    show() {
      this.name = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.totalPrice = "";
      this.isAmortisman = "";
      this.isOneWay = "";
      this.dayCount = "";
      this.explanation = "";
      this.hakedisKilometer = "";
      this.transitionCount = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 1, lng: 0, adress: "" };
      this.rowId = this.detail.rowId; //
      this.name = this.detail.name; //İSİM

      setTimeout(() => {
        this.rowId = this.detail.id;
        this.name = this.detail.name;
        this.fillingPoint = {
          lat: parseFloat(this.detail.outpointLat),
          lng: parseFloat(this.detail.outpointLng),
          adress: this.detail.startPosition,
        };
        this.deliveryPoint = {
          lat: parseFloat(this.detail.targetPointLat),
          lng: parseFloat(this.detail.targetPointLng),
          adress: this.detail.finishPosition,
        };
        this.totalPrice = this.detail.salePrice; //GERÇEK SATIŞ FİYATI
        this.isAmortisman = this.detail.amortismanPrice; //AMORTİS MALİYETİ
        this.isOneWay = this.detail.oneWay; //TEK YÖN
        this.dayCount = this.detail.dayCount; //GÜN SAYISI
        this.explanation = this.detail.explanation; //NOT
        this.hakedisKilometer = this.detail.oneWayKm; //TEK YÖN KM
        this.transitionCount = this.detail.montlytransitionCount;
        this.changeFillingPoint(this.fillingPoint);
        this.changeDeliveryPoint(this.deliveryPoint);
        this.waypointsList = this.detail.wayPoints?.map((item) => {
          return {
            no: item.no,
            name: 0,
            position: {
              lat: Number(item?.latitude),
              lng: Number(item?.longitude),
              adress: item?.adress,
            },
            date: item?.date,
            amount: Number(item?.amount),
          };
        });
      }, 50);
      this.$modal.show("editCalculateroute");
    },
    save() {
      this.load = true;
      axios
        .post(
          calculate.edit,
          {
            rowId: this.rowId,
            name: this.name,
            startPosition: this.receivedName, //BAŞLANGIÇ NOKTASI++++++++++++++
            finishPosition: this.deliveredName, //BİTİŞ NOKTASI +++++++++++++++
            oneWayKm: this.hakedisKilometer, //TEK YÖN KM ++++++++++++++++
            montlytransitionCount: this.transitionCount, //AY İÇİ SEFER SAYISI      +++++++++++
            totalKm: this.Money(
              this.hakedisKilometer * this.transitionCount * 2
            ), //TOPLAM KM     ++++++++++++
            fuelPrice: this.Money(this.totalFuelPrice), //yakıt maliyeti  ++++++++++++++++
            otherPrice: this.Money(this.otherExpensePrice), //DİĞER MASRAFLAR  ++++++++++++
            tonPrice: this.Money(this.tonnagePrice), //ton maliyeti+++++++++++++++
            motorwayPrice: this.Money(this.costDistribution.cost.cash), //OTOYOL MALİYETİ++++++++++0
            cost: this.expensivePrice, //maliyet fıyatı  +++++++
            salePrice: this.totalPrice, //GERÇEK SATIŞ FİYATI  +++++++++++++
            amortismanPrice: this.isAmortisman, //Amortisman  Maliyeti +++++++++++
            oneWay: this.isOneWay, //TEK YÖN ++++++++++++++++
            dayCount: this.dayCount, //GÜN SAYISI +++++++++++++++
            explanation: this.explanation, //NOT ++++++++++++++++
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetPointLat: this.deliveryPoint.lat,
            targetPointLng: this.deliveryPoint.lng,
            waypoint: this.waypointsList,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
          this.load = false;
        });
    },
    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;

      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeLocation() {
      this.changeLocationState = false;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    async calculatePrice() {
      let montlyKilometer =
        this.hakedisKilometer *
        this.transitionCount *
        (this.isOneWay == 1 ? 1 : 2);
     
      const calculateHgs = parseFloat(this.costDistribution.cost.cash) * this.transitionCount * (this.isOneWay == 1 ? 1 : 2);
      const hgsPrice =isNaN(calculateHgs) ? 0 : calculateHgs;
      this.expenseDetail = await calculator(
        this.$store.state.userData.token,
        1,
        montlyKilometer * 12,
        null,
        null,
        montlyKilometer,
        hgsPrice,
        this.isAmortisman == 1,
        this.isOneWay == 1,
        this.dayCount
      );

      const { total, fuelTotalPrice, totalAdbluePrice } = this.expenseDetail;

      this.totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
      this.otherExpensePrice =
        (total - this.totalFuelPrice) * this.transitionCount;
      this.expensivePrice = total;
      this.tonnagePrice = this.expensivePrice / 26 / this.transitionCount;
    },
    numberFormatter(val) {
      const formattedValue = new Intl.NumberFormat("tr-TR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);
      return formattedValue;
    },
  },
  watch: {
    transitionCount() {
      this.debouncedCalculatePrice();
    },
    costDistribution: {
      deep: true,
      handler(val) {
        this.hakedisKilometer = val.totalKilometer;
        this.debouncedCalculatePrice();
      },
    },
    isAmortisman() {
      this.debouncedCalculatePrice();
    },
    isOneWay() {
      this.debouncedCalculatePrice();
    },
    dayCount() {
      this.debouncedCalculatePrice();
    },

    totalPrice(newTotalPrice) {
      if (
        newTotalPrice == 0 ||
        newTotalPrice == "" ||
        newTotalPrice == undefined
      )
        return;

      // bu olmazsa js noktayı ondalık ayrımı olarak algılıyor. ör:1.500 binBeşYüzü BirBuçuk olarak algılıyor
      const formattedPrice = newTotalPrice.includes(".")
        ? newTotalPrice.replace(".", "")
        : newTotalPrice;
      this.totalPrice = formattedPrice;

      const numericTotalPrice = parseFloat(formattedPrice.replace(",", "."));
      const numericExpensivePrice = parseFloat(
        this.expensivePrice.replace(",", ".").replace(".", "")
      );
      this.isNoteRequired = numericTotalPrice < numericExpensivePrice;
      if (this.isNoteRequired) {
        this.$swal.fire({
          icon: "warning",
          title: "UYARI!",
          text: "Maliyet Fiyatının altında bir Satış Fiyatı girildi. Lütfen açıklamanızı Not alanına ekleyin.",
          showConfirmButton: true,
          confirmButtonText: "TAMAM",
        });

        this.isNoteRequired = false;
      }
    },
  },
};
</script>
