<!-- components/JourneyInfoCard.vue (Minimal Tasarım) -->
<template>
  <div
    class="absolute bottom-0 left-0 right-0 bg-white rounded-t-lg shadow-md z-50 transition-all duration-300"
  >
    <!-- Kart Başlığı -->
    <div
      class="flex items-center justify-between px-4 py-2 border-b cursor-pointer"
      @click="toggleCollapse"
    >
      <div class="flex items-center space-x-2">
        <span class="w-2 h-2 rounded-full" :class="getStatusBgClass"></span>
        <h3 class="text-sm font-medium">{{ getStateText }}</h3>
      </div>

      <div class="flex items-center space-x-3">
        <div class="flex items-center">
          <i class="far fa-clock text-gray-500 mr-1 text-xs"></i>
          <span class="text-xs text-gray-600">{{ remainingTimeShort }}</span>
        </div>
        <button class="text-gray-400">
          <i
            :class="collapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
          ></i>
        </button>
      </div>
    </div>

    <!-- Kart İçeriği -->
    <div v-if="!collapsed" class="p-3">
      <!-- İlerleme Göstergesi -->
      <div class="mb-3">
        <div class="h-1.5 bg-gray-100 rounded-full overflow-hidden">
          <div
            class="h-full bg-red-500 rounded-full transition-all duration-500"
            :style="{ width: progressPercentage + '%' }"
          ></div>
        </div>
        <div class="flex justify-between mt-1 text-xs text-gray-400">
          <span>Çıkış</span>
          <span>Yükleme</span>
          <span>Yolda</span>
          <span>Varış</span>
          <span>Teslim</span>
        </div>
      </div>

      <!-- Ana Bilgiler -->
      <div class="grid grid-cols-2 gap-3">
        <!-- Sol Panel -->
        <div class="space-y-3">
          <!-- Araç Bilgileri -->
          <div class="bg-gray-50 rounded p-2">
            <div class="flex items-center mb-1">
              <i class="fas fa-truck text-gray-400 mr-1.5 text-xs"></i>
              <span class="text-xs font-medium text-gray-600"
                >Araç Bilgileri</span
              >
            </div>
            <div class="grid grid-cols-1 gap-1">
              <div class="flex justify-between items-center text-xs">
                <span class="text-gray-500">Araç:</span>
                <span>{{ detail.vehicle || "-" }}</span>
              </div>
              <div class="flex justify-between items-center text-xs">
                <span class="text-gray-500">Sürücü:</span>
                <span>{{ detail.driverName || "-" }}</span>
              </div>
            </div>
          </div>

          <!-- Adres Bilgileri -->
          <div class="bg-gray-50 rounded p-2">
            <div class="flex items-center justify-between mb-1">
              <div class="flex items-center">
                <i
                  class="fas fa-map-marker-alt text-gray-400 mr-1.5 text-xs"
                ></i>
                <span class="text-xs font-medium text-gray-600">{{
                  addressTypeText
                }}</span>
              </div>
            </div>
            <p class="text-xs text-gray-600 line-clamp-2">
              {{ detail.target.adress }}
            </p>
          </div>
        </div>

        <!-- Sağ Panel -->
        <div class="space-y-3">
          <!-- Yolculuk Metrikleri -->
          <div class="bg-gray-50 rounded p-2">
            <div class="flex items-center mb-1">
              <i class="fas fa-tachometer-alt text-gray-400 mr-1.5 text-xs"></i>
              <span class="text-xs font-medium text-gray-600"
                >Yolculuk Bilgileri</span
              >
            </div>
            <div class="grid grid-cols-2 gap-1">
              <div
                class="flex flex-col items-center text-xs p-1 bg-white rounded"
              >
                <span class="text-gray-500">Hız</span>
                <span class="font-medium text-red-500"
                  >{{ detail.speed || 0 }} km/s</span
                >
              </div>
              <div
                class="flex flex-col items-center text-xs p-1 bg-white rounded"
              >
                <span class="text-gray-500">Mesafe</span>
                <span class="font-medium text-red-500"
                  >{{ taskDetail.totalDistance || 0 }} km</span
                >
              </div>
            </div>
          </div>

          <!-- Zaman Bilgileri -->
          <div class="bg-gray-50 rounded p-2">
            <div class="flex items-center mb-1">
              <i class="far fa-calendar-alt text-gray-400 mr-1.5 text-xs"></i>
              <span class="text-xs font-medium text-gray-600"
                >Zaman Bilgileri</span
              >
            </div>
            <div class="grid grid-cols-1 gap-1">
              <div class="flex justify-between items-center text-xs">
                <span class="text-gray-500">Tahmini:</span>
                <span>{{ formattedEstimatedTime }}</span>
              </div>
              <div class="flex justify-between items-center text-xs">
                <span class="text-gray-500">Planlanan:</span>
                <span :class="timeStatusClass">{{
                  formattedTargetDateShort
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Notlar -->
      <div
        v-if="detail.planning.notes"
        class="mt-3 px-2 py-1.5 bg-gray-50 rounded text-xs"
      >
        <div class="flex items-center mb-0.5">
          <i class="fas fa-comment-alt text-gray-400 mr-1.5 text-xs"></i>
          <span class="font-medium text-gray-600">Not:</span>
        </div>
        <p class="text-gray-600 line-clamp-1">{{ detail.planning.notes }}</p>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";

export default {
  name: "journey-info-card",
  props: {
    detail: {
      type: Object,
      required: true,
    },
    taskDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapsed: false,
      interval: null,
    };
  },
  computed: {
    getStateText() {
      const states = {
        1: "Onay Bekliyor",
        2: "Onaylandı",
        3: "Yükleme Noktasına Gidiyor",
        4: "Yüklemede",
        5: "Yola Çıktı",
        6: "Teslim Noktasına Ulaştı",
        7: "Tahliyede",
        8: "Tamamlandı",
        9: "İptal Edildi",
        10: "Onaylanmadı",
      };
      return states[this.detail.planning.state] || "Bilinmiyor";
    },
    getStatusBgClass() {
      const statusClasses = {
        1: "bg-yellow-500",
        2: "bg-green-500",
        3: "bg-blue-500",
        4: "bg-purple-500",
        5: "bg-blue-600",
        6: "bg-indigo-500",
        7: "bg-indigo-600",
        8: "bg-green-600",
        9: "bg-red-500",
        10: "bg-red-600",
      };
      return statusClasses[this.detail.planning.state] || "bg-gray-500";
    },
    progressPercentage() {
      const stateProgress = {
        1: 5, // Onay Bekliyor
        2: 15, // Onaylandı
        3: 30, // Yükleme Noktasına Gidiyor
        4: 40, // Yüklemede
        5: 60, // Yola Çıktı
        6: 80, // Teslim Noktasına Ulaştı
        7: 90, // Tahliyede
        8: 100, // Tamamlandı
        9: 0, // İptal Edildi
        10: 0, // Onaylanmadı
      };
      return stateProgress[this.detail.planning.state] || 0;
    },
    remainingTime() {
      if (!this.taskDetail.estimatedArrival) return "";

      const now = moment();
      const arrival = moment(this.taskDetail.estimatedArrival);
      const duration = moment.duration(arrival.diff(now));

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) % 60;

      if (hours > 0) {
        return `${hours} saat ${minutes} dakika kaldı`;
      } else if (minutes > 0) {
        return `${minutes} dakika kaldı`;
      } else {
        return "Varış bekleniyor";
      }
    },
    remainingTimeShort() {
      if (!this.taskDetail.estimatedArrival) return "Hesaplanıyor";

      const now = moment();
      const arrival = moment(this.taskDetail.estimatedArrival);
      const duration = moment.duration(arrival.diff(now));

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) % 60;

      if (hours > 0) {
        return `${hours}s ${minutes}d`;
      } else if (minutes > 0) {
        return `${minutes}d`;
      } else {
        return "Varış";
      }
    },
    formattedTargetDate() {
      if (!this.detail.planning.targetDate) return "Belirtilmemiş";
      return moment(this.detail.planning.targetDate).format(
        "DD MMM YYYY - HH:mm"
      );
    },
    formattedTargetDateShort() {
      if (!this.detail.planning.targetDate) return "-";
      return moment(this.detail.planning.targetDate).format("DD.MM HH:mm");
    },
    formattedEstimatedTime() {
      if (!this.taskDetail.estimatedArrival) return "-";
      return moment(this.taskDetail.estimatedArrival).format("DD.MM HH:mm");
    },
    timeStatus() {
      if (!this.detail.planning.targetDate || !this.taskDetail.estimatedArrival)
        return "";

      const target = moment(this.detail.planning.targetDate);
      const estimated = moment(this.taskDetail.estimatedArrival);
      const diff = estimated.diff(target, "minutes");

      if (Math.abs(diff) < 30) {
        return "Zamanında";
      } else if (diff > 0) {
        return `${Math.floor(diff / 60)}s ${diff % 60}d gecikme`;
      } else {
        const absDiff = Math.abs(diff);
        return `${Math.floor(absDiff / 60)}s ${absDiff % 60}d erken`;
      }
    },
    timeStatusClass() {
      if (!this.timeStatus) return "";

      if (this.timeStatus === "Zamanında") {
        return "text-green-600";
      } else if (this.timeStatus.includes("gecikme")) {
        return "text-red-500";
      } else {
        return "text-blue-500";
      }
    },
    addressTypeText() {
      return this.detail.planning.state < 4
        ? "Yükleme Noktası"
        : "Teslim Noktası";
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.$emit("toggle-collapse", this.collapsed);
    },

    openInMaps() {
      const { lat, lng } = this.detail.target;
      if (lat && lng) {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        window.open(url, "_blank");
      }
    },

    updateRemainingTime() {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.interval = setInterval(this.updateRemainingTime, 60000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
  
  <style scoped>
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>