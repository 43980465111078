
/**
 * Haversine formülünü kullanarak iki koordinat arasındaki mesafeyi hesaplar.
 *
 * @param {Object} coords1 - İlk koordinat (enlem ve boylam formatında).
 * @param {Object} coords2 - İkinci koordinat (enlem ve boylam formatında).
 * @returns {number} - İki koordinat arasındaki mesafe (kilometre cinsinden).
 */
function haversineDistance(coords1, coords2) {
  const R = 6371e3;
  const lat1Rad = (Math.PI * coords1.lat) / 180;
  const lat2Rad = (Math.PI * coords2.lat) / 180;
  const deltaLat = ((coords2.lat - coords1.lat) * Math.PI) / 180;
  const deltaLon = ((coords2.lng - coords1.lng) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return (R * c) / 1000;
}


module.exports={
  haversineDistance
}