const { isWashStatus } = require("./controller/isWashStatus");
const { isSequential } = require("./controller/isSequential");
const { recommendSingleVehicleForOrder } = require("./recommendSingleVehicleForOrder");
const {recommendMultipleVehiclesForOrder}=require('./recommendMultipleVehiclesForOrder');
const { haversineDistance } = require("./haversineDistance");
const { isVehicleSuitableForOrder } = require("./controller/isVehicleSuitableForOrder");
const { calculateDistancePriority } = require("./calculator/calculateDistancePriority");
const { calculateCustomerPriority } = require("./calculator/calculateCustomerPriority");
const { updateVehicleAvailability } = require("./tools/updateVehicleAvailability");
const { isVehicleSuitableForOrderDetail } = require("./controller/isVehicleSuitableForOrderDetail");



/**
 * Sipariş için en uygun araç veya araçları önerir.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @returns {Array} Önerilen araç veya araçlar listesi.
 */
function recommendVehicleForOrder(
  order,
  vehicles,
  products,
  customers,
  totalCount
) {
  if (order.tonnage <= 30) {
    const vehicle = recommendSingleVehicleForOrder(
      order,
      vehicles,
      customers,
      products,
      false
    );
    if (vehicle) return [vehicle];
    else return [];
  } else {
    const recommendedVehicles = recommendMultipleVehiclesForOrder(
      order,
      vehicles,
      customers,
      products,
      totalCount,
      ''
    );

    return recommendedVehicles;
  }
}

module.exports = {
  isWashStatus,
  isSequential,
  recommendSingleVehicleForOrder,
  recommendVehicleForOrder,
  recommendMultipleVehiclesForOrder,
  haversineDistance,
  isVehicleSuitableForOrder,
  calculateDistancePriority,
  calculateCustomerPriority,
  updateVehicleAvailability,
  isVehicleSuitableForOrderDetail,
};
