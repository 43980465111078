import axios from "axios";
import { routeZone } from "@/networking/urlmanager";
import { decode } from "@/utils/encoder";

/**
 * Bölge verilerini yönetmek için API servisi
 */
export const zoneService = {
    /**
     * Bölge verilerini API'den çeker
     * 
     * @param {Object} options - İstek seçenekleri
     * @param {number} options.page - Sayfa numarası (0'dan başlar)
     * @param {string} options.search - Arama terimi
     * @param {string} options.token - Kimlik doğrulama token'ı
     * 
     * @returns {Promise<Object>} - Bölge verileri ve meta bilgiler
     */
    async getZones({ page = 0, search = "", token }) {
        try {
            const params = {
                page,
                search,
            };

            const response = await axios.get(routeZone.getAll, {
                params,
                headers: {
                    Authorization: `Bareer ${token}`,
                },
            });

            // API'den gelen verileri işle
            const zones = response.data.data.detail.map((item) => ({
                ...item,
                path: decode(item.polyline).polyline,
            }));

            return {
                zones,
                totalCount: response.data.data.totalCount,
                pageCount: response.data.data.pageCount,
            };
        } catch (error) {
            console.error("Bölge verileri alınırken hata:", error);
            throw error;
        }
    },

    /**
     * Belirli bir bölgenin detaylarını getirir
     * 
     * @param {number} zoneId - Bölge ID'si
     * @param {string} token - Kimlik doğrulama token'ı
     * 
     * @returns {Promise<Object>} - Bölge detayları
     */
    async getZoneDetails(zoneId, token) {
        try {
            const response = await axios.get(`${routeZone.getDetail}/${zoneId}`, {
                headers: {
                    Authorization: `Bareer ${token}`,
                },
            });

            // Polygon verilerini çöz
            const zoneData = response.data;
            return {
                ...zoneData,
                path: decode(zoneData.polyline).polyline,
            };
        } catch (error) {
            console.error(`Bölge #${zoneId} detayları alınırken hata:`, error);
            throw error;
        }
    },

    /**
     * Yeni bir bölge oluşturur
     * 
     * @param {Object} zoneData - Bölge verileri
     * @param {string} token - Kimlik doğrulama token'ı
     * 
     * @returns {Promise<Object>} - Oluşturulan bölge
     */
    async createZone(zoneData, token) {
        try {
            const response = await axios.post(routeZone.create, zoneData, {
                headers: {
                    Authorization: `Bareer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Bölge oluşturulurken hata:", error);
            throw error;
        }
    },

    /**
     * Bir bölgeyi günceller
     * 
     * @param {number} zoneId - Bölge ID'si
     * @param {Object} zoneData - Güncellenecek veriler
     * @param {string} token - Kimlik doğrulama token'ı
     * 
     * @returns {Promise<Object>} - Güncellenen bölge
     */
    async updateZone(zoneId, zoneData, token) {
        try {
            const response = await axios.put(`${routeZone.update}/${zoneId}`, zoneData, {
                headers: {
                    Authorization: `Bareer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Bölge #${zoneId} güncellenirken hata:`, error);
            throw error;
        }
    },

    /**
     * Bir bölgeyi siler
     * 
     * @param {number} zoneId - Bölge ID'si
     * @param {string} token - Kimlik doğrulama token'ı
     * 
     * @returns {Promise<Object>} - Silme işlemi sonucu
     */
    async deleteZone(zoneId, token) {
        try {
            const response = await axios.delete(`${routeZone.delete}/${zoneId}`, {
                headers: {
                    Authorization: `Bareer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Bölge #${zoneId} silinirken hata:`, error);
            throw error;
        }
    }
};