<template>
  <div class="py-1">
    <div class="flex items-start">
      <!-- İkon (varsa) -->
      <div v-if="icon" class="text-gray-400 mr-3 w-5 text-center">
        <i :class="`fas ${icon}`"></i>
      </div>

      <div class="flex-1">
        <!-- Başlık -->
        <div class="text-xs text-gray-500">{{ title }}</div>

        <!-- Değer -->
        <div
          class="font-semibold mt-1"
          :class="[
            highlight ? 'text-red-600' : 'text-gray-800',
            { 'text-sm': !bigText, 'text-base': bigText },
          ]"
        >
          {{ value }}
        </div>

        <!-- Ek içerik için slot -->
        <slot name="extra"></slot>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "DetailItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    bigText: {
      type: Boolean,
      default: false,
    },
  },
};
</script>