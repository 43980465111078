<template>
  <div class="py-1 relative">
    <button
      @click="$emit('click')"
      class="w-full flex items-start group focus:outline-none"
    >
      <!-- İkon -->
      <div class="text-gray-400 mr-3 w-5 text-center">
        <i class="fas fa-info-circle"></i>
      </div>

      <div class="flex-1">
        <!-- Başlık -->
        <div class="text-xs text-gray-500 text-left">Durum</div>

        <!-- Durum bilgisi -->
        <div class="flex items-center justify-between w-full">
          <div class="font-semibold mt-1 text-red-500">
            {{ status }}
          </div>

          <!-- Arama ikonu -->
          <div
            class="text-gray-400 group-hover:text-gray-600 transition-colors"
          >
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    </button>

  </div>
</template>
  
  <script>
export default {
  name: "StatusItem",
  props: {
    status: {
      type: String,
      required: true,
    },
    stateId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    getStatusColor() {
      // Durum ID'sine göre renk belirle
      const stateId = parseInt(this.stateId);

      // Örnek durum renkleri (gerçek durumlara göre ayarlayın)
      switch (stateId) {
        case 1: // Yükleniyor
          return "bg-blue-500";
        case 2: // Yolda
          return "bg-green-500";
        case 3: // Beklemede
          return "bg-yellow-500";
        case 4: // Tamamlandı
          return "bg-green-600";
        case 5: // İptal
          return "bg-red-500";
        default:
          return "bg-gray-500";
      }
    },
  },
};
</script>