<!-- Bu bileşen ana harita bileşeninde kullanılan GroupDetail bileşeninin geliştirilmiş halidir -->
<template>
  <div class="bg-white rounded-lg shadow-lg absolute left-5 top-32 z-40 transition-all duration-300 w-72 overflow-hidden">
    <div class="py-2 px-3 bg-blue-600 text-white flex justify-between items-center">
      <h3 class="font-bold text-sm"><i class="fas fa-truck mr-2"></i>Araç Listesi</h3>
      <div class="flex items-center">
        <span v-if="List.length > 0" class="text-xs mr-2 bg-white text-blue-600 px-2 py-0.5 rounded-full">{{ List.length }}</span>
        <button @click="close" class="text-white hover:text-gray-200">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    
    <div v-if="List.length === 0" class="p-4 text-center text-gray-500">
      <i class="fas fa-search fa-2x mb-2"></i>
      <p>Araç bulunamadı</p>
    </div>
    
    <div v-else class="overflow-y-auto custom-scrollbar" style="max-height: 350px;">
      <div class="p-1 border-b">
        <input 
          type="text" 
          v-model="search" 
          placeholder="Araç ara..." 
          class="w-full py-1 px-2 border rounded text-xs"
        />
      </div>
      
      <div class="flex border-b py-1 px-2 bg-gray-100 text-xs text-gray-600">
        <div class="w-24">Plaka</div>
        <div class="flex-1">Durum</div>
      </div>
      
      <div class="vehicle-list">
        <div 
          v-for="(item, i) in filteredList" 
          :key="i"
          @click="selectItem(item.title)"
          class="py-2 px-2 border-b hover:bg-gray-50 cursor-pointer transition-colors duration-200 flex items-center"
          :class="{'bg-blue-50 border-l-2 border-blue-600': selectedVehicle === item.title}"
        >
          <div class="w-24 flex items-center">
            <div class="w-4 h-4 flex items-center justify-center rounded-full mr-1" 
                 :class="item.speed === 0 ? 'bg-red-100' : 'bg-green-100'">
              <i class="fas fa-circle text-xs" 
                 :class="item.speed === 0 ? 'text-red-600' : 'text-green-600'"></i>
            </div>
            <div class="text-xs font-medium text-gray-900 truncate">{{ item.title }}</div>
          </div>
          <div class="flex-1 text-xs text-gray-500">
            <span>{{ getVehicleStatus(item) }}</span>
            <span v-if="item.region" class="ml-1 text-gray-400 text-xxs truncate">({{ item.region }})</span>
          </div>
          <div class="ml-1">
            <button 
              @click.stop="showDetails(item)" 
              class="text-gray-400 hover:text-blue-600"
              title="Detaylar"
            >
              <i class="fas fa-info-circle text-xs"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Detay Modalı -->
    <div v-if="showDetailModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white rounded-lg shadow-xl p-3 max-w-md w-full mx-4">
        <div class="flex justify-between items-center mb-2">
          <h3 class="text-base font-bold">{{ detailItem.title }}</h3>
          <button @click="showDetailModal = false" class="text-gray-500 hover:text-gray-700">
            <i class="fas fa-times"></i>
          </button>
        </div>
        
        <div class="grid grid-cols-2 gap-2 mb-3 text-sm">
          <div>
            <p class="text-xs text-gray-500">Bölge</p>
            <p class="text-sm">{{ detailItem.region || 'Belirtilmemiş' }}</p>
          </div>
          <div>
            <p class="text-xs text-gray-500">Durum</p>
            <p class="text-sm">{{ getVehicleStatus(detailItem) }}</p>
          </div>
          <div class="col-span-2">
            <p class="text-xs text-gray-500">Adres</p>
            <p class="text-sm">{{ detailItem.adress || 'Adres bilgisi yok' }}</p>
          </div>
          <div>
            <p class="text-xs text-gray-500">Sürücü</p>
            <p class="text-sm">{{ detailItem.driverName || 'Belirtilmemiş' }}</p>
          </div>
          <div>
            <p class="text-xs text-gray-500">Dorse</p>
            <p class="text-sm">{{ detailItem.dorse || 'Belirtilmemiş' }}</p>
          </div>
        </div>
        
        <div class="flex justify-end space-x-2">
          <button 
            @click="showDetailModal = false; selectItem(detailItem.title)" 
            class="px-3 py-1 text-xs bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            <i class="fas fa-search-location mr-1"></i>Haritada Göster
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupDetail",
  props: {
    List: {
      type: Array,
      default: () => []
    },
    position: {
      type: Object,
      default: () => ({ lat: 0, lng: 0 })
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: "",
      selectedVehicle: null,
      showDetailModal: false,
      detailItem: {},
      isCollapsed: this.collapsed
    };
  },
  computed: {
    filteredList() {
      if (!this.search) return this.List;
      
      const searchTerm = this.search.toLowerCase();
      return this.List.filter(item => 
        item.title.toLowerCase().includes(searchTerm) ||
        (item.region && item.region.toLowerCase().includes(searchTerm)) ||
        (item.driverName && item.driverName.toLowerCase().includes(searchTerm))
      );
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectItem(title) {
      this.selectedVehicle = title;
      this.$emit("selected", title);
    },
    showDetails(item) {
      this.detailItem = item;
      this.showDetailModal = true;
    },
    getVehicleStatus(item) {
      if (!item) return 'Bilinmiyor';
      
      if (item.speed === 0) {
        return 'Durmuş';
      } else {
        return 'Hareket Halinde';
      }
    },
    // FontAwesome ikonları kullanıyoruz, bu metoda gerek kalmadı
    getStatusIcon(item) {
      if (!item) return 'fa-question-circle';
      
      if (item.speed === 0) {
        return 'fa-pause-circle';
      } else {
        return 'fa-play-circle';
      }
    }
  },
  watch: {
    List: {
      handler(newList) {
        // Liste değiştiğinde seçimi sıfırla
        if (newList.length > 0 && !this.selectedVehicle) {
          this.selectedVehicle = null;
        }
        
        // Eğer seçili araç listede yoksa seçimi sıfırla
        if (this.selectedVehicle) {
          const exists = newList.some(item => item.title === this.selectedVehicle);
          if (!exists) {
            this.selectedVehicle = null;
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vehicle-list > div:hover {
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

/* Çok küçük yazı boyutu */
.text-xxs {
  font-size: 0.65rem;
}

/* Mobil görünüm için uyarlama */
@media (max-width: 640px) {
  .vehicle-list > div {
    padding: 0.5rem 0.25rem;
  }
}
</style>