<template>
  <div>
    <!-- Yetişme durumu -->
    <div class="py-3">
      <div class="flex items-start">
        <div class="text-gray-400 mr-3 w-5 text-center">
          <i class="fas fa-clock"></i>
        </div>

        <div class="flex-1">
          <div class="text-xs text-gray-500">Yetişme Durumu</div>

          <div
            class="font-semibold mt-1 text-sm uppercase"
            :class="willArriveOnTime ? 'text-green-600' : 'text-red-600'"
          >
            <span class="animate-pulse inline-block">
              {{ getArrivalStatusText }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Hedef tarih -->
    <div class="py-3">
      <div class="flex items-start">
        <div class="text-gray-400 mr-3 w-5 text-center">
          <i class="fas fa-calendar-check"></i>
        </div>

        <div class="flex-1">
          <div class="text-xs text-gray-500">{{ getTargetDateLabel }}</div>
          <div class="font-semibold mt-1 text-sm text-gray-800">
            {{ formatDate(targetDate) }}
          </div>
        </div>
      </div>
    </div>

    <!-- Tahmini varış (yalnızca gecikme varsa) -->
    <div v-if="!willArriveOnTime" class="py-3">
      <div class="flex items-start">
        <div class="text-gray-400 mr-3 w-5 text-center">
          <i class="fas fa-hourglass-half"></i>
        </div>

        <div class="flex-1">
          <div class="text-xs text-gray-500">{{ getEstimatedDateLabel }}</div>
          <div class="font-semibold mt-1 text-sm text-red-600">
            {{ formatDate(estimatedDate) }}
          </div>

          <!-- Gecikme süresi -->
          <div class="text-xs text-gray-500 mt-1">
            Tahmini gecikme: {{ getDelayTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";

export default {
  name: "ArrivalStatus",
  props: {
    state: {
      type: [Number, String],
      required: true,
    },
    targetDate: {
      type: [Date, String, Object],
      required: true,
    },
    estimatedDate: {
      type: [Date, String, Object],
      required: true,
    },
    willArriveOnTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getArrivalStatusText() {
      const actionText = parseInt(this.state) <= 3 ? "Doluma " : "Teslime ";
      const statusText = this.willArriveOnTime ? "Yetişecek" : "Yetişemez";
      return actionText + statusText;
    },

    getTargetDateLabel() {
      return parseInt(this.state) <= 3
        ? "Hedef Doluma Giriş Zamanı"
        : "Hedef Teslim Tarihi";
    },

    getEstimatedDateLabel() {
      return parseInt(this.state) <= 3
        ? "Tahmini Dolum Zamanı"
        : "Tahmini Teslim Tarihi";
    },

    getDelayTime() {
      const targetMoment = moment(this.targetDate);
      const estimatedMoment = moment(this.estimatedDate);

      if (estimatedMoment.isAfter(targetMoment)) {
        const duration = moment.duration(estimatedMoment.diff(targetMoment));

        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.asMinutes()) % 60;

        if (hours > 0) {
          return `${hours} saat ${minutes} dakika`;
        } else {
          return `${minutes} dakika`;
        }
      }

      return "0 dakika";
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD MMM YYYY, HH:mm");
    },
  },
};
</script>