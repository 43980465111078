// orderTools/recommendations.js
// Araç önerileri ve tavsiye işlemleri

import { recommendSingleVehicleForOrder } from '@/utils/recomendation/index.js';
import {  compareCosts } from './calculations';

/**
 * Önerilen aracı alır
 * @param {Object} order - Sipariş
 * @param {Array} vehicleList - Araç listesi
 * @param {Array} customers - Müşteriler
 * @param {Array} products - Ürünler
 * @param {Array} blockedVehicles - Engellenmiş araçlar
 * @param {boolean} isCentipede - Kırkayak aracı gerekiyorsa true
 * @param {string} token - Kimlik doğrulama jetonu
 * @returns {Promise<Array|null>} - Önerilen araç veya null
 */
export const getRecommendedVehicle = async (
  order,
  vehicleList,
  customers,
  products,
  blockedVehicles = [],
  isCentipede = false,
  token = null
) => {
  try {
    // Siparişleri planlanan araçları işaretle
    const markedVehicleList = vehicleList.map(vehicle => {
      // Eğer araç daha önce kullanıldıysa orderNos dizisine ekleme yap
      return {
        ...vehicle,
        orderNos: vehicle.orderNos || []
      };
    });
    
    // Araç önerisi al
    const recommendation = await recommendSingleVehicleForOrder(
      order,
      markedVehicleList,
      customers,
      products,
      1, // Maksimum öneri sayısı
      false, // isBulk
      false, // isReturn 
      true, // isOrdered
      blockedVehicles,
      isCentipede,
      token
    );

     // REALLOCATION_NEEDED bayrağı kontrolü ekle
     if (recommendation && recommendation.REALLOCATION_NEEDED) {
      return recommendation;
    }
    
    return recommendation;
  } catch (error) {
    console.error("Araç önerisi alınırken hata oluştu:", error);
    return null;
  }
};

/**
 * Special handling for small orders (≤ 20 tons) to compare centipede vs standard truck
 * @param {Object} order - The order object
 * @param {Array} vehicleList - List of available vehicles
 * @param {Array} customers - List of customers
 * @param {Array} products - List of products
 * @param {Array} blockedVehicles - List of blocked vehicles
 * @param {Object} store - Vuex store
 * @param {Function} swalBox - SweetAlert function
 * @returns {Promise<Array|null>} - Recommended vehicle or null
 */
export const handleSmallOrderRecommendation = async (order, vehicleList, customers, products, blockedVehicles, store, swalBox) => {
  // Try to find a centipede vehicle first
  const recommendedCentipede = await getRecommendedVehicle(
    order,
    vehicleList,
    customers,
    products,
    blockedVehicles,
    true, // isCentipede = true
    store.state.userData.token
  );

  // REALLOCATION_NEEDED kontrolü eklenir
  if (recommendedCentipede && recommendedCentipede.REALLOCATION_NEEDED) {
    return recommendedCentipede;
  }
  

  if (!recommendedCentipede || recommendedCentipede.length === 0) {

    // No centipede available, ask if user wants to use a regular truck
    const result = await swalBox({
      title: 'UYARI !',
      text: 'Siparişe uygun kırkayak bulunamadı, çekici önermek ister misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır'
    });

    if (result.isConfirmed) {
      return await getRecommendedVehicle(
        order,
        vehicleList,
        customers,
        products,
        blockedVehicles,
        false, // isCentipede = false
        store.state.userData.token
      );
    }

    return null;
  }

  // Get regular truck recommendation for comparison
  const recommendedTruck = await getRecommendedVehicle(
    order,
    vehicleList,
    customers,
    products,
    blockedVehicles,
    false, // isCentipede = false
    store.state.userData.token
  );

  // REALLOCATION_NEEDED kontrolü eklenir
 if (recommendedTruck && recommendedTruck.REALLOCATION_NEEDED) {
  return recommendedTruck;
}

  if (!recommendedTruck || recommendedTruck.length === 0) {
    return recommendedCentipede;
  }

  // Araç karşılaştırmalarını hesapla ve kullanıcıya sun
  const truckVehicle = recommendedTruck[0];
  const centipedeVehicle = recommendedCentipede[0];
  
  const comparison = await compareCosts(
    truckVehicle,
    centipedeVehicle,
    order,
    store.state.userData.token
  );

  // Maliyet karşılaştırması
  if (comparison.vehicle1.allCost > comparison.vehicle2.allCost) {
    // Çekici daha ucuzsa
    const costDiff = (comparison.vehicle1.allCost - comparison.vehicle2.allCost).toFixed(2);
    
    const result = await swalBox({
      title: 'UYARI !',
      text: `${truckVehicle.vehicle} plakalı çekicinin maliyeti ${centipedeVehicle.vehicle} plakalı kırkayaktan ${costDiff} ₺ daha az çekiciyle devam etmek ister misiniz?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Çekiciyle devam et',
      cancelButtonText: 'Kırkayakla devam et'
    });

    return result.isConfirmed ? recommendedTruck : recommendedCentipede;
  }

  return recommendedCentipede;
};

/**
 * Belirli bir sipariş için özel araç önerileri sağlar
 * @param {Object} order - Sipariş bilgileri
 * @param {Array} vehicleList - Araç listesi
 * @param {Array} customers - Müşteriler listesi
 * @param {Array} products - Ürünler listesi
 * @param {number} maxCount - Maksimum öneri sayısı
 * @param {string} token - Kimlik doğrulama jetonu
 * @returns {Promise<Array>} - Önerilen araçlar listesi
 */
export const getVehicleRecommendations = async (
  order,
  vehicleList,
  customers,
  products,
  maxCount = 3,
  token
) => {
  try {
    // Daha fazla öneri almak için
    const recommendations = await recommendSingleVehicleForOrder(
      order,
      vehicleList,
      customers,
      products,
      maxCount, // Maksimum öneri sayısı
      false,
      false,
      true,
      [],
      false,
      token
    );

    // REALLOCATION_NEEDED kontrolü
    if (recommendations && recommendations.REALLOCATION_NEEDED) {
      return recommendations;
    }
    
    if (!recommendations || recommendations.length === 0) {
      return [];
    }
    
    return recommendations;
  } catch (error) {
    console.error("Araç önerileri alınırken hata oluştu:", error);
    return [];
  }
};