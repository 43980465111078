<template>
  <div class="md:flex items-center justify-between w-full">
    <!-- Left section: Save orders button -->
    <div class="w-full xl:w-3/12">
      <div class="md:flex items-center mt-2">
        <button
          v-if="!planningLoad && activeState == 2"
          @click="$emit('save-orders')"
          :disabled="saveLoad || selectedCount == 0"
          :class="{
            'ml-2': isFullScreen,
            'bg-opacity-50': saveLoad || selectedCount === 0,
          }"
          class="py-2 px-3 bg-black rounded text-center text-white text-sm flex items-center gap-1"
        >
          <i :class="!saveLoad ? 'fas fa-check' : 'fas fa-spinner fa-spin'"></i>
          {{ !saveLoad ? "Kaydet" : "Kayıt Ediliyor" }}
        </button>

        <button
          v-if="!planningLoad && activeState == 2"
          @click="$emit('clear-orders')"
          :disabled="saveLoad || selectedCount == 0"
          :class="{
            'ml-2': isFullScreen,
            'bg-opacity-50': saveLoad || selectedCount === 0,
          }"
          class="py-2 px-3 bg-red-900 rounded text-center text-white text-sm ml-2"
        >
          <i class="fas fa-trash mr-1"></i>
          Temizle
        </button>

        <button
          v-if="!planningLoad"
          @click="$emit('refresh')"
          class="ml-2 py-2 px-3 bg-gray-600 hover:bg-gray-700 transition rounded text-white text-sm flex items-center justify-center gap-2"
        >
          <template v-if="!loading">
            <i class="fas fa-recycle"></i>
            <span>{{
              selectedFilters.length > 0 ? "Filtre İle Yenile" : "Yenile"
            }}</span>
          </template>
          <template v-else>
            <i class="fas fa-spinner animate-spin"></i>
            <span>Yenileniyor...</span>
          </template>
        </button>

        <p v-if="activeState != 2" class="text-xs text-left ml-2">
          Kaydetmek için Planlananlar'a geçin.
        </p>
      </div>
    </div>

    <!-- Center section: Status tabs -->
    <div
      class="w-6/12 flex items-center transition-all duration-500 justify-center"
    >
      <button
        :disabled="loading"
        @click="$emit('change-tab', 1, true)"
        class="py-2 w-40 rounded border-b-4 bg-white shadow mr-2 text-sm"
        :class="{
          'border-red-700 text-red-700 bg-white': activeState == 1 && !loading,
          'border-red-700 text-red-700 ': activeState == 1 && loading,
          'text-black bg-white opacity-30 ': activeState != 1 && loading,
        }"
      >
        Planlanmayanlar ({{ orderCount.unplanned < 0 ? 1 :orderCount.unplanned  }})
      </button>
      <button
        :disabled="loading"
        v-if="selectedCount > 0"
        @click="$emit('change-tab', 2, true)"
        class="w-40 py-2 rounded bg-white shadow mr-2 text-sm border-b-4"
        :class="{
          'border-red-700 text-red-700 bg-white': activeState == 2 && !loading,
          'border-red-700 text-red-700 ': activeState == 2 && loading,
          'text-black bg-white opacity-30 ': activeState != 2 && loading,
        }"
      >
        Planlananlar ({{ selectedCount }})
      </button>
      <button
        :disabled="loading"
        @click="$emit('change-tab', 3, true)"
        class="w-40 py-2 rounded bg-white shadow text-sm border-b-4"
        :class="{
          'border-red-700 text-red-700 bg-white': activeState == 3 && !loading,
          'border-red-700 text-red-700 ': activeState == 3 && loading,
          'text-black bg-white opacity-30 ': activeState != 3 && loading,
        }"
      >
        Sorunlu ({{ orderCount.error }})
      </button>
    </div>

    <!-- Right section: Search input -->
    <div class="w-3/12 flex items-center"  :class="isFullScreen && 'mr-2'">
      <div class="mr-2 w-full" v-if="activeState!=2">
        <multiselect

          :multiple="true"
          v-model="selectedFilters"
          :options="getFilterList"
          :loading="loading"
          :label="'name'"
          track-by="id"
          :placeholder="loading ? 'Yükleniyor...' : 'Tüm Şube'"
          selectLabel="Seç"
          selectedLabel="Seçildi"
          deselectLabel="Seçimi Kaldır"
          :openDirection="'bottom'"
          :hideSelected="false"
          :close-on-select="false"
          :clear-on-select="false"
        >
          <template #selection="{ values, isOpen }">
            <span
              class="multiselect__single"
              v-if="values.length"
              v-show="!isOpen"
            >
              {{
                values.length > 1
                  ? values.length + " Bayi Seçildi"
                  : values[0].name
              }}
            </span>
          </template>
        </multiselect>
      </div>

      <SearchInput :isFull="true" @searcing="(val) => changeText(val)" />
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/general/search-input.vue";
import diffInput from "@/components/general/diffInput.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "OrderHeader",
  components: {
    SearchInput,
    diffInput,
    Multiselect,
  },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    planningLoad: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saveLoad: {
      type: Boolean,
      default: false,
    },
    activeState: {
      type: Number,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    orderCount: {
      type: Object,
      required: true,
      default: () => ({
        unplanned: 0,
        planned: 0,
        error: 0,
      }),
    },
    search: {
      type: String,
      default: "",
    },
  },

  computed: {
    getFilterList() {
      return this.$store.state.orders.filterList.map((val, index) => {
        return {
          id: index + 1,
          name: val,
        };
      });
    },
  },
  data() {
    return {
      selectedFilters: [],
    };
  },
  watch: {
    selectedFilters(val) {
      this.$store.commit("orders/setActiveFilter", val);
    },
  },
  methods: {
    customMultiLabel(option, selectedOptions) {
      if (selectedOptions.length === 0) return "Seçilmedi";
      if (selectedOptions.length === 1) return selectedOptions[0].name;
      return `${selectedOptions.length} seçenek seçildi`;
    },
    changeText(value) {
      this.$emit("search", value);
    },
    changeFilter(val) {
      console.log("yeni : ", val);
      this.$store.commit("orders/setActiveFilter", val);
    },
  },
};
</script>
