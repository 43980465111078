<template>
  <span
    class="ml-2 text-xs px-2 py-0.5 rounded"
    :class="
      typeId == 1
        ? 'bg-blue-100 text-blue-700'
        : 'bg-purple-100 text-purple-700'
    "
  >
    {{ typeId == 1 ? "Spot" : "Toplu" }}
  </span>
</template>
  
  <script>
export default {
  name: "ShipmentTypeBadge",
  props: {
    typeId: {
      type: Number,
      required: true,
    },
  },
};
</script>