<template>
  <modal
    name="edit-state-tonnage-and-kilometer-plan-modal"
    height="auto"
    width="450px"
    :scrollable="true"
    class="tonnage-kilometer-modal"
  >
    <!-- Başlık -->
    <div class="bg-blue-600 text-white p-4 flex justify-between items-center">
      <h3 class="font-bold text-lg flex items-center">
        <i class="fas fa-ruler mr-2"></i>
        Kilometre ve Tonaj Bilgisi Düzenle
      </h3>
      <button
        class="bg-white text-blue-600 rounded-full h-8 w-8 flex items-center justify-center hover:bg-gray-200"
        @click="$modal.hide('edit-state-tonnage-and-kilometer-plan-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Araç Bilgisi -->
    <div class="p-4 bg-blue-50 border-b border-blue-100 flex items-center">
      <div class="bg-blue-100 p-2 rounded-full text-blue-700 mr-3">
        <i class="fas fa-truck text-xl"></i>
      </div>
      <div>
        <p class="font-bold text-gray-700">{{ vehiclePlaque || 'Araç' }}</p>
        <p class="text-sm text-gray-600">Kapasite: <span class="font-medium">{{ capacity }} ton</span></p>
      </div>
    </div>

    <form
      @submit.prevent="handleSave"
      class="p-5 bg-white text-black"
    >
      <!-- Giriş Alanları -->
      <div class="space-y-5">
        <div class="w-full">
          <label class="block text-gray-700 font-medium mb-2">Tonaj (ton)</label>
          <div class="relative">
            <input
              type="number"
              v-model="tonnage"
              required
              min="0"
              class="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              :class="{'bg-red-50 border-red-300': tonnage > capacity}"
            />
            <div v-if="tonnage > capacity" class="text-red-600 text-sm mt-1 flex items-center">
              <i class="fas fa-exclamation-circle mr-1"></i>
              Araç kapasitesinden fazla
            </div>
          </div>
        </div>
        
        <div class="w-full">
          <label class="block text-gray-700 font-medium mb-2">Kilometre (km)</label>
          <div class="relative">
            <input
              type="number"
              v-model="kilometer"
              required
              min="0"
              class="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
            />
          </div>
        </div>
      </div>

      <!-- Kaydetme Butonu -->
      <div class="mt-6 flex justify-end">
        <button
          type="button"
          class="bg-gray-200 text-gray-700 py-3 px-5 rounded-lg hover:bg-gray-300 mr-3 font-medium"
          @click="$modal.hide('edit-state-tonnage-and-kilometer-plan-modal')"
        >
          İptal
        </button>
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="isLoading ? 'Araç Verileri Çekiliyor' : $t('general.saveButtonLoadTitle')"
          :loadState="load || isLoading"
          class="bg-blue-600 text-white py-3 px-5 rounded-lg hover:bg-blue-700 font-medium"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

//Axios
import axios from "axios";
import { planning, tracking } from "@/networking/urlmanager";

export default {
  name: "edit-tonnage-kilometer-modal",
  props: [
    "rowId",
    "defaultTonnage",
    "defaultKilometer",
    "planId",
    "vehiclePlaque",
    "wayPointNo",
    "typeId",
  ],
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      isLoading: false,
      tonnage: "",
      kilometer: "",
      load: false,
      capacity: 0,
    };
  },
  methods: {
    handleSave() {
      this.load = true;
      if (this.tonnage > this.capacity) {
        this.swalBox(
          "warning",
          "UYARI !",
          "Araç kapasitesinden fazla tonaj girdiniz. Kaydetmek istediğinize emin misiniz?",
          true,
          "Evet, eminim",
          "Hayır"
        ).then((acc) => {
          if (acc.isConfirmed) {
            this.save();
          } else {
            this.load = false;
          }
        });
      } else {
        this.save();
      }
    },
    save() {
      axios
        .post(
          planning.setStateTonnageAndKilometer,
          {
            rowId: this.rowId,
            tonnage: this.tonnage,
            kilometer: this.kilometer,
            planId: this.planId,
            wayPointNo: this.wayPointNo,
            typeId: this.typeId,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "Bilgiler başarıyla kaydedildi",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-state-tonnage-and-kilometer-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    getVehicleData() {
      this.isLoading = true;
      axios
        .get(tracking.getAll + "?targetVehicle=" + this.vehiclePlaque, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.capacity = res.data.data[0].capacity || 0;
          }
        })
        .catch((err) => {
          console.error("Araç verileri alınamadı:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    defaultTonnage(val) {
      this.tonnage = val;
    },
    defaultKilometer(val) {
      this.kilometer = val;
    },
  },
  created() {
    this.tonnage = this.defaultTonnage;
    this.kilometer = this.defaultKilometer;
    this.getVehicleData();
  },
};
</script>

<style>
.tonnage-kilometer-modal {
  z-index: 99999 !important; /* Önceki değerden daha yüksek z-index */
}

.tonnage-kilometer-modal input:focus {
  outline: none;
}

/* Animasyonlar */
.tonnage-kilometer-modal button {
  transition: all 0.2s;
}

.tonnage-kilometer-modal button:hover {
  transform: translateY(-1px);
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  75% { transform: translateX(2px); }
}

.tonnage-kilometer-modal .text-red-600 {
  animation: shake 0.5s ease-in-out;
}
</style>