
<template>
  <div class="fixed xl:top-24 top-0 h-screen xl:h-full w-full bg-white shadow xl:w-56 z-50" style="z-index: 9999999 !important">
    <!-- Başlık -->
    <div class="flex justify-between items-center p-4 border-b border-gray-100">
      <h5 class="text-xs font-bold text-gray-700">GRUP DETAYI</h5>
      <button class="text-gray-400 hover:text-gray-600" @click="$emit('close', true)">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- İçerik -->
    <div class="overflow-y-auto p-3" style="height: calc(100% - 50px)">
      <div
        v-for="(item, index) in List"
        :key="index"
        @click="getDetailAndClose(item)"
        class="py-2.5 px-3 my-1.5 text-xs rounded cursor-pointer hover:bg-gray-50 transition-colors"
      >
        <div class="flex items-center justify-between">
          <span class="text-gray-700">{{ item.label.text }}</span>
          <i class="fas fa-map-marker text-gray-400"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupDetail",
  props: ["List"],
  methods: {
    getDetailAndClose(item) {
      this.$emit("selected", item.label.text);
      this.$emit("close", true);
    },
    getDetail(item) {
      this.$emit("selected", item.label.text);
    },
  },
};
</script>