<template>
  <modal
    name="report-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '90%'"
    @opened="handleOpened()"
    @closed="handleClearInterval()"
  >
    <noteDetail
      modalName="rejection-reason-modal"
      :note="note"
      :title="driverNoteTitle"
    />
    <div
      class="pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 flex items-center justify-center"
    >
      <h4 class="inline-block w-6/12">SEVKİYAT RAPORU</h4>
      <div class="mt-4 w-6/12 ml-auto flex items-center justify-end">
        <div class="w-9/12 flex">
          <searchInput
            v-model="search"
            @searcing="() => getAll(true)"
            inputClass="w-full"
          />
        </div>
        <div
          class="w-1/12 h-11 ml-2 bg-white shadow rounded-md border-gray-400 flex pl-1 cursor-pointer"
          @click="getAll()"
          :disabled="load"
        >
          <button class="p-2 w-1/12">
            <i class="fas fa-sync" :class="load && 'fa-spin'"></i>
          </button>
        </div>
        <div
          class="ml-2 bg-white shadow rounded-md border-gray-400 flex items-center justify-center"
        >
          <logBtn />
          <logModule :moduleId="moduleId" :rowId="rowId" />
        </div>
        <div
          class="w-1/12 h-11 ml-2 bg-white shadow rounded-md border-gray-400 flex pl-1 cursor-pointer"
          @click="$modal.show('report-history-log')"
        >
          <button class="p-2 w-1/12">
            <i class="fas fa-exclamation-triangle"></i>
          </button>
          <reportHistoryModule :rowId="rowId" />
        </div>
        <button
          @click="$modal.hide('report-modal')"
          class="w-1/12 h-11 ml-2 bg-white shadow rounded-md pl-2 border-gray-400 flex items-center cursor-pointer"
        >
          <button class="p-2 w-1/12">
            <i class="fas fa-times"></i>
          </button>
        </button>
      </div>
    </div>
    <bulkVehicleStatus @refresh="getAll()" ref="bulkVehicleStatu" />
    <vehicleStatusModal :typeId="2" :Detail="selected" @refresh="getAll()" />
    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead
          class="bg-white border-black border-dotted border-b-2 border-opacity-20"
        >
          <tr>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20 z-10"
              style="min-width: 200px !important"
            >
              Araç
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Sürücü
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 50px !important"
            >
              Tonaj
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Dolum Tarihi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Teslim Tarihi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20 z-50"
              style="min-width: 150px !important"
            >
              Durum
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Eklenme Tarihi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Sefer Kaydı
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 80px !important"
            ></th>
          </tr>
        </thead>
        <tbody
          class="divide-y-2 divide-gray-500"
          style="background-color: #3a3a3a"
        >
          <tr v-for="item in List" :key="item.id">
            <td class="table-td border-r border-opacity-20">
              <div class="flex items-center">
                <p class="w-8/12 mr-4">{{ item.id }}--{{ item.plaque }}</p>
                <p
                  v-if="
                    item.stateId !== 8 &&
                    item.stateId !== 9 &&
                    item.stateId !== 10
                  "
                  class="w-auto ml-4"
                  :class="
                    item.isStopped == 1
                      ? ' text-white animate-pulse duration-200 text-xs px-2 cursor-pointer select-none bg-red-500 rounded   mx-auto  font-bold'
                      : ''
                  "
                >
                  {{ item.isStopped == 1 ? "STOPPED" : "" }}
                </p>
              </div>
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.driverName }}
            </td>

            <td class="table-td border-r border-opacity-20">
              {{ item.stateId < 9 && item.stateId > 4 ? item.tonnage : 0 }}
            </td>

            <td class="table-td border-r border-opacity-20">
              {{ getDate(item.fillingDate) }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ getDate(item.deliveryDate) }}
            </td>
            <td class="table-td border-r border-opacity-20">
              <statusSelector
                :statusList="statusList"
                :shipId="shipId"
                :active="active"
                :orderNo="active === 1 ? rowId.orderNo : null"
                :orderId="rowId"
                :defaultTitle="item.stateName"
                :stateId="item.stateId"
                :typeId="item.typeId"
                :planId="item.id"
                :plaque="item.plaque"
                :driverTC="item.driverTC"
                @change="
                  (val) => (List.find((x) => x.id === item.id).stateName = val)
                "
                @refresh="() => getAll()"
                @bulkVehicleStatu="
                  (r) => (
                    ($refs.bulkVehicleStatu.modalData = {
                      detail: item,
                      activeStatu: r,
                    }),
                    $refs.bulkVehicleStatu.show()
                  )
                "
              />
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ getDate(item.addDate) }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ filteredList(item) }}
            </td>
            <td class="table-td border-r border-opacity-20 text-center">
              <div class="flex justify-center items-center">
                <button
                  :class="
                    item.isError === 1
                      ? ' border border-white bg-red-500  animate-pulse process-btn '
                      : 'process-btn'
                  "
                  @click="detailItem(item)"
                >
                  <i class="fas fa-search"></i>
                </button>

                <button
                  class="process-btn w-8 h-6 flex justify-center items-center ml-2"
                  :class="item.note === null ? 'opacity-20' : ''"
                  @click="item.note === null ? '' : noteDetailModal(item)"
                >
                  <img
                    class="w-12 h-12"
                    src="../../../assets/ship/noteWhite.svg"
                    alt=""
                  />
                </button>
              </div>
            </td>
          </tr>
          <tableLoader :load="load" :length="List.length" colspan="9" />
        </tbody>
      </table>
    </div>
    <div class="px-4">
      <PaginationComponents
        v-if="totalCount > 0"
        :totalRowCount="totalCount"
        :pageCount="pageCount"
        :currentPage="currentPage + 1"
        @changePage="(r) => (currentPage = r - 1)"
      />
    </div>
  </modal>
</template>
<script>
//npm
import moment from "moment";

//Global Components
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import searchInput from "@/components/general/search-input.vue";
import logBtn from "../logModule/logBtn.vue";
import logModule from "../logModule/index.vue";
import reportHistoryModule from "../reportHistoryModule/index.vue";
import bulkVehicleStatus from "@/components/modals/vehicleStatuModal/bulkVehicleStatus.vue";
import noteDetail from "@/components/modals/noteDetail/index.vue";
//networking
import axios from "axios";
import {
  bulkShipPlanning,
  bulkPlanning,
  planning,
} from "@/networking/urlmanager";

//local components
import statusSelector from "./status.select.vue";

import vehicleStatusModal from "../../../views/operations/planning/components/modals/vehicleStateModel/index.vue";

export default {
  props: ["rowId", "active", "moduleId", "shipId"],
  components: {
    tableLoader,
    PaginationComponents,
    statusSelector,
    vehicleStatusModal,
    searchInput,
    logBtn,
    logModule,
    reportHistoryModule,
    bulkVehicleStatus,
    noteDetail,
  },
  data() {
    return {
      load: false,
      List: [],
      statusList: [],
      search: "",
      selected: null,
      totalCount: 0,
      pageCount: 0,
      currentPage: 0,
      driverNoteTitle: "",
      note: "",
    };
  },
  methods: {
    noteDetailModal(item) {
      this.note = item.note;
      this.driverNoteTitle = "Sürücü Notu";
      this.$modal.show("rejection-reason-modal");
    },
    handleOpened() {
      this.getAll();
      this.getStatusList();
      this.startInterval();
    },
    startInterval() {
      this.intervalId = setInterval(() => {
        this.getAll(false, false);
      }, 10000);
    },
    handleClearInterval() {
      clearInterval(this.intervalId);
    },
    detailItem(item) {
      this.selected = item;
      this.$modal.show("vehicleState-modal");
    },
    getStatusList() {
      axios
        .get(planning.getStatusAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          if (this.typeId == 7 || this.typeId == 8) {
            this.statusList = result.data.data;
          } else {
            this.statusList = result.data.data.filter(
              (r) => r.id != 10 && r.id != 11
            );
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
    getAll(isSearch = false, load = true) {
      if (isSearch) this.currentPage = 0;
      this.List = load ? [] : this.List;
      this.load = load;
      if (this.active === 2) {
        axios
          .get(
            bulkShipPlanning.getReportAll +
              "?rowId=" +
              this.rowId +
              "&page=" +
              this.currentPage +
              "&search=" +
              this.search,

            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.List = res.data.data.detail;
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
          })
          .finally(() => {
            this.load = false;
          });
        return;
      }
      if (this.active === 1) {
        axios
          .get(
            bulkPlanning.getReportAll +
              "?rowId=" +
              this.rowId +
              "&page=" +
              this.currentPage +
              "&search=" +
              this.search,
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.List = res.data.data.detail;
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
          })
          .finally(() => {
            this.load = false;
          });
        return;
      }
    },
    filteredList(val) {
      if (val.stateId !== 8) {
        return "-";
      }

      switch (val.isTourComplatedSuccess) {
        case 1:
          return "Kaydedildi";
        case 2:
        case null:
        case undefined:
          return "Gönderiliyor";
        default:
          return "-";
      }
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    rowId() {
      this.currentPage = 0;
    },
  },
};
</script>
