const { haversineDistance } = require("../haversineDistance.js");

/**
 * Sipariş ve araç arasındaki mesafeyi hesaplar.
 * @param {Object} order - Sipariş detayları.
 * @param {Object} vehicle - Araç detayları.
 * @returns {number} Sipariş ve araç arasındaki mesafe (metre cinsinden).
 */
function calculateDistancePriority(order, vehicle) {
  const fromLocation =
    vehicle?.orderNos?.length && !vehicle.orderNos.includes(order.orderNo)
      ? {
          lat: parseFloat(vehicle.planning.targetPointLat),
          lng: parseFloat(vehicle.planning.targetPointLng),
        }
      : {
          lat: parseFloat(vehicle.latitude),
          lng: parseFloat(vehicle.longitude),
        };
  const distance = haversineDistance(
    {
      lat: parseFloat(
        order?.startLat
          ? order?.startLat
          : order.outpointLat
          ? order.outpointLat
          : order.fillingLatitude
      ),
      lng: parseFloat(
        order?.startLng
          ? order?.startLng
          : order.outpointLng
          ? order.outpointLng
          : order.fillingLongitude
      ),
    },
    fromLocation
  );

  return distance;
}


module.exports={
    calculateDistancePriority
}