<template>
  <div>
    <div class="md:flex mt-4">
      <div class="w-full md:w-4/12 mt-4 md:mt-0">
        <diffInput
          type="number"
          v-model="hakedisKilometerModel"
          :required="true"
          :disabled="true"
          title="Tek yön Kilometresi"
        />
      </div>
      <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
        <diffInput
          type="number"
          :valCount="99"
          :required="true"
          title="Ay içi Sefer Sayısı"
          v-model="transitionCountModel"
          :min="1"
          :step="1"
          :disabled="isEdit"
        />
      </div>
      <div class="w-full md:w-4/12 mt-4 md:mt-0">
        <diffInput
          type="text"
          :value="formattedTotalKilometer"
          :required="true"
          :disabled="true"
          title="Toplam Kilometre"
        />
      </div>
    </div>

    <div class="mt-4 md:flex items-center">
      <div class="w-full md:w-4/12 mt-4 md:mt-0">
        <diffInput
          type="text"
          :value="formattedTotalFuelPrice"
          :disabled="true"
          title="Yakıt Maliyeti"
        />
      </div>
      <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
        <diffInput
          type="text"
          :value="formattedOtherExpensePrice"
          :disabled="true"
          title="Diğer Masraflar"
        />
      </div>
      <div class="w-full md:w-4/12 mt-4 md:mt-0">
        <diffInput
          type="text"
          :disabled="true"
          :value="formattedTonnagePrice"
          title="Ton Maliyeti"
        />
      </div>
    </div>

    <div class="mt-4 md:flex items-center">
      <div class="w-full md:w-4/12 mt-4 md:mt-0">
        <diffInput
          type="text"
          @click="$modal.show('add-hgs-detail-modal')"
          :value="formattedHgsPrice"
          :disabled="true"
          :title="$t('operations.planning.modal.tollHighwayCost')"
        />
      </div>
      <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
        <diffInput
          type="text"
          :value="formattedExpensivePrice"
          :disabled="true"
          title="Maliyet Tutarı"
        />
      </div>

      <div class="w-full md:w-4/12 mt-4 md:mt-0">
        <diffInput
          :priceRequired="false"
          type="price"
          v-model="totalPriceModel"
          title="Gerçek Satış Fiyatı"
          :digitCount="2"
          :max="50000000"
          :disabled="isEdit"
          :min="1"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "CostCalculation",
  components: {
    diffInput,
  },
  props: {
    hakedisKilometer: {
      type: [String, Number],
      default: 0,
    },
    transitionCount: {
      type: [String, Number],
      default: 1,
    },
    totalFuelPrice: {
      type: [String, Number],
      default: 0,
    },
    otherExpensePrice: {
      type: [String, Number],
      default: 0,
    },
    tonnagePrice: {
      type: [String, Number],
      default: 0,
    },
    costDistribution: {
      type: Object,
      default: () => ({ cost: { cash: 0 } }),
    },
    expensivePrice: {
      type: [String, Number],
      default: 0,
    },
    totalPrice: {
      type: [String, Number],
      default: 0,
    },
    isOneWay: {
      type: [String, Number],
      default: 1,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hakedisKilometerModel: {
      get() {
        return this.hakedisKilometer;
      },
      set(value) {
        this.$emit("update:hakedisKilometer", value);
      },
    },
    transitionCountModel: {
      get() {
        return this.transitionCount;
      },
      set(value) {
        this.$emit("update:transitionCount", value);
      },
    },
    totalPriceModel: {
      get() {
        return this.totalPrice;
      },
      set(value) {
        this.$emit("update:totalPrice", value);
      },
    },
    formattedTotalKilometer() {
      const multiplier = this.isOneWay == 1 ? 1 : 2;
      return this.numberFormatter(
        this.hakedisKilometer * this.transitionCount * multiplier
      );
    },
    formattedTotalFuelPrice() {
      return this.numberFormatter(this.totalFuelPrice) + " ₺";
    },
    formattedOtherExpensePrice() {
      return this.numberFormatter(this.otherExpensePrice) + " ₺";
    },
    formattedTonnagePrice() {
      return this.numberFormatter(this.tonnagePrice) + " ₺";
    },
    formattedHgsPrice() {
      const multiplier = this.isOneWay == 1 ? 1 : 2;
      const hgsPrice =
        this.costDistribution.cost.cash * this.transitionCount * multiplier;
      return this.numberFormatter(hgsPrice) + " ₺";
    },
    formattedExpensivePrice() {
      return this.numberFormatter(this.expensivePrice) + " ₺";
    },
  },
  methods: {
    numberFormatter(val) {
      if (val === undefined || val === null) return "0.00";

      const formattedValue = new Intl.NumberFormat("tr-TR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);

      return formattedValue;
    },
  },
};
</script>