// orderTools/processing.js
// Sipariş işleme fonksiyonları

import moment from 'moment';
import { calculator } from '@/utils/cost';

// Diğer modüllerden fonksiyonları içe aktar
import { isOrderContentValid, isFutureDeliveryDate } from './validation';
import { isProductValid, isProductExist, findProductIdBySimilarity } from './productUtils';
import { calculateCustomerPriority } from './customerUtils';
import { calculateDistance } from './calculations';
import { handleSmallOrderRecommendation } from './recommendations';
import { recommendSingleVehicleForOrder } from '../../../../../utils/recomendation';

/**
 * Process order selection for planning
 * @param {Object} order - The order object
 * @param {Array} vehicleList - List of available vehicles
 * @param {Array} products - List of products
 * @param {Array} customers - List of customers
 * @param {Array} blockedVehicles - List of already assigned vehicles
 * @param {Number|null} haveType - Vehicle ownership type (1: Rented, 2: Owned, null: Any)
 * @param {Object} store - Vuex store
 * @param {Function} swalBox - SweetAlert function for showing alerts
 * @returns {Promise<Object|null>} - Processed order or null if invalid
 */
export const processOrderSelection = async (order, vehicleList, products, customers, blockedVehicles = [], haveType = null, store, swalBox) => {
  // Validate order date
  if (!isFutureDeliveryDate(order)) {
    await swalBox({
      title: 'Uyarı',
      text: 'Lütfen dikkat: Siparişinizin teslim tarihi geçmiştir.',
      icon: 'warning',
      confirmButtonText: 'Tamam'
    });
    return null;
  }

  // Validate product and order content
  if (!isProductValid(order.productType, products) || !isOrderContentValid(order)) {
    if (!isProductValid(order.productType, products)) {
      // Ürün kontrol etme ve ürün yönetimi için ek işlemler
      if (!isProductExist(order.productType, products)) {
        const isOkey = await swalBox({
          title: 'Uyarı',
          text: 'ÜRÜN KARTI TANIMLANMAMIŞ VEYA İLGİLİ ÜRÜN KARTINDA İLGİLİ DEĞERLER DOLDURULMAMIŞ!',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Tamam'
        });
        
        if (isOkey.isConfirmed) {
          // Burada ürün ekleme için event tetiklenebilir
          return { productName: order.productType, needProductAdd: true };
        }
        return null;
      } else {
        // Ürün var fakat dorse tipi yok
        const productId = findProductIdBySimilarity(order.productType, products);
        
        const isOkey = await swalBox({
          title: 'Uyarı',
          text: 'Seçili Dorse Bulunmamaktadır.',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Tamam'
        });
        
        if (isOkey.isConfirmed) {
          return { 
            productName: order.productType,
            needProductEdit: true,
            defaultProductDataProp: { id: productId }
          };
        }
        return null;
      }
    } else {
      if (moment(order.deliveryDate).diff(moment(order.orderDate), "days") < 0) {
        await swalBox({
          title: 'Uyarı',
          text: 'Lütfen dikkat: Siparişinizin teslim tarihi, sipariş tarihinden önce belirlenmiş gibi görünüyor. Siparişinizin doğru işlenmesi için, teslim tarihini sipariş tarihinden sonraki bir tarih olarak ayarlayın ve ardından işleminizi yeniden deneyin.',
          icon: 'warning',
          confirmButtonText: 'Tamam'
        });
      } else {
        await swalBox({
          title: 'Uyarı',
          text: 'Sipariş Bilgileri Eksik Lütfen Aşağıdaki Alanların Doldurulduğundan Emin Olun',
          icon: 'warning',
          confirmButtonText: 'Tamam'
        });
      }
      return null;
    }
  }

  // Handle large orders (multiple vehicles needed)
  if (order.unitType === 'TON' ? order.tonnage > 30 : order.tonnage > 1) {
    return {
      ...order,
      vehicleName: '',
      vehicle: {
        id: '',
        name: '',
      },
      isMultiple: true,
      driver: '',
      dorse: '',
      distance: 0,
    };
  }

  // Korsan sürücü kontrolü (haveType = 4)
  if (haveType === 4) {
    return {
      ...order,
      isKorsanDriver: true
    };
  }

  // For single vehicle orders, get recommended vehicle
  return await processOrderWithRecommendedVehicle(order, vehicleList, products, customers, blockedVehicles, haveType, store, swalBox);
};

/**
 * Process an order with a recommended vehicle
 * @param {Object} order - The order object
 * @param {Array} vehicleList - List of available vehicles
 * @param {Array} products - List of products
 * @param {Array} customers - List of customers
 * @param {Array} blockedVehicles - List of already assigned vehicles
 * @param {Number|null} haveType - Vehicle ownership type (1: Rented, 2: Owned, null: Any)
 * @param {Object} store - Vuex store
 * @param {Function} swalBox - SweetAlert function
 * @returns {Promise<Object|null>} - Processed order with vehicle or null
 */
export const processOrderWithRecommendedVehicle = async (order, vehicleList, products, customers, blockedVehicles = [], haveType = null, store, swalBox) => {
  // Create order object for recommendation
  const orderForRecommendation = {
    ...order,
    haveType,
    startLat: order.outpointLat,
    startLng: order.outpointLng,
    endLat: order.targetPointLat,
    endLng: order.targetPointLng,
    tonnage: parseFloat(order.tonnage),
    productName: order.productType,
    token: store.state.userData.token
  };

  // Handle special case for small orders (≤ 20 tons)
  const isTwentyOrLess = parseFloat(order.tonnage) <= 20;

  let recommendedVehicle;

  if (!isTwentyOrLess) {
    // For larger orders, use normal recommendation
    recommendedVehicle = await recommendSingleVehicleForOrder(
      orderForRecommendation,
      vehicleList,
      customers,
      products,
      1,
      false,
      false,
      true,
      blockedVehicles,
      false,
      store.state.userData.token
    );
  } else {
    // For smaller orders, compare centipede vs regular truck
    recommendedVehicle = await handleSmallOrderRecommendation(
      orderForRecommendation,
      vehicleList,
      customers,
      products,
      blockedVehicles,
      store,
      swalBox
    );
  }


  // REALLOCATION_NEEDED bayrağını kontrol et
  if (recommendedVehicle && recommendedVehicle.REALLOCATION_NEEDED) {
    return recommendedVehicle; // Bayrağı olduğu gibi döndür
  }


  if (!recommendedVehicle || recommendedVehicle.length === 0) {
    await swalBox({
      title: 'Uyarı',
      text: 'Lütfen ürün kartını kontrol ediniz. Uygun bir araç bulunamadı, bu sorun ürünün taşınması için seçilen dorse türünün uygun olmamasından kaynaklanıyor olabilir. Ürünün taşınması için gerekli olan dorse türünün doğru şekilde atandığından emin olunuz.',
      icon: 'warning',
      confirmButtonText: 'Tamam'
    });
    return null;
  }

  const recommended = recommendedVehicle[0];

  // Calculate distance and cost
  const distance = calculateDistance(
    order,
    recommended
  );

  const cost = await calculator(
    store.state.userData.token,
    1,
    1,
    recommended,
    order,
    distance,
    0,
    true,
    true,
    1,
    distance / 50
  );

  // Müşteri önceliği hesaplama eklendi
  const customerPriority = calculateCustomerPriority(order, customers);

  // Check if vehicle is assigned to a specific customer fleet
  if (recommended.fleetCustomers &&
    recommended.fleetCustomers.length &&
    !recommended.fleetCustomers.some(customer => customer?.customerId === order?.customerId)) {

    const customerNames = recommended.fleetCustomers
      .map(customer => customer?.customerName)
      .join(", ");

    const result = await swalBox({
      title: 'UYARI !',
      text: `Seçilen aracın (${recommended.vehicle}) ait olduğu filo başka müşteriye atanmış: ${customerNames}. Devam etmek için DEVAM ET'e, yeniden araç önerisi yapmak için YENİDEN ÖNER'e basınız?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Devam et',
      cancelButtonText: 'Yeniden Öner'
    });

    if (!result.isConfirmed) {
      const newBlockedVehicles = [...blockedVehicles, recommended];
      return await processOrderWithRecommendedVehicle(
        order,
        vehicleList,
        products,
        customers,
        newBlockedVehicles,
        haveType,
        store,
        swalBox
      );
    }
  }

  // Müşteri kaydı kontrolü eklendi
  if (customerPriority === 1 && !order?.customerId) {
    const result = await swalBox({
      title: 'Uyarı',
      text: 'Müşteri Kaydı Bulunmamaktadır.',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Tamam'
    });
    
    if (result.isConfirmed) {
      return {
        ...order,
        needCustomerAdd: true,
        customerName: order.customer
      };
    }
    return null;
  }

  // Create final order object with vehicle details
  return {
    ...order,
    vehicleName: recommended.vehicle,
    washCost: cost.totalWashPriceCost,
    washPointName: cost.washPointName,
    recomendationTotalCost: Number(cost.total),
    allCost: Number(cost.total) + Number(cost.totalWashPriceCost),
    vehicle: {
      id: recommended.id,
      name: recommended.vehicle,
      isRent: recommended.haveType,
      position: {
        lat: recommended.latitude,
        lng: recommended.longitude,
      }
    },
    orderAmount: order.tonnage,
    recomendation: {
      ...recommended.recomendation,
      id: recommended.id,
      name: recommended.vehicle,
      position: {
        lat: recommended.latitude,
        lng: recommended.longitude,
      },
      customerPriority: customerPriority,
      vehicleInspectionEndDate: recommended.vehicleInspectionEndDate,
      inspectionEndDate: recommended.inspectionEndDate,
      vehicleAdrEndDate: recommended.vehicleAdrEndDate,
      dorseAdrEndDate: recommended.dorseAdrEndDate,
      srcEndTime: recommended.srcEndTime,
      lastProductAvailable: recommended.lastProductAvailable,
    },
    isMultiple: false,
    driver: recommended.driverName || "",
    dorse: recommended.dorse || "",
    distance: distance || 0,
  };
};

/**
 * Korsan sürücü planlamasını işler
 * @param {Object} order - Sipariş bilgileri
 * @param {Object} pirateDriverData - Korsan sürücü bilgileri
 * @returns {Object} - İşlenmiş sipariş
 */
export const processPirateDriverPlan = (order, pirateDriverData) => {
  const { driverName, driverPhone, vehiclePlate, vehicleType, cost } = pirateDriverData;
  
  return {
    ...order,
    vehicleName: vehiclePlate,
    vehicle: {
      id: "pirate-" + new Date().getTime(),
      name: vehiclePlate,
      position: {
        lat: order.outpointLat || 0,
        lng: order.outpointLng || 0,
      },
    },
    driver: driverName,
    driverPhone: driverPhone,
    isPirateDriver: 1,
    recomendationTotalCost: parseFloat(cost),
    allCost: parseFloat(cost),
    isMultiple: false,
    orderAmount: order.tonnage,
    recomendation: {
      name: vehiclePlate,
      vehicleType: vehicleType,
      capacity: 0,
      isPirateDriver: true,
    }
  };
};