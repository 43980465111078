<template>
  <div class="md:flex mt-5">
    <div class="w-full md:w-6/12 relative pr-4">
      <diffInput
        type="text"
        :required="true"
        title="Araç"
        v-model="vehicleModel"
        :disabled="true"
      />
    </div>

    <div class="md:flex  w-full md:w-6/12">
      <div class="w-full md:w-6/12 relative">
        <diffInput
          type="text"
          v-model="dorseModel"
          :required="true"
          :disabled="true"
          :title="$t('operations.planning.modal.trailer')"
        />
      </div>
      <div class="w-full md:w-6/12 md:pl-2">
        <diffInput
          type="text"
          v-model="personnelModel"
          :required="true"
          :disabled="true"
          :title="$t('operations.planning.modal.driver')"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "VehicleFields",
  components: {
    diffInput,
  },
  props: {
    vehicle: {
      type: [String, Object],
      default: "",
    },
    dorse: {
      type: String,
      default: "",
    },
    personnel: {
      type: String,
      default: "",
    },
  },
  computed: {
    vehicleModel: {
      get() {
        return this.vehicle;
      },
      set(value) {
        this.$emit("update:vehicle", value);
      },
    },
    dorseModel: {
      get() {
        return this.dorse;
      },
      set(value) {
        this.$emit("update:dorse", value);
      },
    },
    personnelModel: {
      get() {
        return this.personnel;
      },
      set(value) {
        this.$emit("update:personnel", value);
      },
    },
  },
};
</script>