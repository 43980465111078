// NPM
import polyLine from "google-polyline";
// Utils imports
import { calculator } from "@/utils/cost";

/**
 * Calculates pricing information based on provided parameters
 * 
 * @param {string} token - Authentication token
 * @param {number} hakedisKilometer - Distance in kilometers
 * @param {number} transitionCount - Number of transitions
 * @param {object|string} vehicle - Vehicle information
 * @param {object} order - Order information
 * @param {object} costDistribution - Cost distribution information
 * @param {number} isAmortisman - Include amortization flag (1 = yes, 2 = no)
 * @param {number} isOneWay - One way flag (1 = yes, 2 = no)
 * @param {number} dayCount - Number of days
 * @returns {object} Calculated pricing information
 */
export const calculatePrice = async (
  token,
  hakedisKilometer,
  transitionCount,
  vehicle,
  order,
  costDistribution,
  isAmortisman,
  isOneWay,
  dayCount
) => {
  // Calculate monthly kilometer based on one-way setting
  let montlyKilometer = hakedisKilometer * transitionCount * 2;
  let hgsPrice = parseFloat(costDistribution.cost.cash) * transitionCount * 2;
  
  if (isOneWay == 1) {
    montlyKilometer = hakedisKilometer * transitionCount;
    hgsPrice = parseFloat(costDistribution.cost.cash);
  }
  
  // Handle NaN values
  hgsPrice = isNaN(hgsPrice) ? 0 : hgsPrice;
  
  // Call the calculator function from utils/cost.js
  const expenseDetail = await calculator(
    token,
    1,
    montlyKilometer * 12, // Yearly kilometer
    vehicle,
    order,
    montlyKilometer,
    hgsPrice,
    isAmortisman == 1, // Convert to boolean
    isOneWay == 1,     // Convert to boolean
    dayCount
  );

  const { total, fuelTotalPrice, totalAdbluePrice } = expenseDetail;

  // Calculate component costs
  const totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
  const otherExpensePrice = total - totalFuelPrice;
  
  // Calculate tonnage price based on one-way setting
  let tonnagePrice;
  if (isOneWay == 1) {
    tonnagePrice = (total / montlyKilometer / 27) * hakedisKilometer;
  } else {
    tonnagePrice = (total / montlyKilometer / 27) * hakedisKilometer * 2;
  }
  
  // Return all calculated values
  return {
    expenseDetail,
    totalFuelPrice,
    otherExpensePrice,
    tonnagePrice,
    expensivePrice: total
  };
};

/**
 * Converts polyline array to encoded string format
 * 
 * @param {Array} val - Polyline array
 * @returns {string} Encoded polyline string
 */
export const changePolyline = (val) => {
  return polyLine.encode(val);
};