var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMarker',{attrs:{"title":_vm.selectedItem.title,"label":{
      text: _vm.selectedItem.title,
      color: 'white',
      className:
        'absolute bottom-3 -right-8 text-xs px-2 text-xs font-bold bg-black rounded',
      fontSize: '8.5px',
    },"position":_vm.selectedItem.position,"icon":_vm.selectedItem.icon},on:{"click":() => _vm.$emit('markerClick', _vm.selectedItem)}}),(
      _vm.selectedActiveDetail.targetPoint.lat &&
      _vm.selectedActiveDetail.targetPoint.lng
    )?_c('GmapMarker',{attrs:{"title":_vm.selectedActiveDetail.state <= 3
        ? 'Yükleme Noktası'
        : _vm.selectedActiveDetail.customer,"label":{
      text:
        _vm.selectedActiveDetail.state <= 3
          ? 'Yükleme Noktası'
          : _vm.selectedActiveDetail.customer,
      color: 'white',
      className:
        'absolute top-4 px-2 rounded left-0 text-xs px-2 text-xs font-bold bg-red-500',
      fontSize: '12px',
    },"position":_vm.selectedActiveDetail.targetPoint}}):_vm._e(),(
      _vm.selectedActiveDetail.polyline && _vm.selectedActiveDetail.polyline.length
    )?_c('GmapPolyline',{attrs:{"path":_vm.selectedActiveDetail.polyline,"options":{ strokeColor: '#C43A3A' }},on:{"update:path":function($event){return _vm.$set(_vm.selectedActiveDetail, "polyline", $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }