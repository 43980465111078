<template>
<modal
name="reallocation-needed-modal"
:height="isMobile ? '100%' : 'auto'"
:width="isMobile ? '100%' : '60%'"
:scrollable="true"
classes="rounded-lg"
>
<div class="bg-white rounded-t-lg shadow-md">
  <div
    class="flex justify-between items-center px-6 py-4 border-b border-gray-200 bg-gray-50 rounded-t-lg"
  >
    <h3 class="text-lg font-medium text-gray-800">Araç Bulunmadı</h3>
    <button
      @click="closeModal"
      class="text-gray-400 hover:text-gray-600 focus:outline-none"
    >
      <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>

  <div class="p-6">
    <div
      class="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6 rounded-r"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-yellow-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-yellow-700">{{ reallocationMessage }}</p>
        </div>
      </div>
    </div>

    <!-- Sipariş Bilgisi -->
    <div
      class="bg-white border border-gray-200 rounded-lg p-4 mb-6 shadow-sm"
    >
      <h4 class="text-base font-semibold text-gray-700 mb-3 border-b pb-2">
        Sipariş Bilgileri
      </h4>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div class="flex items-center">
          <span class="font-medium text-gray-600 w-28">Sipariş No:</span>
          <span class="text-gray-800">{{ order.orderNo }}</span>
        </div>
        <div class="flex items-center">
          <span class="font-medium text-gray-600 w-28">Müşteri:</span>
          <span class="text-gray-800 truncate" :title="order.customer">{{
            order.customer
          }}</span>
        </div>
        <div class="flex items-center">
          <span class="font-medium text-gray-600 w-28">Ürün:</span>
          <span class="text-gray-800">{{ order.productType }}</span>
        </div>
        <div class="flex items-center">
          <span class="font-medium text-gray-600 w-28">Tonaj:</span>
          <span
            :class="{
              'text-gray-800': order.tonnage > 30,
              'text-orange-600 font-medium': order.tonnage <= 30,
            }"
          >
            {{ order.tonnage }} {{ order.unitType }}
            <span
              v-if="order.tonnage <= 30"
              class="text-xs ml-2 bg-orange-100 text-orange-800 py-1 px-2 rounded"
            >
              Tek Araç Seçimi
            </span>
          </span>
        </div>
      </div>
      <div class="mt-3 pt-3 border-t border-gray-100">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div class="block pr-2">
            <span class="font-medium text-gray-600 w-28 block"
              >Yükleme:</span
            >
            <span class="text-gray-800 text-sm break-words">{{
              order.outpointAdress
            }}</span>
          </div>
          <div class="block pr-2">
            <span class="font-medium text-gray-600 w-28 block"
              >Teslimat:</span
            >
            <span class="text-gray-800 text-sm break-words">{{
              order.targetAdress
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Filtreleme Seçenekleri -->
    <div class="mb-4 grid grid-cols-1 md:grid-cols-3 gap-3">
      <div class="relative col-span-2">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            class="w-4 h-4 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Araç plakası, müşteri, sürücü veya sipariş numarası ile ara..."
          class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm"
        />
        <button
          v-if="searchQuery"
          @click="searchQuery = ''"
          class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
        >
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            class="w-4 h-4 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <select
          v-model="sortOption"
          class="w-full pl-10 pr-8 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm appearance-none"
        >
          <option value="distance">Mesafeye Göre (Yakın-Uzak)</option>
          <option value="capacity">Kapasiteye Göre (Büyük-Küçük)</option>
          <option value="customer">Müşteriye Göre (A-Z)</option>
          <option value="driver">Sürücüye Göre (A-Z)</option>
        </select>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
        >
          <svg
            class="w-4 h-4 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>

    <!-- Aktif Siparişler ve Atanmış Araçlar -->
    <div
      class="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden"
    >
      <h4 class="text-base font-semibold text-gray-700 p-4 border-b">
        Aktif Siparişler ve Araçlar
        <span v-if="isSingleVehicleMode" class="text-xs text-orange-600 ml-2">
          (Sipariş tonajı nedeniyle tek seçim yapılabilir)
        </span>
      </h4>
      <div class="overflow-x-auto" style="max-height: 400px">
        <table class="w-full divide-y divide-gray-200 table-fixed">
          <thead class="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th
                scope="col"
                class="w-12 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <template v-if="!isSingleVehicleMode">
                  <input
                    type="checkbox"
                    class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    v-model="selectAll"
                    @change="toggleSelectAll"
                  />
                </template>
                <template v-else>
                  <span>Seç</span>
                </template>
              </th>
              <th
                scope="col"
                class="w-24 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Sipariş No
              </th>
              <th
                scope="col"
                class="w-48 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Müşteri
              </th>
              <th
                scope="col"
                class="w-32 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Araç
              </th>
              <th
                scope="col"
                class="w-32 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Sürücü
              </th>
              <th
                scope="col"
                class="w-48 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Römork Tipi
              </th>
              <th
                scope="col"
                class="w-24 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Kapasite
              </th>
              <th
                scope="col"
                class="w-24 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Mesafe
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-if="filteredPlannedOrders.length === 0"
              class="hover:bg-gray-50"
            >
              <td
                colspan="8"
                class="px-3 py-4 text-center text-sm text-gray-500"
              >
                Araç bulunamadı. Lütfen filtre ayarlarınızı değiştirin.
              </td>
            </tr>
            <tr
              v-for="plannedOrder in filteredPlannedOrders"
              :key="plannedOrder.orderNo"
              :class="{'hover:bg-gray-50': true, 'bg-blue-50': isSingleVehicleMode && selectedOrder === plannedOrder.orderNo}"
            >
              <td class="px-3 py-3 whitespace-nowrap">
                <!-- Tonaj 0-30 arasında ise radio button göster -->
                <template v-if="isSingleVehicleMode">
                  <input
                    type="radio"
                    :id="`order-${plannedOrder.vehicle}`"
                    :value="plannedOrder.vehicle"
                    v-model="selectedOrder"
                    @change="selectSingleVehicle(plannedOrder)"
                    class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                </template>
                <!-- Tonaj 30'dan büyükse checkbox göster -->
                <template v-else>
                  <input
                    type="checkbox"
                    :id="`order-${plannedOrder.orderNo}`"
                    :value="plannedOrder.orderNo"
                    v-model="selectedOrders"
                    @change="updateSelectedVehicles(plannedOrder)"
                    class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                </template>
              </td>
              <td class="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                {{ plannedOrder.orderNo }}
              </td>
              <td class="px-3 py-3 text-sm text-gray-500 overflow-hidden">
                <div
                  class="tooltip overflow-hidden text-ellipsis"
                  :title="
                    plannedOrder.planning?.customer ||
                    plannedOrder.planningInfo?.customer
                  "
                >
                  {{
                    plannedOrder.planning?.customer ||
                    plannedOrder.planningInfo?.customer
                  }}
                </div>
              </td>
              <td
                class="px-3 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
              >
                {{ plannedOrder.vehicle }}
              </td>
              <td class="px-3 py-3 text-sm text-gray-600 overflow-hidden">
                <div
                  class="tooltip overflow-hidden text-ellipsis"
                  :title="plannedOrder.driverName || 'Sürücü bilgisi yok'"
                >
                  {{
                    plannedOrder.driverName ||
                    (plannedOrder.driverFirstName && plannedOrder.driverLastName
                      ? `${plannedOrder.driverFirstName} ${plannedOrder.driverLastName}`
                      : "Belirtilmemiş")
                  }}
                </div>
              </td>
              <td class="px-3 py-3 text-sm text-gray-500 overflow-hidden">
                <div
                  class="tooltip overflow-hidden text-ellipsis"
                  :title="plannedOrder.romorkType"
                >
                  {{ plannedOrder.romorkType }}
                </div>
              </td>
              <td class="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                {{ plannedOrder.capacity }} Kg
              </td>
              <td
                class="px-3 py-3 whitespace-nowrap text-sm font-medium"
                :class="getDistanceColorClass(plannedOrder.distanceToOrder)"
              >
                {{ formatDistance(plannedOrder.distanceToOrder) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div
    class="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3 rounded-b-lg"
  >
    <button
      @click="closeModal"
      class="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      İptal
    </button>
    <button
      @click="reallocateVehicles"
      class="inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
      :disabled="!hasSelectedVehicles"
    >
      <svg
        v-if="hasSelectedVehicles"
        class="mr-1 h-4 w-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        ></path>
      </svg>
      <span v-if="!hasSelectedVehicles">Araç Seçilmedi</span>
      <span v-else-if="isSingleVehicleMode">
        Seçilen Aracı Planla
      </span>
      <span v-else>
        Seçilen Araçları Planla ({{ selectedOrders.length }})
      </span>
    </button>
  </div>
</div>
</modal>
</template>
<script>
export default {
  name: "ReallocationModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    reallocationMessage: {
      type: String,
      default:
        "300 km mesafede uygun araç bulunamadı. Aktif siparişlerden araç çekilebilir.",
    },
    // Planlı siparişler ve onlara atanmış araçlar
    plannedOrders: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOrders: [],
      selectedVehicles: [],
      selectedOrder: null, // Tek araç seçimi için (radio button)
      searchQuery: "",
      sortOption: "distance",
      selectAll: false,
    };
  },
  computed: {
    // Tonaj 0-30 arasında ise tek araç seçim modu
    isSingleVehicleMode() {
      return this.order && this.order.tonnage > 0 && this.order.tonnage <= 30;
    },

    // Araç seçilip seçilmediğini kontrol et
    hasSelectedVehicles() {
      return this.isSingleVehicleMode 
        ? !!this.selectedOrder 
        : this.selectedOrders.length > 0;
    },

    filteredPlannedOrders() {
      // Önce planlı siparişleri filtreleyip mesafe ekleyelim
      let orders = this.plannedOrders.map((order) => {
        // Sipariş dolum noktası ile aracın mevcut konumu arasındaki mesafeyi hesapla
        const distance = this.calculateDistance(
          order.latitude,
          order.longitude,
          this.order.outpointLat,
          this.order.outpointLng
        );

        return {
          ...order,
          distanceToOrder: distance,
        };
      });

      // Arama filtresini uygula
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        orders = orders.filter((order) => {
          const customer = (
            order.planning?.customer ||
            order.planningInfo?.customer ||
            ""
          ).toLowerCase();
          const vehicle = (order.vehicle || "").toLowerCase();
          const orderNo = (order.orderNo || "").toLowerCase();
          const driver = (
            order.driverName ||
            (order.driverFirstName && order.driverLastName
              ? `${order.driverFirstName} ${order.driverLastName}`
              : "")
          ).toLowerCase();

          return (
            customer.includes(query) ||
            vehicle.includes(query) ||
            orderNo.includes(query) ||
            driver.includes(query)
          );
        });
      }

      // Sıralama seçeneğine göre sırala
      switch (this.sortOption) {
        case "distance":
          orders.sort((a, b) => a.distanceToOrder - b.distanceToOrder);
          break;
        case "capacity":
          orders.sort((a, b) => b.capacity - a.capacity);
          break;
        case "customer":
          orders.sort((a, b) => {
            const customerA = (
              a.planning?.customer ||
              a.planningInfo?.customer ||
              ""
            ).toLowerCase();
            const customerB = (
              b.planning?.customer ||
              b.planningInfo?.customer ||
              ""
            ).toLowerCase();
            return customerA.localeCompare(customerB);
          });
          break;
        case "driver":
          orders.sort((a, b) => {
            const driverA = (
              a.driverName ||
              (a.driverFirstName && a.driverLastName
                ? `${a.driverFirstName} ${a.driverLastName}`
                : "")
            ).toLowerCase();
            const driverB = (
              b.driverName ||
              (b.driverFirstName && b.driverLastName
                ? `${b.driverFirstName} ${b.driverLastName}`
                : "")
            ).toLowerCase();
            return driverA.localeCompare(driverB);
          });
          break;
      }

      return orders;
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("reallocation-needed-modal");
    },

    calculateDistance(lat1, lon1, lat2, lon2) {
      // Haversine formülü ile iki koordinat arası mesafeyi hesapla (km cinsinden)
      if (!lat1 || !lon1 || !lat2 || !lon2) return 999999; // Koordinatlar yoksa çok yüksek bir mesafe döndür

      const R = 6371; // Dünya yarıçapı (km)
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Km cinsinden mesafe

      return distance;
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    formatDistance(distance) {
      if (distance === 999999) return "Bilinmiyor";
      return distance < 1
        ? `${Math.round(distance * 1000)} m`
        : `${Math.round(distance * 10) / 10} km`;
    },

    getDistanceColorClass(distance) {
      if (distance === 999999) return "text-gray-400";
      if (distance < 10) return "text-green-600";
      if (distance < 50) return "text-blue-600";
      if (distance < 100) return "text-yellow-600";
      return "text-red-600";
    },

    // Tek araç seçimi için (radio button)
    selectSingleVehicle(plannedOrder) {
      // Radio button ile seçilen tek siparişin verilerini kaydet
      this.selectedVehicles = [plannedOrder.vehicle];
    },

    // Çoklu araç seçimi için (checkbox)
    updateSelectedVehicles(plannedOrder) {
      const index = this.selectedOrders.indexOf(plannedOrder.orderNo);
      
      if (index !== -1) {
        // Sipariş seçiliyse, aracı listeye ekle
        if (!this.selectedVehicles.includes(plannedOrder.vehicle)) {
          this.selectedVehicles.push(plannedOrder.vehicle);
        }
      } else {
        // Sipariş seçili değilse, aracı listeden çıkar
        const vehicleIndex = this.selectedVehicles.indexOf(plannedOrder.vehicle);
        if (vehicleIndex !== -1) {
          this.selectedVehicles.splice(vehicleIndex, 1);
        }
      }

      // Tüm siparişler seçiliyse selectAll işaretle
      this.selectAll = this.selectedOrders.length === this.filteredPlannedOrders.length;
    },

    toggleSelectAll() {
      if (this.isSingleVehicleMode) return; // Tek araç seçim modunda toplu seçime izin verme
      
      if (this.selectAll) {
        // Tüm siparişleri seç - aynı müşteriye ait siparişlerde duplikasyon olmamasını sağla
        this.selectedOrders = this.filteredPlannedOrders.map(
          (order) => order.orderNo
        );
        // Araçları eklerken benzersiz olduklarından emin ol
        this.selectedVehicles = [
          ...new Set(this.filteredPlannedOrders.map((order) => order.vehicle)),
        ];
      } else {
        // Tüm seçimleri temizle
        this.selectedOrders = [];
        this.selectedVehicles = [];
      }
    },

    reallocateVehicles() {
      if (!this.hasSelectedVehicles) return;

      const selectedOrders = this.isSingleVehicleMode 
        ? [this.selectedOrder] 
        : this.selectedOrders;
      
      const selectedVehicleDetails = this.filteredPlannedOrders
        .filter((order) => selectedOrders.includes(order.orderNo))
        .map((order) => ({
          vehicle: order.vehicle,
          capacity: order.capacity,
          romorkType: order.romorkType,
          driverName:
            order.driverName ||
            (order.driverFirstName && order.driverLastName
              ? `${order.driverFirstName} ${order.driverLastName}`
              : null),
          driverTC: order.driverTC,
          orderNo: order.orderNo,
          distanceToOrder: order.distanceToOrder,
        }));

      this.$emit("reallocate", {
        orderNo: this.order.orderNo,
        selectedVehicles: selectedVehicleDetails,
      });

      this.closeModal();
      this.resetForm();
    },

    resetForm() {
      this.selectedOrders = [];
      this.selectedVehicles = [];
      this.selectedOrder = null;
      this.searchQuery = "";
      this.sortOption = "distance";
      this.selectAll = false;
    },

    // SweetAlert veya başka bir bildirim sistemi kullanımı için yardımcı fonksiyon
    showNotification(title, message, type = "warning") {
      if (window.Swal) {
        window.Swal.fire({
          icon: type,
          title,
          text: message,
          confirmButtonText: 'Tamam'
        });
      } else if (this.$bvToast) {
        this.$bvToast.toast(message, {
          title,
          variant: type,
          solid: true,
          autoHideDelay: 5000
        });
      } else {
        alert(message);
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.resetForm();
      }
    },
    order: {
      handler() {
        this.resetForm();
      },
      deep: true,
    },
  },
};
</script>