const { default: axios } = require("axios");
const { managementCosting } = require("@/networking/urlmanager");
const { washPoint } = require("../networking/urlmanager");
const {haversineDistance}=require('./recomendation/haversineDistance.js')
const similarity  = require("similarity");


// Sabitler - global değişkenler yerine
const constants = {
  kilometerForFuel: 0.32, // Yakıt tüketim oranı
  adblueUsePercentage: 0.023, // AdBlue kullanım oranı
  vehiclePerwhellCount: 12, // Araç başına lastik sayısı
  engineConsumption: 40, // Motor yağı tüketimi (litre)
  fuelFilterConsumption: 1, // Yakıt filtresi tüketimi
  separFilterConsumption: 1, // Seperatör filtresi tüketimi
  oilFilterConsumption: 1, // Yağ filtresi tüketimi
  airFilterConsumption: 1, // Hava filtresi tüketimi
  airDryerFilterConsumption: 1, // Hava kurutucu filtresi tüketimi
  brakePadConsumptionPrice: 5, // Fren balatası tüketimi
  diskPriceConsumptionPrice: 5, // Disk tüketimi
  clutchConsumptionPrice: 1, // Debriyaj tüketimi
  trailerInspection: 0, // Römork muayene ücreti
  trailerInsurance: 0, // Römork sigorta ücreti
  investmentCost: 0, // Yatırım maliyeti
  // Periyotlar
  periot: {
    km_40000: 40000,
    km_80000: 80000,
    km_120000: 120000,
    km_150000: 150000,
    km_180000: 180000,
    km_280000: 280000
  }
};


function replaceToMoney(val) {
  return val.includes(",") ? Number(val.replace(",", ".")) : Number(val);
}

let calculatorDataCache = {
  costingData: null,
  washingPoints: null,
  lastFetch: null,
  fetchPromise: null,
};


async function fetchAndCacheData(token) {
  // If there's an ongoing fetch, wait for it
  if (calculatorDataCache.fetchPromise) {
    return calculatorDataCache.fetchPromise;
  }

  // If cache is valid (less than 5 minutes old), use it
  if (
    calculatorDataCache.costingData &&
    calculatorDataCache.lastFetch &&
    Date.now() - calculatorDataCache.lastFetch < 5 * 60 * 1000
  ) {
    console.log("girdi cache : ",1)
    return {
      costingData: calculatorDataCache.costingData,
      washingPoints: calculatorDataCache.washingPoints,
    };
  }


  calculatorDataCache.fetchPromise = Promise.all([
    axios.get(managementCosting.getAll, {
      headers: {
        Authorization: "Bareer " + token,
      },
    }),
    axios.get(washPoint.getWashingLocations, {
      headers: {
        Authorization: "Bareer " + token,
      },
    }),
  ])
    .then(([costingResponse, washingPointsResponse]) => {
      const result = {
        costingData: costingResponse.data.data,
        washingPoints: washingPointsResponse.data.data,
      };

      calculatorDataCache.costingData = result.costingData;
      calculatorDataCache.washingPoints = result.washingPoints;
      calculatorDataCache.lastFetch = Date.now();
      calculatorDataCache.fetchPromise = null;

      return result;
    })
    .catch((error) => {
      calculatorDataCache.fetchPromise = null;
      throw error;
    });

  return calculatorDataCache.fetchPromise;
}

/**
 * En uygun yıkama noktasını bulur
 * @param {Object} vehicle - Araç bilgileri
 * @param {Object} order - Sipariş bilgileri
 * @param {Array} washingPoints - Yıkama noktaları listesi
 * @param {number} motorinPrice - Motorin fiyatı
 * @return {Object} - En uygun yıkama noktası ve maliyeti
 */
function findBestWashPoint(vehicle, order, washingPoints, motorinPrice) {
  if (!vehicle?.recomendation?.isWashing || vehicle.recomendation.isWashing !== 1) {
    return { totalWashPriceCost: 0, washPointName: null, distance: 0 };
  }

  // Eşleşme eşiği
  const SIMILARITY_THRESHOLD = 0.9;
  
  // Araç koordinatları
  const vehicleCoords = {
    lat: typeof vehicle.latitude === "number" ? vehicle.latitude : Number(vehicle.latitude),
    lng: typeof vehicle.longitude === "number" ? vehicle.longitude : Number(vehicle.longitude)
  };
  
  // Sipariş koordinatları
  const orderCoords = order ? {
    lat: order.startLat
      ? Number(order.startLat)
      : Number(order.outpointLat)
      ? Number(order.outpointLat)
      : Number(order.fillingLatitude),
    lng: order.startLng
      ? Number(order.startLng)
      : Number(order.outpointLng)
      ? Number(order.outpointLng)
      : Number(order.fillingLongitude)
  } : null;

  // Veri validasyonu
  if (!vehicle.lastProduct || !washingPoints || washingPoints.length === 0) {
    return { totalWashPriceCost: 0, washPointName: null, distance: 0 };
  }
  
  
  // İlk filtre uygulaması - uygun ürünleri içeren yıkama noktaları
  const filteredWashPoints = washingPoints.filter(point => 
    point.properties.some(item => 
      item.productName && vehicle.lastProduct && 
      similarity(item.productName.trim(), vehicle.lastProduct.trim()) >= SIMILARITY_THRESHOLD
    )
  );
  
  if (filteredWashPoints.length === 0) {
    return { totalWashPriceCost: 0, washPointName: null, distance: 0 };
  }
  
  // Yıkama noktalarını mesafe ve maliyete göre değerlendir
  const ratedWashPoints = filteredWashPoints.map(point => {
    // Araç ile yıkama noktası arasındaki mesafe
    const vehicleToWashDistance = haversineDistance(vehicleCoords, {
      lat: Number(point.latitude),
      lng: Number(point.longitude)
    });
    
    // Sipariş başlangıç noktası ile yıkama noktası arasındaki mesafe
    const orderToWashDistance = orderCoords ? haversineDistance(
      orderCoords,
      {
        lat: Number(point.latitude),
        lng: Number(point.longitude)
      }
    ) : 0;
    
    // Toplam mesafe
    const totalDistance = vehicleToWashDistance + orderToWashDistance;
    
    // Ürün ID'sine göre fiyat hesaplaması
    const matchingProperties = point.properties.filter(
      item => order && item.productId === order.productId
    );
    
    // Ortalama fiyat hesaplama
    const averagePrice = matchingProperties.length > 0
      ? matchingProperties.reduce((sum, item) => sum + item.price, 0) / matchingProperties.length
      : 0;
    
    return {
      ...point,
      distance: totalDistance,
      cost: averagePrice
    };
  });
  
  // En uygun yıkama noktasını bul (en düşük mesafeye sahip)
  const bestPoint = ratedWashPoints.sort((a, b) => a.distance - b.distance)[0];
  
  if (!bestPoint) {
    return { totalWashPriceCost: 0, washPointName: null, distance: 0 };
  }
  
  // Toplam maliyet: mesafe x yakıt tüketimi x yakıt fiyatı + yıkama maliyeti
  const totalWashPriceCost = bestPoint.distance * constants.kilometerForFuel * motorinPrice + bestPoint.cost;
  
  return {
    totalWashPriceCost,
    washPointName: bestPoint.name,
    distance: bestPoint.distance
  };
}

/**
 * Maliyet hesaplama ana fonksiyonu
 */
async function calculator(
  token,
  vehicleCount = 1,
  totalYearKilometer = 1,
  vehicle = null,
  order = null,
  totalMontlyKilometer = 0,
  hgsPrice = 0,
  isAmortisman = true,
  isOneWay = true,
  dayCount = 0,
  hour = 24
) {
  // Performans ölçümü
  const startTime = Date.now();
  
  try {
    // Veri al
    const { costingData, washingPoints } = await fetchAndCacheData(token);
    
    // Veriyi doğrula
    if (!costingData || costingData.length < 20) {
      throw new Error("Geçersiz fiyat verisi");
    }
    
    // Fiyat verilerini alma ve dönüştürme (tek seferde daha okunabilir)
    const prices = {
      insurance: replaceToMoney(costingData[0]?.price),
      trafficInsurance: replaceToMoney(costingData[1]?.price),
      mtv: replaceToMoney(costingData[2]?.price),
      mtv2: replaceToMoney(costingData[3]?.price),
      inspection: replaceToMoney(costingData[4]?.price),
      
      whell: replaceToMoney(costingData[5]?.price),
      engineOil: replaceToMoney(costingData[6]?.price),
      fuelFilter: replaceToMoney(costingData[7]?.price),
      separFilter: replaceToMoney(costingData[8]?.price),
      oilFilter: replaceToMoney(costingData[9]?.price),
      airFilter: replaceToMoney(costingData[10]?.price),
      airDryerFilter: replaceToMoney(costingData[11]?.price),
      
      brakePad: replaceToMoney(costingData[12]?.price),
      brakePadChange: replaceToMoney(costingData[13]?.price),
      
      disk: replaceToMoney(costingData[14]?.price),
      diskChange: replaceToMoney(costingData[15]?.price),
      
      clutch: replaceToMoney(costingData[16]?.price),
      clutchChange: replaceToMoney(costingData[17]?.price),
      
      periodicMaintenance: replaceToMoney(costingData[18]?.price),
      driverWage: replaceToMoney(costingData[19]?.price),
      motorin: replaceToMoney(costingData[20]?.price),
      
      adblue: replaceToMoney(costingData[22]?.price),
      depreciation: replaceToMoney(costingData[23]?.price),
      contactInformation: replaceToMoney(costingData[24]?.price),
      washingCostPerOperation:replaceToMoney(costingData[25]?.price),//OPERASYON BAŞI YIKAMA MALİYETİ
      otherPersonnelPerVehicle: replaceToMoney(costingData[26]?.price)
    };

    // Yıkama noktası hesaplama
    const washInfo = findBestWashPoint(vehicle, order, washingPoints, prices.motorin);
    
    // Hesaplama performansını iyileştirmek için destructuring
    const { periot } = constants;
    
    // 1. Toplam yakıt maliyeti = toplam km * yakıt tüketimi * yakıt birim fiyatı
    const fuelTotalPrice = totalMontlyKilometer * constants.kilometerForFuel * prices.motorin;
    
    // 2. Toplam AdBlue maliyeti = toplam km * AdBlue tüketimi * AdBlue birim fiyatı
    const totalAdbluePrice = totalMontlyKilometer * prices.adblue * constants.adblueUsePercentage;
    
    // 3. Lastik maliyeti = (lastik fiyatı * lastik sayısı / kullanım ömrü) * toplam km
    const totalWhellPrice = ((prices.whell * constants.vehiclePerwhellCount) / periot.km_150000) * totalMontlyKilometer;

    // 4. Motor yağı maliyeti = (yağ fiyatı * yağ miktarı / değişim periyodu) * toplam km
    const totalEngineOilPrice = ((prices.engineOil * constants.engineConsumption) / periot.km_120000) * totalMontlyKilometer;

    // 5. Toplam filtre maliyeti - tüm filtreler için tek formül
    const filterTypes = [
      { price: prices.fuelFilter, consumption: constants.fuelFilterConsumption },
      { price: prices.separFilter, consumption: constants.separFilterConsumption },
      { price: prices.oilFilter, consumption: constants.oilFilterConsumption },
      { price: prices.airFilter, consumption: constants.airFilterConsumption },
      { price: prices.airDryerFilter, consumption: constants.airDryerFilterConsumption }
    ];
    
    const TotalfilterPrice = filterTypes.reduce((total, filter) => {
      return total + ((filter.price * filter.consumption) / periot.km_120000) * totalMontlyKilometer;
    }, 0);

    // 6. Balata maliyeti
    const totalBrakePadPrice = ((prices.brakePad * constants.brakePadConsumptionPrice + prices.brakePadChange) / periot.km_80000) * totalMontlyKilometer;
    
    // 7. Disk maliyeti
    const totalDiskPrice = ((prices.disk * constants.diskPriceConsumptionPrice + prices.diskChange) / periot.km_180000) * totalMontlyKilometer;
    
    // 8. Debriyaj maliyeti
    const totalClutchPrice = ((prices.clutch * constants.clutchConsumptionPrice + prices.clutchChange) / periot.km_280000) * totalMontlyKilometer;
    
    // 9. Periyodik bakım maliyeti
    const totalperiodicMaintenanceCost = (prices.periodicMaintenance / periot.km_40000) * totalMontlyKilometer;
    
    // 10. Resmi giderler (aylık)
    const totalTrafficInsurancePriceAndinsurancePrice = (
      prices.trafficInsurance +
      prices.insurance +
      prices.mtv +
      prices.mtv2 +
      prices.inspection +
      constants.trailerInspection +
      constants.trailerInsurance
    ) / 12;

    // 11. Diğer maliyetler
    const otherCost = prices.contactInformation + prices.otherPersonnelPerVehicle + prices.washingCostPerOperation;
    
    // 12. Toplam maliyet hesaplama - değişken maliyetler
    let totalCost = fuelTotalPrice +
      totalAdbluePrice +
      totalWhellPrice +
      totalEngineOilPrice +
      TotalfilterPrice +
      totalBrakePadPrice +
      totalDiskPrice +
      totalClutchPrice +
      totalperiodicMaintenanceCost +
      hgsPrice;
    
    // 13. Sabit maliyetleri ekle - tek/çift yön durumuna göre
    const dailyMultiplier = isOneWay ? 1 : 2;
    
    // Tüm günlük maliyetleri tek seferde hesapla
    const dailyCosts = [
      { cost: totalTrafficInsurancePriceAndinsurancePrice / 30, apply: false },
      { cost: otherCost / 30, apply: false },
      { cost: constants.investmentCost / 30, apply: false },
      { cost: prices.driverWage / 30, apply: false },
      { cost: prices.depreciation / 30, apply: isAmortisman }
    ];
    
    // Günlük maliyetleri toplama ekle
    dailyCosts.forEach(item => {
      totalCost += item.cost * dayCount;
      // if (item.apply) {
      //   totalCost += item.cost * dayCount * dailyMultiplier;
      // }
    });
    
    // Performans ölçümü sonlandır
    const endTime = Date.now();
    
    // Sonuçları döndür
    return {
      total: totalCost.toFixed(2),
      fuelTotalPrice,
      totalAdbluePrice,
      totalWhellPrice,
      totalEngineOilPrice,
      TotalfilterPrice,
      totalBrakePadPrice,
      totalDiskPrice,
      totalClutchPrice,
      totalperiodicMaintenanceCost,
      totalTrafficInsurancePriceAndinsurancePrice,
      totalWashPriceCost: washInfo.totalWashPriceCost.toFixed(2),
      washPointName: washInfo.washPointName,
      otherCost,
      depreciationPrice: prices.depreciation,
      driverWagePrice: prices.driverWage,
      distanceWithWashing: washInfo.distance
    };
  } catch (error) {
    console.error("Calculator error:", error);
    // Hata durumunda varsayılan değerler döndür
    return {
      total: "0.00",
      totalWashPriceCost: "0.00",
      washPointName: null,
      distanceWithWashing: 0
    };
  }
}
module.exports = {
  calculator,
};
