<template>
  <div
    class="bg-white border border-gray-200 rounded-lg mb-3 hover:shadow-md transition-shadow overflow-hidden"
  >
    <!-- Kart Başlığı -->
    <div class="bg-gray-50 px-3 py-2 border-b border-gray-200">
      <!-- Başlık Satırı - İlk Kısım: Sipariş No ve Sevkiyat Türü -->
      <div class="flex justify-between items-center mb-1.5">
        <div class="flex items-center">
          <span
            class="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded"
          >
            #{{ shipment.orderNo }}
          </span>
          <shipment-type-badge :type-id="shipment.typeId" />
        </div>
        <button
          @click="$emit('toggle')"
          class="text-gray-500 hover:text-gray-700"
        >
          <i
            :class="isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
          ></i>
        </button>
      </div>

      <!-- Başlık Satırı - İkinci Kısım: Ürün Bilgisi -->
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div
            class="bg-yellow-50 border border-yellow-200 px-2 py-0.5 rounded text-yellow-700 text-xs flex items-center"
          >
            <i class="fas fa-box-open mr-1.5"></i>
            <span class="font-medium">{{ shipment.productType }}</span>
          </div>
        </div>
        <!-- Tarihi de ekleyelim -->
        <span class="text-xs text-gray-500">{{
          formatShortDate(shipment.deliveryDate)
        }}</span>
      </div>
    </div>

    <!-- Detay Bölümü -->
    <shipment-details :shipment="shipment" :is-expanded="isExpanded" />

    <!-- Önizleme (kapalıyken) -->
    <div
      v-if="!isExpanded"
      class="px-3 py-2 border-t border-gray-100 flex justify-between text-xs text-gray-500"
    >
      <div class="truncate">
        <i class="fas fa-user mr-1"></i> {{ shipment.customer }}
      </div>
      <div class="truncate ml-2 flex items-center">
        <i class="fas fa-map-marker-alt mr-1"></i>
        <span>{{ getTruncatedLocation(shipment) }}</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";
import ShipmentTypeBadge from "./ShipmentTypeBadge.vue";
import ShipmentDetails from "./ShipmentDetails.vue";

export default {
  name: "ShipmentCard",
  components: {
    ShipmentTypeBadge,
    ShipmentDetails,
  },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle"],
  methods: {
    formatShortDate(date) {
      if (!date) return "";
      return moment(date).format("D MMM");
    },
    getTruncatedLocation(item) {
      // Teslimat noktasının ilk 15 karakterini göster
      return item.deliveredName.length > 15
        ? item.deliveredName.substring(0, 15) + "..."
        : item.deliveredName;
    },
  },
};
</script>