// const baseURL = "http://api.guneyeksport.com:1982";
//const baseURL = "https://apitest.guneyeksport.com:1981";
//const baseURL = "http://192.168.1.27";
//  const baseURL = "http://localhost";
//const baseURL = "https://sirius.tetglobal.com/api";
// const baseURL = "http://192.168.68.142";
 // const baseURL = "http://localhost";
const baseURL = "https://sirius.tetglobal.com/api";
// const baseURL = "http://192.168.68.105";
// const baseURL = "http://34.34.69.225.137:8001";
// const baseURL = "http://34.69.225.137:8003";
// const baseURL = "http://34.69.225.137:8002";
// const baseURL="http://104.247.175.83:1983";
//const baseURL="https://hilal-api.seferiai.com"
export default baseURL;
