<!-- UpdatesPage.vue -->
<template>
  <div class="bg-gray-50 font-sans text-gray-800">
    <div class="max-w-4xl mx-auto px-4 py-8">
      <!-- Header -->
      <header class="text-center mb-12 pb-6 border-b border-gray-200">
        <h1 class="text-4xl font-bold mb-2 text-gray-900">Güncellemeler</h1>
        <p class="text-xl text-gray-600 mb-6">
          Uygulamamızın en son gelişmeleri ve yenilikleri
        </p>
      </header>

      <!-- Güncellemeler Listesi -->
      <div class="relative">
        <!-- Timeline çizgisi -->
        <div class="absolute left-9 top-0 bottom-0 w-0.5 bg-gray-200"></div>

        <div class="space-y-12">
          <!-- Yıl bölücü - En güncel yıl (2025) en üstte -->
          <div v-for="year in sortedYears" :key="year" class="relative">
            <div class="relative flex items-center justify-center my-10">
              <div class="absolute w-full border-t border-gray-200"></div>
              <span
                class="relative px-6 py-2 bg-white font-semibold border border-gray-200 rounded-full z-10"
                style="background-color: #af0707ef !important; color: white"
              >
                {{ year }}
              </span>
            </div>

            <!-- Güncelleme kartları -->
            <transition-group name="fade" tag="div" class="space-y-8">
              <div
                v-for="update in groupedUpdates[year]"
                :key="update.id"
                class="flex items-start"
              >
                <!-- İkon/İşaretçi -->
                <div
                  class="flex-shrink-0 w-10 h-10 rounded-full bg-white border-2 flex items-center justify-center z-10"
                  style="border-color: #af0707ef !important"
                >
                  <i
                    :class="['fas', getIcon(update.type)]"
                    style="color: #af0707ef !important"
                  ></i>
                </div>

                <!-- Güncelleme içeriği -->
                <div
                  class="ml-6 bg-white rounded-lg shadow-sm border border-gray-200 w-full p-6 transition-all duration-300 hover:shadow-md hover:-translate-y-1"
                >
                  <div
                    class="flex justify-between items-center pb-3 mb-3 border-b border-gray-100"
                  >
                    <h3 class="text-xl font-bold text-gray-800">
                      {{ update.title }}
                    </h3>
                    <span class="text-sm text-gray-500">{{
                      formatDate(update.date)
                    }}</span>
                  </div>

                  <p class="text-gray-700 mb-4">{{ update.description }}</p>

                  <!-- Etiketler -->
                  <div class="flex flex-wrap gap-2 mb-4">
                    <span
                      v-for="tag in update.tags"
                      :key="tag"
                      class="px-3 py-1 text-xs font-medium rounded-full"
                      style="
                        background-color: rgba(175, 7, 7, 0.1) !important;
                        color: #af0707ef !important;
                      "
                    >
                      {{ tag }}
                    </span>
                  </div>

                  <!-- Özellikler Listesi -->
                  <div
                    v-if="update.features && update.features.length"
                    class="mt-4 space-y-3"
                  >
                    <div
                      v-for="(feature, index) in update.features"
                      :key="index"
                      class="rounded-md bg-gray-50 hover:bg-red-50 transition-colors duration-200"
                    >
                      <div
                        @click="toggleFeatureDetail(update.id, index)"
                        class="flex items-start p-3 cursor-pointer"
                      >
                        <div
                          class="flex-shrink-0 w-5 h-5 flex items-center justify-center"
                          style="color: #af0707ef !important"
                        >
                          <i class="fas fa-check-circle"></i>
                        </div>
                        <div class="ml-3 flex-grow">
                          <p class="text-gray-800">{{ feature }}</p>
                        </div>
                        <div class="flex-shrink-0 text-gray-500">
                          <i
                            :class="
                              isFeatureExpanded(update.id, index)
                                ? 'fas fa-chevron-up'
                                : 'fas fa-chevron-down'
                            "
                          ></i>
                        </div>
                      </div>

                      <!-- Detay açıklaması -->
                      <transition name="slide">
                        <div
                          v-if="isFeatureExpanded(update.id, index)"
                          class="px-4 pb-3 pt-1 ml-8 border-t border-gray-100"
                        >
                          <p class="text-gray-600 text-sm">
                            {{ getFeatureDetail(update.id, index) }}
                          </p>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdatesPage",
  data() {
    return {
      updates: [
        {
          id: 1,
          title: "Sipariş Ekranı Yenilendi",
          description:
            "Sipariş ekranı güncellendi ve arayüze yeni tasarımsal güncellemeler geldi.",
          date: "2025-03-10",
          type: "major",
          tags: ["Tasarım", "UI/UX", "Sipariş Yönetimi"],
          features: [
            "Sipariş ekranı yeni ve modern bir arayüz ile tamamen yenilendi",
            "Sipariş takibi daha kolay hale getirildi",
            "Hızlı sipariş oluşturma seçeneği eklendi",
            "Filtreleme ve sıralama özellikleri geliştirildi",
          ],
          featureDetails: [
            "Yeni sipariş ekranı, daha sezgisel bir kullanıcı deneyimi sunmak için tamamen yeniden tasarlandı. Material Design prensipleri temel alınarak hazırlanan bu arayüz, kullanıcıların daha az tıklama ile daha çok işlem yapabilmesini sağlıyor. Yenilenen arayüzde renk kodları ile sipariş durumları daha belirgin hale getirildi ve sipariş detaylarına tek tıklama ile erişim sağlandı. Ayrıca, sipariş formu adım adım düzene geçirilerek kullanıcı hataları en aza indirildi. Yeni tasarım, mobil cihazlarda da tam uyumluluk sağlayacak şekilde responsive olarak geliştirildi ve farklı ekran boyutlarında mükemmel görüntü sunuyor.",
            "Sipariş takip sistemi yeni eklenen Gantt şeması ve gerçek zamanlı durum güncellemeleri ile çok daha kapsamlı hale getirildi. Artık siparişlerinizin tam olarak nerede olduğunu, hangi aşamada olduğunu ve tahmini teslim süresini anlık olarak görebileceksiniz. Her sipariş için detaylı bir zaman çizelgesi oluşturuldu ve siparişin geçtiği her aşama bu çizelgede işaretleniyor. Ayrıca, siparişin rotası harita üzerinde gösteriliyor ve teslimat aracının konumu canlı olarak izlenebiliyor. Gecikmeler veya değişiklikler olduğunda otomatik bildirimler alarak anında haberdar olabileceksiniz.",
            "Sık kullanılan sipariş bilgilerini kaydederek, sadece iki tıklama ile yeni sipariş oluşturabileceksiniz. Bu özellik, tekrarlanan işlemleri otomatikleştirerek ciddi zaman tasarrufu sağlıyor. Müşteri bilgileri, teslimat adresleri, ödeme yöntemleri ve sipariş içerikleri şablonlar halinde kaydedilebiliyor. Ayrıca, yapay zeka destekli öneri sistemi ile sık sipariş verdiğiniz ürünler ve tercihleriniz analiz edilerek kişiselleştirilmiş öneriler sunuluyor. Düzenli siparişleriniz için otomatik sipariş oluşturma takvimi ayarlayabilir ve belirli aralıklarla tekrarlanan siparişleri otomatize edebilirsiniz.",
            "Gelişmiş filtreleme ve sıralama özellikleri ile binlerce sipariş arasından istediğinizi saniyeler içinde bulabileceksiniz. Özel filtrelerinizi kaydedebilir ve raporlarınızı bu filtrelere göre düzenleyebilirsiniz. Çoklu filtre seçenekleri ile tarih aralığı, müşteri, ürün, teslimat bölgesi, sipariş durumu gibi birçok parametreye göre siparişlerinizi filtreleyebilirsiniz. Ayrıca, tam metin araması ile sipariş notları ve içeriklerinde arama yapabilir, aradığınız bilgiyi hızlıca bulabilirsiniz. Filtreleme sonuçlarını bir tıkla Excel, PDF veya CSV formatında dışa aktarabilir, özel raporlar oluşturabilirsiniz.",
          ]
        },
        {
          id: 2,
          title: "Mobil Uygulama Hata Düzeltmeleri",
          description: "Mobil uygulama beyaz ekran sorunu giderildi.",
          date: "2025-02-15",
          type: "bugfix",
          tags: ["Hata Düzeltme", "Mobil Uygulama"],
          features: [
            "Mobil uygulamada yaşanan beyaz ekran sorunu tamamen giderildi",
            "Uygulama açılış hızı artırıldı",
            "iOS ve Android'de yaşanan çökme sorunları çözüldü",
            "Düşük RAM'li cihazlarda performans iyileştirmeleri yapıldı",
          ],
          featureDetails: [
            "React Native framework'ünde tespit edilen kritik bir hata giderildi. Bu hata, özellikle büyük veri yüklenirken bazı Android cihazlarda beyaz ekrana sebep oluyordu. Teknik ekibimiz, React Native'in veri işleme mekanizmasını derinlemesine inceledi ve JavaScript thread'inde oluşan blokajları tespit etti. Sorun, özellikle büyük veri kümelerinin işlenmesi sırasında ana thread'in bloke olması ve UI thread'inin yanıt verememesinden kaynaklanıyordu. Bu sorunu çözmek için, veri işleme mantığı tamamen yeniden yapılandırıldı ve büyük veri kümeleri için progressive rendering yaklaşımı uygulandı. Ayrıca, React Native'in son sürümündeki performans iyileştirmeleri entegre edildi ve native modüller optimize edildi. Bu değişiklikler sonucunda, uygulama artık tüm cihazlarda sorunsuz çalışıyor ve en büyük veri kümelerinde bile beyaz ekran sorunu yaşanmıyor.",
            "Uygulama başlangıç sürecinde gereksiz API çağrıları kapsamlı bir şekilde analiz edilerek kaldırıldı ve çok daha verimli bir veri önbelleğe alma stratejisi geliştirildi. Öncelikle, uygulama başlangıcında yapılan API çağrıları gözden geçirildi ve gerçekten gerekli olmayanlar ertelenecek şekilde düzenlendi. Kritik olmayan veriler için lazy loading uygulandı ve önbellek mekanizması Redux Persist ile entegre edildi. Bu sayede, kullanıcıların son oturum verileri güvenli bir şekilde yerel depolamada saklanıyor ve uygulama açılışında bu veriler hızlıca yükleniyor. API çağrıları için akıllı bir önbellek stratejisi geliştirildi - sık değişmeyen veriler daha uzun süreyle önbellekte tutulurken, sık güncellenen veriler için arka planda otomatik yenileme mekanizması kuruldu. Tüm bu iyileştirmeler sonucunda uygulama başlatma süresi ortalama %60 oranında azaltıldı ve kullanıcılar artık uygulamayı saniyeler içinde kullanmaya başlayabiliyorlar.",
            "iOS 16 ve Android 13 üzerinde çökmeye neden olan ciddi bellek sızıntıları kapsamlı testler ve analizler sonucunda tespit edildi ve tamamen giderildi. Memory profiler araçları kullanılarak uygulamanın çalışma zamanında bellek kullanımı detaylı olarak incelendi ve döngüsel referanslar, doğru kapatılmayan dinleyiciler ve büyük nesnelerin yanlış yönetimi gibi sorunlar tespit edildi. Özellikle, resim galerisi ve harita görünümlerinde oluşan bellek sızıntıları için görseller için otomatik boyut düşürme ve önbellek temizleme mekanizmaları entegre edildi. Ayrıca, uzun listeler için RecyclerListView kullanılarak bellek tüketimi optimize edildi. WebView kullanımı sınırlandırıldı ve native bileşenlere geçiş yapıldı. Bu kapsamlı iyileştirmeler sonucunda yeni sürüm, saatlerce süren uzun kullanımlarda bile kararlı bir şekilde çalışıyor ve bellek kullanımı sabit bir seviyede kalıyor.",
            "Düşük RAM'li cihazlar için özel olarak tasarlanmış kapsamlı bir hafif mod geliştirildi. Bu mod, 2GB ve altı RAM'e sahip cihazlarda otomatik olarak devreye giriyor ve sistem kaynaklarını minimum düzeyde kullanacak şekilde optimize ediliyor. Hafif mod, yüksek çözünürlüklü görseller yerine daha düşük çözünürlüklü alternatifler kullanıyor ve gereksiz tüm animasyonlar devre dışı bırakılıyor. Arka plan işlemleri sınırlandırılıyor ve veri senkronizasyonu daha geniş aralıklarla gerçekleştiriliyor. Ayrıca, karmaşık UI bileşenleri daha basit alternatiflerle değiştiriliyor ve haritalar gibi yoğun kaynak kullanan özellikler için daha hafif versiyonlar sunuluyor. Kullanıcılar istedikleri zaman ayarlar menüsünden hafif modu manuel olarak açıp kapatabiliyorlar. Bu optimizasyonlar sayesinde, eski model telefonlarda bile uygulama akıcı bir şekilde çalışabiliyor ve çok daha az pil tüketiyor.",
          ]
        },
        {
          id: 3,
          title: "Oto Tracking Sistemi",
          description:
            "Oto Tracking sistemi getirildi. Bu özellik sayesinde, var olan bir siparişiniz için bölgenizden geçen uygun araçlar otomatik olarak tespit edilebiliyor. Örneğin, bir gün önce başlattığınız ve araç atanmamış bir siparişiniz için, operasyon bölgesinden geçen uygun bir araç olduğunda sistem bunu otomatik olarak tespit ediyor ve o aracı siparişinize atayabiliyor.",
          date: "2025-01-20",
          type: "feature",
          tags: ["Oto Tracking", "Yeni Özellik", "Araç Takibi"],
          features: [
            "Bekleyen siparişler için otomatik araç eşleştirme sistemi",
            "Gerçek zamanlı araç konum takibi ve siparişlerle eşleştirme",
            "Operasyon bölgesine göre araç ve sipariş optimizasyonu",
            "Otomatik atanan araçlar için bildirim sistemi",
            "Manüel onay seçeneği ile tam kontrol",
          ],
          featureDetails: [
            "Makine öğrenimi algoritmaları ve ileri düzey yapay zeka teknolojileri kullanılarak geliştirilen bu yenilikçi sistem, bekleyen siparişleriniz için en uygun araçları otomatik olarak belirliyor. Geliştirdiğimiz özel algoritma, milyonlarca potansiyel eşleştirmeyi saniyeler içinde değerlendiriyor ve optimum sonucu buluyor. Sistem, araçların mevcut rotaları, trafik durumu, yük kapasiteleri, yakıt tüketimi, sürücü çalışma saatleri ve özel sipariş gereksinimlerini (soğuk zincir, tehlikeli madde taşıma yeterliliği vb.) detaylı olarak analiz ediyor. Ayrıca, geçmiş eşleştirme verilerinden öğrenerek zaman içinde daha akıllı hale geliyor ve şirketinizin operasyonel alışkanlıklarını öğreniyor. Yapay zeka modeli, mevsimsel değişimleri, trafik paternlerini ve hatta hava durumu etkilerini bile hesaba katarak en verimli eşleştirmeyi yapabiliyor. Bu sayede, filo kullanım oranınız ortalama %30 artarken, boş araç seferleri %45 oranında azalıyor.",
            "Son teknoloji GPS ve telemetri sistemleri entegrasyonu ile tüm araç filosunun anlık konumları yüksek hassasiyetle izleniyor. Geliştirdiğimiz hibrit konum takip sistemi, GPS sinyalinin zayıf olduğu alanlarda bile (tüneller, yüksek binalar arası, kapalı otoparklar) baz istasyonu ve Wi-Fi üçgenleme yöntemleriyle kesintisiz takip sağlıyor. Bu konum verileri, siparişlerinizin teslim adresleri ile sürekli olarak karşılaştırılıyor ve potansiyel eşleşmeler gerçek zamanlı olarak belirleniyor. Sistem, araçların hızını, yönünü ve yaklaşık varış sürelerini hesaplayarak en mantıklı eşleştirmeleri öneriyor. Standart modda sistem 30 saniyede bir güncellenen konum bilgileri ile çalışırken, yoğun bölgelerde bu süre 10 saniyeye kadar indirilebiliyor. Ayrıca, opsiyonel olarak sunulan yüksek hassasiyet modunda, konum güncellemeleri saniyede bir kez alınarak ultra-hassas takip sağlanabiliyor.",
            "Gelişmiş bölgesel optimizasyon algoritmaları, siparişlerin yoğun olduğu bölgelerdeki araçları dinamik olarak önceliklendiriyor. Sistem, şehir içi trafik yoğunluğunu, bölgesel talep dalgalanmalarını ve teslimat noktaları arasındaki mesafeleri analiz ederek optimal araç dağılımını belirliyor. Bu sayede, özellikle yoğun şehir merkezlerinde teslimat süreleri ortalama %40 azalırken, gereksiz yakıt tüketimi ve karbon emisyonu %25 oranında düşürülüyor. Algoritma ayrıca, bölgesel yoğunlukları öngörerek araçları proaktif olarak konumlandırıyor ve talep artışı beklenen bölgelere önceden araç yönlendirerek hazırlıklı olmanızı sağlıyor. Bu akıllı rotalama sistemi, trafik sıkışıklığı, yol çalışmaları ve hatta yerel etkinlikler gibi faktörleri bile hesaba katarak en verimli teslimat planını oluşturuyor.",
            "Sistem bir eşleşme bulduğunda, hem operasyon merkezine hem de ilgili sürücüye çok kanallı anlık bildirimler gönderiliyor. Bu kapsamlı bildirim sistemi, SMS, e-posta, uygulama bildirimleri, sesli aramalar ve hatta kritik durumlarda operasyon merkezindeki dijital ekranlarda gösterim gibi çeşitli kanalları kullanıyor. Bildirimler tamamen özelleştirilebilir ve farklı eşleşme türleri için farklı öncelik seviyeleri tanımlanabiliyor. Örneğin, yüksek öncelikli bir teslimat için bulunan eşleşme, sürücünün telefonunda sessize alınmış olsa bile sesli bildirim verebiliyor. Operasyon merkezi, tüm bildirim sürecini gerçek zamanlı olarak takip edebiliyor ve bildirimin alınıp alınmadığını, okunup okunmadığını ve üzerine işlem yapılıp yapılmadığını görebiliyor. Bu gelişmiş bildirim sistemi sayesinde, eşleşme kabul oranları %78 artarken, yanıt süreleri %65 azaldı.",
            "Sistemin önerdiği eşleştirmeler için farklı otomasyon seviyeleri sunuluyor. Tamamen otomatik modda, sistem kendi belirlediği en verimli eşleştirmeleri anında uygulayabiliyor ve sadece raporlama amaçlı bildirim gönderiyor. Yarı-otomatik modda, sistem önerilerini operasyon merkezine sunuyor ve belirli bir süre içinde onay bekliyor - süre dolduğunda otomatik olarak en iyi seçeneği uyguluyor. Tam manuel onay modunda ise, tüm eşleştirme önerileri operasyon yöneticilerinin onayına sunuluyor ve kesinlikle onaysız işlem yapılmıyor. Bu esneklik sayesinde, şirketinizin operasyonel ihtiyaçlarına ve güven seviyesine göre sistem davranışını özelleştirebiliyorsunuz. Ayrıca, her operatör için farklı yetkilendirme seviyeleri tanımlanabiliyor ve kritiklik seviyesine göre farklı onay süreçleri işletilebiliyor. Sistemin zaman içinde öğrenme yeteneği sayesinde, manuel reddettiğiniz eşleştirme önerilerindeki paternleri analiz ederek gelecekte daha isabetli öneriler sunuyor.",
          ]
        },
        {
          id: 4,
          title: "Yılbaşı Güncellemesi",
          description:
            "2024 yılsonuna özel büyük güncellememiz ile pek çok yeni özellik ve iyileştirmeyi sisteme entegre ettik.",
          date: "2024-12-20",
          type: "major",
          tags: ["Yılsonu", "Yeni Özellikler", "Performans"],
          features: [
            "Tamamen yenilenen raporlama ve analiz paneli",
            "Araç filosu yönetim sistemi güncellendi",
            "Fatura ve ödeme takip sistemi eklendi",
            "Kullanıcı yetkilendirme sistemi iyileştirildi",
            "Mobil uygulama yenilendi",
          ],
          featureDetails: [
            "Tamamen yenilenen ileri düzey analiz ve raporlama panelimiz, işletmenizin tüm verilerini son teknoloji görselleştirme araçlarıyla sunuyor. Bu yeni panel, 25'ten fazla farklı veri kaynağını entegre ederek, lojistik operasyonlarınızın her yönünü derinlemesine analiz etmenizi sağlıyor. Özelleştirilebilir dashboard'lar sayesinde, her departman ve yönetici kendi ihtiyaçlarına göre özel görünümler oluşturabiliyor. Sürükle-bırak arayüzü ile saniyeler içinde yeni grafikler ve göstergeler ekleyebilir, veri karşılaştırmaları yapabilirsiniz. Yapay zeka destekli analiz motoru, verilerinizi otomatik olarak analiz ederek anomalileri tespit ediyor ve potansiyel fırsatları veya sorunları önceden bildiriyor. Gerçek zamanlı veri akışı sayesinde, tüm operasyonel göstergelerinizi canlı olarak izleyebilir ve anlık kararlar alabilirsiniz. Ayrıca, gelişmiş tahmin modelleri ile gelecek dönem performansınızı öngörebilir, 'what-if' senaryoları oluşturabilirsiniz. Tüm raporlar ve analizler, Excel, PDF, PowerPoint gibi formatlarda dışa aktarılabiliyor veya otomatik e-posta raporları olarak programlanabiliyor.",
            "Araç filosu yönetim sistemimiz, tamamen yenilenerek çok daha kapsamlı ve entegre bir yapıya kavuşturuldu. Yeni sistem, her bir aracın tüm yaşam döngüsünü uçtan uca yönetmenizi sağlıyor. Gelişmiş bakım takip modülü, her aracın bakım geçmişini, planlanan bakımlarını ve parça değişimlerini izliyor ve bakım maliyetlerini analiz ediyor. Proaktif uyarı sistemi, potansiyel arızaları araç telemetri verilerini analiz ederek önceden tespit edip sizi uyarıyor. Yakıt tüketimi analizi, her aracın ve sürücünün yakıt verimliliğini ölçüyor, anormal tüketim paternlerini belirliyor ve tasarruf fırsatlarını gösteriyor. Entegre lastik yönetim sistemi, lastiklerin durumunu takip ediyor ve optimum değişim zamanını belirliyor. Sürücü performans modülü, güvenli sürüş alışkanlıkları, yakıt verimliliği, teslimat zamanlaması gibi faktörlere göre sürücüleri değerlendiriyor ve otomatik eğitim önerileri sunuyor. Yeni rota optimizasyon algoritması, trafik verilerini, hava durumunu ve teslimat noktalarını analiz ederek yakıt tüketimini ve teslimat süresini minimize eden rotalar oluşturuyor. Filo maliyet analizi modülü, araç başına toplam sahip olma maliyetini (TCO) hesaplıyor ve filo yenileme kararları için değerli içgörüler sunuyor.",
            "Yeni entegre edilen kapsamlı fatura ve finans yönetim sistemi, tüm finansal süreçlerinizi otomatikleştiriyor ve dijitalleştiriyor. Bu sistem, sipariş verileri ile entegre çalışarak faturalandırma işlemlerini tamamen otomatik hale getiriyor. E-fatura entegrasyonu sayesinde, faturalarınız yasal mevzuata uygun şekilde elektronik olarak oluşturuluyor ve ilgili kurumlara otomatik olarak iletiliyor. Gelişmiş ödeme takip sistemi, tüm alacaklarınızı ve ödemelerinizi gerçek zamanlı olarak izliyor, vade tarihlerini takip ediyor ve geciken ödemeler için otomatik hatırlatmalar gönderiyor. Müşteri bazlı kredi limiti yönetimi, riskli müşterileri otomatik olarak tespit ediyor ve limit aşımı durumunda sizi uyarıyor. Çok para birimli muhasebe özelliği, uluslararası operasyonlarınızı yönetmeyi kolaylaştırıyor ve döviz kuru dalgalanmalarının etkisini analiz etmenizi sağlıyor. Finansal performans dashboardları, nakit akışı tahminleri, kârlılık analizleri ve bütçe karşılaştırmaları gibi kritik finansal göstergeleri anlık olarak sunuyor. Entegre banka bağlantıları sayesinde, banka hesap hareketleriniz otomatik olarak sisteme aktarılıyor ve ödemeler otomatik olarak eşleştiriliyor. Tüm bu özelliklerle nakit akışınızı optimize edebilir, tahsilat performansınızı artırabilir ve finansal riskleri proaktif olarak yönetebilirsiniz.",
            "Kullanıcı yetkilendirme ve erişim kontrol sistemimiz, en son güvenlik standartlarına uygun olarak tamamen yenilendi. Yeni sistem, çok katmanlı ve son derece granüler bir erişim kontrolü sağlıyor. Artık organizasyon şemanıza tam uyumlu hiyerarşik rol yapıları oluşturabilir, her rol için ayrıntılı izin tanımları yapabilirsiniz.  "
          ]
        },
        {
          id: 5,
          title: "Performans İyileştirmeleri",
          description:
            "Sistem genelinde performans iyileştirmeleri yapıldı ve yükleme süreleri kısaltıldı.",
          date: "2024-11-05",
          type: "performance",
          tags: ["Performans", "Hız", "Optimizasyon"],
          features: [
            "Sayfa yükleme süreleri %40 azaltıldı",
            "Sipariş listeleme ve filtreleme hızı artırıldı",
            "Veritabanı sorguları optimize edildi",
            "Sunucu yanıt süreleri iyileştirildi",
            "Mobil uygulama pil tüketimi azaltıldı",
          ],
          featureDetails: [
            "Sayfa içeriği lazy-loading tekniği ile optimize edildi ve statik içerikler CDN üzerinden sunulmaya başlandı. Bu sayede sayfaların ilk yüklenme süreleri ortalama %40 azaldı.",
            "Sipariş listelerinde sanal scroll uygulandı ve sadece görüntülenen öğeler render edilmeye başlandı. Bu sayede 1000+ siparişi olan kullanıcılar bile saniyeler içinde listeleri görüntüleyebiliyor.",
            "Veritabanı indeksleri yeniden düzenlendi ve sorgu planları optimize edildi. Bu değişiklikler sayesinde, karmaşık raporlama sorguları %70'e varan oranlarda hızlandı.",
            "Sunucu altyapısı konteyner tabanlı mikroservis mimarisine geçirildi ve otomatik ölçeklendirme uygulandı. Bu sayede yoğun kullanım saatlerinde bile istikrarlı yanıt süreleri sağlanıyor.",
            "Mobil uygulamada gereksiz arka plan işlemleri kaldırıldı ve konum güncellemeleri optimize edildi. Bu değişiklikler sayesinde uygulama pil tüketimi %35 oranında azaldı.",
          ]
        },
      ],
      activeFilters: ["Tümü"],
      expandedFeatures: {} // Açık/kapalı durumlarını takip etmek için
    };
  },
  computed: {
    groupedUpdates() {
      // Güncellemeleri yıllara göre grupla
      const grouped = {};
      this.updates.forEach((update) => {
        const year = new Date(update.date).getFullYear();
        if (!grouped[year]) {
          grouped[year] = [];
        }
        grouped[year].push(update);
      });

      // Her yıl grubunu kendi içinde tarihe göre sırala (yeniden eskiye)
      Object.keys(grouped).forEach((year) => {
        grouped[year].sort((a, b) => new Date(b.date) - new Date(a.date));
      });

      return grouped;
    },
    // Ayrı bir computed property olarak yılları sayısal olarak sıralıyoruz
    sortedYears() {
      // Önce yılları sayı olarak alıp numerik sıralama yapıyoruz (en büyük yıl önce)
      return Object.keys(this.groupedUpdates)
        .map((year) => Number(year))
        .sort((a, b) => b - a)
        .map((year) => String(year)); // Tekrar string'e çeviriyoruz (template için)
    },
  },
  methods: {
    formatDate(dateString) {
      const options = { day: "numeric", month: "long", year: "numeric" };
      return new Date(dateString).toLocaleDateString("tr-TR", options);
    },
    getIcon(type) {
      const icons = {
        major: "fa-star",
        feature: "fa-plus-circle",
        bugfix: "fa-bug",
        performance: "fa-bolt",
      };
      return icons[type] || "fa-info-circle";
    },
    toggleFeatureDetail(updateId, featureIndex) {
      // Tüm genişletilmiş özellikleri sıfırla - her güncelleme için boş nesne oluştur
      const newExpandedFeatures = {};

      // Seçilen güncelleme için boş bir nesne oluştur
      newExpandedFeatures[updateId] = {};

      // Eğer zaten açıksa kapat, kapalıysa aç (diğer tüm açık olanlar zaten kapanacak)
      const currentState = this.isFeatureExpanded(updateId, featureIndex);

      if (!currentState) {
        // Sadece tıklanan özelliği aç
        newExpandedFeatures[updateId][featureIndex] = true;
      }

      // Tamamen yeni bir nesne atayarak tüm reaktiviteyi yenile
      this.expandedFeatures = newExpandedFeatures;
    },
    isFeatureExpanded(updateId, featureIndex) {
      // Eğer bu güncelleme için bir kayıt yoksa veya özellik açılmamışsa false döndür
      return this.expandedFeatures[updateId] &&
             this.expandedFeatures[updateId][featureIndex] === true;
    },
    getFeatureDetail(updateId, featureIndex) {
      // İlgili güncellemeyi bul
      const update = this.updates.find(u => u.id === updateId);

      // Eğer güncelleme ve detay bilgisi varsa döndür
      if (update && update.featureDetails && update.featureDetails[featureIndex]) {
        return update.featureDetails[featureIndex];
      }

      // Yoksa varsayılan mesaj döndür
      return "Bu özellik için detaylı açıklama bulunmamaktadır.";
    }
  },
};
</script>

<style>
/* Transition animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

/* Feature detay animasyonu */
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 500px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
