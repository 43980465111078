var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"100vh !important"},attrs:{"max-zoom":14,"center":_vm.center,"zoom":_vm.zoom,"map-type-id":_vm.$store.state.mapView ? 'hybrid' : 'roadmap',"options":{
    minZoom: 3,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
  }},on:{"click":function($event){return _vm.$emit('mapClick')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }