<template>
  <div class="w-full py-2">
    <!-- Filolar Listesi - Her biri kendi alt menüsüyle -->
    <div class="space-y-1.5">
      <div v-for="item in List" :key="item.fleetId" class="w-full">
        <!-- Filo Başlığı -->
        <div
          @click="getDetail(item)"
          class="group rounded-lg transition-all duration-150 relative overflow-hidden"
          :class="selected === item ? 'bg-gradient-to-r from-red-50 to-white' : ''"
        >
          <!-- Sol kenarda renkli gösterge -->
          <div 
            v-if="selected === item" 
            class="absolute left-0 top-0 h-full w-1 bg-red-500 rounded-l"
          ></div>
          
          <div class="px-3 py-2.5 flex items-center">
            <!-- İkon ve başlık içeriği -->
            <div class="flex items-center w-full select-none cursor-pointer">
              <div
                v-if="item.fleetId !== null"
                class="mr-2.5 transition-transform duration-200 text-gray-400 w-4 text-center"
                :class="{'transform rotate-90': item === selected && openSub}"
              >
                <i class="fas fa-chevron-right text-xs"></i>
              </div>
              <span 
                class="text-xs"
                :class="selected === item ? 'font-medium text-gray-800' : 'text-gray-600 group-hover:text-gray-800'"
              >{{ item.fleetName!='All' ? item.fleetName : 'Hepsi' }}</span>
            </div>
          </div>
        </div>

        <!-- Bu filo seçili ve açık ise alt grupları göster -->
        <div 
          v-if="openSub && selected === item && item.fleetId !== null"
          class="mt-1.5 ml-4 space-y-1"
        >
          <!-- Yükleniyor Durumu -->
          <div v-if="load" class="flex items-center p-2 text-gray-400">
            <svg class="animate-spin h-3 w-3 mr-2" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="text-xs">Yükleniyor</span>
          </div>

          <!-- Gruplar Listesi -->
          <div v-else>
            <div v-for="group in parentGroups" :key="group.groupId" class="mb-1.5">
              <!-- Ana Grup -->
              <div
                @click="selectGroup(group)"
                class="group rounded-lg transition-all duration-150 relative overflow-hidden"
                :class="isGroupSelected(group) ? 'bg-gradient-to-r from-red-50 to-white' : ''"
              >
                <!-- Sol kenarda renkli gösterge -->
                <div 
                  v-if="isGroupSelected(group)" 
                  class="absolute left-0 top-0 h-full w-1 bg-red-500 rounded-l"
                ></div>
                
                <div class="px-3 py-2 flex items-center select-none cursor-pointer">
                  <div
                    v-if="hasSubGroups(group)"
                    @click.stop="toggleGroup(group)"
                    class="mr-2.5 transition-transform duration-200 text-gray-400 w-4 text-center"
                    :class="{'transform rotate-90': expandedGroups[group.groupId]}"
                  >
                    <i class="fas fa-chevron-right text-xs"></i>
                  </div>
                  <span 
                    class="text-xs"
                    :class="isGroupSelected(group) ? 'font-medium text-gray-800' : 'text-gray-600 group-hover:text-gray-800'"
                  >{{ group.groupName  }}</span>
                </div>
              </div>

              <!-- Alt Gruplar - Bu grup genişletildiyse göster -->
              <div 
                v-if="expandedGroups[group.groupId]" 
                class="ml-5 mt-1 space-y-1 pl-1 border-l border-gray-100 select-none cursor-pointer"
              >
                <div
                  v-for="subGroup in getSubGroups(group)"
                  :key="subGroup.groupId"
                  @click="selectGroup(subGroup)"
                  class="group rounded-lg transition-all duration-150 relative overflow-hidden"
                  :class="isGroupSelected(subGroup) ? 'bg-gradient-to-r from-red-50 to-white' : ''"
                >
                  <!-- Sol kenarda renkli gösterge -->
                  <div 
                    v-if="isGroupSelected(subGroup)" 
                    class="absolute left-0 top-0 h-full w-1 bg-red-500 rounded-l"
                  ></div>
                  
                  <div class="pl-3 pr-3 py-2">
                    <span 
                      class="text-xs"
                      :class="isGroupSelected(subGroup) ? 'font-medium text-gray-800' : 'text-gray-600 group-hover:text-gray-800'"
                    >{{ subGroup.groupName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Boş Durum -->
    <div v-if="List.length <= 0 && !load" class="flex flex-col items-center justify-center p-6">
      <div class="text-gray-400 mb-3">
        <i class="fas fa-folder-open text-2xl"></i>
      </div>
      <p class="text-xs text-gray-500 mb-3">Herhangi bir grup bilgisi bulunamadı</p>
      <button
        class="px-4 py-1.5 bg-red-500 text-white text-xs rounded-full hover:bg-red-600 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        @click="getAll"
      >
        Yeniden Dene
      </button>
    </div>

    <!-- Yükleniyor Durumu (Ana) -->
    <div
      v-else-if="List.length <= 0 && load"
      class="flex items-center justify-center p-8"
    >
      <svg class="animate-spin h-5 w-5 text-gray-400 mr-2" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span class="text-xs text-gray-500">Yükleniyor</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { tracking } from "@/networking/urlmanager";

export default {
  name: "GroupPanel",
  data() {
    return {
      List: [],
      subList: [],
      load: false,
      openSub: false,
      selectedGroup: null,
      selected: null,
      expandedGroups: {},
    };
  },
  computed: {
    parentGroups() {
      return this.subList.filter((group) => !group.parentGroupId);
    },
  },
  methods: {
    async getAll() {
      this.load = true;
      try {
        const response = await axios.get(tracking.getFleetAll, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        });

        this.List = [
          { fleetId: null, fleetName: "All" },
          ...response.data.data
        ];
      } catch (err) {
        console.error("Filolar yüklenirken hata:", err);
      } finally {
        this.load = false;
      }
    },

    async getDetail(item) {
      if (this.selected === item) {
        // Aynı öğeye tıklandığında sadece aç/kapa yap
        this.openSub = !this.openSub;
        if (!this.openSub) {
          this.selectedGroup = null;
        }
      } else {
        // Yeni bir öğe seçildiğinde
        this.selected = item;
        this.selectedGroup = null;
        
        if (item.fleetId === null) {
          // "All" seçildiğinde alt grupları gösterme
          this.openSub = false;
        } else {
          // Normal filo seçildiğinde alt grupları yükle
          this.openSub = true;
          this.load = true;
          this.subList = [];
          
          try {
            const response = await axios.get(
              tracking.getGroupAll + "?fleetId=" + item.fleetId,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.userData.token,
                },
              }
            );
            this.subList = response.data.data;
          } catch (err) {
            console.error("Gruplar yüklenirken hata:", err);
          } finally {
            this.load = false;
          }
        }
      }
      
      // Her durumda seçilen filo değişikliğini bildir
      this.$emit("changeFleet", item?.fleetId);
    },

    selectGroup(group) {
      this.selectedGroup = group;

      // Eğer bir üst grup seçilirse, alt gruplarını da dahil et
      if (this.hasSubGroups(group)) {
        const subGroupIds = this.getSubGroups(group).map((g) => g.groupId);
        subGroupIds.push(group.groupId);
        this.$emit("changeGroup", subGroupIds);
      } else {
        this.$emit("changeGroup", group.groupId);
      }
    },

    toggleGroup(group) {
      this.$set(
        this.expandedGroups,
        group.groupId,
        !this.expandedGroups[group.groupId]
      );
    },

    hasSubGroups(group) {
      return this.subList.some((g) => g.parentGroupId === group.groupId);
    },

    getSubGroups(group) {
      return this.subList.filter((g) => g.parentGroupId === group.groupId);
    },

    isGroupSelected(group) {
      return this.selectedGroup?.groupId === group.groupId;
    },
  },
  created() {
    this.getAll();
  },
};
</script>