var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapCluster',{attrs:{"styles":_vm.clusterStyles},on:{"click":_vm.handleClusterClick}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"title":m.title,"label":{
      text: m.title,
      color: 'white',
      className:
        'absolute bottom-4 -right-8 text-xs px-2 text-xs font-bold bg-green-700 rounded tracking-widest',
      fontSize: '8.5px',
    },"position":m.position,"icon":m.icon,"rotation":"30"}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }