<template>
  <div class="bg-white rounded-lg shadow-sm p-4">
    <!-- Yükleniyor göstergesi -->
    <loading-spinner v-if="load" />

    <div v-else>
      <!-- Durum geçmişi modali -->
      <StateHistory
        :typeId="getShipmentTypeId(item)"
        ref="stateModal"
        :Detail="detail"
      />

      <!-- Teslimat bilgileri -->
      <section class="divide-y divide-gray-100">
        <!-- Sevkiyat türü bilgisi -->
        <detail-item 
          title="Yük Türü" 
          :value="formatShipmentType(item)" 
          icon="fa-truck-loading"
        />
        
        <!-- Gemi bilgileri (varsa) -->
        <detail-item 
          v-if="item.isShipOperation"
          title="Sevkiyat Bilgileri" 
          :value="`${item.shipId} - ${item.shipName}`"
          icon="fa-ship"
        />
        
        <!-- Sipariş numarası -->
        <detail-item 
          title="Sipariş Numarası" 
          :value="item.orderNo"
          icon="fa-clipboard-list"
          :highlight="true"
        />
        
        <!-- Yükleme yeri -->
        <address-item
          title="Yükleme Yeri"
          :address="item.outpointAdress"
          icon="fa-warehouse"
        />
        
        <!-- Boşaltma yeri -->
        <address-item
          title="Boşaltma Yeri"
          :address="item.targetAdress"
          icon="fa-map-marker-alt"
        />
        
        <!-- Müşteri bilgisi -->
        <detail-item 
          title="Müşteri" 
          :value="detail.customer"
          icon="fa-user"
        />
        
        <!-- Ürün bilgisi -->
        <detail-item 
          title="Ürün" 
          :value="detail.product"
          icon="fa-box"
        />
        
        <!-- Durum bilgisi (tıklanabilir) -->
        <status-item
          :status="detail.stateName"
          :state-id="detail.state"
          @click="showStatusHistory"
        />
        
        <!-- Yetişme durumu ve zaman bilgileri (belirli durumlarda) -->
        <arrival-status
          v-if="detail.state != 4 && detail.state < 6"
          :state="detail.state"
          :target-date="detail.targetDate"
          :estimated-date="calculatePosition.totalMinute"
          :will-arrive-on-time="diffStatus"
        />
      </section>
    </div>
  </div>
</template>

<script>
// NPM paketleri
import moment from "moment";
import axios from "axios";

// API ve yardımcı fonksiyonlar
import { routeEngine } from "@/networking/routeEngine";
import { decode } from "@/utils/encoder";

// Bileşenler
import StateHistory from "../../../../../../operations/planning/components/modals/vehicleStateModel/index.vue";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import DetailItem from "./components/detailItem.vue";
import AddressItem from "./components/AddressItem.vue";
import StatusItem from "./components/StatusItem.vue";
import ArrivalStatus from "./components/ArrivalStatus.vue";

export default {
  name: "DeliveryDetails",
  components: {
    StateHistory,
    LoadingSpinner,
    DetailItem,
    AddressItem,
    StatusItem,
    ArrivalStatus
  },
  props: ["item"],
  data() {
    return {
      detail: {
        customer: "",
        product: "",
        driverName: "",
        stateName: "",
        dorsePlaque: "",
        state: "",
      },
      calculatePosition: {},
      diffStatus: false,
      load: false
    };
  },
  created() {
    this.detail = this.item.planning;
    this.getRoute();
  },
  methods: {
    getShipmentTypeId(item) {
      return item?.isBulkOperation ? 2 : 1;
    },
    
    formatShipmentType(item) {
      if (item?.isBulkOperation == 1) {
        if (item?.isShipOperation) {
          if (item?.isPirateDriver == 1) {
            return "Tahliye";
          } else {
            return item?.shipTypeId == 1 ? "Gemi Sevkiyatı" : "Depo Sevkiyatı";
          }
        } else {
          return "Toplu Sevkiyat";
        }
      } else {
        return "Spot Sevkiyat";
      }
    },
    
    getRoute() {
      this.load = true;
      
      const destination = this.detail.state < 5
        ? {
            lat: parseFloat(this.item.outpointLat),
            lng: parseFloat(this.item.outpointLng),
          }
        : {
            lat: parseFloat(this.item.targettLat),
            lng: parseFloat(this.item.targettLng),
          };
      
      const requestData = {
        from: this.item.position,
        avoidPoints: [],
        vehicleTypeId: 5,
        to: destination,
        waypoints: [],
        isToll: 1,
        routeType: "fast",
        shippedHazardousGoods: 1,
        isShip: 1,
        isAsphalt: 1,
        fuelRatio: 35,
        isPolyline: 1,
        isApi: 1,
      };
      
      axios
        .post(routeEngine.generate, requestData, {
          headers: {
            Authorization: "Bareer " + this.$store.state.routeEngineToken,
          },
        })
        .then((res) => {
          const detail = res.data.data;
          this.calculatePosition = detail;
          this.calculatePosition.totalMinute = moment().add(
            detail.totalMinutes,
            "minutes"
          );

          // Hedef tarihine yetişebilecek mi kontrolü
          const duration = moment.duration(
            moment(this.detail.targetDate).diff(
              this.calculatePosition.totalMinute
            )
          );
          this.diffStatus = duration.asMinutes() > 0;

          // Rota ve hedef noktası bilgilerini üst bileşene ileten event
          this.$emit("selectedActiveDetail", {
            state: this.detail.state,
            targetPoint: destination,
            polyline: decode(detail.routePolyline).polyline,
            customer: this.detail.customer,
          });
        })
        .catch((err) => {
          console.error("Rota bilgisi alınırken hata:", err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    
    showStatusHistory() {
      this.$modal.show("vehicleState-modal");
    }
  }
};
</script>