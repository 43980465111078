const { isVehicleSuitableForOrderDetail } = require("./isVehicleSuitableForOrderDetail");


/**
 * Bir aracın belirli bir sipariş için uygun olup olmadığını kontrol eder.
 * 
 * @param {Object} order - Sipariş bilgileri.
 * @param {Object} vehicle - Araç bilgileri.
 * @param {Array} products - Ürün listesi.
 * @param {boolean} [isBulk=false] - Ürünün toplu (bulk) olup olmadığını belirten bayrak.
 * @param {boolean} [skipTheTonnage=false] - Tonaj kontrolünün atlanıp atlanmayacağını belirten bayrak.
 * @param {boolean} [isClose=false] - Aracın siparişe yakın olup olmadığını belirten bayrak.
 * @returns {boolean} - Araç sipariş için uygunsa `true`, değilse `false` döndürür.
 */
function isVehicleSuitableForOrder(
  order,
  vehicle,
  products,
  isBulk = false,
  skipTheTonnage = false,
  isClose = false
) {
  const {
    isTonnageSuitable,
    isVehicleAvailable,
    dorseAvailable,
    isADRCompatible,
    isSrcAvailable,
    isInspectionAvailable,
    isAdrAvailable,
    haveTypeAvailable,
  } = isVehicleSuitableForOrderDetail(
    order,
    vehicle,
    products,
    isBulk,
    skipTheTonnage,
    isClose
  );
  return (
    isTonnageSuitable &&
    isVehicleAvailable &&
    dorseAvailable &&
    isADRCompatible &&
    isSrcAvailable &&
    isInspectionAvailable &&
    isAdrAvailable &&
    haveTypeAvailable
  );
}


module.exports={
    isVehicleSuitableForOrder
}