<template>
  <modal
    name="add-fleet-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Headers -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Yeni Filo Ekle</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-fleet-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Headers -->

    <!-- Body -->
    <form
      @submit.prevent="save"
      ref="formItem"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full md:mt-4">
        <diffInput
          title="Filo Adı"
          v-model="fleetName"
          type="text"
          required="true"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>

    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// networking
import axios from "axios";
import { fleetConfiguration } from "../../../../networking/urlmanager";
export default {
  name: "add-fleet-modal",
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      fleetName: "",
      load: false,
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post(
          fleetConfiguration.createFleet,
          {
            fleetName: this.fleetName,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.fullname = "";
            this.tcno = "";
            this.password = "";
            this.phone = "";
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("add-fleet-modal");
            this.fleetName="";
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
};
</script>
