<template>
  <div class="relative" @mouseover="showModal()" @mouseleave="hideModal()">
    <button
      class="flex items-center space-x-2 px-3 py-1 rounded-md hover:bg-gray-50 transition-all duration-200"
      :class="{'bg-gray-50': show}"
    >
      <!-- Avatar bölümü -->
      <div class="h-8 w-8 mr-4 rounded-full bg-gray-100 flex items-center justify-center text-gray-600">
        <span v-if="!userAvatar">{{ getInitials($store.state.userData.fullname) }}</span>
        <img v-else :src="userAvatar" alt="Avatar" class="h-full w-full object-cover rounded-full">
      </div>
      
      <!-- Kullanıcı bilgisi -->
      <div class="text-left">
        <p class="text-sm font-medium text-gray-800 leading-tight">
          {{ $store.state.userData.fullname || 'Kullanıcı' }}
        </p>
        <p class="text-xs text-gray-500 leading-tight">
          {{ $store.state.userData.position || "Pozisyon belirtilmemiş" }}
        </p>
      </div>
      
      <!-- Ok simgesi -->
      <svg
        class="h-4 w-4 text-gray-400 transition-transform duration-200"
        :class="{'rotate-180': show}"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <!-- Dropdown menu -->
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="show"
        class="absolute right-0 mt-2 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5"
        style="z-index: 10; width: 100%"
      >
        <!-- Profil linki -->
        <router-link
          to="/profile"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
        >
          <i class="fas fa-user text-gray-400 w-5"></i>
          <span class="ml-2">Profil</span>
        </router-link>
        
        <!-- Ayarlar linki -->
        <!-- <router-link
          to="/settings"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
        >
          <i class="fas fa-cog text-gray-400 w-5"></i>
          <span class="ml-2">Ayarlar</span>
        </router-link> -->
        
        <!-- Ayırıcı çizgi -->
        <div class="border-t border-gray-100 my-1"></div>
        
        <!-- Çıkış butonu -->
        <button
          @click="signOut"
          class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
        >
          <i class="fas fa-sign-out-alt text-gray-400 w-5"></i>
          <span class="ml-2">Çıkış Yap</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "profile-panel",
  data() {
    return {
      show: false,
      userAvatar: null
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    },
    signOut() {
      // Mevcut çıkış işlemi
      this.$store.commit('signOut');
      this.$router.push('/');
    },
    getInitials(name) {
      if (!name) return "";
      return name
        .split(" ")
        .map(n => n[0])
        .join("")
        .toUpperCase()
        .substring(0, 2);
    }
  }
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
</style>