<template>
  <div class="mt-3">
    <h4 class="font-semibold mb-2">
      Bölgedeki Araçlar ({{ vehicles.length }})
    </h4>
    <div class="max-h-48 overflow-y-auto">
      <div
        v-for="(vehicle, vIndex) in vehicles"
        :key="'zone-vehicle-' + vIndex"
        class="p-2 mb-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
        @click="$emit('vehicleSelected', vehicle)"
      >
        <div class="flex items-center">
          <div
            class="w-3 h-3 rounded-full mr-2"
            :class="getVehicleStatusClass(vehicle)"
          ></div>
          <span>{{ vehicle.title || vehicle.vehicle }}</span>
          <span class="ml-auto text-xs text-gray-500">
            {{ vehicle.speed }} km/s
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "VehicleList",
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /**
     * Araç durumuna göre CSS sınıfını belirler
     */
    getVehicleStatusClass(vehicle) {
      if (vehicle.isDeviation) {
        return "bg-red-600"; // Sapma durumunda kırmızı
      } else if (vehicle.speed === 0) {
        return "bg-yellow-500"; // Duruyorsa sarı
      }
      return "bg-green-500"; // Hareket halindeyse yeşil
    },
  },
};
</script>