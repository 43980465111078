import { render, staticRenderFns } from "./reallocation.needed.modal.vue?vue&type=template&id=20adc89d"
import script from "./reallocation.needed.modal.vue?vue&type=script&lang=js"
export * from "./reallocation.needed.modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports