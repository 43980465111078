<template>
  <div
    class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4 h-96"

  >
    <table class="min-w-full divide-y divide-gray-200">
      <thead
        class="bg-white border-black border-dotted border-b-2 border-opacity-20"
      >
        <tr>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 50px !important"
          >
            #
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20 z-50"
            style="min-width: 280px !important"
          >
            Araç
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 100px !important"
          >
            Sürücü
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 100px !important"
          >
            Dorse
          </th>
           <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 100px !important"
          >
            #
          </th>
          
        </tr>
      </thead>
      <tbody
       
        class="divide-y-2 divide-gray-500"
        style="background-color: #3a3a3a"
      >
        <tr
         
          v-for="(item, index) in List"
          :key="index"
        >
          <td class="table-td border-r border-opacity-20">
            <span># {{ index + 1 }} </span>
          </td>
          <td
            class="table-td border-r border-opacity-20 flex justify-between items-center"
          >
            <div class="w-1/2 flex items-center">
              <p class="w-1/2">
                {{ item.vehicle }}
              </p>
              <!-- <p class="w-1/2">{{ item.capacity / 1000 }} TON</p> -->
            </div>
            <div class="w-1/2 flex justify-between items-center">
              <!-- <img
                :title="item.romorkType"
                v-if="item.capacity / 1000 <= 52 && item.capacity / 1000 > 20"
                class="w-12 h-8"
                src="@/assets/vehicles/fuel-truck.png"
                alt=""
              />
              <img
                :title="item.romorkType"
                v-if="item.capacity / 1000 <= 20"
                class="w-10 h-8"
                src="@/assets/vehicles/tank-truck.png"
                alt=""
              />-->
              <span
                class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
                v-if="item.isCustom == 1"
              > 
                Korsan Atama
              </span>
            </div>
          </td>
          <td
          class="table-td border-r border-opacity-20 items-center"
          >{{item.driverName}}</td>
          <td
          class="table-td border-r border-opacity-20  items-center"
          >{{item.dorse}}</td>
          <td class="flex justify-center items-center">
            <button
              type="button"
              class="process-btn"
              @click="deleteItem(item, index)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
         <!-- Liste boşsa -->
  <tr v-if="List.length == 0">
    <td class="text-center py-4 text-white " colspan="5">
      Henüz bir araç eklemediniz.
    </td>
  </tr>
      </tbody>
      <!-- <tableLoader :load="load || isLoading" length="1" colspan="8" /> -->
    </table>
  </div>
</template>

<script>


// Global Components
import vehicleModal from "@/components/modals/vehicle.detail.modal/index.vue";
import tableLoader from "@/components/general/table-loader.vue";
// import reloadVehicleList from "@/components/general/reload-vehicle-list.vue";



export default {
  name: "priated-vehicles",
  props: [
   "List"
  ],
  components: {
    tableLoader,
    vehicleModal,
  },
  data() {
    return {
      load:false,
      height:null
    };
  },

  methods: {
    deleteItem(item, index) {
      this.$emit("deleteVehicle", item, index);
    },

  },
  computed: {
   
  },
  watch: {

  },
};
</script>
