<template>
  <div class="py-3">
    <div class="flex items-start">
      <!-- İkon (varsa) -->
      <div v-if="icon" class="text-gray-400 mr-3 w-5 text-center">
        <i :class="`fas ${icon}`"></i>
      </div>

      <div class="flex-1">
        <!-- Başlık -->
        <div class="text-xs text-gray-500">{{ title }}</div>

        <!-- Adres (kısaltılmış veya tam) -->
        <div class="font-semibold mt-1 text-gray-800 text-sm">
          <template v-if="!expanded">
            {{ truncatedAddress }}
          </template>
          <template v-else>
            {{ address }}
          </template>
        </div>

        <!-- Adres uzunsa göster/gizle butonu -->
        <button
          v-if="isLongAddress"
          @click="expanded = !expanded"
          class="text-xs text-red-500 mt-1 hover:underline focus:outline-none"
        >
          {{ expanded ? "Daha az göster" : "Daha çok göster" }}
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "AddressItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    isLongAddress() {
      return this.address && this.address.length > 20;
    },
    truncatedAddress() {
      if (!this.address) return "";

      // Son iki kelimeyi almaya çalışma
      const lastSpaceIndex = this.address.lastIndexOf(" ");
      if (lastSpaceIndex === -1) return this.address;

      const secondLastSpaceIndex = this.address.lastIndexOf(
        " ",
        lastSpaceIndex - 1
      );
      if (secondLastSpaceIndex === -1) return this.address;

      return this.address.substring(secondLastSpaceIndex + 1);
    },
  },
};
</script>