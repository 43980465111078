<template>
  <GmapCluster @click="handleClusterClick">
    <!-- Normal Araçlar -->
    <GmapMarker
      v-for="m in nonDeviationMarkers"
      :key="m.title"
      :title="m.title"
      @click="() => $emit('markerClick', m, 'vehicle')"
      :label="{
        text: m.title,
        color: 'white',
        className:
          'absolute bottom-4 -right-8 text-xs px-2 font-bold bg-blue-700 rounded tracking-widest',
        fontSize: '8.5px',
      }"
      :position="m.position"
      :icon="m.icon"
      rotation="30"
    >
    </GmapMarker>

    <!-- Sapma Durumundaki Araçlar -->
    <GmapMarker
      v-for="m in deviationMarkers"
      :key="m.title"
      :title="m.title"
      @click="() => $emit('markerClick', m, 'vehicle')"
      :label="{
        text: m.title,
        color: 'white',
        className:
          'absolute bottom-4 -right-8 text-xs px-2 font-bold bg-red-600 rounded tracking-widest animate-pulse border-2 border-white',
        fontSize: '8.5px',
      }"
      :position="m.position"
      :icon="m.icon"
      rotation="30"
    >
    </GmapMarker>
  </GmapCluster>
</template>
  
  <script>
export default {
  name: "VehicleMarkers",
  props: {
    markers: {
      type: Array,
      required: true,
    },
    selectedGroup: {
      type: [String, Number, Array],
      default: 0,
    },
    selectedFleet: {
      type: [String, Number],
      default: 0,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["markerClick", "clusterClick"],
  computed: {
    /**
     * Sapma durumundaki araçları filtreleme
     */
    deviationMarkers() {
      return this.markers.filter((m) => m.isDeviation);
    },

    /**
     * Normal araçları filtreleme
     */
    nonDeviationMarkers() {
      return this.markers.filter((m) => !m.isDeviation);
    },
  },
  methods: {
    /**
     * Kümeleme tıklama olayını işleme
     */
    handleClusterClick(cluster) {
      this.$emit("clusterClick", cluster, "vehicle");
    },
  },
};
</script>