// orderTools/productUtils.js
// Ürün ile ilgili yardımcı işlevler

import similarity from 'similarity';

/**
 * Check if a product is valid in the system
 * @param {string} productType - Product type to check
 * @param {Array} products - List of products
 * @returns {boolean} - Whether the product is valid
 */
export const isProductValid = (productType, products) => {
  const findItem = products.find(
    (item) =>
      similarity(
        item?.name?.toString().toLowerCase().replace(/\s/g, ''),
        productType?.toString().toLowerCase().replace(/\s/g, '')
      ) > 0.95
  );

  if (!findItem) return false;

  if (Array.isArray(findItem.dorseTypes)) {
    return findItem.dorseTypes.length > 0;
  }

  return true;
};

/**
 * Check if a product exists in the products array
 * @param {string} productType - Product name to check
 * @param {Array} products - Products array
 * @returns {boolean} - Whether the product exists
 */
export const isProductExist = (productType, products) => {
  return products.some(item => 
    similarity(
      item?.name?.toString().toLowerCase().replace(/\s/g, ''),
      productType?.toString().toLowerCase().replace(/\s/g, '')
    ) > 0.95
  );
};

/**
 * Find product ID by similarity
 * @param {string} productType - Product name
 * @param {Array} products - Products array
 * @returns {string|null} - Product ID or null
 */
export const findProductIdBySimilarity = (productType, products) => {
  const product = products.find(item => 
    similarity(
      item?.name?.toString().toLowerCase().replace(/\s/g, ''),
      productType?.toString().toLowerCase().replace(/\s/g, '')
    ) > 0.95
  );
  
  return product ? product.id : null;
};

/**
 * Ürünün tehlikeli maddeler listesinde olup olmadığını kontrol eder (ADR gerektirir)
 * @param {string} productType - Ürün adı
 * @param {Array} products - Ürünler dizisi
 * @returns {boolean} - Ürünün ADR gerektirip gerektirmediği
 */
export const isProductRequiresADR = (productType, products) => {
  const product = products.find(item => 
    similarity(
      item?.name?.toString().toLowerCase().replace(/\s/g, ''),
      productType?.toString().toLowerCase().replace(/\s/g, '')
    ) > 0.95
  );
  
  // typeId 2, ADR gerektiren tehlikeli maddeleri temsil eder
  return product && product.typeId === 2;
};

/**
 * Verilen ürün için uygun dorse tiplerini bulur
 * @param {string} productType - Ürün adı
 * @param {Array} products - Ürünler dizisi
 * @returns {Array} - Uygun dorse tipleri listesi
 */
export const getCompatibleDorseTypes = (productType, products) => {
  const product = products.find(item => 
    similarity(
      item?.name?.toString().toLowerCase().replace(/\s/g, ''),
      productType?.toString().toLowerCase().replace(/\s/g, '')
    ) > 0.95
  );
  
  if (!product || !Array.isArray(product.dorseTypes)) {
    return [];
  }
  
  return product.dorseTypes;
};