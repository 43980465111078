<template>
  <modal
    name="pirate-vehicle-detail-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
  
   <div 
   >
    <div class="flex p-5 justify-between">
        <h1 class="font-semibold text-2xl">Araç Detayı</h1>
         <button
            type="button"
            class="px-3 text-lg"
            @click="$modal.hide('pirate-vehicle-detail-modal')"
        >
            <i class="fas fa-times"></i>
        </button>
    </div>
     <!-- KORSAN BİLGİLENDİRME -->
    <div
        class="bg-gray-100 dark:bg-gray-800 p-3 rounded-lg m-4 border-l-4 border-blue w-[300px]"
      >
        <h5 class="flex items-center">
          <i class="fas fa-info-circle mr-2 text-yellow-500"></i>
           Bu Sipariş Korsan araç ile planlanacaktır.
        </h5>
        
      </div>
      <div class=" m-4 p-4 rounded-lg  bg-gray-50 shadow">
        <h1 class="font-semibold text-xl mb-4">Sürücü Bilgileri</h1>
        <recommendation-detail
          label="Sürücü İsmi"
          :value="`${detail?.driverName}`"
        />
        <recommendation-detail
          label="Sürücü Telefonu"
          :value="`${detail?.driverPhone}`"
        />
        <recommendation-detail
          label="Araç Plakası"
          :value="`${detail?.vehicleName}`"
        />
        <recommendation-detail
          label="Dorse Plakası"
          :value="`${detail?.dorse}`"
        />
    </div> 

      
   </div>
  </modal>
</template>

<script>
//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";

// local components
import RecommendationDetail from "./recommendationDetail.vue";
export default {
  name: "vehicle-modal",
  props: ["detail"],
  components: {
    RecommendationDetail,
    asyncBtn,
  },
  data() {
    return {
      customer: "",
      hakedisKilometer: "",
    };
  },
};
</script>
