

/**
 * İki değerin ağırlıklı ortalamasını hesaplar.
 *
 * @param {number} value1 - Birinci değer.
 * @param {number} weight1 - Birinci değerin ağırlığı.
 * @param {number} value2 - İkinci değer.
 * @param {number} weight2 - İkinci değerin ağırlığı.
 * @returns {number} - Ağırlıklı ortalama sonucu.
 */
function weightedAverage(value1, weight1, value2, weight2) {
    const totalWeight = weight1 + weight2;
    if (totalWeight === 0) return 0; // Sıfıra bölme hatasını önlemek için
    return ((value1 * weight1) + (value2 * weight2)) / totalWeight;
  }

  module.exports={
    weightedAverage
  }