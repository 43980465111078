<template>
  <modal
    name="edit-branch-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="14" :rowId="detail.id" />

    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex justify-center items-center"
    >
      <h4 class="inline-block w-10/12">NOKTA DÜZENLE</h4>
      <div class="w-2/12 flex items-center justify-center">
        <div class="w-6/12">
          <div class="w-6/12">
            <logBtn />
          </div>
        </div>
        <div class="w-6/12">
          <button class="p-2 w-1/12" @click="$modal.hide('edit-branch-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Body -->
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Adı"
          v-model="name"
          maxlength="1024"
          minlength="2"
          required
          type="Text"
        />
      </div>

      <div class="w-full my-5">
        <fleetSelect
          v-model="fleets"
          :selectedList="fleetsList"
          @update:fleets="updateFleets"
        />
      </div>

      <div class="mt-4">
        <GmapItem
          placeHolder="Konum | Adres"
          height="200px"
          @change="(val) => (position = val)"
          :lat="position.lat"
          :lng="position.lng"
          :draggable="true"
          :defaultAdress="position.adress"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";
import fleetSelect from "@/components/general/fleet.select.vue";
import axios from "axios";
import { branches } from "@/networking/urlmanager";

export default {
  name: "edit-branch-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    GmapItem,
    logModule,
    logBtn,
    fleetSelect,
  },
  data() {
    return {
      name: "",
      fleets: [],
      fleetsList: [],
      position: {
        lat: 0,
        lng: 0,
        adress: "",
      },
      load: false,
    };
  },
  methods: {
    updateFleets(newFleets) {
      this.fleetsList = this.fleetsList.map((fleet) => {
        const updatedFleet = newFleets.find((f) => f.fleetId === fleet.fleetId);
        return updatedFleet || fleet;
      });

      // Add any new fleets that don't exist in fleetsList
      newFleets.forEach((fleet) => {
        if (!this.fleetsList.some((f) => f.fleetId === fleet.fleetId)) {
          this.fleetsList.push(fleet);
        }
      });

      // Filter out any fleets with status 3 that weren't in the original list
      this.fleetsList = this.fleetsList.filter(
        (fleet) =>
          fleet.status !== 3 ||
          this.detail?.fleets?.some((f) => f.fleetId === fleet.fleetId)
      );
    },

    async save() {
      if (this.isEmptyText(this.name)) {
        await this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen Şube ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      if (!this.position?.lat) {
        await this.swalBox(
          "warning",
          "UYARI!",
          "Lütfen ilk önce bir adres seçin"
        );
        return;
      }

      const newFleetList = this.fleetsList.map((item) => ({
        rowId: item.rowId,
        status: item.status,
        fleetId: item.fleetId,
        fleetName: item.fleetName,
      }));

      try {
        this.load = true;
        await axios.post(
          branches.edit,
          {
            fleets: newFleetList,
            name: this.name,
            address: this.position.adress,
            lat: this.position.lat,
            lng: this.position.lng,
            rowId: this.detail?.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );

        await this.swalBox(
          "success",
          this.$t("general.successTitle"),
          "",
          false,
          this.$t("general.OkayTitle")
        );

        this.$emit("refresh", true);
        this.$modal.hide("edit-branch-modal");
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },

    getDetail() {
      setTimeout(() => {
        this.name = this.detail?.name;
        this.position = {
          adress: this.detail?.address,
          lat: Number(this.detail?.latitude),
          lng: Number(this.detail?.longitude),
        };

        if (this.detail?.fleets && this.detail.fleets[0] !== null) {
          // Set both fleetsList and fleets
          this.fleetsList = this.detail.fleets.map((fleet) => ({
            ...fleet,
            status: 1, // Status 1 for existing fleets
          }));

          // Also set the fleets array for v-model binding
          this.fleets = this.fleetsList.map((fleet) => ({
            fleetId: fleet.fleetId,
            fleetName: fleet.fleetName,
          }));
        }else{
          this.fleetsList = [];
          this.fleets = [];
        }
      }, 50);
    },
  },
};
</script>
