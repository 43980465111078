<template>
  <div class="absolute top-20 left-64 z-10 ml-4">
    <div
      class="flex items-center bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
    >
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        type="text"
        placeholder="Bölge adı ara..."
        class="px-4 py-2 focus:outline-none w-64 dark:bg-gray-800 dark:text-white"
        @keyup.enter="$emit('search')"
      />
      <button
        @click="$emit('search')"
        class="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700"
      >
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "ZoneSearch",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue", "search"],
};
</script>