// services/mapHelpers.js
import moment from "moment";
import { getRotationIcon } from "@/utils/rotatingIconGenerator";

/**
 * Harita ile ilgili yardımcı fonksiyonlar
 */
export const mapHelpers = {
  /**
   * Araç verisini harita işaretçisine dönüştür
   * @param {Object} vehicle - Araç verisi
   * @returns {Object} - Harita işaretçisi formatında araç
   */
  mapVehicleToMarker(vehicle) {
    const iconUrl =
      vehicle.speed === 0
        ? require("@/assets/marker/2.png")
        : getRotationIcon(vehicle.direction * 60);
    
    return {
      ...vehicle,
      id: vehicle.id,
      title: vehicle.vehicle,
      region: vehicle.region,
      position: {
        lat: parseFloat(vehicle.latitude),
        lng: parseFloat(vehicle.longitude),
      },
      icon: { url: iconUrl },
      takoDate: vehicle.driverDetail
        ? moment
            .utc(vehicle.driverDetail.totalRunTime - 45 * 60000)
            .format("HH:mm:ss")
        : 0,
    };
  },

  /**
   * Fabrika/müşteri verisini harita işaretçisine dönüştür
   * @param {Object} vectorItem - Fabrika/müşteri verisi
   * @returns {Object} - Harita işaretçisi formatında fabrika/müşteri
   */
  mapVectorToMarker(vectorItem) {
    return {
      ...vectorItem,
      title: vectorItem.name,
      position: {
        lat: vectorItem.latitude,
        lng: vectorItem.longtitude,
      },
      icon: {
        url: require("@/assets/marker/factoryIcon.png"),
      },
    };
  },

  /**
   * Teslimat tarihinin belirli bir aralıkta olup olmadığını kontrol et
   * @param {string|Date} deliveryDate - Teslimat tarihi
   * @param {Object} dateRange - Tarih aralığı {start, end}
   * @returns {boolean} - Tarih aralıkta ise true, değilse false
   */
  isDeliveryDateInRange(deliveryDate, dateRange) {
    if (!dateRange || !dateRange.start || !dateRange.end) {
      return true; // Tarih aralığı belirtilmemiş, her zaman true döner
    }

    const delivery = moment(deliveryDate);
    const start = moment(dateRange.start);
    const end = moment(dateRange.end);

    return delivery.isBetween(start, end, 'day', '[]'); // [] içinde olup olmadığını kontrol eder (başlangıç ve bitiş dahil)
  },

  /**
   * Ürün kontrolü yap (bu fonksiyon orijinal kodda eksik, varsayılan olarak bir değer döndürülüyor)
   * @param {string|number} productId - Ürün ID'si 
   * @param {string} productName - Ürün adı
   * @returns {boolean} - Eşleşme varsa true, yoksa false
   */
  productController(productId, productName) {
    // Bu fonksiyon orijinal kodda eksik, gerçek uygulamada ürün kontrolü yapılıyor olmalı
    // Eğer gerçek fonksiyon mevcut ise, buraya eklenebilir
    //console.warn("productController fonksiyonu uygulanmadı, ürün kontrolü yapılmıyor");
    //return true; // Varsayılan olarak her zaman true döner
    return productId===productName;
  },

  /**
   * Harita için kümeleme stillerini oluştur
   * @param {string} type - Küme tipi ('default', 'problem', vb.)
   * @returns {Array} - Kümeleme stilleri
   */
  getClusterStyles(type = 'default') {
    if (type === 'problem') {
      return [
        {
          url: require("@/assets/marker/factoryClusterRed.png"),
          height: 40,
          width: 40,
          textColor: "black",
          textSize: 14,
        },
      ];
    }
    
    return [
      {
        url: require("@/assets/marker/factoryClusterGreen.png"),
        height: 30,
        width: 30,
        textColor: "black",
        textSize: 12,
      },
      {
        url: require("@/assets/marker/factoryClusterYellow.png"),
        height: 30,
        width: 30,
        textColor: "black",
        textSize: 14,
      },
      {
        url: require("@/assets/marker/factoryClusterRed.png"),
        height: 40,
        width: 40,
        textColor: "black",
        textSize: 14,
      },
      {
        url: require("@/assets/marker/factoryClusterRed.png"),
        height: 40,
        width: 40,
        textColor: "black",
        textSize: 14,
      },
      {
        url: require("@/assets/marker/factoryClusterRed.png"),
        height: 40,
        width: 40,
        textColor: "black",
        textSize: 14,
      },
    ];
  }
};