// services/api.js
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
import { getLocationAll } from '@/utils/detailData.js';

/**
 * Araç takip sistemine ait API isteklerini yöneten servis
 */
export const trackingService = {
  /**
   * Tüm araç konumlarını getir
   * @param {string} token - Kullanıcı kimlik doğrulama jetonu
   * @returns {Promise<Array>} - Araç verileri
   */
  async getAll(token) {
    try {
      // Mevcut uygulamada getLocationAll özelleştirilmiş fonksiyon kullanılıyor
      const data = await getLocationAll(token);
      return data;
      
      // Alternatif olarak doğrudan API çağrısı:
      // const response = await axios.get(
      //   tracking.getAll + "?isVehicleScreen=true",
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // return response.data;
    } catch (error) {
      console.error("Konum verileri yüklenirken hata:", error);
      throw error;
    }
  },

  /**
   * Tüm fabrika/müşteri konumlarını getir
   * @param {string} token - Kullanıcı kimlik doğrulama jetonu
   * @returns {Promise<Array>} - Fabrika/müşteri verileri
   */
  async getVectors(token) {
    try {
      const response = await axios.get(tracking.getVectors, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data || [];
    } catch (error) {
      console.error("Vektör verileri yüklenirken hata:", error);
      throw error;
    }
  },

  /**
   * Belirli bir araç için detay verisi getir
   * @param {number} vehicleId - Araç ID'si
   * @param {string} token - Kullanıcı kimlik doğrulama jetonu
   * @returns {Promise<Object>} - Araç detay verisi
   */
  async getVehicleDetail(vehicleId, token) {
    try {
      const response = await axios.get(`${tracking.getVehicleDetail}/${vehicleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`${vehicleId} ID'li araç detayları yüklenirken hata:`, error);
      throw error;
    }
  }
};