<template>
  <modal
    name="pirate-driver-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '55%'"
    :scrollable="true"
    @before-open="resetForm"
    @opened="getDetail"
    @before-close="handleModalClose"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between bg-gray-50 dark:bg-gray-800 dark:text-gray-200"
    >
      <h4 class="font-bold text-lg flex items-center">
        <i class="fas fa-user-secret text-red-600 mr-2"></i>
        KORSAN SÜRÜCÜ İLE PLANLAMA
      </h4>
      <button
        class="p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full transition"
        @click="$modal.hide('pirate-driver-modal')"
        title="Kapat"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <!-- Sipariş Bilgileri Özeti -->
      <div
        class="bg-gray-100 dark:bg-gray-800 p-3 rounded-lg mb-5 border-l-4 border-blue-500"
      >
        <h5 class="font-bold mb-2 flex items-center">
          <i class="fas fa-info-circle mr-2 text-blue-500"></i>
          Sipariş Bilgileri
        </h5>
        <div class="grid grid-cols-2 gap-3 text-sm">
          <div>
            <p>
              <span class="text-gray-600 dark:text-gray-400">Sipariş No:</span> #{{
                item?.orderNo || "N/A"
              }}
            </p>
            <p>
              <span class="text-gray-600 dark:text-gray-400">Müşteri:</span>
              {{ item?.customer || "N/A" }}
            </p>
          </div>
          <div>
            <p>
              <span class="text-gray-600 dark:text-gray-400">Ürün:</span>
              {{ item?.productType || "N/A" }}
            </p>
            <div>
      <p>
        <span class="text-gray-600 dark:text-gray-400">Miktar:</span>
        {{ item?.tonnage || "0" }} {{ item?.unitType || "" }}
      </p>
    </div>
          </div>
        </div>
      </div>

      <!-- Sürücü Bilgileri -->
      <div class="mb-4">
        <h5 class="font-bold border-b border-gray-200 dark:border-gray-700 pb-2 mb-3">
          Sürücü Bilgileri
        </h5>
        <div class="md:flex">
          <div class="w-full md:w-6/12 mb-4 md:mb-0 md:pr-2">
            <diffInput
              type="text"
              :required="true"
              v-model="driverName"
              :titleNone="true"
              title="Sürücü Adı"
              placeholder="Sürücünün tam adını girin"
            />
          </div>
          <div class="w-full md:w-6/12 md:pl-2">
            <diffInput
              type="tel"
              :required="true"
              :titleNone="true"
              v-model="driverPhone"
              title="Sürücü Telefonu"
              placeholder="05XX XXX XX XX"
            />
          </div>
        </div>
      </div>

      <!-- Araç Bilgileri -->
      <div class="mb-4">
        <h5 class="font-bold border-b border-gray-200 dark:border-gray-700 pb-2 mb-3">
          Araç Bilgileri
        </h5>
        <div class="md:flex">
          <div class="w-full md:w-6/12 mb-4 md:mb-0 md:pr-2">
            <diffInput
              type="text"
              :required="true"
              v-model="vehiclePlate"
              :titleNone="true"
              title="Araç Plakası"
              placeholder="Plakayı girin (34XX0000)"
              @input="formatPlate"
            />
          </div>
          <div class="w-full md:w-6/12 mb-4 md:mb-0 md:pr-2">
            <diffInput
              type="text"
              :required="true"
              v-model="dorse"
              :titleNone="true"
              title="Dorse Plakası"
              placeholder="Plakayı girin (34XX0000)"
              @input="formatPlate"
            />
          </div>
        </div>
      </div>

      <!-- Maliyet -->
      <div class="mb-6">
        <h5 class="font-bold border-b border-gray-200 dark:border-gray-700 pb-2 mb-3">
          Maliyet Bilgileri
        </h5>
        <div class="md:flex items-center">
          <div class="w-full md:w-6/12 mb-4 md:mb-0 md:pr-2">
            <diffInput
              type="number"
              :required="true"
              v-model="cost"
              title="Maliyet (₺)"
              placeholder="0.00"
              min="0"
              step="0.01"
            />
          </div>
          <div class="w-full md:w-6/12 md:pl-2">
            <div class="flex items-center mt-5">
              <div class="h-3 flex-grow bg-gray-200 rounded-full overflow-hidden">
                <div
                  class="h-full bg-blue-500"
                  :class="{
                    'bg-red-500': costRatio > 0.8,
                    'bg-yellow-500': costRatio > 0.6 && costRatio <= 0.8,
                    'bg-green-500': costRatio <= 0.6,
                  }"
                  :style="{ width: `${Math.min(costRatio * 100, 100)}%` }"
                ></div>
              </div>
              <span
                class="ml-2 text-sm"
                :class="{
                  'text-red-500': costRatio > 0.8,
                  'text-yellow-500': costRatio > 0.6 && costRatio <= 0.8,
                  'text-green-500': costRatio <= 0.6,
                }"
                >{{ Math.round(costRatio * 100) }}%</span
              >
            </div>
            <p class="text-xs text-gray-500 mt-1">
              Maliyet oranı (ortalama fiyatlara göre)
            </p>
          </div>
        </div>
      </div>

      <!-- Uyarı -->
      <div
        class="w-full p-4 bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-700 rounded-lg mb-6"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <i class="fas fa-exclamation-triangle text-yellow-500 text-lg"></i>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800 dark:text-yellow-200">
              Önemli Bilgi
            </h3>
            <div class="mt-2 text-sm text-yellow-700 dark:text-yellow-300">
              <p>
                Bu sipariş korsan sürücü ile planlanacaktır. Planlama dışı tüm maliyetleri
                dahil etmeyi unutmayın. Girilen maliyet, toplam maliyet olarak
                kaydedilecektir.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Butonlar -->
      <div class="w-full flex justify-end items-center space-x-3 mt-6">
        <button
          type="button"
          @click="$modal.hide('pirate-driver-modal')"
          class="px-4 py-2 border border-gray-300 rounded-md text-gray-700 dark:text-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 transition"
        >
          İptal
        </button>
        <asyncBtn
          icon="fas fa-check"
          text="Planlamayı Kaydet"
          loadTitle="Kaydediliyor..."
          :loadState="load"
          class="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition"
        />
      </div>
    </form>
  </modal>
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

export default {
  name: "pirate-driver-modal",
  components: {
    diffInput,
    asyncBtn,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        orderNo:'',
        customer:'',
        productType:'',
        unitType:'',
        tonnage:0


      }),
    },
  },
  data() {
    return {
      driverName: "",
      driverPhone: "",
      vehiclePlate: "",
      dorse:"",
      vehicleType: "Çekici",
      cost: 0,
      load: false,
      isEditMode: false,
      originalData: null,
      averageCostPerTon: 350, // Ortalama maliyet (ton başına)
    };
  },
  computed: {
  isMobile() {
    return window.innerWidth <= 768;
  },
  costRatio() {
    // Check if this.item exists before accessing its properties
    if (!this.item) return 0;
    
    // Use optional chaining and nullish coalescing to safely access tonnage
    const tonnage = this.item?.tonnage ?? 0;
    // Maliyetin, ortalama maliyete göre oranı
    const averageCost = (tonnage || 1) * this.averageCostPerTon;
    return averageCost > 0 ? this.cost / averageCost : 0;
  },
},
  methods: {
    getDetail() {
      if (this.item.isPirateDriver == 1) {
        this.isEditMode = true;
        this.vehiclePlate = this.item.vehicleName;
        this.driverName = this.item.driver;
        this.driverPhone = this.item.driverPhone;
        this.vehicleType = this.item.recomendation?.vehicleType || "Çekici";
        this.cost = this.item.allCost;

        // Orijinal verileri sakla (iptal durumunda kullanmak için)
        this.originalData = {
          vehicleName: this.item.vehicleName,
          driver: this.item.driver,
          driverPhone: this.item.driverPhone,
          vehicleType: this.item.recomendation?.vehicleType || "Çekici",
          allCost: this.item.allCost,
        };
      } else {
        this.isEditMode = false;
        this.originalData = null;

        // Yeni planlama için varsayılan değerler belirle
        const estimatedCost = (this.item.tonnage || 0) * this.averageCostPerTon;
        this.cost = Math.round(estimatedCost);
      }
    },

    formatPlate() {
      // Plaka formatını düzenle (örn: 34ABC123 -> 34 ABC 123)
      if (!this.vehiclePlate) return;

      // Boşlukları kaldır ve harfleri büyük harf yap
      let plate = this.vehiclePlate.replace(/\s+/g, "").toUpperCase();

      // Türkiye plaka formatına göre düzenle (il kodu + harf + sayı)
      const match = plate.match(/^(\d{2})([A-Z]{1,3})(\d{1,4})$/);
      if (match) {
        this.vehiclePlate = `${match[1]} ${match[2]} ${match[3]}`;
      }
    },

    save() {
      this.load = true;

      try {
        // TC kimlik numarası oluştur (rastgele) - gerçek bir sistem değil
        const driverTC = "KORSAN-" + new Date().getTime();

        // Korsan sürücü ile planlamayı parent bileşene bildir
        const pirateDriverPlan = {
          ...this.item,
          vehicleName: this.vehiclePlate,
          vehicle: {
            id: "pirate-" + new Date().getTime(),
            name: this.vehiclePlate,
            position: {
              lat: this.item.outpointLat || 0,
              lng: this.item.outpointLng || 0,
            },
          },
          driver: this.driverName,
          driverName: this.driverName, // TableRow'da görüntülenecek sürücü adı
          driverPhone: this.driverPhone,
          driverTC: driverTC, // TC bilgisi eksik olduğunda validasyon hatası veriyor
          dorse:this.dorse,
          isPirateDriver: 1,
          recomendationTotalCost: parseFloat(this.cost),
          allCost: parseFloat(this.cost),
          isMultiple: false,
          orderAmount: this.item.tonnage,
          recomendation: {
            name: this.vehiclePlate,
            dorse:this.dorse,
            vehicleType: this.vehicleType,
            capacity: 0,
            isPirateDriver: true,
            driverName: this.driverName,
            driver: this.driverName,
          },
        };

        // Parent bileşene oluşturulan planı gönder
        this.$root.$emit("add-pirate-driver-plan", pirateDriverPlan);

        this.$swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Korsan sürücü ile planlama başarıyla kaydedildi.",
          confirmButtonText: "Tamam",
        });

        this.$modal.hide("pirate-driver-modal");
        this.$emit("refresh");
      } catch (error) {
        console.error("Korsan sürücü planı oluşturulurken hata:", error);
        this.$swal.fire({
          icon: "error",
          title: "Hata",
          text: "Planlama kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.",
          confirmButtonText: "Tamam",
        });
      } finally {
        this.load = false;
      }
    },

    resetForm() {
      this.driverName = "";
      this.driverPhone = "";
      this.dorse= "";
      this.vehiclePlate = "";
      this.vehicleType = "Çekici";
      this.cost = 0;
      this.isEditMode = false;
      this.originalData = null;
    },

    handleModalClose() {
      // Eğer düzenleme modundaysak ve orijinal veriler varsa
      if (this.isEditMode && this.originalData) {
        // Orijinal verileri kullanarak planı koru
        const preservePlan = {
          ...this.item,
          vehicleName: this.originalData.vehicleName,
          vehicle: {
            id: this.item.vehicle?.id || "pirate-" + new Date().getTime(),
            name: this.originalData.vehicleName,
            position: {
              lat: this.item.outpointLat || 0,
              lng: this.item.outpointLng || 0,
            },
          },
          driver: this.originalData.driver,
          driverPhone: this.originalData.driverPhone,
          isPirateDriver: 1,
          recomendationTotalCost: parseFloat(this.originalData.allCost),
          allCost: parseFloat(this.originalData.allCost),
          isMultiple: false,
          orderAmount: this.item.tonnage,
          recomendation: {
            name: this.originalData.vehicleName,
            vehicleType: this.originalData.vehicleType,
            capacity: 0,
            isPirateDriver: true,
          },
        };

        // Değişiklikleri iptal ederek orijinal planı koruyalım
        this.$root.$emit("preserve-pirate-driver-plan", preservePlan);
      }
    },
  },
  created() {
    // root event listener
    this.$root.$on("reset-pirate-driver-form", this.resetForm);
  },
  beforeDestroy() {
    // Listener temizle
    this.$root.$off("reset-pirate-driver-form", this.resetForm);
  },
};
</script>

<style scoped>
/* Animasyonlar */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
