<template>
  <div
    class="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 p-2 rounded-lg shadow-lg z-10 flex items-center"
  >
    <!-- Önceki sayfa butonu -->
    <button
      @click="$emit('previousPage')"
      :disabled="currentPage <= 1"
      class="px-3 py-1 rounded-l-md border"
      :class="
        currentPage <= 1
          ? 'bg-gray-200 text-gray-400'
          : 'bg-white hover:bg-gray-100'
      "
    >
      <i class="fas fa-chevron-left"></i>
    </button>

    <!-- Mevcut sayfa / Toplam sayfa -->
    <span class="px-4 border-t border-b">
      {{ currentPage }} / {{ pageCount }}
    </span>

    <!-- Sonraki sayfa butonu -->
    <button
      @click="$emit('nextPage')"
      :disabled="currentPage >= pageCount"
      class="px-3 py-1 rounded-r-md border"
      :class="
        currentPage >= pageCount
          ? 'bg-gray-200 text-gray-400'
          : 'bg-white hover:bg-gray-100'
      "
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "ZonePagination",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
  },
  emits: ["previousPage", "nextPage"],
};
</script>
