<template>
    <div class="flex items-center">
      <!-- Clear search button -->
      <div
        v-if="columnKey === searchColumn"
        @click="$emit('clear-search')"
        class="cursor-pointer mr-1"
      >
        <i class="fas fa-times text-red-600"></i>
      </div>
      
      <!-- Sort Button - Dynamic approach -->
      <button
        v-if="isSortable"
        class="pr-1"
        @click="$emit('toggle-sort', columnKey === 'dropdownColumnDurum' ? 'stateName' : columnKey)"
      >
        <i
          v-if="sortColumn !== columnKey"
          class="fas fa-sort"
        ></i>
        <i
          v-if="sortState === 1 && sortColumn === columnKey"
          class="fas fa-sort-alpha-up"
        ></i>
        <i
          v-if="sortState === 2 && sortColumn === columnKey"
          class="fas fa-sort-alpha-down"
        ></i>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "PlanningSortButton",
    props: {
      columnKey: {
        type: String,
        required: true
      },
      sortColumn: {
        type: String,
        default: ''
      },
      sortState: {
        type: Number,
        default: 0
      },
      searchColumn: {
        type: String,
        default: ''
      },
      sortableColumns: {
        type: Array,
        default: () => [
          'orderNo', 
          'customer', 
          'receivedName', 
          'deliveredName', 
          'productType', 
          'dropdownColumnDurum'
        ]
      }
    },
    computed: {
      isSortable() {
        return this.sortableColumns.includes(this.columnKey);
      }
    }
  }
  </script>