<template>
  <div
    class="w-full top-0 sticky z-30 bg-white dark:bg-gray-800/95 backdrop-blur-sm border-b border-gray-100 dark:border-gray-700 shadow-sm"
    style="z-index: 999 !important"
  >
    <div class="w-full flex items-center md:justify-between h-16">
      <div class="md:w-10/12 xl:w-8/12 flex justify-start items-center">
        <NavbarItem />
      </div>

      <div class="relative flex items-center xl:w-4/12 w-10/12 justify-end">
        <div
          v-if="$store.state.userData.typeId == 2"
          class="hidden sm:block"
        >
          <darkMode :inline="true" />
        </div>

        <!-- Refresh button with animation -->
        <button 
          @click="loadFunc" 
          class="inline-flex items-center px-3 py-1.5 rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors shadow-sm text-sm font-medium"
        >
          <span class="flex items-center">
            <img
              :src="require($store.state.darkMode ? '@/assets/refresh-dark.png' : '@/assets/refresh-light.png')"
              :class="{'animate-spin': load}"
              class="w-4 h-4 mr-2"
              alt="Yenile"
            />
            Yenile
          </span>
        </button>

        <!-- User profile dropdown -->
        <profilePanel />
      </div>
    </div>

    <!-- Section tabs below header -->
    <sectionItem />
  </div>
</template>

<script>
import darkMode from "./components/darkmode.item.vue";
import profilePanel from "./components/profile.panel.vue";
import languagePanel from "./components/languages.item.vue";
import feedbackMenu from "../components/feedback.vue";
import NavbarItem from "./navbar.vue";
import sectionItem from "./section.vue";

export default {
  name: "header-item",
  components: {
    profilePanel,
    languagePanel,
    darkMode,
    feedbackMenu,
    NavbarItem,
    sectionItem,
  },
  data() {
    return {
      load: false,
    };
  },
  methods: {
    loadFunc() {
      this.load = true;
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
  },
};
</script>

<style scoped>
/* Use Tailwind's built-in animate-spin for refresh icon */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
</style>