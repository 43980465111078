const planningStatus= [
    { id: 11, name: "Devam Edenler" },
    { id: 0, name: "Hepsi" },
    { id: 1, name: "Onay Bekliyor" },
    { id: 2, name: "Onaylandı" },
    { id: 3, name: "Yükleme Noktasına Gidiyor" },
    { id: 4, name: "Yüklemede " },
    { id: 5, name: "Yola Çıktı" },
    { id: 6, name: "Teslim Noktasına Ulaştı" },
    { id: 7, name: "Tahliyede" },
    { id: 8, name: "Tamamlandı" },
    { id: 9, name: "İptal Edildi" },
    { id: 10, name: "Onaylanmadı" },
  ]



function getPlanningStatusName(id){
    if(!id) return "Bilinmiyor"
    const check=planningStatus.find(r=>r.id==id).name

    if(check) return check;
    else return 'Bilinmiyor'
  }

  module.exports={
    planningStatus,
    getPlanningStatusName
  }