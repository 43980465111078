<template>
  <div
    class="absolute top-20 right-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg z-10 max-w-sm"
  >
    <!-- Panel başlığı ve kapatma butonu -->
    <div class="flex justify-between items-center mb-2">
      <h3 class="text-lg font-bold">{{ zone.name }}</h3>
      <button @click="$emit('close')" class="text-gray-500 hover:text-gray-700">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Bölge detayları -->
    <div class="text-sm">
      <p class="mb-1">
        <strong>Adres:</strong> {{ zone.address || "Belirtilmemiş" }}
      </p>
      <p class="mb-1">
        <strong>Müşteri Kodu:</strong>
        {{ zone.customerCode || "Yok" }}
      </p>
      <p class="mb-1">
        <strong>Oluşturma Tarihi:</strong>
        {{ formatDate(zone.createDate) }}
      </p>
    </div>

    <!-- Bölgedeki araçlar -->
    <VehicleList
      v-if="vehicles.length > 0"
      :vehicles="vehicles"
      @vehicleSelected="$emit('vehicleSelected', $event)"
    />
  </div>
</template>
  
  <script>
import moment from "moment";
import VehicleList from "./VehicleList.vue";

export default {
  name: "ZoneInfoPanel",
  components: {
    VehicleList,
  },
  props: {
    zone: {
      type: Object,
      required: true,
    },
    vehicles: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * Tarihi formatlı şekilde döndürür
     */
    formatDate(dateStr) {
      return moment(dateStr).format("DD.MM.YYYY HH:mm");
    },
  },
};
</script>