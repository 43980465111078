const similarity = require("similarity");


/**
 * Bir ürün tipinin, aracın en son kullandığı ürüne olan benzerliğine göre öncelik puanını hesaplar.
 *
 * @param {Object} order - Ürün detaylarını içeren sipariş nesnesi.
 * @param {string} [order.productName] - Siparişteki ürünün adı.
 * @param {string} [order.productType] - Eğer productName mevcut değilse, ürün tipi.
 * @param {Object} vehicle - Önceki ürün detaylarını içeren araç nesnesi.
 * @param {string} [vehicle.lastProduct] - Aracın en son ilişkilendirildiği ürün.
 * @returns {number} - Öncelik değerini döndürür: Eğer son ürün eşleşirse 0.9, aksi takdirde 1 döndürür.
 */

function calculateProductTypePriority(order, vehicle) {
    const productName = order?.productName
      ? order?.productName
      : order?.productType;
  
    const lastProductVehicle =
      similarity(vehicle?.lastProduct, productName) > 0.8;
    return lastProductVehicle ? 0.9 : 1;
  }

  module.exports={
    calculateProductTypePriority
  }