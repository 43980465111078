<template>
  <div
    :class="[
      !isFullScreen
        ? 'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4'
        : 'w-full overflow-y-scroll h-screen pb-28 mt-4',
    ]"
  >
    <div class="relative">
      <table class="min-w-full divide-y divide-gray-200">
        <TableHeader
          :activeState="activeState"
          :isPlanning="isPlanning"
          :isFullScreen="isFullScreen"
        />
        <tbody
          class="divide-y-2 divide-gray-500"
          style="background-color: #3a3a3a"
        >
          <TableRow
            v-for="(item) in orders"
            :key="item.orderNo"
            :item="item"
            :activeState="activeState"
            :isPlanning="isPlanning"
            :products="products"
            :vehicleList="filteredVehicles"
            :customers="customers"
            @select-item="$emit('select-order', item)"
            @view-order="$emit('view-order', item)"
            @remove-order="$emit('remove-order', item)"
            @change-vehicle="(val) => $emit('change-vehicle', val, item)"
            @change-driver="(driver) => $emit('change-driver', driver, item)"
            @handle-personel-change="(driver) => $emit('handle-personel-change', driver, item)"
            @open-context-menu="(event) => $emit('open-context', event, item)"
            @isVehicleConflictModalVisible="(event)=>isVehicleConflictModalVisible=event"
          />
          <TableLoader
            :load="loading"
            :length="orders.length"
            :colspan="activeState === 2 ? 13 : 11"
          />
          <tr v-if="planningLoad">
            <td colspan="8" class="text-center py-4 text-white">
              <i class="fas fa-spinner fa-spin"></i> Planlanıyor
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <VehicleConflictModal @close="reset" :visible="isVehicleConflictModalVisible.show" :orderInfo="isVehicleConflictModalVisible.order" :vehicleInfo="isVehicleConflictModalVisible.vehicle" />
  </div>
</template>
  
  <script>
import TableHeader from "./TableHeader.vue";
import TableRow from "./TableRow.vue";
import TableLoader from "@/components/general/table-loader.vue";
import VehicleConflictModal from './VehicleConflictModal.vue';
export default {
  name: "OrdersTable",
  components: {
    TableHeader,
    TableRow,
    TableLoader,
    VehicleConflictModal
  },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    activeState: {
      type: Number,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    planningLoad: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      required: true,
    },
    vehicleList: {
      type: Array,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVehicleConflictModalVisible:{show:false,order:{},vehicle:{}}
    }
  },
  methods:{
    reset(){
      this.isVehicleConflictModalVisible={show:false,order:{},vehicle:{}};
    }
  },
  computed: {
    filteredVehicles() {
      return this.vehicleList.map((item) => ({
        ...item,
        name: item.vehicle,
        isRent: item?.haveType,
      }));
    },
  },
};
</script>