/**
 * Tek bir araç için öneri oluşturur. (Optimize edilmiş versiyon)
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @returns {Object|null} Önerilen araç veya null.
 */

const { calculateCustomerPriority } = require("./calculator/calculateCustomerPriority");
const { calculateProductTypePriority } = require("./calculator/calculateProductTypePriority");
const { calculateDistancePriority } = require("./calculator/calculateDistancePriority");
const { isVehicleSuitableForOrder } = require("./controller/isVehicleSuitableForOrder");
const { isWashStatus } = require("./controller/isWashStatus");
const { isVehicleSuitableForOrderDetail } = require("./controller/isVehicleSuitableForOrderDetail");
const {haversineDistance} =require('./haversineDistance')
const similarity = require("similarity");

const { checkForReallocation } = require("./recommendedModules/reallocate/index");
const { optimizeVehicles, addCloseBranchVehicles } = require("./recommendedModules/optimizeVehicles");
const processPolygonGroups = require("./recommendedModules/processPolygonGroups");
const calculateVehicleCosts = require("./recommendedModules/calculateVehicleCosts");
const preFilterVehicles = require("./recommendedModules/preFilterVehicles");



/**
 * Tek bir araç için öneri oluşturur. (Optimize edilmiş versiyon)
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {number} totalCount - Toplam önerilecek araç sayısı.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @param {boolean} [setAvailable=true] - Araç durumunu güncelleme.
 * @param {Array} [blockedVehicles=[]] - Bloklanmış araçlar listesi.
 * @param {boolean} [isClose=false] - Yakın mesafe kontrolü.
 * @param {string} token - Kimlik doğrulama tokeni.
 * @returns {Object|Array} Önerilen araç veya araçlar listesi.
 */
async function recommendSingleVehicleForOrder(
  order,
  vehicles,
  customers,
  products,
  totalCount,
  isBulk = false,
  skipTheTonnage = false,
  setAvailable = true,
  blockedVehicles = [],
  isClose = false,
  token
) {
  // Uygunluk kontrolü için kapanış fonksiyonu
  const isSuitable = (vehicle) => {

    return isVehicleSuitableForOrder(
      order,
      vehicle,
      products,
      isBulk,
      skipTheTonnage,
      isClose
    );
  };
  
  // 1. Uygun araçları filtrele

  let suitableVehicles = preFilterVehicles(vehicles, order, isSuitable);
  
  if (suitableVehicles.length === 0) {
    return { message: "Bu sipariş için Uygun araç bulunamadı." };
  }
  
  // 2. Bloklanmış araçları filtrele
  if (blockedVehicles?.length > 0) {
    const blockedPlates = new Set(blockedVehicles.map(v => v?.plate));
    suitableVehicles = suitableVehicles.filter(v => !blockedPlates.has(v?.plate));
  }


  // 3. Araçları önceliklendirme
  const prioritizedVehicles = suitableVehicles.map(vehicle => prioritizeVehicle(vehicle, order, customers, products));

  // 4. Maliyet hesaplamaları
  const vehiclesWithCosts = await calculateVehicleCosts(prioritizedVehicles, order, token);
  
  // 5. Polygon gruplarını işle ve sırala
  const processedVehicles = processPolygonGroups(vehiclesWithCosts);

  // 6. Araçları costPriorityAverage ve polygonScore'a göre sırala
  const sortedVehicles = processedVehicles.sort((a, b) => {
    const costDiff = a.costPriorityAverage - b.costPriorityAverage;
    if (costDiff !== 0) return costDiff;
    return b.polygonScore - a.polygonScore;
  });
  
  // 7. Optimal araçları seç ve gerekirse ek araçlar ekle
  let topVehicles = optimizeVehicles(sortedVehicles, order, totalCount, setAvailable, vehicles);

  // 8. Yakın şubedeki araçları ekle
  topVehicles = addCloseBranchVehicles(topVehicles, sortedVehicles);
  
  
  // 9. Uzak mesafe kontrolü
  const reallocationCheck = checkForReallocation(
    topVehicles, 
    vehicles, 
    order, 
    products, 
    300,
    isBulk,
    skipTheTonnage,
    isClose
  );

  // Eğer yeniden atama gerekliyse, bu sonucu döndür
  if (reallocationCheck) {
    return reallocationCheck;
  }


  // Normal sonucu döndür
  return topVehicles;
}

/**
 * Bir aracı önceliklendiren yardımcı fonksiyon
 * @param {Object} vehicle - Araç
 * @param {Object} order - Sipariş 
 * @param {Array} customers - Müşteriler
 * @param {Array} products - Ürünler
 * @returns {Object} Önceliklendirilmiş araç
 */
function prioritizeVehicle(vehicle, order, customers, products) {
  // Latitude ve longitude değerini polygon varsa güncelle
  const vehicleLocation = vehicle.polygonName ? {
    ...vehicle,
    latitude: vehicle.polygonLat,
    longitude: vehicle.polygonLng
  } : vehicle;
  
  // Öncelik değerlerini hesapla
  const distancePriority = calculateDistancePriority(order, vehicleLocation);
  const customerPriority = calculateCustomerPriority(order, customers);
  const productTypePriority = calculateProductTypePriority(order, vehicle);
  const washPriority = isWashStatus(customers, products, vehicle, order);
  
  // Yıkama durumuna göre öncelik değeri
  const washPriorityValue = washPriority.status == 2 ? 0.9 : 1.0;
  
  // Kombine öncelik hesaplama
  const combinedPriority = (distancePriority/100).toFixed(3) *
    customerPriority *
    washPriorityValue *
    productTypePriority;
  
  // Araç tipini kontrol et
  const isCentipede = vehicle.capacity >= 18000 && vehicle.capacity <= 22000;
  
  // Ürün adını al
  const productName = order?.productName ? order?.productName : order?.productType;
  
  // Yakın şube kontrolü
  let isCloseBranch = false;
  if (vehicle.fleetBranch && vehicle.fleetBranch.length > 0) {
    const branch = vehicle.fleetBranch[0];
    const distance = haversineDistance(
      { lat: branch.latitude, lng: branch.longitude },
      { lat: order.endLat, lng: order.endLng }
    );
    if (distance < 250.0) {
      isCloseBranch = true;
    }
  }
  
  return {
    ...vehicle,
    recomendation: {
      ...vehicle,
      ...isVehicleSuitableForOrderDetail(
        order,
        vehicle,
        products,
        false,
        false,
        isCentipede
      ),
      distancePriority: distancePriority.toFixed(2),
      customerPriority,
      capacity: (vehicle.capacity / 1000).toFixed(2),
      isWashing: washPriority.status,
    },
    isCloseBranch,
    priority: combinedPriority,
    distance: parseFloat(distancePriority).toFixed(2),
    lastProductAvailable: !vehicle?.lastProduct
      ? 3
      : similarity(vehicle?.lastProduct, productName) > 0.8
        ? 1
        : 2,
  };
}

module.exports = {
  recommendSingleVehicleForOrder

};