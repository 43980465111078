
const {checkForReallocation}=require('./checkForReallocation');
const { getOrdersWithVehicles } = require('./getOrdersWithVehicles');
const { reallocateVehicles } = require('./reallocateVehicles');
const { findBusyButSuitableVehicles } = require('./findBusyButSuitableVehicles');
const { areAllVehiclesTooFar } = require('./areAllVehiclesTooFar');



module.exports = {
  checkForReallocation,
  getOrdersWithVehicles,
  reallocateVehicles,
  findBusyButSuitableVehicles,
  areAllVehiclesTooFar
};