<!-- InternetConnectionWarning.vue -->
<template>
  <transition name="fade">
    <div v-if="isVisible" class="fixed bottom-4 right-4 z-50 max-w-sm">
      <div
        :class="[
          'rounded-lg shadow-lg p-4 flex items-center gap-3 transition-all duration-300',
          connectionQuality === 'offline'
            ? 'bg-red-600 text-white'
            : 'bg-yellow-400 text-yellow-900',
        ]"
      >
        <!-- Yanıp sönen ikon -->
        <div :class="{ 'pulse-animation': true }">
          <svg
            v-if="connectionQuality === 'offline'"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
            />
          </svg>
        </div>

        <div class="flex-1">
          <p class="font-medium">
            {{
              connectionQuality === "offline"
                ? "İnternet bağlantısı yok!"
                : "Zayıf internet bağlantısı!"
            }}
          </p>
          <p class="text-sm">
            {{
              connectionQuality === "offline"
                ? "Lütfen internet bağlantınızı kontrol edin."
                : "Bazı özellikler düzgün çalışmayabilir."
            }}
          </p>
        </div>

        <button
          @click="handleDismiss"
          :class="[
            'p-1 rounded-full',
            connectionQuality === 'offline'
              ? 'hover:bg-red-500'
              : 'hover:bg-amber-200',
          ]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </transition>
</template>
  
  <script>
export default {
  name: "InternetConnectionWarning",
  data() {
    return {
      isVisible: false,
      connectionQuality: "good", // 'good', 'weak', 'offline'
      dismissed: false,
      intervalId: null,
    };
  },
  mounted() {
    // İlk kontrol
    this.checkConnection();

    // Düzenli kontrol için interval ayarla
    this.intervalId = setInterval(this.checkConnection, 10000);

    // Online/offline event dinleyicileri
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
  },
  beforeDestroy() {
    // Temizlik
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    window.removeEventListener("online", this.checkConnection);
    window.removeEventListener("offline", this.checkConnection);
  },
  methods: {
    checkConnection() {
      // Eğer kullanıcı uyarıyı kapattıysa, gösterme
      if (this.dismissed) {
        return;
      }

      // Offline durumu kontrol et
      if (!navigator.onLine) {
        this.connectionQuality = "offline";
        this.isVisible = true;
        return;
      }

      // Network Information API kullanılabilirse
      if ("connection" in navigator && navigator.connection) {
        const connection = navigator.connection;
        if (connection.downlink < 1 || connection.effectiveType === "2g") {
          this.connectionQuality = "weak";
          this.isVisible = true; // Otomatik kapanma yok
        } else {
          this.connectionQuality = "good";
          // Zayıf bağlantı durumundan normal duruma döndüğünde bile göstermeye devam et
          if (!this.isVisible) {
            this.isVisible = false;
          }
        }
      } else {
        // Alternatif yöntem - yanıt süresini kontrol et
        const startTime = Date.now();
        fetch("/api/ping", { method: "GET", cache: "no-store" })
          .then((response) => {
            const endTime = Date.now();
            const responseTime = endTime - startTime;
            if (responseTime > 1000) {
              this.connectionQuality = "weak";
              this.isVisible = true; // Otomatik kapanma yok
            } else {
              this.connectionQuality = "good";
              // Zayıf bağlantı durumundan normal duruma döndüğünde bile göstermeye devam et
              if (!this.isVisible) {
                this.isVisible = false;
              }
            }
          })
          .catch(() => {
            this.connectionQuality = "offline";
            this.isVisible = true;
          });
      }
    },
    handleDismiss() {
      this.dismissed = true;
      this.isVisible = false;

      // Kullanıcı uyarıyı kapattığında, yeni kontroller yapılana kadar dismissed durumunu koru
      // Bu, kullanıcı kapatana kadar uyarının tekrar görünmemesini sağlar

      // Dismissed durumunu sıfırlamak için bir timeout ekleyebilirsiniz (isteğe bağlı)
      // Örneğin, 30 dakika sonra dismissed durumunu sıfırlamak için:
      // setTimeout(() => {
      //   this.dismissed = false;
      // }, 30 * 60 * 1000);
    },
  },
};
</script>
  
  <style scoped>
/* Yanıp sönme animasyonu */
.pulse-animation {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Geçiş animasyonu */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>