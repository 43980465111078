<template>
  <div>
    <div class="p-2 bg-gray-200 dark:bg-gray-900">
      <!-- Modals -->
      <!-- <previewMapModal v-if="selectedPreview.id" :detail="selectedPreview" /> -->
      <addModal @refresh="getAll()" />
      <editModal @refresh="getAll()" :rowId="selected.id" :isDisabled="true" />
      <vehicleStateModel
        :typeId="1"
        ref="vehicleStateModel"
        :Detail="selected"
      />
      <locationDetailModal
        :vehicle="selected.vehicle"
        :stateId="selected.stateId"
      />

      <notesModal
        :orderId="selected.id"
        :orderStatus="selected.stateName"
        :isBulk="0"
      />

      <changeVehicleModal
        :detail="selected"
        :order="selected"
        @refresh="getAll()"
      />
      <vehicleStatuModal @refresh="getAll()" ref="vehicleStatu" />
      <!-- Modals -->

      <div class="w-full">
        <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
          <div class="w-full">
            <!-- Header -->
            <div class="w-full">
              <div
                class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm"
              >
                <div class="w-full">
                  <TableItem 
                  
                  :List="List"
                  :load="load"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Global Components
import userEditModal from "@/components/modals/userEditModal/index.vue";

import vehicleStatuModal from "@/components/modals/vehicleStatuModal/vehicleStatusModal.vue";
import notesModal from "@/components/modals/notesModal/index.vue";

// Local Components
import addModal from "./components/modals/addModal/index.vue";
import previewMapModal from "./components/modals/preview-modal/index.vue";
import editModal from "./components/modals/editModal/index.vue";

// Local Components
import changeVehicleModal from "./components/change/vehicle.vue";
import vehicleStateModel from "./components/modals/vehicleStateModel/index.vue";
import locationDetailModal from "./components/modals/mapLocation/index.vue";

// Networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import TableItem from './components/table/index.vue'

export default {
  name: "planning-module",
  components: {
    vehicleStateModel,
    previewMapModal,
    addModal,
    editModal,
    notesModal,
    locationDetailModal,
    changeVehicleModal,
    userEditModal,
    vehicleStatuModal,
    TableItem
  },
  data() {
    return {
      selectedItems: [],
      List: [],
      load: false,
      filterArr: [],
      sortState: 0,
      sortColumn: "",
      searchColumn: "",
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      statusFilter: false,
      selectedStatusId: null,
      filterState: false,
      selectedPreview: {
        id: "",
        plaque: "",
        vehicleId: "",
        orderNo: "",
        outpointLat: "",
        outpointLng: "",
        targetPointLat: "",
        targetPointLng: "",
        deliveryDate: "",
        amount: "",
        customer: "",
        productType: "",
      },
      selected: {
        id: "",
        plaque: "",
        vehicleId: "",
        orderNo: "",
        outpointLat: "",
        outpointLng: "",
        targetPointLat: "",
        targetPointLng: "",
        deliveryDate: "",
        amount: "",
        customer: "",
        productType: "",
      },
     
      status: true,
     
      statusList: [],
      statusId: 11,
      currentIndex: 0,
      statusFilter: false,
      needInterval: true, // app.vue da setInterval için lazım silme
    };
  },
  methods: {
    noteDetailModal(item) {
      this.selected = item;
      this.$modal.show("notes-modal");
    },
    showPreviewMapModal(item) {
      console.log("heeee");

      this.selectedPreview = item;
      this.$modal.show("preview-map-modal");
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    userEditModal(item) {
      // this.activeSpot = true;
      this.selected = item;
      this.$modal.show("ueserEdit-modal");
    },
    handleItemClick(item) {
      if (item.stateId < 8) {
        this.selected = item;
        this.$modal.show("location-detail-modal");
      }
    },
    vehicleStatesModel(item) {
      this.selected = item;
      this.$modal.show("vehicleState-modal");
    },
    toggleSort(column) {
      this.currentPage = 0;
      if (column === "stateName") {
        if (this.sortColumn !== "dropdownColumnDurum") {
          this.sortState = 0;
        }
        this.sortColumn = "dropdownColumnDurum";
      } else {
        if (this.sortColumn !== column) {
          this.sortState = 0;
        }
        this.sortColumn = column;
      }

      // Toggle the sort state between 0, 1, and 2
      this.sortState = (this.sortState + 1) % 3;
      // Determine the filter based on the sort state
      let filter = [];
      if (this.sortState === 1) {
        filter = [{ sort: 1, column }];
      } else if (this.sortState === 2) {
        filter = [{ sort: 2, column }];
      } else {
        this.sortColumn = "";
      }
      this.filterArr = [JSON.stringify(filter)];
      this.getAll();
    },
    showEditModal(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("edit-plan-modal");
      }, 50);
    },
    getStatusList() {
      axios
        .get(planning.getStatusAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          if (this.typeId == 7 || this.typeId == 8) {
            this.statusList = result.data.data;
          } else {
            this.statusList = result.data.data.filter(
              (r) => r.id != 10 && r.id != 11
            );
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      // this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    clearSearch() {
      this.search = "";
      this.handleSearch();
    },
    handleDblRowClick(data, key) {
      // this.clickSearch = true;
      this.search = "";
      this.currentPage = 0;
      this.searchColumn = key;
      this.search = data[key];
      this.getAll(true);
    },
    stopFiltering() {
      this.orderId = "";
      this.statusId = 11;
      this.currentIndex = 2;
      this.currentPage = 0;

      //this.selectedStatusId
      this.getAll();
    },
    setStatusFilter(statusId) {
      this.statusId = statusId;
      if (this.statusId === 0) {
        this.currentIndex = 2;
      }

      this.currentPage = 0;
      this.statusFilter = false;
      this.load = true;
      this.getAll();
    },
    getAll(load = true) {
      // Statü parametresi verilmediyse veya statü değişiklik göstermiyorsa işlemi sonlandır
      if (this.statusId !== false) {
        if (this.status == true) {
          this.currentIndex = 2;
          this.status = false;
        }
        if (this.statusId === undefined) {
          // Sayfa değişikliğinde sadece sayfa parametresini güncelle ve filtreleme yapma
          this.statusId = this.selectedStatusId;
        } else {
          // eğer statü sıralaması yapılmışken order sıralaması kalmışsa sıfırlansın
          if (this.statusId !== 0) this.stateNameOrder = "";
          // Filtreleme yapıldığında seçilen statüyü sakla ve sayfa numarasını sıfırla
          this.selectedStatusId = this.statusId;
        }
        this.List = load ? [] : this.List;
        this.load = load;
        const params = {
          page: this.currentPage,
          search: this.search,
          stateNameOrder: this.stateNameOrder,
          userList: this.selectedItems,
          status: this.statusId,
        };
        if (this.sortState) {
          params.filter = this.filterArr;
        }
        if (this.searchColumn) {
          params.column = this.searchColumn;
        }
        axios
          .get(planning.getAll, {
            params,
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          })
          .then((res) => {
            this.List = res.data.data.detail
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
          })
          .finally(() => {
            this.load = false;
            // this.sendStatus = false;
          });
      }
    },
    filteredList() {
      return this.List.map((item) => {
        if (item.stateId !== 8) {
          return { ...item, isTourComplatedSuccess: "-" };
        }

        let isTourComplatedSuccess;

        switch (item.isTourComplatedSuccess) {
          case 1:
            isTourComplatedSuccess = "Kaydedildi";
            break;
          case 2:
          case null:
          case undefined:
            isTourComplatedSuccess = "Gönderiliyor";
            break;
          default:
            isTourComplatedSuccess = "-";
        }

        return { ...item, isTourComplatedSuccess };
      });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              planning.delete,
              {
                rowId: item.id.toString(),
                orderNo: item.orderNo,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((result) => {
              const detail = result.data.data;
              if (!detail.status) {
                this.swalBox(
                  "warning",
                  this.$t("general.warningTitle"),
                  result.data.message,
                  false,
                  this.$t("general.OkayTitle")
                );
              } else {
                this.swalBox(
                  "success",
                  this.$t("general.successTitle"),
                  "",
                  false,
                  this.$t("general.OkayTitle")
                );
              }
              if (!this.filterState) this.getAll();
              // else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    handleSelectedItemsChanged(newSelectedItems) {
      this.selectedItems = newSelectedItems;
    },
  },
  created() {
    this.getStatusList();
    const tabIndex = this.$store.state.pages.List.findIndex(
      (i) => i.id === this.$route.name
    );
    if (tabIndex === -1) {
      this.getAll(false);
    }
  },
  beforeDestroy() {
    this.selectedPreview = {
      id: "",
      plaque: "",
      vehicleId: "",
      orderNo: "",
      outpointLat: "",
      outpointLng: "",
      targetPointLat: "",
      targetPointLng: "",
      deliveryDate: "",
      amount: "",
      customer: "",
      productType: "",
    };
  },
  watch: {
    currentPage() {
      if (!this.filterState) this.getAll();
    },
    filterState(val) {
      if (!val) this.getAll();
    },
    selectedItems() {
      this.getAll();
    },
  },
};
</script>
