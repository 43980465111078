// orderTools/calculations.js
// Mesafe, maliyet ve diğer hesaplama işlevleri

import { calculator } from '@/utils/cost';

/**
 * Calculate distance between order points and vehicle
 * @param {Object} order - The order object
 * @param {Object} vehicle - The vehicle object
 * @returns {number} - Distance in km
 */
export const calculateDistance = (order, vehicle) => {
  if (!order ||!vehicle) {
    throw new Error('Invalid order or vehicle');
  }
  // Simple distance calculation (could be enhanced with actual route distance)
  const startLat = parseFloat(order.outpointLat);
  const startLng = parseFloat(order.outpointLng);
  const vehicleLat = parseFloat(vehicle.latitude);
  const vehicleLng = parseFloat(vehicle.longitude);

  // Haversine formula for distance calculation
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(vehicleLat - startLat);
  const dLon = deg2rad(vehicleLng - startLng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(startLat)) * Math.cos(deg2rad(vehicleLat)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km

  return parseFloat(distance.toFixed(2));
};

/**
 * Convert degrees to radians
 * @param {number} deg - Degrees
 * @returns {number} - Radians
 */
export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

/**
 * Compares costs between different vehicle types
 * @param {Object} vehicle1 - First vehicle
 * @param {Object} vehicle2 - Second vehicle
 * @param {Object} order - Order to calculate costs for
 * @param {string} token - Authentication token
 * @returns {Promise<Object>} - Comparison results
 */
export const compareCosts = async (vehicle1, vehicle2, order, token) => {
  const distance1 = calculateDistance(order, vehicle1);
  const distance2 = calculateDistance(order, vehicle2);
  
  // Calculate costs in parallel
  const [cost1, cost2] = await Promise.all([
    calculator(
      token,
      1,
      1,
      vehicle1,
      order,
      distance1,
      0,
      false,
      true,
      1
    ),
    calculator(
      token,
      1,
      1,
      vehicle2,
      order,
      distance2,
      0,
      false,
      true,
      1
    )
  ]);
  
  // Parse costs
  const total1 = parseFloat(cost1.total);
  const total2 = parseFloat(cost2.total);
  
  // Compute difference
  const difference = Math.abs(total1 - total2);
  const cheaperVehicle = total1 < total2 ? vehicle1 : vehicle2;
  
  return {
    vehicle1: {
      vehicle: vehicle1,
      total: total1,
      distance: distance1,
      washCost: parseFloat(cost1.totalWashPriceCost),
      allCost: total1 + parseFloat(cost1.totalWashPriceCost)
    },
    vehicle2: {
      vehicle: vehicle2,
      total: total2,
      distance: distance2,
      washCost: parseFloat(cost2.totalWashPriceCost),
      allCost: total2 + parseFloat(cost2.totalWashPriceCost)
    },
    difference,
    cheaperVehicle
  };
};

/**
 * Calculates the optimal vehicle based on distance and cost
 * @param {Array} vehicles - List of vehicles
 * @param {Object} order - The order
 * @param {string} token - Authentication token
 * @returns {Promise<Object>} - Best vehicle with cost information
 */
export const findOptimalVehicle = async (vehicles, order, token) => {
  if (!vehicles || vehicles.length === 0) {
    return null;
  }
  
  // Eğer sadece bir araç varsa onu döndür
  if (vehicles.length === 1) {
    const vehicle = vehicles[0];
    const distance = calculateDistance(order, vehicle);
    const cost = await calculator(
      token,
      1, 
      1,
      vehicle,
      order,
      distance,
      0,
      false,
      true,
      1
    );
    
    return {
      vehicle,
      distance,
      total: parseFloat(cost.total),
      washCost: parseFloat(cost.totalWashPriceCost),
      allCost: parseFloat(cost.total) + parseFloat(cost.totalWashPriceCost),
      washPointName: cost.washPointName
    };
  }
  
  // Birden fazla araç varsa en uygun olanı bul
  let bestVehicle = vehicles[0];
  let bestDistance = calculateDistance(order, bestVehicle);
  let bestCost = null;
  
  // Tüm araçlar için maliyet hesapla
  const calculations = await Promise.all(
    vehicles.map(async (vehicle) => {
      const distance = calculateDistance(order, vehicle);
      const cost = await calculator(
        token,
        1,
        1,
        vehicle,
        order,
        distance,
        0,
        false,
        true,
        1
      );
      
      return {
        vehicle,
        distance,
        total: parseFloat(cost.total),
        washCost: parseFloat(cost.totalWashPriceCost),
        allCost: parseFloat(cost.total) + parseFloat(cost.totalWashPriceCost),
        washPointName: cost.washPointName
      };
    })
  );
  
  // En düşük maliyetli aracı bul
  const optimal = calculations.reduce((best, current) => {
    return current.allCost < best.allCost ? current : best;
  }, calculations[0]);
  
  return optimal;
};