/**
 * recommendModules/helpers/calculateVehicleCosts.js
 * Araç maliyetlerini hesaplayan yardımcı fonksiyon
 */

const { calculator } = require("../../cost");

/**
 * Maliyet hesaplamaları için batch işleme yapan fonksiyon
 * @param {Array} vehicles - Araçlar listesi
 * @param {Object} order - Sipariş detayları
 * @param {string} token - Kimlik doğrulama tokeni
 * @param {number} batchSize - Batch boyutu
 * @returns {Promise<Array>} Maliyet bilgileriyle zenginleştirilmiş araçlar
 */
async function calculateVehicleCosts(vehicles, order, token, batchSize = 10) {
  // Promise'leri toplu halde oluştur ama hemen çalıştırma
  const costPromises = vehicles.map(vehicle => {
    return () => calculator(
      token,
      1,
      1,
      vehicle,
      order,
      Number(vehicle.distance),
      0,
      true,
      true,
      1,
      Number(vehicle.distance) / 50
    ).then(cost => {
      return {
        ...vehicle,
        washPointName: cost.washPointName,
        recomendationTotalCost: Number(cost.total),
        washCost: Number(cost.totalWashPriceCost),
        allCost: Number(cost.total) + Number(cost.totalWashPriceCost),
        distanceWithWashing: cost?.distanceWithWashing
      };
    }).catch(error => {
      console.error(`Failed to calculate cost for vehicle ${vehicle.id}:`, error);
      return {
        ...vehicle,
        recomendationTotalCost: 0,
        washCost: 0,
        allCost: 0
      };
    });
  });

  // Batch işleme için slice - her seferde batchSize kadar araç işle
  const results = [];
  
  for (let i = 0; i < costPromises.length; i += batchSize) {
    const batch = costPromises.slice(i, i + batchSize);
    const batchResults = await Promise.all(batch.map(promise => promise()));
    results.push(...batchResults);
  }
  
  return results;
}

module.exports = calculateVehicleCosts;