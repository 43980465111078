<template>
  <div class="flex items-center py-3 px-4">
    <div v-if="icon" class="text-gray-400 mr-3 w-5 text-center">
      <i :class="`fas ${icon}`"></i>
    </div>
    
    <div class="flex-1">
      <div class="flex justify-between items-center">
        <span class="text-sm text-gray-600">{{ title }}</span>
        <span :class="['text-sm font-medium', valueColor]">{{ value }}</span>
      </div>
      
      <!-- Slot for extra content like progress bars -->
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailItem",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    icon: {
      type: String,
      default: ""
    },
    valueColor: {
      type: String,
      default: "text-gray-700"
    }
  }
};
</script>