
const { calculateDistancePriority } = require("../../calculator/calculateDistancePriority");
const { areAllVehiclesTooFar } = require("./areAllVehiclesTooFar");
const { findBusyButSuitableVehicles } = require("./findBusyButSuitableVehicles");



/**
 * Uzak mesafede araç bulunamadığında araç çekme kontrolünü yapar
 * @param {Array} recommendedVehicles - Önerilen araçlar 
 * @param {Array} allVehicles - Tüm araçlar listesi
 * @param {Object} order - Sipariş detayları
 * @param {Array} products - Ürünler listesi
 * @param {number} maxDistance - Maksimum mesafe
 * @param {boolean} isBulk - Toplu taşıma mı
 * @param {boolean} skipTheTonnage - Tonaj kontrolünü atla
 * @param {boolean} isClose - Yakında mı
 * @returns {Object|null} - REALLOCATION_NEEDED durumu veya null
 */
function checkForReallocation(
    recommendedVehicles, 
    allVehicles, 
    order, 
    products, 
    maxDistance = 300,
    isBulk = false,
    skipTheTonnage = false,
    isClose = false
  ) {
    // Herhangi bir araç bulunamadıysa veya bulunan araçlar çok uzaktaysa
    if ((recommendedVehicles.length === 0 || areAllVehiclesTooFar(recommendedVehicles, maxDistance, order))) {
      // İşi olan ancak yine de uygun olabilecek araçları bul
      const busyButSuitableVehicles = findBusyButSuitableVehicles(
        allVehicles, 
        order, 
        products, 
        isBulk, 
        skipTheTonnage, 
        isClose
      );
      
      console.log(`İşi olan ama teknik olarak uygun araç sayısı: ${busyButSuitableVehicles.length}`);
      
      // Eğer işi olan uygun araçlar varsa, bunları sırala ve döndür
      if (busyButSuitableVehicles.length > 0) {
        // Mesafeye göre sırala
        const sortedBusyVehicles = busyButSuitableVehicles.map(vehicle => {
          const distancePriority = calculateDistancePriority(order, vehicle);
          return {
            ...vehicle,
            distance: parseFloat(distancePriority).toFixed(2),
            // Araç bilgilerini ekle
            orderedFor: Array.isArray(vehicle.orderNos) ? vehicle.orderNos.join(', ') : '',
            // Planlama bilgilerini ekle  
            planningInfo: vehicle.planning || {}
          };
        }).sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
        
        // En fazla 10 araç döndür
        const limitedBusyVehicles = sortedBusyVehicles.slice(0, 10);
        
        return {
          REALLOCATION_NEEDED: true,
          maxDistance: maxDistance,
          vehicles: limitedBusyVehicles, // İşi olan ama uygun araçlar
          busyVehiclesCount: busyButSuitableVehicles.length,
          message: `${maxDistance} km mesafede uygun boş araç bulunamadı. Aktif siparişlerden araç çekmek ister misiniz?`
        };
      }
      
      // İşi olan uygun araç da bulunamadıysa
      return {
        REALLOCATION_NEEDED: true,
        maxDistance: maxDistance,
        vehicles: [], // Boş
        busyVehiclesCount: 0,
        message: `Ne ${maxDistance} km mesafede uygun araç ne de işi olan uygun araç bulunamadı.`
      };
    }
    
    // Uzak mesafe bir durum yok, null döndür
    return null;
  }

  module.exports={
    checkForReallocation
  }
  
  