<template>
  <div class="flex justify-center items-center h-32">
    <div
      class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-500"
    ></div>
  </div>
</template>
  
  <script>
export default {
  name: "LoadingSpinner",
};
</script>