<template>
  <div class="w-full relative">
    <!-- Toast Yenileme Animasyonu -->
    <div
      v-if="showToast"
      class="fixed top-5 right-5 bg-blue-600 text-white px-4 py-3 rounded-lg shadow-lg z-50 flex items-center animate-toast"
    >
      <i class="fas fa-sync-alt mr-2" :class="{ 'fa-spin': toastLoading }"></i>
      <span>{{ toastMessage }}</span>
    </div>

    <!-- Harita Tip Değiştirme Butonu - Animasyon eklendi -->
    <button
      @click="$store.commit('toggleMapStatus')"
      class="px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center absolute right-2 top-2 z-50 shadow-xl transition-all duration-300 hover:scale-105"
      :class="$store.state.mapView == true ? 'bg-black ' : 'bg-white'"
    >
      <img
        class="w-6 h-6"
        :src="
          $store.state.mapView == false
            ? require('@/assets/marker/mapstatus.svg')
            : require('@/assets/marker/mapstatus_white.svg')
        "
      />
    </button>

    <!-- Manuel Yenileme Butonu -->
    <button
      @click="refresh()"
      class="px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center absolute right-2 top-20 z-50 shadow-xl transition-all duration-300 hover:scale-105 bg-white"
      title="Haritayı Yenile"
      :disabled="toastLoading"
    >
      <i class="fas fa-sync-alt text-blue-600" :class="{ 'fa-spin': toastLoading }"></i>
    </button>

    <!-- Arama ve Filtreleme Seçenekleri -->
    <div class="absolute left-5 top-5 z-50 bg-white shadow-xl rounded-lg p-3 w-72">
      <div class="mb-2">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Araç ara..."
          class="w-full p-2 border rounded"
          @input="filterMarkers"
        />
      </div>
      <div class="flex space-x-2">
        <button
          @click="filterByStatus('all')"
          class="px-2 py-1 text-xs rounded"
          :class="currentFilter === 'all' ? 'bg-blue-600 text-white' : 'bg-gray-200'"
        >
          Tümü
        </button>
        <button
          @click="filterByStatus('moving')"
          class="px-2 py-1 text-xs rounded"
          :class="currentFilter === 'moving' ? 'bg-blue-600 text-white' : 'bg-gray-200'"
        >
          Hareket Halinde
        </button>
        <button
          @click="filterByStatus('stopped')"
          class="px-2 py-1 text-xs rounded"
          :class="currentFilter === 'stopped' ? 'bg-blue-600 text-white' : 'bg-gray-200'"
        >
          Durmuş
        </button>
      </div>
    </div>

    <!-- Grup Detay Bileşeni - Geliştirilen liste görünümü -->
    <GroupDetail
      @close="refresh()"
      :List="filteredMarkers"
      :position="position"
      v-if="markers.length > 0"
      @selected="handleMarkerSelect"
      class="z-40"
    />

    <!-- Harita Bileşeni -->
    <div>
      <GmapMap
        ref="gmap"
        :center="position"
        :zoom="zoomLevel"
        :map-type-id="$store.state.mapView ? 'hybrid' : 'roadmap'"
        style="width: 100%; height: 67vh !important"
        :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false,
        }"
      >
        <GmapCluster @click="handleClusterMouseOver">
          <GmapMarker
            v-for="m in filteredMarkers"
            :key="m.id"
            :title="m.title"
            :animation="
              m.id === selectedMarkerId && googleMapsLoaded
                ? google.maps.Animation.BOUNCE
                : null
            "
            :label="{
              text: m.title,
              color: 'white',
              className: `absolute bottom-4 -right-8 text-xs px-2 text-xs font-bold rounded tracking-widest ${
                m.id === selectedMarkerId ? 'bg-red-600' : 'bg-blue-700'
              }`,
              fontSize: '8.5px',
            }"
            :position="m.position"
            :icon="m.id === selectedMarkerId ? getHighlightedIcon(m) : m.icon"
            @click="handleMarkerClick(m)"
          >
          </GmapMarker>
        </GmapCluster>
      </GmapMap>
    </div>

    <!-- Araç Bulunamadı Ekranı - Geliştirilmiş görünüm -->
    <div
      v-if="!toastLoading && markers.length == 0"
      class="w-full flex items-center justify-center"
      style="height: 50vh"
    >
      <div class="inline text-center bg-white p-6 rounded-lg shadow-md max-w-md">
        <i class="fas fa-exclamation-triangle fa-4x text-yellow-500"></i>
        <h2 class="text-xl font-bold mt-4 mb-2">Araç Konumu Bulunamadı</h2>
        <p class="block mt-2 px-6 leading-7 text-gray-600">
          Lütfen aracın takip sisteminin doğru çalıştığından emin olun ve internet
          bağlantınızı kontrol edin.
        </p>
        <button
          @click="refresh()"
          class="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
        >
          <i class="fas fa-sync-alt mr-2"></i>Yenile
        </button>
      </div>
    </div>

    <!-- Seçilen Araç Detay Kartı -->
    <div
      v-if="selectedMarker"
      class="absolute bottom-5 left-5 right-5 md:left-auto md:right-5 md:w-80 bg-white p-4 rounded-lg shadow-lg z-50 transition-all duration-300"
    >
      <div class="flex justify-between items-start mb-2">
        <h3 class="font-bold text-lg">{{ selectedMarker.title }}</h3>
        <button @click="selectedMarker = null" class="text-gray-500 hover:text-gray-700">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="grid grid-cols-2 gap-2 text-sm">
        <div>
          <p class="text-gray-600">Bölge:</p>
          <p class="font-medium">{{ selectedMarker.region || "Belirtilmemiş" }}</p>
        </div>
        <div>
          <p class="text-gray-600">Durum:</p>
          <p class="font-medium">
            {{ selectedMarker.speed == 0 ? "Durmuş" : "Hareket Halinde" }}
          </p>
        </div>
        <div class="col-span-2">
          <p class="text-gray-600">Adres:</p>
          <p class="font-medium">{{ selectedMarker.adress || "Adres bilgisi yok" }}</p>
        </div>
        <div>
          <p class="text-gray-600">Dorse:</p>
          <p class="font-medium">{{ selectedMarker.dorse || "Belirtilmemiş" }}</p>
        </div>
        <div>
          <p class="text-gray-600">Sürücü:</p>
          <p class="font-medium">{{ selectedMarker.driverName || "Belirtilmemiş" }}</p>
        </div>
      </div>
      <div class="mt-3 flex justify-between">
        <button
          @click="centerAndZoomToMarker(selectedMarker)"
          class="bg-blue-600 text-white px-3 py-1 rounded text-sm hover:bg-blue-700 transition-colors"
        >
          <i class="fas fa-search-location mr-1"></i>Yakınlaştır
        </button>
        <button
          @click="openDirections(selectedMarker)"
          class="bg-green-600 text-white px-3 py-1 rounded text-sm hover:bg-green-700 transition-colors"
        >
          <i class="fas fa-directions mr-1"></i>Yol Tarifi
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import GroupDetail from "@/components/modals/markerDetail/groupDetail.vue";

// axios
import axios from "axios";
import { tracking, bulkPlanning, bulkShipPlanning } from "@/networking/urlmanager";
import { getLocationAll } from "../../../utils/detailData";

export default {
  name: "collectivelocation-location-detail",
  props: ["rowId", "activeAxios"],
  components: {
    GroupDetail,
  },
  data() {
    return {
      isAll: true,
      vehicles: [],
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      showDetail: false,
      markers: [],
      filteredMarkers: [],
      searchTerm: "",
      currentFilter: "all",
      selectedMarker: null,
      selectedMarkerId: null,
      styles: {
        position: "absolute",
        top: "-20px",
      },
      toastLoading: false,
      showToast: false,
      toastMessage: "Araç konumları yükleniyor...",
      refreshInterval: null,
      googleMapsLoaded: false,
      google: null, // Vue'da google'ı reaktif bir değişken olarak tanımla
      isLoadingData: false, // Veri yükleme durumunu izleyen bayrak
    };
  },
  methods: {
    refresh() {
      // Prevent refresh if already loading
      if (this.isLoadingData || this.toastLoading) {
        return;
      }

      this.markers = [];
      this.filteredMarkers = [];
      this.selectedMarker = null;
      this.selectedMarkerId = null;
      this.showToast = true;
      this.toastLoading = true;
      this.toastMessage = "Araç konumları yükleniyor...";
      this.getVehicleAll();

      // Toast mesajını 5 saniye sonra kapat
      setTimeout(() => {
        this.hideToast();
      }, 5000);
    },
    hideToast() {
      this.showToast = false;
    },
    showSuccessToast() {
      this.toastLoading = false;
      this.toastMessage = "Araç konumları güncellendi!";
      this.showToast = true;

      // 3 saniye sonra toast'ı kapat
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },
    showErrorToast(message = "Bir hata oluştu!") {
      this.toastLoading = false;
      this.toastMessage = message;
      this.showToast = true;

      // 3 saniye sonra toast'ı kapat
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },
    getVehicleAll() {
      this.toastLoading = true;
      this.vehicles = [];

      const endpoint =
        this.activeAxios === 1 ? bulkPlanning.vehiclesAll : bulkShipPlanning.vehiclesAll;

      axios
        .get(endpoint + "?rowId=" + this.rowId + "&isAll=" + this.isAll, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.vehicles = res.data.data.filter((r) => r.isStopped != 1);
          this.getAll();
        })
        .catch((err) => {
          this.toastLoading = false;
          this.isLoadingData = false;
          this.showErrorToast(
            "Araçlar yüklenemedi: " + (err.response?.data?.message || "Bağlantı hatası")
          );
          if (typeof this.errorBox === "function") {
            this.errorBox(err.response);
          } else {
            console.error("Araçlar yüklenemedi:", err);
          }
        });
    },
    getAll() {
      // Prevent multiple simultaneous calls
      if (this.isLoadingData) return;

      this.isLoadingData = true;

      getLocationAll(this.$store.state.userData.token)
        .then((res) => {
          if (Array.isArray(res)) {
            if (res.length > 0) {
              let markers = res.filter((r) => {
                return this.vehicles.find(
                  (a) =>
                    a.plaque.toLowerCase().replace(/\s/g, "") ==
                    r.vehicle.toLowerCase().replace(/\s/g, "")
                );
              });

              if (markers.length > 0) {
                this.center = {
                  lat: markers[0].latitude,
                  lng: markers[0].longitude,
                };
                this.position = {
                  lat: markers[0].latitude,
                  lng: markers[0].longitude,
                };

                this.markers = markers.map((el) => {
                  let icon = null;

                  if (el.speed == 0) {
                    icon = require("@/assets/marker/2.png");
                  } else {
                    switch (el.speedDirection) {
                      case 0:
                        icon = require("@/assets/marker/0.png");
                        break;
                      case 1:
                        icon = require("@/assets/marker/1.png");
                        break;
                      case 3:
                        icon = require("@/assets/marker/3.png");
                        break;
                      case 4:
                        icon = require("@/assets/marker/4.png");
                        break;
                      default:
                        icon = require("@/assets/marker/5.png");
                        break;
                    }
                  }

                  return {
                    id: el.id,
                    title: el.vehicle,
                    region: el.region,
                    position: {
                      lat: parseFloat(el.latitude),
                      lng: parseFloat(el.longitude),
                    },
                    adress: el.address,
                    vehicle: el.vehicle,
                    dorse: el.dorse,
                    driverName: el.driverName,
                    state: el.state,
                    isBulkOperation: el.isBulkOperation,
                    icon: icon,
                    speed: el.speed,
                    speedDirection: el.speedDirection,
                  };
                });

                this.filteredMarkers = [...this.markers];
                this.zoomLevel = 8;
              }
            }
          }

          // Yükleme işlemi tamamlandığında toast mesajını güncelle
          this.toastLoading = false;
          this.isLoadingData = false;
          this.showSuccessToast();
        })
        .catch((err) => {
          console.log(err);
          this.toastLoading = false;
          this.isLoadingData = false;
          this.showErrorToast("Konum verileri yüklenemedi");
        });
    },
    reset() {
      this.toastLoading = true;
      this.position = { lat: 41.015137, lng: 28.97953 };
      this.zoomLevel = 5;
      this.markers = [];
      this.filteredMarkers = [];
      this.selectedMarker = null;
      this.selectedMarkerId = null;
    },
    handleMarkerSelect(val) {
      const marker = this.markers.find((r) => r.title.includes(val));
      if (marker) {
        this.centerAndZoomToMarker(marker);
        this.selectedMarker = marker;
        this.selectedMarkerId = marker.id;
      }
    },
    handleMarkerClick(marker) {
      this.selectedMarker = marker;
      this.selectedMarkerId = marker.id;
      this.centerAndZoomToMarker(marker);
    },
    centerAndZoomToMarker(marker) {
      if (marker && marker.position) {
        this.position = marker.position;
        this.zoomLevel = 14;

        // Haritanın merkez pozisyonunu değiştir
        if (this.$refs.gmap && this.$refs.gmap.$mapObject) {
          this.$refs.gmap.$mapObject.setCenter(marker.position);
          this.$refs.gmap.$mapObject.setZoom(14);
        }
      }
    },
    handleClusterMouseOver(cluster) {
      if (typeof cluster.markers_ != "undefined") {
        // Burada filtreleme ve seçim mantığını koruyoruz
        // Ancak cluster içindeki belirli bir markere tıklandığında o markerı seçeriz
        const clusterMarkers = cluster.markers_;
        if (clusterMarkers.length === 1) {
          this.handleMarkerClick(clusterMarkers[0]);
        } else {
          // Birden fazla marker içeren cluster tıklandığında
          // filteredMarkers'ı güncelliyoruz
          this.filteredMarkers = clusterMarkers;
        }
      }
    },
    filterMarkers() {
      if (!this.searchTerm && this.currentFilter === "all") {
        this.filteredMarkers = [...this.markers];
        return;
      }

      let filtered = [...this.markers];

      // Arama terimini uygula
      if (this.searchTerm) {
        const term = this.searchTerm.toLowerCase();
        filtered = filtered.filter(
          (m) =>
            m.title.toLowerCase().includes(term) ||
            (m.region && m.region.toLowerCase().includes(term)) ||
            (m.driverName && m.driverName.toLowerCase().includes(term))
        );
      }

      // Filtrelemeyi uygula
      if (this.currentFilter !== "all") {
        filtered = filtered.filter((m) => {
          if (this.currentFilter === "moving") {
            return m.speed > 0;
          } else if (this.currentFilter === "stopped") {
            return m.speed === 0;
          }
          return true;
        });
      }

      this.filteredMarkers = filtered;
    },
    filterByStatus(status) {
      this.currentFilter = status;
      this.filterMarkers();
    },
    getHighlightedIcon(marker) {
      // Mevcut ikonları kullan ama seçili marker için ölçeklendirme yap
      let icon = null;

      if (marker.speed == 0) {
        icon = require("@/assets/marker/2.png");
      } else {
        switch (marker.speedDirection) {
          case 0:
            icon = require("@/assets/marker/0.png");
            break;
          case 1:
            icon = require("@/assets/marker/1.png");
            break;
          case 3:
            icon = require("@/assets/marker/3.png");
            break;
          case 4:
            icon = require("@/assets/marker/4.png");
            break;
          default:
            icon = require("@/assets/marker/5.png");
            break;
        }
      }

      // Google Maps API kontrolü
      if (this.googleMapsLoaded && this.google) {
        // Var olan ikon URL'sini kullan ama boyutunu büyüt
        return {
          url: icon,
          scaledSize: new this.google.maps.Size(36, 36), // Normal boyutun 1.5 katı (varsayılan 24x24)
          origin: new this.google.maps.Point(0, 0),
          anchor: new this.google.maps.Point(18, 18),
        };
      } else {
        // Google Maps API yüklenmemişse sadece URL'yi döndür
        return icon;
      }
    },
    openDirections(marker) {
      // Google Maps yol tarifi için link oluştur
      if (marker && marker.position) {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${marker.position.lat},${marker.position.lng}`;
        // Güvenli bir şekilde yeni sekme açma
        if (typeof window !== "undefined") {
          window.open(url, "_blank");
        }
      }
    },
    // Google Maps API'nin yüklenip yüklenmediğini kontrol et
    checkGoogleMapsLoaded() {
      // window global nesnesine güvenli erişim
      if (typeof window !== "undefined") {
        if (window.google && window.google.maps) {
          this.googleMapsLoaded = true;
          this.google = window.google; // Google nesnesini reaktif değişkene ata
          console.log("Google Maps API yüklendi");
        } else {
          console.warn(
            "Google Maps API yüklenmemiş, animasyonlar ve özel ikon boyutlandırma devre dışı bırakılacak"
          );
          this.googleMapsLoaded = false;

          // Google Maps API'nin yüklenmesini bekle
          const checkGoogleInterval = setInterval(() => {
            if (window.google && window.google.maps) {
              this.googleMapsLoaded = true;
              this.google = window.google; // Google nesnesini reaktif değişkene ata
              clearInterval(checkGoogleInterval);
              console.log("Google Maps API yüklendi, tüm özellikler etkinleştirildi");
            }
          }, 20000);

          // 20 saniye sonra hala yüklenmediyse intervali temizle
          setTimeout(() => {
            if (!this.googleMapsLoaded) {
              clearInterval(checkGoogleInterval);
              console.error("Google Maps API 20 saniye içinde yüklenemedi");
            }
          }, 20000);
        }
      }
    },
  },
  created() {
    this.getVehicleAll();
    this.checkGoogleMapsLoaded();
  },
  watch: {
    rowId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.refresh();
        }
      },
      immediate: false,
    },
  },
};
</script>

<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Marker animasyon stillerini ekle */
@keyframes markerPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.selected-marker {
  animation: markerPulse 1.5s infinite;
}

/* Toast animasyonu */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-toast {
  animation: fadeInDown 0.5s forwards;
}

.toast-exit {
  animation: fadeOut 0.5s forwards;
}
</style>
