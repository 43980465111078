const similarity = require("similarity");

const washingStatus = {
  yes: 1,
  no: 2,
  driverAction: 3,
};


/**
 * Sipariş ve araç için uygunluk kontrolü yapar.
 * @param {Object} order - Sipariş detayları.
 * @param {Object} vehicle - Araç detayları.
 * @param {Array} products - Ürünler listesi.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @returns {boolean} Araçın sipariş için uygun olup olmadığı bilgisi.
 */

function isWashStatus(customers, products, vehicle, order) {
  const customer = customers.find(
    (c) => similarity(c.name, order.customer) > 0.85
  );

  let scoreTypes = {
    status: 0,
    score: 0,
  };
  if (customer?.requiredWashingStatus == washingStatus.yes) {
    // müşteri yıkamayı zorunlu tutmuş
    scoreTypes.score = 1;
    scoreTypes.status = washingStatus.yes;
  } else {
    if (vehicle?.lastProduct) {
      if (vehicle.lastProduct == (order?.productType || order?.productName)) {
        // ürün aynı ise yıkamaya gerek yok
        scoreTypes.score = 1;
        scoreTypes.status = washingStatus.no;
      } else {

        const filteredProducts = products
        .map((pValue) => ({
          product: pValue,
          score: similarity(
            pValue?.name,
            order?.productName ? order?.productName : order?.productType
          ),
        }))
        .filter((item) => item.score >= 0.8);
      const findProduct = filteredProducts.length
        ? filteredProducts.reduce((bestMatch, item) =>
            item.score > bestMatch.score ? item : bestMatch
          ).product
        : null; // Eğer uygun ürün yoksa `null` döndür

        // aracın en son taşıdığı yük ile mevcut ürünün birlikte yıkama yapmadan taşınabileceği ürünler kontrol edilir
        if (
          findProduct?.movableProducs?.some(
            (r) => similarity(r.name, vehicle.lastProduct) > 0.8
          )
        ) {
          // aracı yıkamaya gerek yok
          scoreTypes.score = 0.5;
          scoreTypes.status = washingStatus.no;
        } else {
          scoreTypes.score = 1;
          scoreTypes.status = washingStatus.yes;
        }
      }
    } else {
      scoreTypes.score = 1;
      scoreTypes.status = washingStatus.driverAction;
      // son ürün yok ise sürücüye sormalısın
    }
  }
  return scoreTypes;
}


module.exports={
    isWashStatus
}