<template>
  <div class="w-full bg-white rounded-lg shadow-sm">
    <div class="border-b border-gray-200 px-4 py-3">
      <h3 class="text-sm font-bold text-gray-700">Araç Bilgileri</h3>
    </div>
    
    <div class="divide-y divide-gray-100">
      <!-- Görev Durumu -->
      <detailItem
        title="Görev Durumu"
        :value="detail?.state != 0 ? 'Aktif' : 'Pasif'"
        :valueColor="detail?.state != 0 ? 'text-green-600' : 'text-gray-500'"
        icon="fa-toggle-on"
      />

      <!-- Sürücü -->
      <detailItem
        title="Sürücü"
        :value="detail?.driverName ? detail?.driverName : 'Belirlenmedi'"
        icon="fa-user"
      />
      
      <!-- Sürüş Süresi -->
      <detailItem 
        v-if="!detail.isPirateDriver" 
        title="Sürüş Süresi" 
        :value="detail?.takoDate" 
        icon="fa-clock"
      />
      
      <!-- Araç Tipi -->
      <detailItem 
        title="Araç Tipi" 
        :value="detail?.vehicleType" 
        icon="fa-truck"
      />
      
      <!-- Dorse -->
      <detailItem
        title="Dorse"
        :value="detail?.dorse ? detail?.dorse : 'Belirlenmedi'"
        icon="fa-truck-loading"
      />
      
      <!-- Taşıma Kapasitesi -->
      <detailItem
        title="Taşıma Kapasitesi"
        :value="detail?.capacity ? detail?.capacity + ' Ton' : 'Dorse Yok'"
        icon="fa-weight"
      />
      
      <!-- Yakıt Seviyesi -->
      <detailItem
        v-if="!detail.isPirateDriver"
        title="Yakıt Seviyesi"
        :value="detail?.canbusFuelLevel > 0 ? detail?.canbusFuelLevel + ' %' : '0%'"
        icon="fa-gas-pump"
      >
        <template v-slot:extra>
          <div class="w-full bg-gray-200 rounded h-1.5 mt-1">
            <div class="h-1.5 rounded" 
              :class="getFuelBarColor(detail?.canbusFuelLevel)"
              :style="`width: ${detail?.canbusFuelLevel > 0 ? detail?.canbusFuelLevel : 0}%`">
            </div>
          </div>
        </template>
      </detailItem>
      
      <!-- Araç Hızı -->
      <detailItem
        v-if="!detail.isPirateDriver"
        title="Araç Hızı" 
        :value="detail?.speed + ' km/h'" 
        icon="fa-tachometer-alt"
        :valueColor="getSpeedColor(detail?.speed)"
      />
      
      <!-- Bölge -->
      <detailItem
        v-if="!detail.isPirateDriver"
        title="Bölge" 
        :value="detail?.region" 
        icon="fa-map-marker-alt"
      />

      <detailItem
        v-if="!detail.isPirateDriver"
        title="Filo" 
        :value="detail?.fleetName" 
        icon="fa-building"
      />
    </div>
  </div>
</template>

<script>
// Local Components
import detailItem from "./item.vue";

export default {
  name: "current-detail",
  props: ["detail"],
  components: {
    detailItem,
  },
  methods: {
    getFuelBarColor(level) {
      if (!level || level < 20) return 'bg-red-500';
      if (level < 40) return 'bg-yellow-500';
      return 'bg-green-500';
    },
    getSpeedColor(speed) {
      if (!speed || speed === 0) return 'text-gray-500';
      if (speed > 90) return 'text-red-600';
      return 'text-green-600';
    }
  }
};
</script>