<template>
  <tr colspan="3">
    <td class="table-td border-r border-opacity-20">#</td>
    <td class="table-td border-r border-opacity-20">
      <select
        v-model="selectedOrder"
        class="w-full roudned p-2 text-black rounded"
      >
        <option value="">Seçilmedi</option>
        <option v-for="item in order" :key="item.orderNo" :value="item.orderNo">
          {{ item.orderNo + " / " + item.name }}
        </option>
      </select>
    </td>
    <td class="table-td border-r border-opacity-20">
      <vehicleSelect
        v-if="selectedOrder > 0"
        v-model="vehicle"
        :optList="getFilterVehicle"
        :order="selectedOrderDetail"
        :products="products"
        :customers="customers"
        :load="vehicleLoad"
        :isBulk="true"
        :sorted="true"
        :warningControl="true"
        :selectedVehicles="selectedVehicles"
      />
    </td>
    <td class="table-td border-r border-opacity-20">
      {{ vehicle?.address }}
    </td>
    <td class="table-td border-r border-opacity-20">
      {{ vehicle?.washPointName }}
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="vehicle?.washCost >= 0"> {{ vehicle.washCost }} </span>
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="vehicle?.recomendationTotalCost >= 0">
        {{ vehicle.recomendationTotalCost }}
      </span>
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="vehicle?.allCost">
        {{ vehicle.allCost.toFixed(2) }}
      </span>
    </td>
    <td class="text-center">
      <button type="button" class="process-btn" @click="addItem">
        <i class="fas fa-plus mr-1.5"></i> Ekle
      </button>
    </td>
  </tr>
</template>

<script>
import { calculator } from "@/utils/cost";
import { calculateDistancePriority,isWashStatus } from "@/utils/recomendation";
import vehicleSelect from "../../../../../orders/components/vehicle-select.vue";

export default {
  name: "new-vehicle-row",
  props: [
    "order",
    "products",
    "customers",
    "vehicleList",
    "activeList",
    "selectedVehicles",
  ],
  components: {
    vehicleSelect,
  },
  data() {
    return {
      vehicle: "",
      vehicleLoad: false,
      dorse: "",
      driverName: "",
      driverTC: "",
      capacity: "",
      selectedOrder: null,
      selectedOrderDetail: {},
      calculationInProgress: false,
    };
  },
  methods: {
    async calculateCost(vehicleParameter) {
      if (
        !this.vehicle ||
        this.calculationInProgress ||
        !this.selectedOrderDetail
      )
        return null;

      this.calculationInProgress = true;

      const distancePriority = calculateDistancePriority(
        this.selectedOrderDetail,
        this.vehicle
      );
      const distance = parseFloat(distancePriority).toFixed(2);

      try {
        const cost = await calculator(
          this.$store.state.userData.token,
          1,
          1,
          vehicleParameter||this.vehicle,
          this.selectedOrderDetail,
          distance,
          0,
          true,
          true,
          1,
          distance / 50
        );

        return {
          ...this.vehicle,
          washPointName: cost.washPointName,
          recomendationTotalCost: Number(cost.total),
          washCost: Number(cost.totalWashPriceCost),
          allCost: Number(cost.total) + Number(cost.totalWashPriceCost),
          distanceWithWashing:cost?.distanceWithWashing
        };
      } catch (error) {
        console.error(
          `Failed to calculate cost for vehicle ${
            this.vehicle?.id || "unknown"
          }:`,
          error
        );
        return null;
      } finally {
        this.calculationInProgress = false;
      }
    },

    async addItem() {
      if (!this.vehicle || !this.selectedOrder) return;

      try {
        this.vehicleLoad = true;

        if (!this.vehicle.recomendationTotalCost) {
          const costData = await this.calculateCost();
          if (costData) {
            this.vehicle = { ...this.vehicle, ...costData };
          }
        }

        const item = this.order?.find(
          (item) => item.orderNo == this.selectedOrder
        );

        if (item) {
          this.$emit("addItem", {
            ...this.vehicle,
            orderNo: item.orderNo,
            orderName: item.name,
            outpointLat: item.outpointLat,
            outpointLng: item.outpointLng,
          });
          this.vehicle = "";
        }
      } catch (error) {
        console.error("Error adding vehicle:", error);
      } finally {
        this.vehicleLoad = false;
      }
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList?.map((item) => ({
        ...item,
        name: item.vehicle,
        isRent: item?.haveType,
      }));
    },
  },
  watch: {
    selectedOrder(val) {
      const item = this.order?.find((item) => item.orderNo == val);
      this.selectedOrderDetail = item || {};
    },
    vehicle: {
      async handler(newValue, oldValue) {
        if (
          newValue &&
          this.selectedOrderDetail &&
          (!newValue.recomendationTotalCost || !newValue.washCost) &&
          JSON.stringify(newValue) !== JSON.stringify(oldValue)
        ) {
          let vehicleFromVehicleList = this.vehicleList.find((item) => item.plate === this.vehicle.plate)
          const costData = await this.calculateCost(
            {...vehicleFromVehicleList, recomendation:{
            isWashing: isWashStatus(
              this.customers,
              this.products,
              vehicleFromVehicleList,
              this.order
            ).status
            }}
          );
          if (costData) {
            this.vehicle = { ...this.vehicle, ...costData };
          }
        }
      },
      deep: true,
    },
  },
};
</script>
