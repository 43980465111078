const {
  tracking,
  customer,
  product,
  routePlanner,
} = require("@/networking/urlmanager");
const { routeEngine } = require("@/networking/routeEngine");
const axios = require("axios");

const productCache = {
  data: null,
  timestamp: 0,
};

const customerCache = {
  data: null,
  timestamp: 0,
};
const locationCache = {
  data: null,
  timestamp: 0,
};



async function getLocationAll(token) {
  const cacheDuration = 180000; // 1 dakika = 60,000 ms
  const currentTime = Date.now();

  // Eğer cache geçerli ise cache'den veriyi döndür
  if (locationCache.data && currentTime - locationCache.timestamp < cacheDuration) {
    console.log("Cache kullanılıyor (Araç verisi)...");
    return locationCache.data;
  }

  try {
    console.log("API'den araç verisi alınıyor...");
    const result = await axios.get(tracking.getAll + "?isActive=true", {
      headers: {
        Authorization: "Bareer " + token,
      },
    });

    const data = result.data.data
      .map((item) => ({
        ...item,
        isAvailable: true,
      }))
      .filter((item) => item.driverTC);

    // Yeni veriyi cache'e kaydet
    locationCache.data = data;
    locationCache.timestamp = currentTime;

    return data;
  } catch (error) {
    console.error("Araç verileri getirilemedi:", error.message);

    // Kullanıcıya anlamlı bir hata mesajı göstermek için alert yerine console uyarısı
    return [];
  }
}
async function getCustomers(token) {
  const cacheDuration = 300000; // 1 dakika = 60,000 ms
  const currentTime = Date.now();

  // Eğer cache'de veri varsa ve süresi dolmadıysa, cache'i kullan
  if (customerCache.data && currentTime - customerCache.timestamp < cacheDuration) {
    console.log("Cache kullanılıyor (Müşteri verisi)...");
    return customerCache.data;
  }

  try {
    console.log("API'den müşteri verisi alınıyor...");
    const result = await axios.get(customer.getAllNotPagination, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });

    const data = Array.isArray(result.data.data) ? result.data.data : [];

    // Yeni veriyi cache'e kaydet
    customerCache.data = data;
    customerCache.timestamp = currentTime;

    return data;
  } catch (error) {
    console.log(error.response);
    return [];
  }
}
async function getProducts(token) {
  const cacheDuration = 300000; // 1 dakika = 60,000 ms
  const currentTime = Date.now();

  // Eğer cache'de veri varsa ve süresi dolmadıysa, cache'i kullan
  if (productCache.data && currentTime - productCache.timestamp < cacheDuration) {
    console.log("Cache kullanılıyor...");
    return productCache.data;
  }

  try {
    console.log("API'den veri alınıyor...");
    const result = await axios.get(product.getWithFilterAll, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });

    const data = Array.isArray(result.data.data) ? result.data.data : [];

    // Yeni veriyi cache'e kaydet
    productCache.data = data;
    productCache.timestamp = currentTime;

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
async function getRoute(
  from = { lat: 0, lng: 0 },
  to = { lat: 0, lng: 0 },
  isAdr = false,
  token
) {
  try {
    if (from.lat == 0 || from.lng == 0 || to.lat == 0 || to.lng == 0) {
      console.log(
        "Başlangıç ve bitiş konumları boş olduğundan dolayı rota hesaplaması yapılamadı"
      );
      return;
    }

    const detail = await axios.post(
      routeEngine.generate,
      {
        from,
        to,
        waypoints: [],
        isToll: 1,
        routeType: "fast",
        shippedHazardousGoods: isAdr,
        isShip: 1,
        isAsphalt: 1,
        fuelRatio: 35,
        isPolyline: 1,
        vehicleTypeId: 5, // tır
        isApi: 1,
      },
      {
        headers: {
          Authorization: "Bareer " + this.$store.state.routeEngineToken,
        },
      }
    );
    return detail.data.data;
    // return detail.data.data[0];
  } catch (error) {
    console.warn(error);
    return null;
  }
}


const yesNoOptions = [
  {
    id: 1,
    name: "Evet",
  },
  {
    id: 2,
    name: "Hayır",
  },
];
module.exports = {
  getLocationAll,
  getCustomers,
  getProducts,
  getRoute,
  yesNoOptions,
};
