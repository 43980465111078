<template>
  <transition name="fade">
    <div v-if="show" class="fixed inset-0 z-50 overflow-y-auto" style="z-index:99999 !important">
      <div
        class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity" @click="$emit('close')">
          <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>

        <!-- Modal Container -->
        <div
          class="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <!-- Modal Body -->
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <!-- Warning Icon -->
                <i class="fas fa-exclamation-triangle text-red-600"></i>
              </div>

              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-white">
                  Planı İptal Et
                </h3>

                <div class="mt-2">
                  <p class="text-sm text-gray-300">
                    <span class="font-bold">#{{ orderData.orderNo }}</span>
                    numaralı siparişin planlamasını iptal etmek istediğinize
                    emin misiniz?
                  </p>
                </div>

                <!-- Order Details -->
                <div class="mt-4 bg-gray-700 p-3 rounded text-sm">
                  <div class="grid grid-cols-2 gap-2">
                    <div>
                      <span class="text-gray-400">Müşteri:</span>
                      <div class="text-white">{{ orderData.customer }}</div>
                    </div>
                    <div>
                      <span class="text-gray-400">Ürün:</span>
                      <div class="text-white">{{ orderData.productType }}</div>
                    </div>
                    <div>
                      <span class="text-gray-400">Miktar:</span>
                      <div class="text-white">
                        {{ orderData.tonnage }} {{ orderData.unitType }}
                      </div>
                    </div>
                    <div>
                      <span class="text-gray-400">Tarih:</span>
                      <div class="text-white">
                        {{ formatDeliveryDate(orderData.deliveryDate) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Vehicle Info -->
                <div
                  v-if="orderData.vehicle && orderData.vehicle.name"
                  class="mt-3 p-3 bg-gray-700 rounded text-sm"
                >
                  <span class="text-gray-400">Atanan Araç:</span>
                  <div class="text-white">{{ orderData.vehicle.name }}</div>
                </div>

                <!-- Warning -->
                <div class="mt-3 text-yellow-500 text-sm">
                  <i class="fas fa-info-circle mr-1"></i>
                  Bu işlem geri alınamaz ve sipariş yeniden planlanması
                  gerekecektir.
                </div>
              </div>
            </div>
          </div>

          <!-- Modal Footer -->
          <div
            class="bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
          >
            <button
              @click="$emit('confirm')"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              Planı İptal Et
            </button>
            <button
              @click="$emit('close')"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-500 shadow-sm px-4 py-2 bg-gray-800 text-base font-medium text-gray-300 hover:bg-gray-600 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";

export default {
  name: "ConfirmationModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      // Varsayılan değer ekliyoruz - null geldiğinde hata vermemesi için
      default: () => ({
        orderNo: "",
        customer: "",
        productType: "",
        tonnage: 0,
        unitType: "",
        deliveryDate: null,
        vehicle: null,
        tabCache: new TabCache(),
        lastTabChange: Date.now(),
      }),
    },
  },
  computed: {
    // Prop üzerinden gelen order'ı kullanmak yerine computed property kullanıyoruz
    orderData() {
      return (
        this.order || {
          orderNo: "",
          customer: "",
          productType: "",
          tonnage: 0,
          unitType: "",
          deliveryDate: null,
          vehicle: null,
        }
      );
    },
  },
  methods: {
    formatDeliveryDate(date) {
      if (!date) return "Belirtilmemiş";
      return moment(date).format("D MMMM YYYY");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>