<template>
  <modal
    name="addCalculateroute-modal"
    :height="isMobile ? '100%' : '100%'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="w-full text-2xl dark:text-white dark:bg-gray-900 h-full overflow-y-scroll"
    >
      <div class="dark:bg-gray-800 px-5 shadow-sm rounded">
        <!-- Header -->
        <div class="flex items-center mb-4 w-full">
          <i class="fas fa-calculator fa-2x text-xl"></i>
          <h5 class="font-bold ml-4 text-xl w-full">Yeni Rota Hesapla</h5>
          <div class="m-4 w-1/12 ml-auto flex">
            <button type="button" @click="hide()" class="ml-12">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <!-- Header -->

        <!-- Body -->
        <form @submit.prevent="save" class="h-full bg-white p-2">
          <div class="w-full full mt-4 md:mt-0">
            <diffInput
              type="text"
              max="255"
              min="2"
              v-model="name"
              :required="true"
              title="Hesap Adı"
              class="text-base"
            />
          </div>
          <div>
            <div class="w-full mt-4">
              <gmapInputVue
                @change="(r) => changeFillingPoint(r)"
                :defaultAdress="fillingPoint.adress"
                :lat="fillingPoint.lat"
                :lng="fillingPoint.lng"
                height="150px"
                placeHolder="Başlangıç"
                :hideMap="true"
                class="text-base"
              />
            </div>
            <div class="w-full mt-4">
              <gmapInputVue
                @change="(r) => changeDeliveryPoint(r)"
                :defaultAdress="deliveryPoint.adress"
                :lat="deliveryPoint.lat"
                :lng="deliveryPoint.lng"
                height="150px"
                :hideMap="true"
                placeHolder="Bitiş"
                class="text-base"
              />
            </div>
          </div>

          <waypoints
            class="text-sm"
            :minDate="fillingDate"
            :maxDate="deliveryDate"
            v-model="waypointsList"
            :maxTonnage="30"
            :isHiddenDate="true"
          />
          <!-- maxTonnage waypoinst'de Kullanılmıyor gerek yoksa sil -->
          <div class="w-full my-6">
            <routeViewiver
              v-if="
                fillingPoint.lat > 0 &&
                fillingPoint.lng > 0 &&
                deliveryPoint.lat > 0 &&
                deliveryPoint.lng > 0
              "
              ref="routerViev"
              :customer="receivedName"
              :outpoint="deliveredName"
              :center="{
                lat: Number(fillingPoint.lat),
                lng: Number(fillingPoint.lng),
              }"
              :startPosition="{
                adress: fillingPoint.adress,
                lat: Number(fillingPoint.lat),
                lng: Number(fillingPoint.lng),
              }"
              @changeStartPosition="(val) => (fillingPoint = val)"
              @changeEndPosition="(val) => (deliveryPoint = val)"
              @changeOutpointId="(r) => (receivedName = r)"
              @changeCustomerId="(r) => (deliveredName = r)"
              @changePolyline="(r) => changePolyline(r)"
              :endPosition="{
                adress: deliveryPoint.adress,
                lat: Number(deliveryPoint.lat),
                lng: Number(deliveryPoint.lng),
              }"
              :height="'250px'"
              @change="(r) => (costDistribution = r)"
              :waypointsList="waypointsList"
            />
          </div>

          <div class="md:flex my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="number"
                v-model="hakedisKilometer"
                :required="true"
                title="Tek yön Kilometresi"
                disabled="true"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="number"
                :valCount="99"
                v-model="transitionCount"
                :required="true"
                title="Ay içi Sefer Sayısı"
                :max="99"
                :min="1"
                :step="1"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :value="
                  isOneWay == 2
                    ? hakedisKilometer * transitionCount * 2
                    : hakedisKilometer
                "
                :required="true"
                :disabled="true"
                title="Toplam Kilometre"
              />
            </div>
          </div>

          <div class="md:flex items-center my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :value="numberFormatter(totalFuelPrice) + ' ₺'"
                :disabled="true"
                title="Yakıt Maliyeti"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="text"
                :value="numberFormatter(otherExpensePrice) + ' ₺'"
                :disabled="true"
                title="Diğer Masraflar"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :disabled="true"
                :value="numberFormatter(tonnagePrice) + ' ₺'"
                title="Ton Maliyeti"
              />
            </div>
          </div>

          <div class="md:flex items-center my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                @click="$modal.show('add-hgs-detail-modal')"
                :value="
                  numberFormatter(
                    costDistribution.cost.cash * transitionCount * isOneWay
                  ) + ' ₺'
                "
                :disabled="true"
                :title="$t('operations.planning.modal.tollHighwayCost')"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="text"
                :value="numberFormatter(expensivePrice) + ' ₺'"
                :disabled="true"
                title="Maliyet Fiyatı"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="price"
                :value="numberFormatter(totalPrice)"
                :digitCount="2"
                title="Gerçek Satış Fiyatı"
              />
            </div>
          </div>

          <div class="mt-6 mb-3 md:flex items-center">
            <div
              class="w-full mt-4 md:mt-0"
              :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12'"
            >
              <diffInput
                type="select"
                :optList="getYesNoList"
                v-model="isAmortisman"
                title="Amortisman Maliyeti"
              />
            </div>
            <div
              class="w-full mt-4 md:mt-0 md:px-2"
              :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12 md:pl-2'"
            >
              <diffInput
                type="select"
                :optList="getYesNoList"
                v-model="isOneWay"
                title="Tek Yön"
              />
            </div>

            <div class="w-full md:w-4/12 mt-4 md:mt-0" v-if="isOneWay == 1">
              <diffInput
                type="number"
                :valCount="99"
                v-model="dayCount"
                :required="true"
                title="Gün Sayısı"
                :max="99"
                :min="1"
                :step="1"
              />
            </div>
          </div>
          <div class="w-full mt-4">
            <diffInput
              type="textarea"
              v-model="explanation"
              :title="$t('general.note')"
              class="text-sm"
              maxlength="500"
              :required="isNoteRequired"
            />
          </div>
          <div>
            <asyncBtn
              icon="fas fa-check"
              :text="$t('general.saveButtonTitle')"
              :loadTitle="$t('general.saveButtonLoadTitle')"
              :loadState="load"
              class="w-full py-1 mt-4"
            />
          </div>
        </form>

        <!-- Body -->
      </div>
    </div>
  </modal>
</template>

<script>
//npm
import polyLine from "google-polyline";
import _ from "lodash";
//Global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import diffInput from "@/components/general/diffInput.vue";
import routeViewiver from "@/components/devItem/route-generator/index";

//Networking
import axios from "axios";
import { calculate } from "@/networking/urlmanager";

//Local components
import waypoints from "./waypointsCalculator.vue";
import { calculator } from "@/utils/cost";

export default {
  name: "calculAddModal",
  components: {
    routeViewiver,
    diffInput,
    gmapInputVue,
    asyncBtn,
    waypoints,
  },
  data() {
    return {
      fillingDate: "", // Gereksiz state ?
      deliveryDate: "", // Gereksiz state ?
      name: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      hakedisKilometer: "",
      transitionCount: 1,
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",
      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 1,
      expenseDetail: {},
      waypointsList: [],
      isNoteRequired: true,
      changeLocationState: false,
      load: false,
      debouncedCalculatePrice: _.debounce(this.calculatePrice, 300),
    };
  },

  methods: {
    addRefresh() {
      this.name = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 0, lng: 0, adress: "" };
      this.hakedisKilometer = 0;
      this.transitionCount = 1;
      this.costDistribution = { cost: { cash: 0 } };
      this.explanation = "";
      this.expensivePrice = 0;
      this.totalPrice = 0;
      this.totalFuelPrice = 0;
      this.otherExpensePrice = 0;
      this.tonnagePrice = 0;
      this.isAmortisman = 1;
      this.isOneWay = 1;
      this.dayCount = 1;
      this.load = false;
      this.waypointsList = [];
    },

    hide() {
      this.$modal.hide("addCalculateroute-modal");
    },

    validateTotalPrice(newTotalPrice) {
      if (this.isNoteRequired == true) {
        return true;
      }
      if (
        newTotalPrice == 0 ||
        newTotalPrice == "" ||
        newTotalPrice == undefined
      )
        return;
      //   // bu olmazsa js noktayı ondalık ayrımı olarak algılıyor. ör:1.500 binBeşYüzü BirBuçuk olarak algılıyor
      const formattedPrice = newTotalPrice.includes(".")
        ? newTotalPrice.replace(".", "")
        : newTotalPrice;
      this.totalPrice = formattedPrice;
      const numericTotalPrice = parseFloat(formattedPrice.replace(",", "."));
      const numericTonnagePrice = parseFloat(this.tonnagePrice);
      this.isNoteRequired = numericTotalPrice < numericTonnagePrice;

      if (this.isNoteRequired) {
        this.$swal.fire({
          icon: "warning",
          title: "UYARI!",
          text: "Ton maliyetinin altında bir Satış Fiyatı girildi. Lütfen açıklamanızı Not alanına ekleyin.",
          showConfirmButton: true,
          confirmButtonText: "TAMAM",
        });
        this.isNoteRequired = false;
        return false;
      }
      return true;
    },

    save() {
      if (this.isNoteRequired == false && this.totalPrice < this.tonnagePrice) {
        this.$swal.fire({
          icon: "warning",
          title: "UYARI!",
          text: "Ton maliyetinin altında bir Satış Fiyatı girildi. Lütfen açıklamanızı Not alanına ekleyin.",
          showConfirmButton: true,
          confirmButtonText: "TAMAM",
        });
        return;
      }
      if (!this.validateTotalPrice(this.totalPrice)) {
        return;
      }
      this.load = true;
      axios
        .post(
          calculate.add,
          {
            name: this.name,
            startPosition: this.receivedName, //BAŞLANGIÇ NOKTASI++++++++++++++
            finishPosition: this.deliveredName, //BİTİŞ NOKTASI +++++++++++++++
            oneWayKm: this.hakedisKilometer, //TEK YÖN KM ++++++++++++++++
            montlytransitionCount: this.transitionCount, //AY İÇİ SEFER SAYISI      +++++++++++
            totalKm: this.Money(
              this.hakedisKilometer * this.transitionCount * 2
            ),
            fuelPrice: this.Money(this.totalFuelPrice), //yakıt maliyeti  ++++++++++++++++
            otherPrice: this.Money(this.otherExpensePrice), //DİĞER MASRAFLAR  ++++++++++++
            tonPrice: this.Money(this.tonnagePrice), //ton maliyeti+++++++++++++++
            motorwayPrice: this.Money(this.costDistribution.cost.cash), //OTOYOL MALİYETİ++++++++++0
            cost: this.expensivePrice, //maliyet fıyatı  +++++++
            salePrice: this.totalPrice, //GERÇEK SATIŞ FİYATI  +++++++++++++
            amortismanPrice: this.isAmortisman, //Amortisman  Maliyeti +++++++++++
            oneWay: this.isOneWay, //TEK YÖN ++++++++++++++++
            dayCount: this.dayCount, //GÜN SAYISI +++++++++++++++
            explanation: this.explanation, //NOT ++++++++++++++++
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetPointLat: this.deliveryPoint.lat,
            targetPointLng: this.deliveryPoint.lng,
            waypoint: this.waypointsList,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.addRefresh();
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
          this.load = false;
        });
    },
    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;
      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeLocation() {
      this.changeLocationState = false;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changePolyline(val) {
      this.polyline = polyLine.encode(val);
    },
    async calculatePrice() {
      const montlyKilometer =
        this.hakedisKilometer *
        this.transitionCount *
        (this.isOneWay == 1 ? 1 : 2);

      const calculateHgs = parseFloat(this.costDistribution.cost.cash) * this.transitionCount * (this.isOneWay == 1 ? 1 : 2);
      const hgsPrice =isNaN(calculateHgs) ? 0 : calculateHgs;
      this.expenseDetail = await calculator(
        this.$store.state.userData.token,
        1,
        montlyKilometer * 12,
        null,
        null,
        montlyKilometer,
        hgsPrice,
        this.isAmortisman == 1,
        this.isOneWay == 1,
        this.dayCount
      );

      const { total, fuelTotalPrice, totalAdbluePrice } = this.expenseDetail;
      this.totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
      this.otherExpensePrice = total - this.totalFuelPrice;
      this.expensivePrice = total;
      this.tonnagePrice = this.expensivePrice / 26 / this.transitionCount;
    },
    numberFormatter(val) {
      const formattedValue = new Intl.NumberFormat("tr-TR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);
      return formattedValue;
    },
  },
  watch: {
    transitionCount() {
      this.debouncedCalculatePrice();
    },
    costDistribution: {
      deep: true,
      handler(val) {
        this.hakedisKilometer = val.totalKilometer;
        this.debouncedCalculatePrice();
      },
    },
    isAmortisman() {
      this.debouncedCalculatePrice();
    },
    isOneWay() {
      this.debouncedCalculatePrice();
    },
    dayCount() {
      this.debouncedCalculatePrice();
    },
    totalPrice(val) {
      this.isNoteRequired = Number(val) == 0;
    },
  },
};
</script>
