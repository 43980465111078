<!-- Toast Loader Component (LoadingToast.vue) -->
<template>
  <transition name="toast-fade" >
    <div v-if="visible" class="toast-container">
      <div class="toast-content">
        <i class="fas fa-spinner fa-spin"></i>
        <span class="toast-message">{{ message }}</span>
      </div>
    </div>
  </transition>
</template>
  
  <script>
export default {
  name: "LoadingToast",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Loading...",
    },
  },
};
</script>
  
  <style scoped>
.toast-container {
  position: fixed !important;
  top: 20vh !important;
  left: 20px;
  z-index: 9999999 !important;
  max-width: 300px;
}

.toast-content {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 12px;
}

.toast-message {
  font-size: 14px;
}

/* Animation */
.toast-fade-enter-active,
.toast-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.toast-fade-enter-from,
.toast-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>