/**
 * recommendModules/helpers/processPolygonGroups.js
 * Polygon gruplarını işleyen yardımcı fonksiyon
 */

const { weightedAverage } = require('../tools/weightedAverage');

/**
 * Polygon gruplandırma ve sıralama için optimize edilmiş fonksiyon
 * @param {Array} vehicles - Araçlar listesi
 * @returns {Array} İşlenmiş araçlar listesi
 */
function processPolygonGroups(vehicles) {
  // İlk önce polygon gruplarını oluştur (Map kullanarak)
  const polygonGroups = new Map();
  
  // Sadece polygon adı olanları grupla
  vehicles.forEach(vehicle => {
    if (vehicle.polygonName) {
      if (!polygonGroups.has(vehicle.polygonName)) {
        polygonGroups.set(vehicle.polygonName, []);
      }
      polygonGroups.get(vehicle.polygonName).push(vehicle);
    }
  });
  
  // Her grup için minimum maliyeti hesapla ve atama yap
  polygonGroups.forEach(group => {
    // En küçük maliyeti bul
    const minCost = Math.min(...group.map(v => v.allCost));
    
    // Bu grupta her araca aynı sıralama maliyeti ata
    group.forEach(v => {
      v.sortingCost = minCost;
    });
  });
  
  // costPriorityAverage değerlerini bir defada hesapla
  vehicles.forEach(vehicle => {
    const sortingCost = vehicle.polygonName ? (vehicle.sortingCost || vehicle.allCost) : vehicle.allCost;
    
    // priority değerini bir kez hesapla ve cache'le
    const priorityValue = parseFloat(vehicle.priority.toFixed(3));
    vehicle.costPriorityAverage = weightedAverage(
      priorityValue,
      0.3,
      sortingCost,
      0.7
    );
  });
  
  return vehicles;
}

module.exports = processPolygonGroups;