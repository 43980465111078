const similarity = require("similarity");

/**
 * Müşteri önceliğini hesaplar.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} customers - Müşteriler listesi.
 * @returns {number} Müşteri önceliği.
 */
function calculateCustomerPriority(order, customers) {
  const customer = customers.find(
    (c) => similarity(c.name, order.customer) > 0.8
  );
  return customer ? customer.priority : 1;
}

module.exports={
    calculateCustomerPriority
}