<template>
  <modal
    name="add-order-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">YENİ SİPARİŞ</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-order-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="md:flex">
        <div class="w-full mt-4">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderName"
            title="Sipariş Adı"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="orderDate"
            title="Sipariş Tarihi"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="complateDate"
            title="Kapanış Tarihi"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
          />
        </div>
      </div>

      <div class="md:flex" v-if="false">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput type="text" title="Alıcı" v-model="deliveredName" />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput type="text" title="Gönderici" v-model="receivedName" />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <gmapInputVue
            @change="(r) => changeFillingPoint(r)"
            :defaultAdress="fillingPoint.adress"
            :lat="fillingPoint.lat"
            :lng="fillingPoint.lng"
            placeHolder="Gönderici"
            height="150px"
            :hideMap="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <gmapInputVue
            @change="(r) => changeDeliveryPoint(r)"
            :defaultAdress="deliveryPoint.adress"
            :lat="deliveryPoint.lat"
            :lng="deliveryPoint.lng"
            placeHolder="Alıcı"
            height="150px"
            :hideMap="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            title="Mal Sınıfı"
          />
        </div>
        <div class="w-full mt-4 md:w-4/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            v-model="amount"
            title="Miktar"
          />
        </div>
        <div class="w-full mt-4 md:w-2/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            title="Birim"
            v-model="unitType"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12">
          <diffInput
            type="datetime-local"
            v-model="fillingDate"
            :max="deliveryDate"
            :required="true"
            :title="$t('operations.planning.modal.fillingTime')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            :min="fillingDate"
            type="datetime-local"
            v-model="deliveryDate"
            :required="true"
            :title="$t('operations.planning.modal.deliveryTime')"
          />
        </div>
      </div>

      <div class="w-full mt-4">
        <routeViewiver
          v-if="
            // changeLocationState &&
            fillingPoint.lat > 0 &&
            fillingPoint.lng > 0 &&
            deliveryPoint.lat > 0 &&
            deliveryPoint.lng > 0
          "
          ref="routerViev"
          :customer="receivedName"
          :outpoint="deliveredName"
          :center="{
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :startPosition="{
            adress: fillingPoint.adress,
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :endPosition="{
            adress: deliveryPoint.adress,
            lat: Number(deliveryPoint.lat),
            lng: Number(deliveryPoint.lng),
          }"
          :height="'200px'"
          @change="(r) => (hakedisKilometer = r.totalKilometer)"
        />
      </div>

      <div class="w-full mt-4">
        <diffInput
          type="number"
          v-model="hakedisKilometer"
          :required="true"
          :disabled="true"
          title="Kilometre"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import routeViewiver from "@/components/devItem/route-generator/index.vue";

// Networking
import axios from "axios";
import { orders } from "@/networking/urlmanager";

export default {
  name: "add-plan-modal",
  components: {
    diffInput,
    asyncBtn,
    gmapInputVue,
    routeViewiver,
  },
  data() {
    return {
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",

      changeLocationState: false,
      load: false,
    };
  },
  methods: {
    resetAll() {
      this.orderNo = "";
      this.orderName = "";
      this.orderDate = "";
      this.complateDate = "";
      this.customer = "";
      this.invoicedCompany = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 0, lng: 0, adress: "" };
      this.productType = "";
      this.amount = "";
      this.unitType = "";
      this.fillingDate = "";
      this.deliveryDate = "";
      this.hakedisKilometer = "";
    },
    save() {
      this.load = true;
      axios
        .post(
          orders.add,
          {
            orderNo: this.orderNo,
            orderName: this.orderName,
            orderDate: this.orderDate,
            complateDate: this.complateDate,
            customer: this.customer,
            invoicedCompany: this.invoicedCompany,
            receivedName: this.receivedName,
            deliveredName: this.deliveredName,
            productType: this.productType,
            amount: this.amount,
            unitType: this.unitType,
            fillingDate: this.fillingDate,
            deliveryDate: this.deliveryDate,

            kilometer: this.hakedisKilometer,

            outpointAdress: this.fillingPoint.adress,
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetpointAdress: this.deliveryPoint.adress,
            targetpointLat: this.deliveryPoint.lat,
            targetpointLng: this.deliveryPoint.lng,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.resetAll();
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("add-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;
      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;

      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
  },
};
</script>
