/**
 * Araç kullanılabilirliğini günceller.
 * @param {number} vehicleId - Güncellenecek aracın ID'si.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {boolean} isAvailable - Araç için kullanılabilirlik durumu.
 */

function updateVehicleAvailability(topVehicles, vehicles, order) {
  topVehicles.forEach((vehicle) => {
    const vehicleIndex = vehicles.findIndex((v) => v.id === vehicle.id);
    if (vehicleIndex !== -1) {
      vehicles[vehicleIndex].orderNos.push(order.orderNo);
    }
  });
}

module.exports={
    updateVehicleAvailability
}