<template>
  <div 
    class="fixed top-5 right-5 bg-red-600 text-white px-4 py-3 rounded-md flex items-center justify-center shadow-lg transition-all duration-300 ease-in-out transform scale-100 opacity-90"
  >
    <i class="fas fa-spinner fa-spin mr-2 text-lg"></i>
    <span class="font-medium text-sm tracking-wide">Veriler eşitleniyor...</span>
  </div>
</template>

<script>
export default {
  name: "loading-indicator",
};
</script>

<style scoped>
/* Küçük ekranlarda daha iyi görünmesi için */
@media (max-width: 640px) {
  div {
    width: auto;
    font-size: 14px;
    padding: 8px 12px;
  }
}
</style>
