<template>
  <div
    class="flex flex-col items-center justify-center text-center h-32 text-gray-500"
  >
    <i :class="['fas', icon, 'text-2xl mb-2']"></i>
    <span class="text-xs">{{ message }}</span>
  </div>
</template>
  
  <script>
export default {
  name: "EmptyState",
  props: {
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "fa-info-circle",
    },
  },
};
</script>