<template>
  <div
    class="w-full bg-white border-b border-gray-200 shadow-sm"
    v-if="$store.state?.activeTabs?.length > 0"
  >
    <div
      class="flex overflow-x-auto w-screen hide-scrollbar"
      style="width: 100% !important; max-width: 100%"
    >
      <draggable 
        v-model="myList" 
        :animation="150"
        ghost-class="ghost-tab"
        handle=".tab-handle"
      >
        <transition-group
          type="transition"
          name="tab-list"
          tag="div"
          class="flex items-center"
        >
          <div
            class="tab-item group h-10 ml-1 flex items-center justify-between cursor-pointer select-none transition-all duration-150"
            v-for="item in $store.state.activeTabs"
            :key="item.key"
            :class="[
              item.url == $route.path
                ? 'active-tab text-red-600'
                : 'text-gray-600 hover:text-gray-800',
            ]"
          >
            <div class="tab-handle h-full flex items-center">
              <button
                @click="goRoute(item)"
                class="text-sm px-4 h-full flex items-center max-w-[160px] text-left transition-colors"
                :title="item.label"
              >
                <span class="truncate font-medium">{{ item.label }}</span>
              </button>
            </div>

            <button
              v-if="$store.state.activeTabs?.length > 1"
              class="close-btn w-6 h-6 flex items-center justify-center mx-1 rounded-full opacity-0 group-hover:opacity-100 hover:bg-gray-100"
              :class="item.url == $route.path ? 'hover:bg-red-50' : ''"
              @click="removeItem(item)"
              title="Close tab"
            >
              <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </transition-group>
      </draggable>

      <!-- Optional: New Tab Button -->
      <div v-if="showNewTabButton" class="flex items-center ml-2">
        <button 
          @click="addNewTab" 
          class="text-gray-500 hover:text-gray-700 h-10 px-3 transition-colors"
        >
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "seferi-tabs",
  components: {
    draggable,
  },
  props: {
    showNewTabButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goRoute(item) {
      if (item.url != this.$route.path) {
        this.$router.push(item.url);
      }
    },
    removeItem(item) {
      const params = {
        item,
        currentRoute: this.$route.path,
      };
      this.$store.commit("removeTab", params);
      this.$store.commit("pages/removePage", { id: item.key });
    },
    addNewTab() {
      // Implement this method based on your application's needs
      this.$emit('add-new-tab');
    }
  },
  computed: {
    myList: {
      get() {
        return this.$store.state.activeTabs;
      },
      set(value) {
        this.$store.commit("updateActiveTabsList", value);
      },
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar but keep functionality */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Tab item styling */
.tab-item {
  min-width: 100px;
  max-width: 180px;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
}

.tab-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.active-tab {
  background-color: #fef2f2; /* Red-50 from Seferi design system */
}

.active-tab::after {
  background-color: #dc2626; /* Red-600 from Seferi design system */
}

.tab-item:hover:not(.active-tab)::after {
  background-color: #e5e7eb; /* Gray-200 from Seferi design system */
}

/* Ghost tab appearance when dragging */
.ghost-tab {
  background: #f9fafb;
  opacity: 0.7;
  border-color: transparent !important;
}

/* Tab transition animations */
.tab-list-move {
  transition: transform 0.2s ease;
}

.tab-list-enter-active, .tab-list-leave-active {
  transition: all 0.3s ease;
}

.tab-list-enter-from, .tab-list-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Ensure text doesn't wrap */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>