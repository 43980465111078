<template>
  <div class="w-full pb-4 h-96">
    <div class="flex items-center justify-between">
      <h4 class="font-bold">YIKAMA NOKTASI ÖZELLİKLERİ</h4>
    </div>
    <div class="w-full items-center justify-center max-h-60">
      <div class="w-full flex mt-3">
        <div class="w-10/12 ml-2">
          <ProductsSelect v-model="products" :selectedList="List" />
        </div>
        <div
          class="w-2/12 ml-2 rounded-md border flex items-center justify-end"
        >
          <button
            type="button"
            @click="save()"
            class="text-green-500 mx-auto py-1 px-2 rounded border border-green-500 text-sm"
          >
            <i class="fas fa-check"></i>
          </button>
        </div>
      </div>
      <div class="w-full flex"></div>
    </div>
    <div class="w-full pt-2 mt-2" v-if="sortedList.length && !isLoading">
      <LyrausTable
        :columns="getList"
        :data="sortedList"
        :tableHeight="'30vh'"
        :buttonsColumn="{ label: 'İşlem' }"
      >
        <template v-slot:colButtons="{ item, index }">
          <div class="flex items-center justify-end space-x-2 px-2">
            <button
              v-if="item.status === 3"
              type="button"
              @click="reverseDelete(index)"
              class="text-gray-500 text-sm"
            >
              <i class="fas fa-redo"></i>
            </button>
            <button
              v-else
              type="button"
              @click="removeItem(index, item)"
              class="text-red-500 text-sm"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </template>
        <template v-slot:rowButtons="{ item, columnKey }">
          <div v-for="(priceSlot, index) in propertyList" :key="index">
            <div v-if="columnKey == priceSlot.id">
              <input
                type="number"
                :disabled="item.status === 3"
                :value="item[columnKey]"
                @input="debounceInput($event, item, columnKey)"
                :min="0"
                class="w-full border-gray-200 text-left rounded dark:border-gray-500 dark:text-gray-300 text-black border pl-4 text-base"
              />
            </div>
          </div>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>
<script>
//npm
import { LyrausTable } from "lyraus-ui";

//networking
import diffInput from "@/components/general/diffInput.vue";

//local components
import ProductsSelect from "./products.select.vue";

import _ from "lodash";

export default {
  props: ["value", "isEdit", "propertyList"],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    diffInput,
    ProductsSelect,
  },
  data() {
    return {
      columns: [
        {
          label: "Ürün",
          key: "productName",
        },
      ],
      properties: [],
      products: [],
      isLoading: false,
      List: [],
    };
  },
  methods: {
    debounceInput: _.debounce((event, item, columnKey) => {
      item[columnKey] = Number(event.target.value);
    }, 10),
    save() {
      try {
        if (!this.products.length) {
          this.swalBox(
            "warning",
            "UYARI",
            "Ürün seçmelisiniz!",
            false,
            "Tamam"
          );
          return;
        }

        for (const product of this.products) {
          const result = this.propertyList.reduce((acc, { id }) => {
            const label = String(id);
            acc[label] = 0;
            return acc;
          }, {});
          const params = {
            ...result,
            productName: product.name,
            productId: product.id,
            status: 2,
          };
          const newArr = [...this.List, params];
          this.List = newArr;
        }

        this.products = [];
      } catch (error) {
        this.swalBox(
          "warning",
          "UYARI",
          "Yıkama özelliği eklenemedi!",
          false,
          "Tamam"
        );
      }
    },
    removeItem(val, item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Yıkama özelliğini silmek istediğinize emin misiniz?",
        true,
        this.$t("general.yes"),
        this.$t("general.no")
      ).then((acc) => {
        if (acc.isConfirmed) {
          try {
            if (this.isEdit && item.status === 1) {
              this.List[val].status = 3;
            } else {
              this.List.splice(val, 1);
            }
          } catch (error) {
            this.swalBox(
              "warning",
              "UYARI",
              "Yıkama özelliği silinemedi!",
              false,
              "Tamam"
            );
          }
        }
      });
    },
    reverseDelete(val) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Yıkama özelliğini geri almak istediğinize emin misiniz?",
        true,
        this.$t("general.yes"),
        this.$t("general.no")
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.List[val].status = 1;
        }
      });
    },
  },
  computed: {
    getList() {
      this.isLoading = true;
      const propertyColumns = this.propertyList.map((item) => ({
        key: String(item.id),
        label: item.name,
        dontDisplay: true,
        center: true,
      }));
      setTimeout(() => {
        this.isLoading = false;
      }, 100);

      return [...this.columns, ...propertyColumns];
    },
    sortedList() {
      return this.List.sort((a, b) =>
        a.productName.localeCompare(b.productName)
      );
    },
  },
  created() {
    this.List = [];
  },
  watch: {
  value(val) {
    if (!val || !Array.isArray(val)) {
      this.List = [];
      return;
    }
    this.List = val;
    if (this.isEdit && val.length) {
      const integerKeys = Object.keys(val[0])
        .filter((key) => !isNaN(parseInt(key)))
        .map((key) => {
          const propertyIndex = this.propertyList.findIndex(
            (item) => item.id === parseInt(key)
          );
          if (propertyIndex !== -1) {
            return {
              id: parseInt(key),
              name: this.propertyList[propertyIndex].name,
            };
          }
        })
      this.properties = integerKeys;
    }
  },
  List(val) {
    this.$emit("change", val);
  },
}

};
</script>
