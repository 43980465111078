var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapCluster',{on:{"click":_vm.handleClusterClick}},[_vm._l((_vm.nonDeviationMarkers),function(m){return _c('GmapMarker',{key:m.title,attrs:{"title":m.title,"label":{
      text: m.title,
      color: 'white',
      className:
        'absolute bottom-4 -right-8 text-xs px-2 font-bold bg-blue-700 rounded tracking-widest',
      fontSize: '8.5px',
    },"position":m.position,"icon":m.icon,"rotation":"30"},on:{"click":() => _vm.$emit('markerClick', m, 'vehicle')}})}),_vm._l((_vm.deviationMarkers),function(m){return _c('GmapMarker',{key:m.title,attrs:{"title":m.title,"label":{
      text: m.title,
      color: 'white',
      className:
        'absolute bottom-4 -right-8 text-xs px-2 font-bold bg-red-600 rounded tracking-widest animate-pulse border-2 border-white',
      fontSize: '8.5px',
    },"position":m.position,"icon":m.icon,"rotation":"30"},on:{"click":() => _vm.$emit('markerClick', m, 'vehicle')}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }