<template>
  <div class="bg-gray-50 min-h-screen">
    <div class="max-w-7xl mx-auto px-4 py-6">
      <!-- Header -->
      <div class="text-center mb-6 pb-4 border-b border-gray-200">
        <h1 class="text-2xl font-semibold mb-1">Güncellemeler</h1>
        <p class="text-gray-600 text-sm">
          Uygulamanızın en son gelişmeleri ve yenilikleri
        </p>
      </div>

      <!-- Year selector -->
      <div class="text-center mb-6">
        <button
          class="bg-red-700 text-white px-6 py-1.5 rounded-full text-sm font-medium"
        >
          2025
        </button>
      </div>

      <!-- Admin Form -->
      <div class="bg-white rounded-lg shadow-sm p-5 mb-6">
        <form
          @submit.prevent="saveUpdate"
          class="grid grid-cols-2 gap-x-6 gap-y-4"
        >
          <!-- Left Column -->
          <div class="space-y-4">
            <!-- Title -->
            <div>
              <label
                for="title"
                class="block text-xs font-medium text-gray-700 mb-1"
                >Başlık</label
              >
              <input
                type="text"
                id="title"
                v-model="updateData.title"
                placeholder="Güncelleme Başlığı"
                class="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm"
                required
              />
            </div>

            <!-- Description -->
            <div>
              <label
                for="description"
                class="block text-xs font-medium text-gray-700 mb-1"
                >Açıklama</label
              >
              <textarea
                id="description"
                v-model="updateData.description"
                placeholder="Güncelleme açıklaması..."
                class="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm h-20 resize-none"
                required
              ></textarea>
            </div>

            <!-- Type, Priority & Status in a row -->
            <div class="grid grid-cols-3 gap-3">
              <!-- Type -->
              <div>
                <label
                  for="type"
                  class="block text-xs font-medium text-gray-700 mb-1"
                  >Tip</label
                >
                <select
                  id="type"
                  v-model="updateData.type"
                  class="w-full px-2 py-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent appearance-none bg-white text-sm"
                  required
                >
                  <option value="">Seçin</option>
                  <option value="major">Major</option>
                  <option value="minor">Minor</option>
                  <option value="patch">Patch</option>
                </select>
              </div>

              <!-- Priority -->
              <div>
                <label class="block text-xs font-medium text-gray-700 mb-1"
                  >Öncelik</label
                >
                <select
                  v-model="updateData.priority"
                  class="w-full px-2 py-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent appearance-none bg-white text-sm"
                >
                  <option value="low">Düşük</option>
                  <option value="medium">Orta</option>
                  <option value="high">Yüksek</option>
                </select>
              </div>

              <!-- Status -->
              <div>
                <label
                  for="status"
                  class="block text-xs font-medium text-gray-700 mb-1"
                  >Durum</label
                >
                <select
                  id="status"
                  v-model="updateData.status"
                  class="w-full px-2 py-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent appearance-none bg-white text-sm"
                >
                  <option value="draft">Taslak</option>
                  <option value="pending">Beklemede</option>
                  <option value="published">Yayında</option>
                  <option value="archived">Arşivlenmiş</option>
                </select>
              </div>
            </div>

            <!-- Dates in a row -->
            <div class="grid grid-cols-2 gap-4">
              <!-- Start Date -->
              <div>
                <label
                  for="date"
                  class="block text-xs font-medium text-gray-700 mb-1"
                  >Başlangıç</label
                >
                <input
                  type="datetime-local"
                  id="date"
                  v-model="updateData.date"
                  :min="todayMinDate"
                  :max="maxDate"
                  class="w-full px-3 py-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm"
                  required
                />
              </div>

              <!-- End Date -->
              <div>
                <label
                  for="endDate"
                  class="block text-xs font-medium text-gray-700 mb-1"
                  >Bitiş (Opsiyonel)</label
                >
                <input
                  type="datetime-local"
                  id="endDate"
                  v-model="updateData.endDate"
                  :min="updateData.date || todayMinDate"
                  :max="maxEndDate"
                  class="w-full px-3 py-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm"
                />
              </div>
            </div>

            <!-- Tags -->
            <div>
              <label class="block text-xs font-medium text-gray-700 mb-1"
                >Etiketler</label
              >
              <div class="flex flex-wrap gap-2">
                <div
                  v-for="tag in availableTags"
                  :key="tag.value"
                  class="inline-flex items-center px-2 py-1 bg-red-50 border border-gray-200 rounded-full text-xs"
                >
                  <input
                    type="checkbox"
                    :id="'tag-' + tag.value"
                    :value="tag.value"
                    v-model="updateData.tags"
                    class="mr-1 h-3 w-3 text-red-700 focus:ring-red-500 border-gray-300 rounded"
                  />
                  <label :for="'tag-' + tag.value">{{ tag.text }}</label>
                </div>
              </div>
            </div>

            <!-- Submit Button for Left Column (hidden on desktop, visible on mobile) -->
            <div class="flex justify-end mt-4 lg:hidden">
              <button
                type="submit"
                class="px-5 py-2 bg-red-700 text-white rounded-md text-sm font-medium hover:bg-red-800 focus:outline-none focus:ring-1 focus:ring-red-700 focus:ring-offset-1"
              >
                {{ editMode ? "Güncellemeyi Düzenle" : "Güncellemeyi Kaydet" }}
              </button>
            </div>
          </div>

          <!-- Right Column -->
          <div class="space-y-4">
            <!-- Enhanced Features Section -->
            <div>
              <div class="flex items-center justify-between mb-2">
                <label class="block text-xs font-medium text-gray-700"
                  >Özellikler ve Detayları</label
                >
                <button
                  type="button"
                  @click="addFeature"
                  class="text-xs text-red-700 hover:text-red-800 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5 mr-0.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Yeni Ekle
                </button>
              </div>
              <div
                class="space-y-3 max-h-52 overflow-y-auto pr-1 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
              >
                <div
                  v-for="(feature, index) in updateData.featureItems"
                  :key="index"
                  class="p-3 border border-gray-200 rounded-md bg-gray-50"
                >
                  <div class="flex justify-between items-start">
                    <div class="flex-grow">
                      <div class="flex items-center mb-2">
                        <input
                          type="text"
                          v-model="feature.title"
                          placeholder="Özellik başlığı"
                          class="w-full px-3 py-1.5 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm bg-white"
                          required
                        />
                      </div>
                      <textarea
                        v-model="feature.detail"
                        placeholder="Özellik detayını açıklayın..."
                        class="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm h-16 resize-none bg-white"
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      @click="removeFeature(index)"
                      class="ml-2 h-6 w-6 flex items-center justify-center bg-red-50 text-red-700 rounded-md text-xs hover:bg-red-100"
                    >
                      ×
                    </button>
                  </div>
                  <div class="flex mt-2 text-xs items-center">
                    <label class="inline-flex items-center mr-4">
                      <input
                        type="checkbox"
                        v-model="feature.isImportant"
                        class="h-3 w-3 text-red-700 focus:ring-red-500 border-gray-300 rounded"
                      />
                      <span class="ml-1 text-gray-600">Önemli</span>
                    </label>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        v-model="feature.isNew"
                        class="h-3 w-3 text-red-700 focus:ring-red-500 border-gray-300 rounded"
                      />
                      <span class="ml-1 text-gray-600">Yeni</span>
                    </label>
                    <select
                      v-model="feature.category"
                      class="ml-auto px-2 py-0.5 border border-gray-200 rounded text-xs bg-white"
                    >
                      <option value="">Kategori Seçin</option>
                      <option value="ui">Arayüz</option>
                      <option value="performance">Performans</option>
                      <option value="security">Güvenlik</option>
                      <option value="feature">Yeni Özellik</option>
                      <option value="bugfix">Hata Düzeltmesi</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                v-if="updateData.featureItems.length === 0"
                class="text-center py-6 text-sm text-gray-500 border border-dashed border-gray-300 rounded-md"
              >
                Henüz özellik eklenmedi. Yukarıdaki "Yeni Ekle" butonuna
                tıklayarak özellik ekleyebilirsiniz.
              </div>
            </div>

            <!-- Additional Notes -->
            <div>
              <label class="block text-xs font-medium text-gray-700 mb-1"
                >Ek Notlar</label
              >
              <textarea
                v-model="updateData.additionalNotes"
                placeholder="Bu güncelleme ile ilgili ek notlar..."
                class="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-red-700 focus:border-transparent text-sm h-16 resize-none"
              ></textarea>
            </div>

            <!-- Submit Button -->
            <div class="flex justify-center mt-4 hidden lg:flex">
              <button
                type="submit"
                class="w-full px-5 py-2 bg-red-700 text-white rounded-md text-sm font-medium hover:bg-red-800 focus:outline-none focus:ring-1 focus:ring-red-700 focus:ring-offset-1"
              >
                {{ editMode ? "Güncellemeyi Düzenle" : "Güncellemeyi Kaydet" }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- Updates List -->
      <div v-if="savedUpdates.length > 0">
        <h2 class="text-lg font-semibold mb-3">Mevcut Güncellemeler</h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            v-for="update in savedUpdates"
            :key="update.id"
            class="bg-white rounded-lg shadow-sm p-4"
          >
            <div class="flex justify-between items-center mb-2">
              <h3 class="text-base font-medium">{{ update.title }}</h3>
              <span class="text-xs text-gray-500">{{
                formatDate(update.date)
              }}</span>
            </div>

            <p class="text-xs text-gray-700 mb-3 line-clamp-2">
              {{ update.description }}
            </p>

            <!-- Feature List in Card -->
            <div
              v-if="update.featureItems && update.featureItems.length > 0"
              class="mb-3"
            >
              <div class="text-xs font-medium text-gray-700 mb-1">
                Özellikler:
              </div>
              <ul class="text-xs text-gray-600 ml-4 list-disc">
                <li
                  v-for="(feature, idx) in update.featureItems.slice(0, 2)"
                  :key="idx"
                  class="line-clamp-1"
                >
                  {{ feature.title }}
                  <span v-if="feature.isNew" class="text-green-600 text-xs ml-1"
                    >• Yeni</span
                  >
                  <span
                    v-if="feature.isImportant"
                    class="text-red-600 text-xs ml-1"
                    >• Önemli</span
                  >
                </li>
                <li
                  v-if="update.featureItems.length > 2"
                  class="text-xs text-gray-500 italic"
                >
                  ... ve {{ update.featureItems.length - 2 }} özellik daha
                </li>
              </ul>
            </div>

            <div class="flex flex-wrap gap-1 mb-3">
              <span
                v-for="tag in update.tags"
                :key="tag"
                class="px-2 py-0.5 bg-red-50 text-red-700 text-xs rounded-full"
              >
                {{ tag }}
              </span>
            </div>

            <div class="flex justify-end space-x-2">
              <button
                @click="editUpdate(update)"
                class="px-3 py-1 bg-blue-50 text-blue-700 rounded-md text-xs"
              >
                Düzenle
              </button>
              <button
                @click="deleteUpdate(update.id)"
                class="px-3 py-1 bg-red-50 text-red-700 rounded-md text-xs"
              >
                Sil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updateData: {
        id: null,
        title: "",
        description: "",
        date: "",
        endDate: "",
        type: "",
        tags: [],
        featureItems: [], // Her biri başlık ve detay içeren özellik öğeleri
        additionalNotes: "",
        priority: "medium",
        status: "draft",
      },
      savedUpdates: [],
      availableTags: [
        { value: "Tasarım", text: "Tasarım" },
        { value: "UI/UX", text: "UI/UX" },
        { value: "Sipariş", text: "Sipariş Yönetimi" },
        { value: "Performans", text: "Performans" },
        { value: "Güvenlik", text: "Güvenlik" },
      ],
      editMode: false,
    };
  },
  computed: {
    todayMinDate() {
      const today = new Date();
      // Formatted as YYYY-MM-DDThh:mm
      return today.toISOString().slice(0, 16);
    },
    maxDate() {
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 1);
      return maxDate.toISOString().slice(0, 16);
    },
    maxEndDate() {
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 2);
      return maxDate.toISOString().slice(0, 16);
    },
  },
  methods: {
    addFeature() {
      // Her özellik için başlık ve detay içeren bir nesne oluştur
      this.updateData.featureItems.unshift({
        title: "",
        detail: "",
        isImportant: false,
        isNew: true,
        category: "",
      });
    },
    removeFeature(index) {
      this.updateData.featureItems.splice(index, 1);
    },
    saveUpdate() {
      const updateToSave = { ...this.updateData };

      if (!this.editMode) {
        updateToSave.id = Date.now();
        this.savedUpdates.unshift(updateToSave);
      } else {
        const index = this.savedUpdates.findIndex(
          (u) => u.id === updateToSave.id
        );
        if (index !== -1) {
          this.savedUpdates.splice(index, 1, updateToSave);
        }
        this.editMode = false;
      }

      this.resetForm();
      console.log("Güncelleme kaydedildi:", updateToSave);
    },
    editUpdate(update) {
      this.updateData = JSON.parse(JSON.stringify(update));
      this.editMode = true;
    },
    deleteUpdate(id) {
      if (confirm("Bu güncellemeyi silmek istediğinizden emin misiniz?")) {
        this.savedUpdates = this.savedUpdates.filter((u) => u.id !== id);
      }
    },
    resetForm() {
      this.updateData = {
        id: null,
        title: "",
        description: "",
        date: "",
        endDate: "",
        type: "",
        tags: [],
        featureItems: [],
        additionalNotes: "",
        priority: "medium",
        status: "draft",
      };
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("tr-TR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style>
/* Custom scrollbar styles */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-thin::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 10px;
}
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}
</style>
