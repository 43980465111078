<template>
  <modal
    name="vehicleState-modal"
    height="auto"
    width="850px"
    :scrollable="true"
    @opened="show"
  >
    <div class="flex flex-col" style="height:70vh  z-50 !important">
      <!-- Görsel Başlık - Daha kolay anlaşılır -->
      <div class="bg-blue-600 text-white p-4 flex justify-between items-center">
        <h3 class="text-xl font-bold flex items-center">
          <i class="fas fa-truck-moving mr-3 text-2xl"></i>
          Araç Durum Bilgileri
        </h3>
        <button 
          class="bg-white text-blue-600 rounded-full h-10 w-10 flex items-center justify-center hover:bg-gray-200"
          @click="hide"
        >
          <i class="fas fa-times text-lg"></i>
        </button>
      </div>

      <!-- Özet Bilgi Kutusu - Araç ve Son Durum -->
      <div class="p-4 bg-blue-50 border-b border-blue-200">
        <div class="flex">
          <div class="bg-blue-100 p-3 rounded-lg mr-4 text-blue-700">
            <i class="fas fa-info-circle text-2xl"></i>
          </div>
          <div>
            <div class="flex items-center">
              <span class="font-bold text-gray-700 text-lg mr-2">{{ Detail.vehicle || 'Araç' }}</span>
              <span class="bg-blue-100 text-blue-700 py-1 px-3 rounded-full text-sm">
                {{ getLastStatusName }}
              </span>
            </div>
            <p class="text-gray-600 mt-1">Planlama: <span class="font-medium">{{ getPlanDetails }}</span></p>
          </div>
        </div>
      </div>

      <!-- Düzenleme bileşeni artık ayrı bir modal olarak kullanılıyor -->

      <!-- Ana İşlem Çubuğu -->
      <div class="bg-gray-100 p-3 border-b border-gray-200 flex justify-between items-center">
        <div class="flex items-center">
          <span class="text-gray-700 mr-3">Toplam: <b>{{ List.length }}</b> hareket kaydı</span>
          <div class="relative ml-2">
            <div class="flex items-center">
              <input 
                v-model="searchTerm" 
                class="border border-gray-300 rounded-lg py-2 px-3 w-64 pr-8 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 focus:outline-none" 
                placeholder="Durum, araç veya şoför ara..."
                type="text"
                @keyup="checkSearchResults"
              />
              <i class="fas fa-search absolute right-3 top-2.5 text-gray-400"></i>
            </div>
            <div v-if="showNoResults" class="absolute z-10 mt-1 bg-white border border-red-200 rounded-md p-2 text-red-600 text-sm w-64 shadow-sm">
              <i class="fas fa-exclamation-circle mr-1"></i> Sonuç bulunamadı
            </div>
          </div>
        </div>
        
        <div class="flex">
          <button 
            @click="refreshData" 
            class="bg-white border border-gray-300 rounded-lg py-2 px-4 flex items-center text-gray-700 mr-2 hover:bg-gray-50"
          >
            <i class="fas fa-sync-alt mr-2" :class="{'animate-spin': load}"></i>
            Yenile
          </button>
          <button 
            @click="$emit('showNotes')" 
            class="bg-white border border-gray-300 rounded-lg py-2 px-4 flex items-center text-gray-700 hover:bg-gray-50"
          >
            <i class="fas fa-sticky-note mr-2"></i>
            Notlar
          </button>
        </div>
      </div>

      <!-- Yükleniyor Göstergesi -->
      <div v-if="load" class="flex justify-center items-center p-20 bg-white">
        <div class="text-center">
          <div class="inline-block h-16 w-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mb-3"></div>
          <p class="text-gray-600 font-medium">Araç bilgileri yükleniyor...</p>
        </div>
      </div>

      <!-- Arama Sonuçları Durumu -->
      <div v-if="searchTerm && filteredList.length === 0 && List.length > 0" class="flex flex-col items-center justify-center p-16 bg-white">
        <i class="fas fa-search text-gray-400 text-5xl mb-4"></i>
        <h4 class="text-xl font-bold text-gray-700 mb-2">Arama Sonucu Bulunamadı</h4>
        <p class="text-gray-500 text-center max-w-md">
          "{{ searchTerm }}" ile ilgili sonuç bulunamadı. Farklı bir arama terimi deneyin veya tüm kayıtları görmek için arama kutusunu temizleyin.
        </p>
        <button 
          @click="searchTerm = ''" 
          class="mt-6 bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 flex items-center"
        >
          <i class="fas fa-times mr-2"></i>
          Aramayı Temizle
        </button>
      </div>

      <!-- Durum Kartları - Görsel ve kolay anlaşılır arayüz -->
      <div v-else-if="List.length > 0" class="p-4 bg-gray-50 overflow-y-auto" style="max-height: 600px;">
        <div class="space-y-4">
          <div 
            v-for="(item, index) in filteredList" 
            :key="index"
            :class="[
              'p-5 bg-white rounded-lg shadow-sm border-l-4 transition-all duration-200',
              getStatusColorClass(item),
              item.isError == 1 && item.errorAnswered == 2 ? 'border-red-500 shadow-md animate-pulse' : '',
            ]"
          >
            <!-- Durum Başlığı ve İşlemler -->
            <div class="flex justify-between items-start">
              <div class="flex items-center">
                <div class="flex-shrink-0 h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-700 font-bold mr-3">
                  {{ index + 1 }}
                </div>
                <div>
                  <div class="flex items-center">
                    <h4 class="font-bold text-gray-800 text-lg">{{ item.name }}</h4>
                    <span v-if="item.isError == 1 && item.errorAnswered == 2" class="flex items-center ml-2 text-red-500 bg-red-50 py-1 px-2 rounded-full text-sm">
                      <i class="fas fa-exclamation-triangle mr-1"></i>
                      Dikkat Gerekiyor
                    </span>
                  </div>
                  <p class="text-gray-500 text-sm">{{ formattedDate(item.addDate) }}</p>
                </div>
              </div>
              
              <div class="flex">
                <button
                  v-if="item.kilometer && item.tonnage"
                  @click="editItem(item)"
                  class="bg-blue-50 text-blue-600 p-2 rounded-lg hover:bg-blue-100 ml-2 flex items-center"
                  title="Kilometre ve Tonaj Düzenle"
                >
                  <i class="fas fa-pen mr-1"></i>
                  Düzenle
                </button>
                
                <button
                  v-if="item.errorAnswered == 1"
                  @click="showDetail(item)"
                  class="bg-yellow-50 text-yellow-700 p-2 rounded-lg hover:bg-yellow-100 ml-2 flex items-center"
                  title="Açıklama Göster"
                >
                  <i class="fas fa-comment-alt mr-1"></i>
                  Açıklama
                </button>
              </div>
            </div>
            
            <!-- Kilometre/Tonaj Bilgisi -->
            <div v-if="item.kilometer && item.tonnage" class="mt-4 bg-gray-50 p-3 rounded-lg flex">
              <div class="flex-1 border-r border-gray-300 pr-4">
                <p class="text-sm text-gray-500">Kilometre</p>
                <p class="font-bold text-gray-900 text-lg">{{ item.kilometer }} km</p>
              </div>
              <div class="flex-1 pl-4">
                <p class="text-sm text-gray-500">Tonaj</p>
                <p class="font-bold text-gray-900 text-lg">{{ item.tonnage }} ton</p>
              </div>
            </div>
            
            <!-- Belgeler Bölümü -->
            <div v-if="hasDocuments(item)" class="mt-4 grid grid-cols-3 gap-3">
              <div v-if="item.fileUrl" class="col-span-1">
                <p class="text-sm text-gray-500 mb-1 flex items-center">
                  <i class="fas fa-file-invoice text-green-600 mr-1"></i>
                  İrsaliye
                </p>
                <linkBtn
                  :filekey="item.fileUrl"
                  :containerClass="'w-full bg-green-50 text-green-700 py-2 px-3 rounded-lg hover:bg-green-100 flex items-center justify-center'"
                >
                  <i class="fas fa-eye mr-2"></i> Görüntüle
                </linkBtn>
              </div>
              <div v-if="item.fileTwoUrl" class="col-span-1">
                <p class="text-sm text-gray-500 mb-1 flex items-center">
                  <i class="fas fa-receipt text-purple-600 mr-1"></i>
                  Kantar Fişi
                </p>
                <linkBtn
                  :filekey="item.fileTwoUrl"
                  :containerClass="'w-full bg-purple-50 text-purple-700 py-2 px-3 rounded-lg hover:bg-purple-100 flex items-center justify-center'"
                >
                  <i class="fas fa-eye mr-2"></i> Görüntüle
                </linkBtn>
              </div>
              <div v-if="item.fileThreeUrl" class="col-span-1">
                <p class="text-sm text-gray-500 mb-1 flex items-center">
                  <i class="fas fa-file-alt text-orange-600 mr-1"></i>
                  Diğer Belge
                </p>
                <linkBtn
                  :filekey="item.fileThreeUrl"
                  :containerClass="'w-full bg-orange-50 text-orange-700 py-2 px-3 rounded-lg hover:bg-orange-100 flex items-center justify-center'"
                >
                  <i class="fas fa-eye mr-2"></i> Görüntüle
                </linkBtn>
              </div>
            </div>
            
            <!-- Hata Durumu Açıklama İsteği -->
            <div v-if="item.isError == 1 && item.errorAnswered == 2" class="mt-4 bg-red-50 p-4 rounded-lg border border-red-200">
              <p class="text-red-700 flex items-center mb-3">
                <i class="fas fa-exclamation-circle text-lg mr-2"></i>
                <span class="font-medium">Planlanan miktardan farklı teslimat yapıldı. Lütfen açıklama yapınız.</span>
              </p>
              <button 
                @click="showErrorAlert(item)" 
                class="w-full bg-red-600 text-white py-3 px-4 rounded-lg hover:bg-red-700 text-base font-medium"
              >
                <i class="fas fa-comment-dots mr-2"></i>
                Açıklama Ekle
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Boş Durum -->
      <div v-else-if="!load && List.length === 0" class="flex flex-col items-center justify-center p-16 bg-white">
        <div class="h-32 w-32 bg-gray-200 rounded-full flex items-center justify-center mb-6">
          <i class="fas fa-truck-loading text-gray-400 text-4xl"></i>
        </div>
        <h4 class="text-xl font-bold text-gray-700 mb-2">Henüz Hareket Kaydı Bulunmuyor</h4>
        <p class="text-gray-500 text-center max-w-md">
          Bu araç için henüz durum bilgisi girilmemiş. Araç hareket ettiğinde veya yeni bir işlem yapıldığında burada görünecektir.
        </p>
        <button 
          @click="refreshData" 
          class="mt-6 bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 flex items-center"
        >
          <i class="fas fa-sync-alt mr-2"></i>
          Yenile
        </button>
      </div>

      <setTonnageAndKilometer
        :planId="planId"
        :rowId="selected.id"
        :typeId="typeId"
        :defaultKilometer="selected.kilometer"
        :defaultTonnage="selected.tonnage"
        :vehiclePlaque="Detail.vehicle"
        @refresh="() => getVehiceStatus()"
        :wayPointNo="this.wayPointNo"
      />
    </div>
  </modal>
</template>

<script>
// networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import moment from "moment";

// local component
import setTonnageAndKilometer from "./setTonnageAndKilometer.vue";

//global components
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";
import linkBtn from "@/components/devItem/link-btn.vue";

export default {
  components: {
    diffInput,
    tableLoader,
    moment,
    linkBtn,
    setTonnageAndKilometer,
  },
  name: "vehicle-state-modal",
  props: ["Detail", "typeId"],
  data() {
    return {
      load: false,
      List: [],
      status: "",
      planId: "",
      selected: {},
      wayPointNo: "",
      searchTerm: "",
      // showEditComponent artık kullanılmıyor - ayrı modal yaklaşımı kullanıyoruz
      showNoResults: false,
      errorMessage: "Bu teslim noktasına boşaltılan ürün miktarı, boşaltılması gereken ürün miktarından farklı. Lütfen sebebini belirtiniz."
    };
  },
  computed: {
    filteredList() {
      if (!this.searchTerm || this.searchTerm.trim() === '') return this.List;
      
      const term = this.searchTerm.toLowerCase().trim();
      return this.List.filter(item => 
        (item.name && item.name.toLowerCase().includes(term)) || 
        (item.kilometer && item.kilometer.toString().includes(term)) ||
        (item.tonnage && item.tonnage.toString().includes(term)) ||
        (item.driver && item.driver.toLowerCase().includes(term)) ||
        (item.vehicle && item.vehicle.toLowerCase().includes(term))
      );
    },
    getLastStatusName() {
      if (!this.List || this.List.length === 0) return "Henüz Başlamadı";
      return this.List[0].name || "Durum Bilgisi";
    },
    getPlanDetails() {
      if (!this.Detail) return "-";
      
      const origin = this.Detail.originName || "Başlangıç";
      const destination = this.Detail.destinationName || "Varış";
      
      return `${origin} → ${destination}`;
    }
  },
  methods: {
    hide() {
      this.$modal.hide("vehicleState-modal");
      this.$emit("refresh", true);
    },
    
    formattedDate(date) {
      if (!date) return '-';
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },

    refreshData() {
      this.getVehiceStatus();
    },
    
    show() {
      this.load = true;
      this.List = [];
      this.searchTerm = "";
      this.showNoResults = false;
      setTimeout(() => {
        this.getVehiceStatus();
      }, 50);
    },
    
    checkSearchResults() {
      // Arama sonucunda eğer sonuç yoksa uyarı göster
      if (this.searchTerm && this.searchTerm.trim() !== '') {
        this.showNoResults = this.filteredList.length === 0;
      } else {
        this.showNoResults = false;
      }
    },

    editItem(item) {
      console.log("girdi item",item)
      let nameParts = item.name.split(".");
      this.wayPointNo = nameParts.length > 1 ? nameParts[0] : "0";
      this.planId = this.Detail.id;
      this.selected = item;
      
      // Düzenleme modunu aktif etmek yerine düzenleme modalını gösteriyoruz
      this.$modal.show("edit-state-tonnage-and-kilometer-plan-modal");
    },
    
    onEditComplete() {
      // Düzenleme tamamlandığında verileri yeniliyoruz
      this.getVehiceStatus();
    },
    
    getStatusColorClass(item) {
      if (item.isError == 1 && item.errorAnswered == 2) return 'border-l-red-500';
      
      // Durum ID'sine göre renk belirleme
      const stateId = item.stateId;
      
      if (stateId === 1) return 'border-l-blue-500'; // Başlangıç
      if (stateId === 2) return 'border-l-purple-500'; // Yükleme
      if (stateId === 3) return 'border-l-indigo-500'; // Yola Çıktı
      if (stateId === 6) return 'border-l-yellow-500'; // Teslim Noktasına Ulaştı
      if (stateId === 8) return 'border-l-green-500'; // Teslim Tamamlandı
      if (stateId === 9) return 'border-l-teal-500'; // Tamamlandı
      
      return 'border-l-gray-300';
    },
    
    hasDocuments(item) {
      return item.fileUrl || item.fileTwoUrl || item.fileThreeUrl;
    },
    
    getVehiceStatus() {
      this.List = [];
      this.load = true;
      
      axios
        .get(
          planning.getVehiceStatus,
          {
            params: {
              planId: this.Detail.id,
              typeId: this.typeId
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        )
        .then((response) => {
          const list = response.data.data;
          const totalWayPoints = this.Detail?.wayList?.length || 0;
          let deliveryCounter = 1;
          let completedCounter = 1;
          
          this.List = list.map((item) => {
            if (item.stateId == 6 && deliveryCounter <= totalWayPoints) {
              item.name = deliveryCounter + ". Teslim Noktasına Ulaştı";
              deliveryCounter++;
            } else if (item.stateId == 8 && completedCounter <= totalWayPoints) {
              item.name = completedCounter + ". Teslim Noktası Tamamlandı";
              completedCounter++;
            } else if (item.stateId == 8 && completedCounter == totalWayPoints + 1 && totalWayPoints > 0) {
              item.name = "Son Teslim Noktası Tamamlandı";
            } else if (item.stateId == 6 && totalWayPoints > 0) {
              item.name = "Son Teslim Noktasına Ulaştı";
            }
            return item;
          });
          
          // Tarihe göre sırala (en yeniler en üstte)
          this.List.sort((a, b) => {
            return new Date(b.addDate) - new Date(a.addDate);
          });
        })
        .catch((err) => {
          console.error("Araç durum geçmişi alınamadı:", err);
          // this.swalBox(
          //   "warning",
          //   "Uyarı !",
          //   err.response?.data?.message || "Bilgiler yüklenirken bir hata oluştu. Lütfen tekrar deneyin.",
          //   false,
          //   "Tamam"
          // );
        })
        .finally(() => {
          this.load = false;
        });
    },
    
    showErrorAlert(item) {
      if (!(item.isError == 1 && item.errorAnswered == 2)) return;
      
      this.swalBox(
        "warning",
        "Açıklama Gerekiyor",
        this.errorMessage,
        true,
        "Kaydet",
        "İptal",
        true
      ).then((val) => {
        if (val.isConfirmed && val.value) {
          this.setErrorNote(item.id, val.value.toString());
        }
      });
    },
    
    setErrorNote(rowId, note) {
      this.load = true;
      
      axios
        .post(
          planning.setStateWarning,
          {
            rowId: rowId,
            note: note,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          const detail = result.data.data;
          
          if (!detail.status) {
            this.swalBox(
              "warning",
              "Uyarı",
              result.data.message || "İşlem sırasında bir hata oluştu.",
              false,
              "Tamam"
            );
          } else {
            this.swalBox(
              "success",
              "Başarılı",
              "Açıklama başarıyla kaydedildi.",
              false,
              "Tamam"
            );
            this.getVehiceStatus();
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    
    showDetail(item) {
      const formattedDate = moment(item.answeredDate).format("DD MMMM YYYY HH:mm");
      
      // Açıklama detayını swal kutusunda gösteriyoruz
      this.swalBox(
        "info",
        'NOT',
        `Not Tarihi : ${formattedDate} 
         Not : ${item.errorNote || "Açıklama bulunmuyor"}`,
        false,
        "Kapat"
      );
    },
  },
};
</script>

<style>
.vehicle-state-modal {
  z-index: 9999 !important;
}

/* Kart görünümündeki dalgalanma animasyonu */
@keyframes pulse {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(220, 38, 38, 0.2);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(220, 38, 38, 0);
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Diğer stil iyileştirmeleri */
.vehicle-state-modal button {
  transition: all 0.2s;
}

.vehicle-state-modal button:hover {
  transform: translateY(-1px);
}

.vehicle-state-modal input:focus {
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}

/* Belge bağlantıları daha görünür */
.vehicle-state-modal .grid-cols-3 a {
  font-weight: 500;
  transition: all 0.3s ease;
}

.vehicle-state-modal .grid-cols-3 a:hover {
  transform: scale(1.03);
}

/* Kart görünümü daha belirgin gölgeler */
.vehicle-state-modal .bg-white.rounded-lg {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.vehicle-state-modal .bg-white.rounded-lg:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
}

/* Tonaj ve kilometre bilgisi daha belirgin */
.vehicle-state-modal .font-bold.text-gray-900.text-lg {
  letter-spacing: 0.5px;
}

/* İkonlar için özel stil */
.vehicle-state-modal .fas {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

</style>