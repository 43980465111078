<template>
  <div
    :class="{ 'max-h-0': !isExpanded, 'max-h-96': isExpanded }"
    class="transition-all duration-300 overflow-hidden"
  >
    <div class="p-3">
      <div class="mb-3">
        <label class="text-xs text-gray-500">TAŞINAN ÜRÜN</label>
        <p class="font-semibold text-sm mt-0.5">{{ shipment.productType }}</p>
      </div>

      <div class="mb-3">
        <label class="text-xs text-gray-500">MÜŞTERİ</label>
        <p class="font-semibold text-sm mt-0.5">{{ shipment.customer }}</p>
      </div>

      <div class="grid grid-cols-2 gap-3">
        <div>
          <label class="text-xs text-gray-500">DOLUM NOKTASI</label>
          <p class="font-semibold text-sm mt-0.5">
            {{ shipment.receivedName }}
          </p>
          <p class="text-xs text-gray-500">
            {{ formatDate(shipment.fillingDate) }}
          </p>
        </div>

        <div>
          <label class="text-xs text-gray-500">TESLİM NOKTASI</label>
          <p class="font-semibold text-sm mt-0.5">
            {{ shipment.deliveredName }}
          </p>
          <p class="text-xs text-gray-500">
            {{ formatDate(shipment.deliveryDate) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";

export default {
  name: "ShipmentDetails",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("D MMM YYYY, HH:mm");
    },
  },
};
</script>
  
  <style scoped>
/* Mobil görünüm için */
@media (max-width: 640px) {
  .grid-cols-2 {
    grid-template-columns: 1fr;
  }
}
</style>