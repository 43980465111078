
const { isVehicleSuitableForOrder } = require("../../controller/isVehicleSuitableForOrder");


/**
 * İşi olan fakat teknik olarak uygun olan araçları bulur
 * @param {Array} allVehicles - Tüm araçlar listesi
 * @param {Object} order - Sipariş detayları
 * @param {Array} products - Ürünler listesi 
 * @param {boolean} isBulk - Toplu taşıma mı
 * @param {boolean} skipTheTonnage - Tonaj kontrolünü atla
 * @param {boolean} isClose - Yakında mı
 * @returns {Array} İşi olan ama uygun araçlar listesi
 */
function findBusyButSuitableVehicles(allVehicles, order, products, isBulk = false, skipTheTonnage = false, isClose = false) {
  return allVehicles.filter(vehicle => {
    // İşi olan araçlar (orderNos dizisi boş değil)
    const hasBusyVehicles = Array.isArray(vehicle.orderNos) && vehicle.orderNos.length > 0;
    
    // Bu araç yeni sipariş için teknik olarak uygun mu?
    const isTechnicallyCompatible = isVehicleSuitableForOrder(
      order,
      vehicle,
      products,
      isBulk,
      skipTheTonnage,
      isClose
    );
    
    // İşi olan ve teknik olarak uygun olan araçları seç
    return hasBusyVehicles && isTechnicallyCompatible;
  });
}

module.exports={
    findBusyButSuitableVehicles
}