export default {
    namespaced: true,
    state: () => ({
      List:[],
      activeFilter:[],
      filterList:[],
    }),
    mutations: {
      setActiveFilter(state, payload){
        console.log("payload : ",payload)
        state.activeFilter=payload
      },
      clearFilter(state){
          state.activeFilter="";
      },

      setFilterList(state, payload){
        state.filterList=payload
      },
      clearFilterList(state){
          state.filterList=[];
      },

      addOrder(state,payload){
        const check=state.List.find(r=>r.tab==payload.tab && r.page==payload.page)
        
        if(!check){
          state.List.push({
            page:payload.page,
            data:payload.data,
            filterCenter:payload.filterCenter,
            tab:payload.tab,
            date:new Date()
          })
        }
      },

      clearPage(state) {
        state.List = [];
      },
    },
  };