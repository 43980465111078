
const isAdr = 2;

const moment=require('moment')
const similarity = require("similarity");
/**
 * ---------------
 * Belirli bir aracın, verilen bir siparişi alıp alamayacağını kontrol eder. Örneğin, aracın 18 Nisan'da
 * bir iş aldığını ve 19 Nisan'da 12:30'da teslim edeceğini varsayalım. Bu işlev, 19 Nisan 12:30'dan sonraki
 * siparişler için aracın mevcut konumunu ve sonraki siparişlerin teslim noktalarını kontrol ederek, aracı uygun
 * hale getirir (örneğin, zincirleme siparişler gibi).
 * ---------------
 * @param {Object} vehicle - Kontrol edilecek araç objesi.
 * @param {Object} order - Kontrol edilecek sipariş objesi.
 * @returns {boolean} - Eğer araç belirli bir siparişi alabilirse true, aksi halde false döner.
 */

const { isSequential } = require("./isSequential");


function isVehicleSuitableForOrderDetail(
  order,
  vehicle,
  products,
  isBulk = false,
  skipTheTonnage = false,
  isClose = false
) {
  let capacity = vehicle?.capacity / 1000;
  if (capacity >= 26 && capacity <= 30) {
    capacity = 30.5;
  }
  let isTonnageSuitable = order.tonnage <= capacity || isBulk || skipTheTonnage;

  const isVehicleAvailable =
    (Array.isArray(vehicle?.orderNos)
      ? vehicle.orderNos.filter((r) => r != order.orderNo).length == 0
      : true) ||
      (vehicle?.planning?.isStopped === 1 && Array.isArray(vehicle?.orderNos) &&  vehicle.orderNos.length === 1) || //orderNosta sadece yükünü almışken durdurulmuş aracın sipariş no su var ise bu veya şartı true döner
    isSequential(vehicle, order);


  const filteredProducts = products
  .map((pValue) => ({
    product: pValue,
    score: similarity(
      pValue?.name?.toString().toLocaleLowerCase().replace(/\s/g, ""),
      order?.productName ? order?.productName.toString().toLocaleLowerCase().replace(/\s/g, "") : order?.productType.toString().toLocaleLowerCase().replace(/\s/g, "")
    ),
  }))
  .filter((item) => item.score >= 0.8);
const findProduct = filteredProducts.length
  ? filteredProducts.reduce((bestMatch, item) =>
      item.score > bestMatch.score ? item : bestMatch
    ).product
  : null; // Eğer uygun ürün yoksa `null` döndür



  if (!findProduct) {
    console.log("ürün uygun değil");
    return {
      isTonnageSuitable: false,
      isVehicleAvailable: false,
      isInspectionAvailable: false,
      dorseAvailable: false,
      isAdrAvailable: false,
      isADRCompatible: false,
      isLastproduct: false,
    }; // Ürün bulunamazsa, araç uygun değildir.
  }

  // Ürünün ADR'li olup olmadığını kontrol et
  //Muayenesi bitmiş araçların ve siparişin teslim tarihinden önce bitecek araçları false döndürüyoruz.
  const vehicleIsRent = vehicle.haveType ? vehicle.haveType : vehicle.isRent;
  const productRequiresADR = findProduct.typeId === isAdr;

  let isInspectionAvailable =

  (vehicle.isDorseRent == 1 ? true 
                          : moment(vehicle.inspectionEndDate).diff(moment(), "days") > 0 &&
                            moment(vehicle.inspectionEndDate).diff(moment(order?.deliveryDate),"days") > 0) &&
    (vehicleIsRent == 1
      ? true
      : moment(vehicle.vehicleInspectionEndDate).diff(moment(), "days") > 0 &&
        moment(vehicle.vehicleInspectionEndDate).diff(
          moment(order?.deliveryDate),
          "days"
        ) > 0)
      ? true
      : false;

  let isAdrAvailable =
( vehicleIsRent == 1 
     ? true 
     : moment(vehicle.vehicleAdrEndDate).diff(moment(), "days") > 0 &&
      moment(vehicle.vehicleAdrEndDate).diff(
      moment(order?.deliveryDate),
      "days"
    ) > 0) &&
    (vehicle.isDorseRent == 1
      ? true
      : moment(vehicle.dorseAdrEndDate).diff(moment(), "days") > 0 &&
        moment(vehicle.dorseAdrEndDate).diff(
          moment(order?.deliveryDate),
          "days"
        ) > 0)
      ? true
      : false;
  let isSrcAvailable =
    vehicleIsRent == 1 || !productRequiresADR
      ? true
      : moment(vehicle.srcEndTime).diff(moment(), "days") > 0 &&
        moment(vehicle.srcEndTime).diff(moment(order?.deliveryDate), "days") > 0
      ? true
      : false;

  const ADRVehicle = vehicle?.transportPurpose?.includes("TEHLİKELİ");

  // Eğer Tehlikeli ürün değilse adr uygunluğuna bakmasın
  isAdrAvailable = productRequiresADR ? isAdrAvailable : true;

  let haveTypeAvailable = true;

  if (order.haveType && order.haveType!=3) {
    // kiralık
    haveTypeAvailable = order.haveType == vehicle.haveType;
    // 1 ise kiralık 
    // 2 ise özmal olmalı
  }


  // eğer araç ihtiyacı kırk ayak ise buradaki öneri yapısı çalışarak uygun arabaları döndürür
  if (order.tonnage <= 20 && isClose) {
    const isTonnageSuitable =
      vehicle.capacity >= 18000 && vehicle.capacity <= 22000;

    const productName = order?.productName
      ? order?.productName
      : order?.productType;

    return {
      haveTypeAvailable: true, // kırkayak engel olmasın diye true olarak eklettim
      isTonnageSuitable,
      isVehicleAvailable,
      dorseAvailable: true,
      isADRCompatible:
        !productRequiresADR || (productRequiresADR && ADRVehicle),
      isInspectionAvailable,
      isAdrAvailable,
      isSrcAvailable,
      lastProductAvailable: !vehicle?.lastProduct
        ? 3
        : similarity(vehicle?.lastProduct, productName) > 0.8
        ? 1
        : 2,
    };
  }

  //  Araçta bu dorse türü olup olmadığını kontrol et
  const findDorseType = findProduct.dorseTypes.filter((find) => {
    if (typeof find.name == "string" && typeof vehicle.romorkType == "string") {
      if (
        similarity(
          find?.name.toLowerCase().replace(/\s/g, ""),
          vehicle?.romorkType?.toLowerCase().replace(/\s/g, "")
        ) > 0.8
      ) {
        // console.log("Benzer dorse:",find,"romork:",vehicle.romorkType);
        return find;
      }
    }
  });

  // const allADRDorse = findDorseType.some((dorse) => dorse.name.includes("ADR"));

  const allADRDorse = vehicle?.romorkType?.includes("ADR");

  const productName = order?.productName
    ? order?.productName
    : order?.productType;

  const isADRCompatible =
    !productRequiresADR || (productRequiresADR && allADRDorse && ADRVehicle);


  // Aracın sequential (canlı atama) olup olmadığını kontrol et
  const isLiveAssignment = isSequential(vehicle, order);

  return {
    isTonnageSuitable,
    isVehicleAvailable,
    haveTypeAvailable,
    dorseAvailable: findDorseType.length > 0,
    isADRCompatible,
    isSrcAvailable,
    isInspectionAvailable,
    isAdrAvailable,
    isLiveAssignment,
    lastProductAvailable: !vehicle?.lastProduct
      ? 3
      : similarity(vehicle?.lastProduct, productName) > 0.8
      ? 1
      : 2,
  };
}

module.exports={
    isVehicleSuitableForOrderDetail
}