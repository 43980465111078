<template>
  <div>
    <div class="md:flex">
      <div class="w-full md:w-6/12 mt-4">
        <diffInput
          type="date"
          minlength="1"
          :required="true"
          v-model="orderDate"
          title="Sipariş Tarihi"
          :disabled="true"
        />
      </div>
      <div class="w-full md:w-6/12 mt-4 md:pl-2">
        <diffInput
          type="date"
          minlength="1"
          :required="true"
          v-model="complateDate"
          title="Kapanış Tarihi"
          :disabled="true"
        />
      </div>
    </div>

    <div class="md:flex">
      <div class="w-full md:w-6/12 mt-4">
        <diffInput
          type="text"
          minlength="1"
          :required="true"
          v-model="customerModel"
          :disabled="true"
          title="Müşteri"
        />
      </div>
      <div class="w-full mt-4 md:w-6/12 md:pl-2">
        <diffInput
          type="text"
          :title="$t('operations.planning.modal.billedCompany')"
          v-model="invoicedCompanyModel"
          :disabled="true"
        />
      </div>
    </div>

    <div class="md:flex">
      <div class="w-full mt-4 md:w-6/12">
        <diffInput
          type="text"
          :disabled="true"
          title="Alıcı"
          v-model="deliveredNameModel"
        />
      </div>
      <div class="w-full mt-4 md:w-6/12 md:pl-2">
        <diffInput
          type="text"
          :disabled="true"
          title="Gönderici"
          v-model="receivedNameModel"
        />
      </div>
    </div>

    <div class="md:flex">
      <div class="w-full mt-4 md:w-6/12">
        <diffInput
          type="text"
          v-model="productTypeModel"
          :required="true"
          title="Mal Sınıfı"
          :disabled="true"
        />
      </div>
      <div class="w-full mt-4 md:w-4/12 pl-2">
        <diffInput
          type="text"
          :required="true"
          v-model="amountModel"
          title="Miktar"
          :disabled="true"
        />
      </div>
      <div class="w-full mt-4 md:w-2/12 pl-2">
        <diffInput
          type="text"
          :required="true"
          title="Birim"
          v-model="unitTypeModel"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "FormFields",
  components: {
    diffInput,
  },
  props: {
    orderDate: {
      type: String,
      default: "",
    },
    complateDate: {
      type: String,
      default: "",
    },
    customer: {
      type: String,
      default: "",
    },
    invoicedCompany: {
      type: String,
      default: "",
    },
    receivedName: {
      type: String,
      default: "",
    },
    deliveredName: {
      type: String,
      default: "",
    },
    productType: {
      type: String,
      default: "",
    },
    amount: {
      type: [String, Number],
      default: "",
    },
    unitType: {
      type: String,
      default: "",
    },
  },
  computed: {
    // Using computed properties with getters/setters to maintain two-way binding
    orderDateModel: {
      get() {
        return this.orderDate;
      },
      set(value) {
        this.$emit("update:orderDate", value);
      },
    },
    complateDateModel: {
      get() {
        return this.complateDate;
      },
      set(value) {
        this.$emit("update:complateDate", value);
      },
    },
    customerModel: {
      get() {
        return this.customer;
      },
      set(value) {
        this.$emit("update:customer", value);
      },
    },
    invoicedCompanyModel: {
      get() {
        return this.invoicedCompany;
      },
      set(value) {
        this.$emit("update:invoicedCompany", value);
      },
    },
    receivedNameModel: {
      get() {
        return this.receivedName;
      },
      set(value) {
        this.$emit("update:receivedName", value);
      },
    },
    deliveredNameModel: {
      get() {
        return this.deliveredName;
      },
      set(value) {
        this.$emit("update:deliveredName", value);
      },
    },
    productTypeModel: {
      get() {
        return this.productType;
      },
      set(value) {
        this.$emit("update:productType", value);
      },
    },
    amountModel: {
      get() {
        return this.amount;
      },
      set(value) {
        this.$emit("update:amount", value);
      },
    },
    unitTypeModel: {
      get() {
        return this.unitType;
      },
      set(value) {
        this.$emit("update:unitType", value);
      },
    },
  },
};
</script>