<template>
  <div class="min-h-screen w-full bg-gray-50 overflow-hidden flex">
    <!-- Left Column - Feature Slider -->
    <div class="hidden md:block md:w-2/3 bg-gradient-to-br from-gray-900 to-gray-800 text-white relative overflow-hidden">
      <!-- Background elements -->
      <div class="absolute inset-0 overflow-hidden">
        <div class="absolute top-1/4 left-1/3 w-64 h-64 rounded-full bg-red-500 opacity-10 blur-3xl"></div>
        <div class="absolute bottom-1/4 right-1/3 w-64 h-64 rounded-full bg-blue-500 opacity-10 blur-3xl"></div>
      </div>
      
      <!-- Route visualization pattern -->
      <svg class="absolute inset-0 w-full h-full opacity-10" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,30 Q25,10 50,30 T100,30" stroke="white" stroke-width="0.2" fill="none" />
        <path d="M0,40 Q25,60 50,40 T100,40" stroke="white" stroke-width="0.2" fill="none" />
        <path d="M0,70 Q25,50 50,70 T100,70" stroke="white" stroke-width="0.2" fill="none" />
        <circle cx="20" cy="30" r="0.5" fill="white" class="animate-ping" style="animation-delay: 0s; animation-duration: 3s;" />
        <circle cx="40" cy="40" r="0.5" fill="white" class="animate-ping" style="animation-delay: 1s; animation-duration: 4s;" />
        <circle cx="65" cy="70" r="0.5" fill="white" class="animate-ping" style="animation-delay: 2s; animation-duration: 3.5s;" />
      </svg>

      <!-- Slider Content -->
      <div class="relative h-full z-10">
        <!-- Navigation -->
        <div class="absolute top-8 left-8 flex items-center">
          <!-- <div class="w-10 h-10 bg-white/10 rounded-xl flex items-center justify-center">
            <span class="text-white text-xl font-bold">S</span>
          </div> -->
          <img src="../..//assets/logoseferi/logoresponsive.png" class="w-14" alt="">
          <h1 class="ml-3 text-2xl font-bold">Seferi</h1>
        </div>

        <!-- Slider -->
        <div class="h-full flex flex-col justify-center px-16">
          <div class="carousel relative" v-if="currentSlide === 0">
            <!-- Slide 1 -->
            <div class="slide-content space-y-8">
              <div class="flex items-center">
                <div class="w-12 h-12 rounded-full bg-red-600/20 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                  </svg>
                </div>
                <span class="ml-3 text-red-400 text-sm font-medium uppercase tracking-wider">Yapay Zeka Destekli Lojistik</span>
              </div>

              <h2 class="text-5xl font-bold leading-tight">
                Seferi Filonuzu
                <div class="inline-block relative">
                  <span class="relative z-10">Akıllıca</span>
                  <span class="absolute bottom-1 left-0 w-full h-3 bg-red-600/30 rounded-sm -z-10"></span>
                </div>
                Takip Ediyor
              </h2>

              <p class="text-xl text-gray-300 max-w-2xl">
                Yapay zekamız tüm filonuzu gerçek zamanlı olarak sürekli izler, bakım ihtiyaçlarını sorun haline gelmeden önce tahmin eder ve arıza süresini %78'e kadar azaltır.
              </p>

              <div class="flex items-center space-x-4 pt-4">
                <div class="relative h-20 w-full max-w-xl bg-white/5 rounded-xl p-4 overflow-hidden">
                  <div class="flex justify-between mb-2">
                    <span class="text-sm font-medium">Filo İzleme</span>
                    <span class="text-sm font-medium">98% Doğruluk</span>
                  </div>
                  <div class="h-2 w-full bg-white/10 rounded-full">
                    <div class="h-full w-11/12 bg-gradient-to-r from-green-400 to-blue-500 rounded-full"></div>
                  </div>
                  
                  <!-- Vehicle dots -->
                  <div class="absolute bottom-3 left-[10%] w-2 h-2 bg-green-400 rounded-full"></div>
                  <div class="absolute bottom-3 left-[30%] w-2 h-2 bg-green-400 rounded-full"></div>
                  <div class="absolute bottom-3 left-[45%] w-2 h-2 bg-green-400 rounded-full"></div>
                  <div class="absolute bottom-3 left-[65%] w-2 h-2 bg-yellow-400 rounded-full animate-pulse"></div>
                  <div class="absolute bottom-3 left-[85%] w-2 h-2 bg-green-400 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel relative" v-if="currentSlide === 1">
            <!-- Slide 2 -->
            <div class="slide-content space-y-8">
              <div class="flex items-center">
                <div class="w-12 h-12 rounded-full bg-blue-600/20 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </div>
                <span class="ml-3 text-blue-400 text-sm font-medium uppercase tracking-wider">Akıllı Rota Planlama</span>
              </div>

              <h2 class="text-5xl font-bold leading-tight">
                Seferi Rotalarınızı 
                <div class="inline-block relative">
                  <span class="relative z-10">Optimal olarak</span>
                  <span class="absolute bottom-1 left-0 w-full h-3 bg-blue-600/30 rounded-sm -z-10"></span>
                </div>
                Planlıyor
              </h2>

              <p class="text-xl text-gray-300 max-w-2xl">
                Gelişmiş yapay zekamız trafiği, hava durumunu ve teslimat önceliklerini analiz ederek her araç için mükemmel rotayı oluşturuyor, yakıt maliyetlerini %32 oranında azaltıyor ve zamanında teslimatları artırıyor.
              </p>

              <div class="flex items-center space-x-4 pt-4">
                <div class="relative h-20 w-full max-w-xl bg-white/5 rounded-xl p-4 overflow-hidden">
                  <div class="flex justify-between mb-2">
                    <span class="text-sm font-medium">Rota Verimliliği</span>
                    <span class="text-sm font-medium">+42% İyileştirme</span>
                  </div>
                  <div class="h-2 w-full bg-white/10 rounded-full">
                    <div class="h-full w-5/6 bg-gradient-to-r from-blue-400 to-purple-500 rounded-full"></div>
                  </div>
                  
                  <!-- Route visualization -->
                  <svg class="absolute inset-0 w-full h-full" viewBox="0 0 100 20" preserveAspectRatio="none">
                    <path d="M10,15 Q30,5 50,15 T90,15" stroke="rgba(96, 165, 250, 0.5)" stroke-width="0.5" fill="none" stroke-dasharray="1,1" />
                    <circle cx="10" cy="15" r="0.8" fill="#60a5fa" />
                    <circle cx="90" cy="15" r="0.8" fill="#60a5fa" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel relative" v-if="currentSlide === 2">
            <!-- Slide 3 -->
            <div class="slide-content space-y-8">
              <div class="flex items-center">
                <div class="w-12 h-12 rounded-full bg-green-600/20 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <span class="ml-3 text-green-400 text-sm font-medium uppercase tracking-wider">Basitleştirilmiş Operasyonlar</span>
              </div>

              <h2 class="text-5xl font-bold leading-tight">
                Seferi Lojistiği 
                <div class="inline-block relative">
                  <span class="relative z-10"></span>
                  <span class="absolute bottom-1 left-0 w-full h-3 bg-green-600/30 rounded-sm -z-10"></span>
                </div>

                Yönetiyor
              </h2>

              <p class="text-xl text-gray-300 max-w-2xl">
                Platformumuz, sürücü atamalarından dijital dokümantasyona kadar karmaşık lojistik iş akışlarını otomatikleştirerek idari iş yükünü %65, insan hatasını ise %92 oranında azaltıyor.
              </p>

              <div class="flex items-center space-x-4 pt-4">
                <div class="relative h-20 w-full max-w-xl bg-white/5 rounded-xl p-4 overflow-hidden">
                  <div class="flex justify-between mb-2">
                    <span class="text-sm font-medium">Süreç Otomasyonu</span>
                    <span class="text-sm font-medium">87% Tamamlandı</span>
                  </div>
                  <div class="h-2 w-full bg-white/10 rounded-full">
                    <div class="h-full w-10/12 bg-gradient-to-r from-green-400 to-teal-500 rounded-full"></div>
                  </div>
                  
                  <!-- Document automation visualization -->
                  <div class="absolute top-10 left-4 w-4 h-4 border border-white/30 rounded-sm transform rotate-45 opacity-20"></div>
                  <div class="absolute top-12 left-20 w-4 h-4 border border-white/30 rounded-sm transform -rotate-12 opacity-30"></div>
                  <div class="absolute top-9 left-32 w-4 h-4 border border-white/30 rounded-sm transform rotate-12 opacity-40"></div>
                  <div class="absolute top-11 left-48 w-4 h-4 border border-white/30 rounded-sm transform -rotate-6 opacity-50"></div>
                  <div class="absolute top-10 left-64 w-4 h-4 border border-green-400/50 rounded-sm transform rotate-3 opacity-90"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- Slider controls -->
          <div class="absolute bottom-8 left-0 right-0 flex justify-center space-x-2">
            <button @click="currentSlide = 0" class="w-3 h-3 rounded-full" :class="currentSlide === 0 ? 'bg-white' : 'bg-white/30'"></button>
            <button @click="currentSlide = 1" class="w-3 h-3 rounded-full" :class="currentSlide === 1 ? 'bg-white' : 'bg-white/30'"></button>
            <button @click="currentSlide = 2" class="w-3 h-3 rounded-full" :class="currentSlide === 2 ? 'bg-white' : 'bg-white/30'"></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column - Progressive Login -->
    <div class="w-full md:w-1/3 bg-white flex flex-col">
      <!-- AI Status Indicator -->
      <div class="absolute top-6 right-6 flex items-center px-3 py-1.5 bg-gray-100 rounded-full">
        <span class="inline-block h-2 w-2 rounded-full bg-green-500 mr-2 animate-pulse"></span>
        <span class="text-xs font-medium text-gray-700">AI Agent Active</span>
      </div>

      <!-- Login Steps Container -->
      <div class="flex-1 flex flex-col justify-center px-8 sm:px-16">
        <!-- Step 1: Username -->
        <div v-if="authStep === 1" class="space-y-8">
          <div class="text-center mb-6">
            <div class="inline-flex mb-4 bg-red-50 p-3 rounded-xl">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
              </svg>
            </div>
            <h2 class="text-2xl font-bold text-gray-900">Seferi'ye Hoş Geldiniz</h2>
            <p class="text-gray-500 mt-1">Lojistik kontrol panelinize erişmek için oturum açın</p>
          </div>

          <div class="space-y-6">
            <div class="space-y-2">
              <label for="username" class="block text-sm font-medium text-gray-700">
                Kullanıcı Adı
              </label>
              <div class="relative rounded-lg">
                <input
                  v-model="username"
                  type="text"
                  id="username"
                  class="block w-full pl-12 pr-4 py-4 text-gray-900 rounded-xl border border-gray-200 focus:border-red-500 focus:ring-1 focus:ring-red-500"
                  placeholder="Enter your username"
                  @keyup.enter="nextStep"
                />
                <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            <button
              @click="nextStep"
              class="w-full flex justify-center items-center space-x-2 py-4 px-6 bg-red-600 text-white font-medium rounded-xl shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
            >
              <span>Devam Et</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>

          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-200"></div>
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-500">Yardıma ihtiyacım var.? </span>
              </div>
            </div>
            <div class="mt-6 flex justify-center">
              <a href="#" class="text-sm opacity-50 font-medium text-red-600 hover:text-red-500">
                İletişim Desteği (Yakında)
              </a>
            </div>
          </div>
        </div>

        <!-- Step 2: Password -->
        <div v-if="authStep === 2" class="space-y-8">
          <div class="mb-6">
            <button @click="authStep = 1" class="flex items-center text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
              <span class="text-sm">Geri</span>
            </button>

            <div class="mt-6 flex items-center">
              <div class="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                <span class="text-gray-700 font-medium">{{ username.charAt(0).toUpperCase() }}</span>
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-700">Tekrar hoş geldiniz,</p>
                <p class="text-base font-semibold text-gray-900">{{ username }}</p>
              </div>
            </div>
          </div>

          <div class="space-y-6">
            <div class="space-y-2">
              <div class="flex items-center justify-between">
                <label for="password" class="block text-sm font-medium text-gray-700">
                  Parola
                </label>
                <button
                  type="button"
                  @click="forgotPassword"
                  class="text-xs font-medium text-red-600 hover:text-red-500"
                >
                  Parolamı unuttum?
                </button>
              </div>
              <div class="relative rounded-lg">
                <input
                  v-model="password"
                  type="password"
                  id="password"
                  class="block w-full pl-12 pr-4 py-4 text-gray-900 rounded-xl border border-gray-200 focus:border-red-500 focus:ring-1 focus:ring-red-500"
                  placeholder="Enter your password"
                  @keyup.enter="login"
                />
                <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Remember Me -->
            <div class="flex items-center">
              <div class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                :class="rememberMe ? 'bg-red-600' : 'bg-gray-200'"
                @click="toggleRememberMe">
                <span 
                  aria-hidden="true" 
                  class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-300 ease-in-out" 
                  :class="rememberMe ? 'translate-x-5' : 'translate-x-0'">
                </span>
              </div>
              <span class="ml-3 text-sm text-gray-600">Beni Hatırla</span>
            </div>

            <button
              @click="login"
              class="w-full flex justify-center items-center space-x-2 py-4 px-6 bg-red-600 text-white font-medium rounded-xl shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
              :class="{'opacity-80': load, 'cursor-wait': load}"
              :disabled="load"
            >
              <span v-if="load" class="flex items-center">
                <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Giriş Yapılıyor...
              </span>
              <span v-else>Giriş Yap</span>
            </button>
          </div>
        </div>

        <!-- Step 3: Forgot Password -->
        <div v-if="authStep === 3" class="space-y-8">
          <div class="mb-6">
            <button @click="authStep = 2" class="flex items-center text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
              <span class="text-sm">Girişe geri dön</span>
            </button>

            <div class="mt-6">
              <h2 class="text-2xl font-bold text-gray-900">Reset your password</h2>
              <p class="text-gray-500 mt-1">We'll send a password reset link to your email</p>
            </div>
          </div>

          <div class="space-y-6">
            <div class="space-y-2">
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <div class="relative rounded-lg">
                <input
                  v-model="email"
                  type="email"
                  id="email"
                  class="block w-full pl-12 pr-4 py-4 text-gray-900 rounded-xl border border-gray-200 focus:border-red-500 focus:ring-1 focus:ring-red-500"
                  placeholder="Enter your email address"
                />
                <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Reset Button -->
            <div v-if="!isCountdownActive">
              <button
                @click="resetPassword"
                class="w-full flex justify-center items-center space-x-2 py-4 px-6 bg-red-600 text-white font-medium rounded-xl shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
                :class="{'opacity-80': load, 'cursor-wait': load}"
                :disabled="load"
              >
                <span v-if="load" class="flex items-center">
                  <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Link Gönderiliyor...
                </span>
                <span v-else>Sıfırlama bağlantısı gönder</span>
              </button>
            </div>
            
            <!-- Countdown State -->
            <div
              v-else
              class="w-full py-4 px-6 bg-red-50 text-red-700 text-center flex items-center justify-center rounded-xl"
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Tekrar dene: {{ formatTime(countdown) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="py-4 px-8 sm:px-16 text-center">
        <p class="text-xs text-gray-500">Powered by Sirius AI Tech © 2025</p>
      </div>
    </div>
  </div>
</template>

<script>
// npm
import VuePassword from "vue-password";

// networking
import axios from "axios";
import { user } from "@/networking/urlmanager.js";

export default {
  components: {
    VuePassword,
  },
  data() {
    return {
      authStep: 1, // 1: Username, 2: Password, 3: Forgot Password
      currentSlide: 0,
      sliderInterval: null,
      load: false,
      username: "",
      password: "",
      email: "",
      companyCode: "6",
      isCountdownActive: false,
      countdown: 60,
      countdownInterval: null,
      rememberMe: false,
      showConnectionWarning: false
    };
  },
  methods: {
    nextStep() {
      if (this.username.trim() === '') {
        this.showError('Lütfen kullanıcı adınızı girin');
        return;
      }
      this.authStep = 2;
    },
    forgotPassword() {
      this.authStep = 3;
    },
    toggleRememberMe() {
      this.rememberMe = !this.rememberMe;
    },
    closeConnectionWarning() {
      this.showConnectionWarning = false;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
    startCountdown() {
      this.isCountdownActive = true;
      this.countdown = 60;

      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownInterval);
      this.isCountdownActive = false;
      this.countdown = 60;
    },
    startSliderInterval() {
      this.sliderInterval = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % 3;
      }, 8000);
    },
    // Kullanıcı bilgilerini localStorage'a kaydetme
    saveUserToLocalStorage() {
      if (this.rememberMe && this.username) {
        localStorage.setItem('rememberedUser', this.username);
      } else {
        localStorage.removeItem('rememberedUser');
      }
    },
    // LocalStorage'dan kullanıcı bilgilerini alma
    getSavedUser() {
      const savedUsername = localStorage.getItem('rememberedUser');
      if (savedUsername) {
        this.username = savedUsername;
        this.rememberMe = true;
        // If we have a saved username, go directly to password step
        this.authStep = 2;
      }
    },
    showError(message) {
      // Show error notification - implementation would depend on your UI components
      this.swalBox(
        "error",
        "Error",
        message,
        false,
        "OK"
      );
    },
    login() {
      if (!this.username || !this.password) {
        this.showError('Lütfen hem kullanıcı adı hem de şifre girin');
        return;
      }
      
      this.load = true;
      
      // Beni hatırla işlemi için kullanıcı adını localStorage'a kaydet
      if (this.rememberMe) {
        this.saveUserToLocalStorage();
      } else {
        localStorage.removeItem('rememberedUser');
      }
      
      axios
        .post(user.signIn, {
          username: this.username,
          password: this.password,
          companyId: this.companyCode,
          rememberMe: this.rememberMe
        })
        .then((result) => {
          this.$store.commit("signIn", result.data.data);

          const filteredRoutes = this.$router.options.routes
            .filter(
              (item) =>
                item.meta.module &&
                this.$store.state.userData.perms.some(
                  (r) => r.editState != 0 && r.permtypeId == item.meta.module
                )
            )
            .sort(function (a, b) {
              return a.meta.module - b.meta.module;
            });

          if (this.$store.state.userData.perms.length > 0) {
            this.$store.commit("complateFirstAuth");
            this.$router.push(filteredRoutes[0]);
          } else {
            this.$store.commit("complateFirstAuth");
            this.$store.commit("generalPermError");

            this.$router.push("/general-perm-error");
          }
          this.load = false;
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
    async resetPassword() {
      if (!this.email) {
        this.showError('Lütfen e-posta adresinizi girin');
        return;
      }
      
      try {
        this.load = true;
        await axios.post(user.fargotPasswordCreate, {
          email: this.email,
          companyId: this.companyCode,
        });
        this.load = false;
        this.swalBox(
          "success",
          "Success",
          "Şifre sıfırlama bağlantısı e-posta adresinize gönderildi",
          false,
          "OK"
        );
        this.startCountdown();
      } catch (err) {
        this.errorBox(err.response);
        this.load = false;
      }
    },
  },
  created() {
    // Start the slider
    this.startSliderInterval();
    
    // Set light mode and handle mobile view
    this.$store.commit("activeLightMode");
    document.documentElement.classList.remove("dark");

    if (this.isMobile) {
      this.$store.commit("hideNavState");
    }

    // LocalStorage'dan kullanıcı bilgilerini al
    this.getSavedUser();

    
    // Düzenli olarak internet bağlantısını kontrol et
    setInterval(this.checkConnection, 30000);

    if (this.$store.state.isAuth) {
      const filteredRoutes = this.$router.options.routes
        .filter(
          (item) =>
            item.meta.module &&
            this.$store.state.userData.perms.some(
              (r) => r.editState != 0 && r.permtypeId == item.meta.module
            )
        )
        .sort(function (a, b) {
          return a.meta.module - b.meta.module;
        });

      console.log(filteredRoutes[0])
      this.$store.commit("complateFirstAuth");
      this.$router.push(filteredRoutes[0]);
    }
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    if (this.sliderInterval) {
      clearInterval(this.sliderInterval);
    }
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); }
  to { transform: translateY(0); }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

/* Slide transitions */
.carousel {
  animation: fadeIn 0.5s ease-out;
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: opacity 0.5s, transform 0.5s ease;
}

.slide-up-enter, .slide-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}

input:focus {
  outline: none;
}

button, input {
  transition: all 0.2s ease-in-out;
}
</style>