const { recommendSingleVehicleForOrder } = require("./recommendSingleVehicleForOrder");
/**
 * Bir sipariş için birden fazla aracı önerir ve bunları toplam maliyete göre sıralar.
 *
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Mevcut araçlar listesi.
 * @param {Array} customers - Müşteri bilgileri listesi.
 * @param {Array} products - Ürün bilgileri listesi.
 * @param {number} totalCount - Toplam adet değeri.
 * @param {Array} blockedVehicles - Önerilerden hariç tutulacak araçlar listesi.
 * @param {string} token - Kimlik doğrulama tokeni.
 * @returns {Promise<Array>} - Önerilen araçların maliyetlerine göre sıralanmış dizisi.
 */

async function recommendMultipleVehiclesForOrder(
    order,
    vehicles,
    customers,
    products,
    totalCount,
    blockedVehicles = [],
    token
  ) {
    try {
      const recommendedVehicles = await recommendSingleVehicleForOrder(
        order,
        vehicles,
        customers,
        products,
        totalCount,
        true,
        false,
        false,
        blockedVehicles,
        false,
        token
      );
      

      if (recommendedVehicles.REALLOCATION_NEEDED) {
        // recommendSingleVehicleForOrder'dan gelen bayrağı ve mesajı aynen döndür
        console.log("yakın mesafeda araç yok aktif siparişlerden araç çekilmesi gerekiyor")
        return recommendedVehicles;
      }

      if (!Array.isArray(recommendedVehicles)) {
        throw new Error("Invalid recommendations received");
      }
      return recommendedVehicles;
    } catch (error) {
      console.error("Error in vehicle recommendation:", error);
      return [];
    }
  }

  module.exports={
    recommendMultipleVehiclesForOrder
  }