
const { calculateDistancePriority } = require("../../calculator/calculateDistancePriority");


/**
 * Belirli bir mesafeden daha uzak olup olmadığını kontrol eder
 * @param {Array} vehicles - Araç listesi
 * @param {number} maxDistance - Maksimum kabul edilebilir mesafe (km)
 * @param {Object} order - Sipariş detayları
 * @returns {boolean} - Tüm araçlar maxDistance'dan uzaksa true döner
 */
function areAllVehiclesTooFar(vehicles, maxDistance, order) {
    if (vehicles.length === 0) return true;
    
    const distances = vehicles.map(vehicle => 
      parseFloat(vehicle.distance || calculateDistancePriority(order, vehicle))
    );
    
    const minDistance = Math.min(...distances);
    return minDistance > maxDistance;
  }
  

  module.exports={
    areAllVehiclesTooFar
  }