<template>
  <div
    v-if="show"
    class="fixed xl:top-16 top-0 h-screen xl:h-full w-full bg-white xl:right-0 shadow-lg xl:w-64 transition-all duration-300 z-20"
  >
    <!-- Panel Başlığı -->
    <div class="flex items-center justify-between p-4 border-b">
      <h3 class="font-medium text-gray-800">Gelişmiş Filtreleme</h3>
      <button
        @click="hideModal()"
        class="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 text-gray-500 transition-colors"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Filtre Formu -->
    <div class="p-4 overflow-y-auto" style="height: calc(100% - 60px)">
      <form @submit.prevent="filterItem()" class="space-y-6">
        <!-- Bölge Filtresi -->
        <div class="filter-group">
          <geofilterInput v-model="region" />
        </div>
        
        <!-- Durum Filtresi -->
        <div class="filter-group">
          <wordStatusInput v-model="workStatus" />
        </div>
        
        <!-- Ürün Filtresi -->
        <div class="filter-group">
          <productInput
            v-model="productId"
            title="Taşınan Ürün"
            :toggleSelectAll="true"
            @changeName="productName = $event"
          />
        </div>
        
        <!-- Teslimat Filtresi -->
        <div class="filter-group">
          <deliveryInput
            v-model="deliveryState"
            @changeStartDate="deliveryDate.start = $event"
            @changeEndDate="deliveryDate.end = $event"
            :detail="deliveryDate"
          />
        </div>
        
        <!-- Butonlar -->
        <div class="pt-4">
          <asyncBtn
            icon="fas fa-filter"
            text="Filtreleri Uygula"
            loadTitle="Filtreleniyor"
            :loadState="load"
            class="w-full rounded-lg"
          />
          
          <button
            type="button"
            @click="filterClear"
            class="w-full mt-3 py-2 text-sm text-gray-600 hover:text-red-500 transition-colors"
          >
            <i class="fas fa-times-circle mr-1"></i>
            Filtreleri Temizle
          </button>
        </div>
      </form>
      
      <!-- Filtre Özeti -->
      <div v-if="hasActiveFilters" class="mt-6 pt-4 border-t border-gray-100">
        <h4 class="text-xs font-medium text-gray-500 mb-2">Aktif Filtreler</h4>
        
        <div class="flex flex-wrap gap-2">
          <span 
            v-if="region.key" 
            class="text-xs bg-red-50 text-red-600 px-2 py-1 rounded-full flex items-center"
          >
            <span>{{ region.key }}</span>
            <button @click="clearFilter('region')" class="ml-1.5 text-red-500">
              <i class="fas fa-times-circle"></i>
            </button>
          </span>
          
          <span 
            v-if="workStatus !== 1" 
            class="text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded-full flex items-center"
          >
            <span>{{ getWorkStatusText }}</span>
            <button @click="clearFilter('workStatus')" class="ml-1.5 text-blue-500">
              <i class="fas fa-times-circle"></i>
            </button>
          </span>
          
          <span 
            v-if="productName" 
            class="text-xs bg-green-50 text-green-600 px-2 py-1 rounded-full flex items-center"
          >
            <span>{{ productName }}</span>
            <button @click="clearFilter('product')" class="ml-1.5 text-green-500">
              <i class="fas fa-times-circle"></i>
            </button>
          </span>
          
          <span 
            v-if="deliveryState !== 1" 
            class="text-xs bg-purple-50 text-purple-600 px-2 py-1 rounded-full flex items-center"
          >
            <span>{{ getDeliveryStateText }}</span>
            <button @click="clearFilter('delivery')" class="ml-1.5 text-purple-500">
              <i class="fas fa-times-circle"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import geofilterInput from "./geofilter.input.vue";
import wordStatusInput from "./workStatus.input.vue";
import deliveryInput from "./delivery.input.vue";
import productInput from "@/components/devItem/productInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

export default {
  name: "FilterPanel",
  props: ["detail"],
  components: {
    geofilterInput,
    wordStatusInput,
    productInput,
    deliveryInput,
    asyncBtn,
  },
  data() {
    return {
      region: {
        key: null,
        id: 1,
      },
      workStatus: 1,
      productName: "",
      productId: "",
      deliveryState: 1,
      deliveryDate: {
        start: null,
        end: null,
      },
      load: false,
      show: false,
    };
  },
  computed: {
    hasActiveFilters() {
      return (
        this.region.key || 
        this.workStatus !== 1 || 
        this.productName || 
        this.deliveryState !== 1
      );
    },
    getWorkStatusText() {
      const statusMap = {
        2: "Durmuş",
        3: "Hareket Halinde",
      };
      return statusMap[this.workStatus] || "Tümü";
    },
    getDeliveryStateText() {
      const stateMap = {
        2: "Belirli Tarih Aralığı",
      };
      return stateMap[this.deliveryState] || "Tümü";
    }
  },
  methods: {
    showModal() {
      this.show = true;
    },
    
    hideModal() {
      this.show = false;
      this.$emit("closeModal", true);
    },
    
    filterItem() {
      this.$emit("change", {
        region: this.region,
        workStatus: this.workStatus,
        productId: this.productId ? this.productId : null,
        productName: this.productName ? this.productName : null,
        deliveryDate: this.deliveryDate ? this.deliveryDate : null,
        deliveryState: this.deliveryState ? this.deliveryState : 1,
      });
      this.hideModal();
    },
    
    filterClear() {
      this.region = { key: null, id: 1 };
      this.workStatus = 1;
      this.productId = null;
      this.productName = "";
      this.deliveryDate = { start: null, end: null };
      this.deliveryState = 1;
      
      this.$emit("change", {
        region: { key: null, id: 1 },
        workStatus: 1,
        productId: null,
        productName: null,
        deliveryDate: null,
        deliveryState: 1,
      });
      this.hideModal();
    },
    
    clearFilter(type) {
      switch(type) {
        case 'region':
          this.region = { key: null, id: 1 };
          break;
        case 'workStatus':
          this.workStatus = 1;
          break;
        case 'product':
          this.productId = null;
          this.productName = "";
          break;
        case 'delivery':
          this.deliveryState = 1;
          this.deliveryDate = { start: null, end: null };
          break;
      }
    }
  },
  created() {
    const val = this.detail;
    this.region = val.region;
    this.workStatus = val.workStatus;
    this.productId = val.productId;
    this.productName = val.productName;
    this.deliveryDate = val.deliveryDate;
    this.deliveryState = val.deliveryState;
  },
  watch: {
    detail(val) {
      this.region = val.region;
      this.workStatus = val.workStatus;
      this.productId = val.productId;
      this.productName = val.productName; 
      this.deliveryDate = val.deliveryDate;
      this.deliveryState = val.deliveryState;
    },
  },
};
</script>

<style scoped>
.filter-group {
  @apply  px-2 py-1 rounded-lg;
}
</style>