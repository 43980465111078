import moment from "moment";
import { haversineDistance } from "@/utils/recomendation";
import { getCustomerDetail } from "../api/planningService";

/**
 * Calculates the day of week from a date string
 * 
 * @param {string} dateStr - Date string
 * @returns {number} Day of week (0-6, Sunday is 0)
 */
export const getDayOfWeek = (dateStr) => {
  const date = dateStr ? new Date(dateStr) : new Date();
  return date.getDay();
};

/**
 * Calculates the estimated time in minutes to travel between two points
 * 
 * @param {object} params - Object containing from/to coordinates 
 * @returns {number} Estimated travel time in minutes
 */
export const distanceTwoLocationHour = ({ fromLat, fromLng, toLat, toLng }) => {
  const distance = haversineDistance(
    { lat: fromLat, lng: fromLng },
    { lat: toLat, lng: toLng }
  );
  
  // Calculate time based on average speed of 45km/h plus 20 minutes buffer
  return Math.round((distance / 45) * 60 + 20);
};

/**
 * Adds minutes to a time string
 * 
 * @param {string} time - Time string in format "HH:mm"
 * @param {number} minutesToAdd - Minutes to add
 * @returns {string} New time string in format "HH:mm"
 */
export const addMinutesToTime = (time, minutesToAdd) => {
  const timeMoment = moment(time, "HH:mm");
  timeMoment.add(minutesToAdd, "minutes");
  
  return timeMoment.format("HH:mm");
};

/**
 * Checks if a given time is within working hours
 * 
 * @param {string} arrivalTime - Arrival time in format "HH:mm"
 * @param {object} workingHours - Object with startHour and endHour
 * @returns {boolean} True if time is within working hours
 */
export const isWithinWorkingHours = (arrivalTime, workingHours) => {
  const { startHour, endHour } = workingHours;
  
  const arrivalMoment = moment(arrivalTime, "HH:mm");
  const startMoment = moment(startHour, "HH:mm");
  const endMoment = moment(endHour, "HH:mm");
  
  return arrivalMoment.isBetween(startMoment, endMoment, undefined, "[]");
};

/**
 * Checks if the delivery can be made within the customer's working hours
 * 
 * @param {number} fromLat - Starting latitude
 * @param {number} fromLng - Starting longitude
 * @param {number} toLat - Destination latitude
 * @param {number} toLng - Destination longitude
 * @param {string} vehicleArrivalTime - Vehicle arrival time
 * @param {string} fillingDate - Date/time for filling
 * @param {string} customerId - Customer ID to check working hours
 * @param {string} token - Authentication token
 * @returns {boolean} True if delivery can be made within working hours
 */
export const checkWorkingHours = async (
  fromLat, 
  fromLng, 
  toLat, 
  toLng, 
  vehicleArrivalTime, 
  fillingDate, 
  customerId,
  token
) => {
  // Calculate distance and time between locations
  const params = {
    fromLat: Number(fromLat),
    fromLng: Number(fromLng),
    toLat: Number(toLat),
    toLng: Number(toLng),
  };
  
  const distanceHour = distanceTwoLocationHour(params);
  
  // Get arrival time estimate
  let arrivalTime;
  
  if (vehicleArrivalTime) {
    // For delivery check
    arrivalTime = addMinutesToTime(
      vehicleArrivalTime,
      distanceHour + Number(fillingDate || 0)
    );
  } else {
    // For filling check (from vehicle to filling point)
    const currentTime = moment().format("HH:mm");
    arrivalTime = addMinutesToTime(currentTime, distanceHour);
  }
  
  // Get customer working hours
  const workingHours = await getCustomerDetail(customerId, token);
  
  // No working hours defined means always available
  if (workingHours[0] === null) return true;
  
  const dayOfWeek = getDayOfWeek();
  const dayOfWeekWorkingHours = workingHours.find(
    (item) => item.dayId === dayOfWeek
  );
  
  // No working hours for this day means always available
  if (!dayOfWeekWorkingHours) return true;
  
  // Check if arrival time is within working hours
  return isWithinWorkingHours(arrivalTime, dayOfWeekWorkingHours);
};