<template>
  <modal
    :name="modalName || 'notes-modal'"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '600px'"
    :scrollable="true"
    @opened="show"
  >
    <div class="flex flex-col">
      <!-- Modal header -->
      <div class="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center bg-white dark:bg-gray-800">
        <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Durum Geçmişi</h3>
        <button 
          class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200" 
          @click="$modal.hide(modalName || 'notes-modal')"
        >
          <i class="fas fa-times text-xl"></i>
        </button>
      </div>

      <!-- Add note button -->
      <div class="p-4 bg-gray-50 dark:bg-gray-800">
        <asyncBtn
          icon="fas fa-plus"
          text="Yeni Not"
          class="btn bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded transition duration-200"
          @click="openAddModal"
          :loading="isLoading"
        />
      </div>

      <!-- Table container -->
      <div class="flex-grow p-4 bg-gray-50 dark:bg-gray-800" style="height:60vh !important">
        <div v-if="load" class="flex justify-center items-center py-8 h-full">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600"></div>
        </div>
        
        <div v-else class="bg-white dark:bg-gray-900 rounded-lg shadow-sm overflow-hidden">
          <LyrausTable
            :data="List"
            :columns="columns"
            :isLoading="load"
            :alternateRowBg="['white', 'gray-50']"
            :totalCount="totalCount"
            :enablePagination="true"
            :pageCount="pageCount"
            :pageProp="currentPage"
            @update-current-page="handleCurrentPage"
            :buttonsColumn="{ label: 'İşlem', width: 100 }"
            :paginationButtonColor="'red-600'"
            :paginationHoverColor="'red-700'"
            :innerThClass="'py-3 px-4 text-left text-xs font-medium text-gray-700 dark:text-gray-300 tracking-wider border-b border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-800'"
            :innerTdClass="'px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300 border-b border-gray-200 dark:border-gray-700'"
          >
            <template v-slot:colButtons="{ item }">
              <div class="flex items-center justify-end">
                <button 
                  class="p-2 text-gray-500 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200" 
                  @click="showEditModal(item)" 
                  title="Düzenle"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <button 
                  class="p-2 text-gray-500 hover:text-red-600 dark:hover:text-red-400 transition-colors duration-200" 
                  @click="handleDelete(item)"
                  title="Sil"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </template>

            <template v-slot:empty>
              <div class="text-center py-8 text-gray-500 dark:text-gray-400">
                <i class="fas fa-clipboard-list text-4xl mb-2"></i>
                <p>Henüz not eklenmemiş.</p>
              </div>
            </template>
          </LyrausTable>
        </div>
      </div>
    </div>

    <!-- Note modal component -->
    <NoteModal
      :title="mode === 'add' ? 'Not Ekle' : 'Not Düzenle'"
      :note="selected ? selected.message : undefined"
      :on-submit="mode === 'add' ? addNote : editNote"
      :isLoading="isLoading"
    />
  </modal>
</template>

<script>
import axios from "axios";

// network
import { orderNotes } from "../../../networking/urlmanager";

//global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import NoteModal from "@/components/modals/addEditNoteModal/index.vue";

export default {
  components: {
    asyncBtn,
    NoteModal,
  },
  name: "notes-modal",
  props: ["orderId", "orderStatus", "isBulk", "type", "evacuationId", "modalName"],
  data() {
    return {
      load: false,
      isLoading: false,
      selected: undefined,
      mode: "add",
      List: [],
      totalCount: 0,
      currentPage: 0,
      pageCount: 0,
      columns: [
        {
          key: "operatorName",
          label: "Operatör",
        },
        {
          key: "createDate",
          label: "Oluşturulma Zamanı",
          isDate: true,
          format: (value) => this.formatDate(value)
        },
        {
          key: "orderStatus",
          label: "Sipariş Durumu",
          format: (value) => this.formatStatus(value)
        },
        {
          key: "message",
          label: "Not",
          maxWidth: "300px",
          truncate: true
        },
      ],
    };
  },
  computed: {
    apiParams() {
      const params = {
        page: this.currentPage,
      };
      
      if (this.type === "gemi" || this.type === "depo") {
        params.evacuationType = this.type === "gemi" ? 1 : 2;
        params.evacuationId = this.evacuationId;
      } else {
        params.orderId = this.orderId;
      }
      
      return params;
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '-';
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    },
    
    formatStatus(status) {
      // Burada duruma göre renklendirme yapılabilir
      return status || '-';
    },
    
    getAll() {
      this.load = true;
      
      axios
        .get(orderNotes.getAll, {
          params: this.apiParams,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    
    addNote(note) {
      this.isLoading = true;
      const params = {
        message: note,
        orderStatus: this.orderStatus,
        isBulk: this.isBulk,
      };
      
      if (this.type === "gemi" || this.type === "depo") {
        params.evacuationType = this.type === "gemi" ? 1 : 2;
        params.evacuationId = this.evacuationId;
      } else {
        params.orderId = this.orderId;
      }
      
      axios
        .post(orderNotes.addNote, params, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            "BAŞARILI",
            "Not başarıyla eklendi",
            false,
            "Tamam"
          );
          this.$modal.hide("add-edit-note-modal");
          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    
    editNote(note) {
      this.isLoading = true;
      const params = {
        rowId: this.selected.id,
        message: note,
        orderStatus: this.orderStatus,
        isBulk: this.isBulk,
      };
      
      if (this.type === "gemi" || this.type === "depo") {
        params.evacuationType = this.type === "gemi" ? 1 : 2;
        params.evacuationId = this.evacuationId;
      } else {
        params.orderId = this.orderId;
      }
      
      axios
        .post(orderNotes.editNote, params, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            "BAŞARILI",
            "Not başarıyla düzenlendi",
            false,
            "Tamam"
          );
          this.$modal.hide("add-edit-note-modal");
          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    
    deleteNote(item) {
      this.isLoading = true;
      
      axios
        .post(orderNotes.deleteNote, { rowId: item.id }, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            "BAŞARILI",
            "Not başarıyla silindi",
            false,
            "Tamam"
          );
          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    
    handleDelete(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Notu silmek istediğinize emin misiniz?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.deleteNote(item);
        }
      });
    },

    showEditModal(item) {
      this.mode = "edit";
      this.selected = item;
      this.$modal.show("add-edit-note-modal");
    },
    
    hide() {
      this.$modal.hide(this.modalName || "notes-modal");
      this.$emit("refresh", true);
    },
    
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    
    openAddModal() {
      this.mode = "add";
      this.selected = undefined;
      this.$modal.show("add-edit-note-modal");
    },
    
    show() {
      this.load = true;
      this.List = [];
      setTimeout(() => {
        this.getAll();
      }, 50);
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>

<style scoped>
.notes-modal {
  z-index: 9999 !important;
}

/* Tablo geçişleri */
.notes-modal table tr {
  transition: background-color 0.2s ease;
}

/* NoteModal içindeki yükleme durumu için animasyon */
.loading-spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid #e53e3e;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobil optimizasyonları */
@media (max-width: 640px) {
  .notes-modal table th,
  .notes-modal table td {
    padding: 8px 4px;
    font-size: 12px;
  }
}
</style>