<template>
  <tr @contextmenu.prevent="(event) => $emit('open-context-menu', event)">
    <td
      v-if="isPlanning"
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none z-40"
    >
      <i v-if="activeState == 1" class="bg-white rounded block h-4 w-4"></i>
      <i
        v-else-if="activeState == 2"
        class="fas fa-check bg-blue-500 text-white rounded"
        style="padding: 2px"
      ></i>
    </td>

    <!-- Order Number Cell -->
    <td
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <span class="text-white text-opacity-80"># {{ item.orderNo }}</span>
      <p class="text-xs" :title="item.orderName">
        {{ truncateText(item.orderName, 13) }}
      </p>
    </td>

    <!-- Filling Location Cell -->
    <td
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer"
    >
      <div class="text-white" style="font-size: 10px">
        <span class="text-xs" :title="item.receivedName">
          {{ truncateText(item.receivedName, 24) }}
        </span>
      </div>
      <div class="flex items-center justify-between">
        <p class="text-white text-opacity-75" style="font-size: 9px">
          {{ getLastThreeWords(item.outpointAdress) }}
        </p>
        <p class="text-white text-opacity-50" style="font-size: 8px">
          {{ formatDate(item.orderDate) }}
        </p>
      </div>
    </td>

    <!-- Delivery Location Cell -->
    <td
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer"
    >
      <div
        class="text-white"
        style="font-size: 10px"
        :title="item.deliveredName"
      >
        {{ truncateText(item.deliveredName, 22) }}
      </div>
      <div class="flex items-center justify-between">
        <p class="text-white text-opacity-75" style="font-size: 8px">
          {{ getLastThreeWords(item.targetAdress) }}
        </p>
        <p class="text-white opacity-50" style="font-size: 8px">
          {{ formatDeliveryDate(item.deliveryDate) }}
          {{ item.deliveryTime }}
        </p>
      </div>
    </td>

    <!-- Customer Cell -->
    <td
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <p :title="item.customer">{{ truncateText(item.customer, 22) }}</p>
    </td>

    <!-- Product Type Cell -->
    <td
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
      :title="!isProductValid(item.productType) && 'Ürün Sistemde Bulunamadı'"
      :class="!isProductValid(item.productType) && 'bg-red-700 animate-pulse'"
    >
      {{ item.productType }}
    </td>

    <!-- Quantity Cell -->
    <td
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <p class="text-xs">{{ item.tonnage }} {{ item.unitType }}</p>
    </td>

    <!-- Cells only visible when viewing planned orders (activeState === 2) -->
    <!-- Wash Point Cell -->
    <td
      v-if="!item.isMultiple && activeState === 2"
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <p class="text-xs">{{ item.washPointName || "-" }}</p>
    </td>

    <!-- Wash Cost Cell -->
    <td
      v-if="!item.isMultiple && activeState === 2"
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <p class="text-xs">{{ item.washCost }}</p>
    </td>

    <!-- Trip Cost Cell -->
    <td
      v-if="!item.isMultiple && activeState === 2"
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <p class="text-xs">{{ item.recomendationTotalCost }}</p>
    </td>

    <!-- Total Cost Cell -->
    <td
      v-if="!item.isMultiple && activeState === 2"
      @click="$emit('select-item')"
      class="table-td border-r border-opacity-20 cursor-pointer select-none"
    >
      <p class="text-xs">{{ Number(item.allCost).toFixed(2) }}</p>
    </td>

    <!-- Vehicle Cell -->
    <td
      v-if="activeState < 3"
      class="table-td w-28 border-r border-opacity-20 text-center"
      :colspan="item.isMultiple && 6"
    >
      <!-- Small order handling -->
      <div
        v-if="
          !item.isMultiple &&
          activeState == 1 &&
          (item.unitType === 'TON' ? item.tonnage < 31 : item.tonnage < 2)
        "
        class="px-4 py-1 rounded w-full text-opacity-80 text-white"
      >
        SPOT SİPARİŞ
      </div>

      <!-- Vehicle selection for planned orders -->
      <VehicleSelect
        v-else-if="!item.isMultiple && activeState == 2"
        :value="item.vehicle"
        :optList="vehicleList"
        @changeName="(val) => $emit('change-vehicle', val)"
        @changeDriverName="(driver) => $emit('change-driver', driver)"
        :customers="customers"
        :order="item"
        :sorted="true"
        :products="products"
        @changeDorse="(val) => (item.dorse = val)"
        @isVehicleConflictModalVisible="(val)=>$emit('isVehicleConflictModalVisible',val)"
      />

      <!-- Bulk order button -->
      <button
        v-else
        @click="showConfirm"
        class="px-4 py-1 rounded w-full text-opacity-80 text-white"
      >
        TOPLU SİPARİŞ
      </button>
    </td>

    <!-- Driver Cell -->
    <td
      v-if="activeState == 2 && !item.isMultiple"
      class="table-td border-r border-opacity-20 text-center"
    >
      <div v-if="!item.isPirateDriver">
        <PersonnelSelect
          :titleNone="true"
          :hideContainer="true"
          :value="item.vehicleName"
          @changeName="(val) => $emit('change-driver', val)"
          @changeTC="(val) => (item.driverTC = val)"
          @onChange="(val)=> $emit('handle-personel-change', val, item)"
          :isCache="true"
          class="py-2"
        />
      </div>

      <!-- Korsan sürücü için -->
      <div v-else class="text-white bg-red-800 px-2 py-1 rounded">
        {{ item.driver || item.driverName }}
        <i class="fas fa-user-secret ml-1"></i>
      </div>
    </td>

    <!-- Actions Cell -->
    <td v-if="activeState == 2" class="table-td text-center">
      <button
        class="process-btn mr-2"
        v-if="item.tonnage <= 30"
        @click="$emit('view-order')"
      >
        <i class="fas fa-search"></i>
      </button>
      <button class="process-btn" @click="$emit('remove-order')">
        <i class="fas fa-trash"></i>
      </button>
    </td>
  </tr>
</template>
  
  <script>
import VehicleSelect from "./vehicle-select.vue";
import PersonnelSelect from "@/components/devItem/personnelInput.vue";
import similarity from "similarity";
import moment from "moment";

export default {
  name: "TableRow",
  components: {
    VehicleSelect,
    PersonnelSelect,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    activeState: {
      type: Number,
      required: true,
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      required: true,
    },
    vehicleList: {
      type: Array,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (!text) return "";
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },

    getLastThreeWords(address) {
      if (!address) return "";

      const words = address.split(" ");
      if (words.length <= 3) return address;

      return words[words.length - 2] + " / " + words[words.length - 3];
    },

    isProductValid(product) {
      const findItem = this.products.find(
        (item) =>
          similarity(
            item?.name?.toString().toLowerCase().replace(/\s/g, ""),
            product?.toString().toLowerCase().replace(/\s/g, "")
          ) > 0.95
      );

      if (!findItem) return false;

      if (Array.isArray(findItem.dorseTypes)) {
        return findItem.dorseTypes.length > 0;
      }

      return true;
    },

    formatDate(date) {
      if (!date) return "Belirtilmemiş";
      return moment(date).format("LLL");
    },

    formatDeliveryDate(date) {
      if (!date) return "Belirtilmemiş";
      return moment(date).format("D MMMM YYYY");
    },

    showConfirm() {
      this.$swal({
        title: "UYARI",
        text: "BU SİPARİŞ KAYIT EDİLDİKTEN SONRA TOPLU PLANLAMA EKRANINDAN PLANLANABİLİR!",
        icon: "warning",
        confirmButtonText: "Tamam",
      });
    },
  },
};
</script>