<template>
  <div
    class="fixed right-72 z-10 md:w-5/12 xl:w-4/12 flex items-center"
    style="right: 260px; top: 120px"
  >
    <!-- Gruplar (Bölgeler) Butonu -->
    <button
      @click="$emit('toggleZones')"
      class="px-3 ml-2 h-10 text-sm rounded-lg justify-center flex items-center"
      :class="showZones === true ? 'bg-red-700 text-white' : 'bg-white'"
    >
      <i class="fas fa-align-center"></i>
      <span class="ml-2">Gruplar</span>
    </button>

    <!-- Müşteriler Butonu -->
    <button
      @click="$emit('toggleFactories')"
      class="ml-2 h-10 px-4 text-sm rounded-lg justify-center flex items-center"
      :class="showFactories === true ? 'bg-red-700 text-white' : 'bg-white'"
    >
      <img
        class="w-5 h-5"
        :src="
          showFactories === false
            ? require('@/assets/marker/factoryDark.png')
            : require('@/assets/marker/factoryLight.png')
        "
      />
      <span class="ml-2">Müşteriler</span>
    </button>

    <!-- Özet Butonu -->
    <button
      @click="$emit('openSummary')"
      class="mx-2 bg-white text-sm rounded-md shadow-xl h-10 px-3 flex items-center"
    >
      <i class="fas fa-list"></i>
      <span class="ml-2">Özet</span>
    </button>

    <!-- Arama Kutusu -->
    <input
      type="search"
      class="w-10/12 h-10 px-2 border-b-2 rounded focus:outline-none bg-white"
      placeholder="Aramak için yazınız..."
      :value="search"
      @input="$emit('searchChanged', $event.target.value)"
      @keypress.enter="$emit('searchSubmitted')"
    />

    <!-- Filtre Butonu -->
    <button
      @click="$emit('openFilterPanel')"
      class="mx-2 bg-white h-10 rounded-md shadow-xl flex items-center px-4 text-sm"
    >
      <i class="fas fa-filter"></i>
      <span class="ml-2">Filtre</span>
    </button>
  </div>
</template>
  
  <script>
export default {
  name: "MapControls",
  props: {
    showZones: {
      type: Boolean,
      default: false,
    },
    showFactories: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
  },
  emits: [
    "toggleZones",
    "toggleFactories",
    "searchChanged",
    "searchSubmitted",
    "openFilterPanel",
    "openSummary",
  ],
};
</script>