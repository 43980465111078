<template>
  <transition name="fade">
    <div
      v-if="show"
      class="fixed inset-0 z-50 overflow-y-auto"
      style="z-index: 9999 !important"
    >
      <div
        class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity" @click="$emit('close')">
          <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>

        <!-- Modal Container -->
        <div
          class="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <!-- Modal Body -->
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <!-- Warning Icon -->
                <i class="fas fa-exclamation-circle text-yellow-600"></i>
              </div>

              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-white">
                  Siparişleri Kaydet
                </h3>

                <div class="mt-2">
                  <p class="text-sm text-gray-300">
                    Seçilen <strong>{{ orderCount }}</strong> siparişi kaydetmek
                    istediğinize emin misiniz?
                  </p>
                </div>

                <!-- Order List -->
                <div
                  class="mt-4 bg-gray-700 p-3 rounded text-sm max-h-60 overflow-y-auto"
                >
                  <div
                    v-for="order in orders"
                    :key="order.orderNo"
                    class="border-b border-gray-600 py-2 text-white"
                  >
                    <span class="text-gray-400">#{{ order.orderNo }}</span>
                    - <span class="text-white">{{ order.customer }}</span> ({{
                      order.productType
                    }}
                    - {{ order.tonnage }} {{ order.unitType }})
                  </div>
                </div>

                <!-- Bulk Order Warning -->
                <div
                  v-if="orderCount > 10"
                  class="mt-3 text-yellow-500 text-sm"
                >
                  <i class="fas fa-info-circle mr-1"></i>
                  Seçilen sipariş sayısı <strong>{{ orderCount }}</strong
                  >. Bu işlem uzun sürebilir. Devam etmek istediğinize emin
                  misiniz?
                </div>

                <!-- Warning -->
                <div class="mt-3 text-yellow-500 text-sm">
                  <i class="fas fa-info-circle mr-1"></i>
                  Bu işlem geri alınamaz. Lütfen bilgileri kontrol edin.
                </div>
              </div>
            </div>
          </div>

          <!-- Modal Footer -->
          <div
            class="bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
          >
            <button
              @click="saveAs()"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              <span v-if="!loading">
                <i class="fas fa-check"></i> Evet, Tümünü Kaydet
              </span>
              <span v-else>
                <i class="fas fa-spinner fa-spin"></i> Kayıt Ediliyor
              </span>
            </button>
            <button
              @click="$emit('close')"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-500 shadow-sm px-4 py-2 bg-gray-800 text-base font-medium text-gray-300 hover:bg-gray-600 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SaveOrderConfirmationModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    orderCount: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    saveAs() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>