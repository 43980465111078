<template>
  <div class="max-h-screen overflow-y-auto xl:pb-1 pb-10">
    <div
      class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mb-4 h-72"
    >
      <vehicleModal :detail="selected" />

      <table class="min-w-full divide-y divide-gray-200">
        <thead
          class="bg-white border-black border-dotted border-b-2 border-opacity-20"
        >
          <tr>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 50px !important"
            >
              #
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20 z-50"
              style="min-width: 280px !important"
            >
              Sipariş
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20 z-50"
              style="min-width: 280px !important"
            >
              Araç
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 180px !important"
            >
              Konum
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 180px !important"
            >
              Yıkama Noktası
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            >
              Yıkama Maliyeti
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            >
              Sefer Maliyeti
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            >
              Toplam Maliyet
            </th>
            <th
              v-if="!hiddenInsert"
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 90px !important"
            ></th>
          </tr>
        </thead>
        <tbody
          v-if="!isMultipleDay"
          class="divide-y-2 divide-gray-500"
          style="background-color: #3a3a3a"
        >
          <newVehicle
            v-if="changeState == false && !hiddenInsert"
            @addItem="(r) => addItem(r)"
            :order="orders"
            :products="products"
            :customers="customers"
            :vehicleList="vehicleList"
            :selectedVehicles="List"
          />

          <tr
            v-show="
              (!changeState || List?.length > 0) &&
              (!load || !isRecommendationInProgress)
            "
            v-for="(item, index) in List"
            :key="index"
          >
            <td class="table-td border-r border-opacity-20">
              <span># {{ index + 1 }} </span>
            </td>
            <td class="table-td border-r border-opacity-20">
              <span>
                {{
                  orders?.find((orderItem) => orderItem.orderNo == item.orderNo)
                    .name
                }}
              </span>
            </td>
            <td
              class="table-td border-r border-opacity-20 flex justify-between items-center"
            >
              <div class="w-1/2 flex items-center">
                <p class="w-1/2">
                  {{ item.vehicle }}
                </p>
                <p class="w-1/2">{{ item.capacity / 1000 }} TON</p>
              </div>
              <div class="w-1/2 flex justify-between items-center">
                <img
                  :title="item.romorkType"
                  v-if="item.capacity / 1000 <= 52 && item.capacity / 1000 > 20"
                  class="w-12 h-8"
                  src="@/assets/vehicles/fuel-truck.png"
                  alt=""
                />
                <img
                  :title="item.romorkType"
                  v-if="item.capacity / 1000 <= 20"
                  class="w-10 h-8"
                  src="@/assets/vehicles/tank-truck.png"
                  alt=""
                />
                <span
                  class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
                  v-if="item.isCustom == 1"
                >
                  Manuel Atama
                </span>
              </div>
            </td>
            <td class="table-td border-r border-opacity-20">
              <p>
                {{ item.address + " / " + Money(item?.recomendation.isWashing == 1 ? item?.distanceWithWashing : item.distance) + " KM" }}
              </p>
            </td>
            <td class="table-td border-r border-opacity-20">
              <p>
                {{ item.washPointName }}
              </p>
            </td>
            <td class="table-td border-r border-opacity-20">
              <p v-if="item.washCost >= 0">
                {{ item.washCost }}
              </p>
              <p v-else>Loading...</p>
            </td>
            <td class="table-td border-r border-opacity-20">
              <p v-if="item.recomendationTotalCost >= 0">
                {{ item.recomendationTotalCost }}
              </p>
              <p v-else>Loading...</p>
            </td>
            <td class="table-td border-r border-opacity-20">
              <p v-if="item.allCost >= 0">
                {{ Number(item.allCost).toFixed(2) }}
              </p>
              <p v-else>Loading...</p>
            </td>
            <td v-if="!hiddenInsert" class="flex justify-center items-center">
              <button
                type="button"
                class="process-btn mr-2"
                @click="vehicleDetailItem(item)"
              >
                <i class="fas fa-search"></i>
              </button>
              <button
                type="button"
                class="process-btn"
                @click="deleteItem(item, index)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <td
            colspan="8"
            class="text-center p-4 text-white"
            v-if="
              changeState == true &&
              List?.length == 0 &&
              (!load || !isRecommendationInProgress)
            "
          >
            <i class="fas fa-recycle fa-3x"></i>
            <h3 class="text-2xl mt-2">ARAÇ PLANLA</h3>
            <p class="text-sm my-2 text-white text-opacity-75">
              bu sipariş için araç planlamak için planla butonuna basınız
            </p>
            <button
              class="bg-red-500 px-4 py-1 rounded"
              type="button"
              @click="() => calculateVehicle()"
            >
              Araç Öner
            </button>
          </td>
        </tbody>
        <tableLoader
          :load="load || isRecommendationInProgress"
          length="1"
          colspan="8"
        />
      </table>
    </div>
  </div>
</template>

<script>
// NPM
import _ from "lodash";
import similarity from "similarity";

// Global Components
import vehicleModal from "@/components/modals/vehicle.detail.modal/index.vue";
import tableLoader from "@/components/general/table-loader.vue";
// import reloadVehicleList from "@/components/general/reload-vehicle-list.vue";

// Local Components
import newVehicle from "./new.vehicle.vue";

// Utils
import {
  getCustomers,
  getLocationAll,
  getProducts,
  customer,
} from "@/utils/detailData";
import {
  recommendMultipleVehiclesForOrder,
  calculateDistancePriority,
  calculateCustomerPriority,
  isVehicleSuitableForOrderDetail,
  isWashStatus,
  haversineDistance,
} from "@/utils/recomendation";
import moment from "moment";

//Axios
import axios from "axios";
import { bulkShipPlanning } from "@/networking/urlmanager";

export default {
  name: "vehicle-table",
  props: ["orders", "shipId", "isLaterAddOrder"],
  components: {
    tableLoader,
    newVehicle,
    vehicleModal,
  },
  data() {
    return {
      activeEror: "",
      load: false,
      changeState: true,
      List: [],
      // isRecomendedListEmpty: false,
      dedicatedVehicles: [],
      currentVehicles: [],
      vehicleList: [],
      customers: [],
      products: [],
      new: {
        vehicle: "",
        dorse: "",
        driverName: "",
        driverTC: "",
      },
      washingLocations: [],
      insufficientErr: false,
      vehicleArrivalTime: "",
      recAccVehicleCapacity: 0,
      currAccVehicleCapacity: 0,
      isDeleted: false,
      selected: { id: "", plaque: "", vehicleId: "" },
      hiddenInsert: false,
      isManuel: false,
      newOrder: undefined,

      isCalculationInProgress: false,
      isRecommendationInProgress: false,
      debouncedCalculateVehicle: _.debounce(this.calculateVehicle, 300),
    };
  },

  methods: {
    addItem(item) {
      if (item) {
        const order = this.orders?.find(
          (orderItem) => orderItem.orderNo == item.orderNo
        );
        const findProduct = this.products.find((pValue) => {
          return (
            similarity(
              pValue?.name?.toString().toLocaleLowerCase().replace(/\s/g, ""),
              order?.productType
                ?.toString()
                .toLocaleLowerCase()
                .replace(/\s/g, "")
            ) >= 0.8
          );
        });
        if (findProduct?.typeId === 2) {
          //  Araçta bu dorse türü olup olmadığını kontrol et
          const findDorseType = findProduct.dorseTypes.filter((find) => {
            if (
              typeof find.name == "string" &&
              typeof item.romorkType == "string"
            ) {
              if (
                similarity(
                  find?.name.toLowerCase().replace(/\s/g, ""),
                  item?.romorkType?.toLowerCase().replace(/\s/g, "")
                ) > 0.85
              ) {
                return find;
              }
            }
          });

          const allADRDorse = item?.romorkType?.includes("ADR");
          const ADRVehicle = item?.transportPurpose?.includes("TEHLİKELİ");

          if (!allADRDorse || !ADRVehicle || findDorseType.length < 1) {
            this.$swal
              .fire({
                icon: "warning",
                title: this.$t("general.warningTitle"),
                text: "Uygun olmayan araç",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: this.$t("general.confirmText"),
                cancelButtonText: this.$t("general.rejectText"),
                customClass: {
                  popup: "w-1/2",
                },
                html: `<div class="flex flex-col items-center">
                ${
                  findDorseType.length < 1
                    ? `<span class="w-full">
                      Bu araç bu ürünü taşımak için uygun bir dorseye sahip
                      değildir!
                    </span>`
                    : ""
                }
                ${
                  !allADRDorse
                    ? `<span class="w-full">
                      Bu aracın dorsesi tehlikeli ürün taşımaya uygun değildir!
                    </span>`
                    : ""
                }
                ${
                  !ADRVehicle
                    ? `<span class="w-full">
                      Bu çekici tehlikeli ürün taşımaya uygun değildir!
                    </span>`
                    : ""
                }
                <br/>
                <span class="w-full">Devam etmek istediğinize emin misiniz?</span>
                </div>`,
              })
              .then((acc) => {
                if (acc.isConfirmed) {
                  this.addVehicle(item);
                }
              });
          } else {
            this.addVehicle(item);
          }
        } else {
          this.addVehicle(item);
        }
      }
    },
    addVehicle(item) {
      this.insufficientErr = false;
      let index = this.List.some((r) => parseInt(r.id) == parseInt(item.id));
      if (!index) {
        this.isDeleted = true;
        this.isManuel = true;

        const vehicleIndex = this.vehicleList.findIndex(
          (vitem) => vitem.id == item.id
        );

        const order = this.orders?.find(
          (orderItem) => orderItem.orderNo == item.orderNo
        );

        this.vehicleList[vehicleIndex].orderNos = [item.orderNo];
        this.List.push({
          ...item,
          plate: item.name,
          vehicle: item.name,
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
          isRent: item?.haveType ?? item?.isRent,
          distance: calculateDistancePriority(
            { startLat: item.outpointLat, startLng: item.outpointLng },
            { latitude: item.latitude, longitude: item.longitude }
          ),
          isCustom: 1,
          recomendation: {
            isWashing: isWashStatus(this.customers, this.products, item, order)
              .status,
          },
        });

        this.$emit("addVehicle", true);

        setTimeout(() => {
          this.isDeleted = false;
          this.isManuel = false;
        }, 50);
      } else {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Bu Araç Zaten Listede Var",
          false,
          this.$t("general.OkayTitle")
        );
      }
    },
    deleteItem(detail, index) {
      if (confirm("ARACI LİSTEDEN ÇIKARMAK İSTEDİĞİNİZE EMİN MİSİNİZ?")) {
        this.isDeleted = true;
        const vehicleIndex = this.vehicleList.findIndex(
          (item) => item.id == detail.id
        );

        if (vehicleIndex > -1) {
          this.vehicleList[vehicleIndex].orderNos = [];
        }

        this.List.splice(index, 1);
        this.$emit("removeVehicle", true);

        setTimeout(() => {
          this.isDeleted = false;
        }, 50);
      }
    },
    vehicleDetailItem(item) {
      const { latitude, longitude, ...rest } = item;
      const order = this.orders?.find(
        (orderItem) => orderItem.orderNo == item.orderNo
      );
      if (item.isCustom == 2) {
        this.selected = {
          outpointLng: Number(order.outpointLng),
          outpointLat: Number(order.outpointLat),
          targetPointLat: Number(order.targetPointLat),
          targetPointLng: Number(order.targetPointLng),
          ...rest,
          tonnage: item.recomendation.capacity,
          orderAmount: Number(order.amount),
          recomendation: {
            ...item.recomendation,
            position: { lat: latitude, lng: longitude },
          },
        };
      } else {
        this.selected = {
          outpointLng: Number(
            order.outpointLng ? order.outpointLng : order.fillingLongitude
          ),
          outpointLat: Number(
            order.outpointLat ? order.outpointLat : order.fillingLatitude
          ),
          targetPointLat: Number(
            order.targetPointLat ? order.targetPointLat : order.deliveryLatitude
          ),
          targetPointLng: Number(
            order.targetPointLng
              ? order.targetPointLng
              : order.deliveryLongitude
          ),
          ...rest,
          tonnage: item.capacity,
          orderAmount: Number(order.amount),
          recomendation: {
            ...item.recomendation,
            name: item.vehicle,
            position: { lat: latitude, lng: longitude },
            capacity: item.capacity,
            customerPriority: calculateCustomerPriority(order, this.customers),
            distancePriority: calculateDistancePriority(
              {
                startLat: Number(
                  order.outpointLat ? order.outpointLat : order.fillingLatitude
                ),
                startLng: Number(
                  order.outpointLng ? order.outpointLng : order.fillingLongitude
                ),
              },
              { latitude: latitude, longitude: longitude }
            ).toFixed(2),
            ...isVehicleSuitableForOrderDetail(
              order,
              item,
              this.products,
              false,
              false
            ),
          },
        };
      }
      this.$modal.show("vehicle-modal");
    },
    async vehicleRecomended(item, blockedVehicles = []) {
      if (this.isRecommendationInProgress) {
        console.log(
          "vehicleRecomended: A recommendation is already in progress"
        );
        return null;
      }

      this.isRecommendationInProgress = true;

      try {
        const order = {
          orderNo: item.orderNo,
          haveType: item.haveType === 3 ? null : item.haveType,
          startLat: item.outpointLat ? item.outpointLat : item.fillingLatitude,
          startLng: item.outpointLng ? item.outpointLng : item.fillingLongitude,
          endLat: item.targetPointLat
            ? item.targetPointLat
            : item.deliveryLatitude,
          endLng: item.targetPointLng
            ? item.targetPointLng
            : item.deliveryLongitude,
          deliveryDate: item.deliveryDate
            ? item.deliveryDate
            : item.estimatedComplateDate,
          tonnage:
            item.unitType === "TON"
              ? parseFloat(item.amount ? item.amount : item.tonnage)
              : item.tonnage * 26,
          productName: item.productType,
          productId: item.productId,
          token: this.$store.state.userData.token,
        };
        this.newOrder = order;
        const recommendedVehicles = await recommendMultipleVehiclesForOrder(
          order,
          this.vehicleList,
          this.customers,
          this.products,
          item.recomendedCount,
          [...this.List, ...blockedVehicles],
          this.$store.state.userData.token
        );
        return recommendedVehicles;
      } finally {
        this.isRecommendationInProgress = false;
        console.log("vehicleRecomended finished");
      }
    },

    async processOrderItem(blockedVehicles = []) {
      this.List = this.List.filter((item) => item.isCustom == 1);
      if (this.totalCount < this.List.length) {
        this.List = this.List.slice(0, this.totalCount);
      }

      for (let i = 0; i < this.orders?.length; i++) {
        const item = this.orders[i];
        if (!item || item.isCustom) {
          console.log("processOrderItem: No valid order or custom order");
          return;
        }

        let recommendedVehicles = await this.vehicleRecomended(
          item,
          blockedVehicles
        );

        if (recommendedVehicles) {
          recommendedVehicles = recommendedVehicles.map((vehicle) => {
            return {
              ...vehicle,
              orderNo: item.orderNo,
              orderName: item.name,
            };
          });

          await this.handleRecommendedVehicles(recommendedVehicles, item);
        }
      }
    },
    async handleRecommendedVehicles(recommendedVehicles, order) {
      if (
        !Array.isArray(recommendedVehicles) ||
        recommendedVehicles.length === 0
      ) {
        this.handleNoRecommendedVehicles();
        return;
      }

      if (recommendedVehicles.length < this.totalCount) {
        this.handleInsufficientVehicles(recommendedVehicles);
      }

      const newDedicatedVehicles = this.getNewDedicatedVehicles(
        recommendedVehicles,
        order
      );

      if (newDedicatedVehicles.length) {
        await this.handleDedicatedVehicles(
          newDedicatedVehicles,
          recommendedVehicles,
          order
        );
      } else {
        this.addRecommendedVehiclesToList(recommendedVehicles, order);
      }
    },
    handleNoRecommendedVehicles() {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Lütfen ürün kartını kontrol ediniz. Uygun bir araç bulunamadı, bu sorun ürünün taşınması için seçilen dorse türünün uygun olmamasından kaynaklanıyor olabilir. Ürünün taşınması için gerekli olan dorse türünün doğru şekilde atandığından emin olunuz.",
        false,
        this.$t("general.OkayTitle")
      );
      this.load = false;
    },
    handleInsufficientVehicles(recommendedVehicles) {
      this.insufficientErr = true;
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        `Bu iş için girilen araç sayısı ${this.recomendedCount} ama müsait araç sayısı ${recommendedVehicles.length} olduğu için araç sayısı güncellendi.`,
        false,
        this.$t("general.OkayTitle")
      );
      this.$emit("update-total-count", recommendedVehicles.length);
    },
    getNewDedicatedVehicles(recommendedVehicles, order) {
      return recommendedVehicles.filter(
        (item) =>
          item.fleetCustomers.length &&
          !item.fleetCustomers.some(
            (customer) => customer?.customerId === order?.customerId
          )
      );
    },
    async getCustomerDetail(customerId) {
      try {
        const response = await axios.get(
          `${customer.getAll}?customerId=${customerId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.userData.token}`,
            },
          }
        );
        if (response.data.data.detail.length > 0)
          return response.data.data.detail[0].shiftHours;
        else {
          this.$swal.fire({
            icon: "warning",
            title: "UYARI!",
            text: "Müşteri kartı veya çalışma saatleri bilgilerine ulaşılamadı. Müşteri silinmiş olabilir, lütfen kontrol edip tekrar deneyin.",
            confirmButtonText: "Tamam",
          });
        }
      } catch (err) {
        this.errorBox(err.response);
      }
    },
    getDayOfWeek(dateStr) {
      const date = dateStr ? new Date(dateStr) : new Date();
      const dayOfWeekNumber = date.getDay();

      return dayOfWeekNumber;
    },
    distanceTwoLocationHour({ fromLat, fromLng, toLat, toLng }) {
      const distance = haversineDistance(
        { lat: fromLat, lng: fromLng },
        {
          lat: toLat,
          lng: toLng,
        }
      );
      return Math.round((distance / 45) * 60 + 20);
    },
    addMinutesToTime(time, minutesToAdd) {
      const timeMoment = moment(time, "HH:mm");
      timeMoment.add(minutesToAdd, "minutes");

      return timeMoment.format("HH:mm");
    },
    isWithinWorkingHours(arrivalTime, workingHours) {
      const { startHour, endHour } = workingHours;

      const arrivalMoment = moment(arrivalTime, "HH:mm");
      const startMoment = moment(startHour, "HH:mm");
      const endMoment = moment(endHour, "HH:mm");

      return arrivalMoment.isBetween(startMoment, endMoment, undefined, "[]");
    },
    async checkDeliveryCustomerWorkingHours(order) {
      const params = {
        fromLat: Number(order.outpointLat),
        fromLng: Number(order.outpointLng),
        toLat: Number(order.targetPointLat),
        toLng: Number(order.targetPointLng),
      };
      const distanceHour = this.distanceTwoLocationHour(params);
      const vehicleArrivalTime = this.addMinutesToTime(
        this.vehicleArrivalTime,
        distanceHour + Number(this.fillingDate)
      );

      const workingHours = await this.getCustomerDetail(
        order.deliveryCustomerId
      );

      if (workingHours[0] === null) return true;
      const dayOfWeek = this.getDayOfWeek();
      const dayOfWeekWorkingHours = workingHours.find(
        (item) => item.dayId === dayOfWeek
      );

      if (!dayOfWeekWorkingHours) return true;
      const result = this.isWithinWorkingHours(
        vehicleArrivalTime,
        dayOfWeekWorkingHours
      );

      return result;
    },

    async checkFillingCustomerWorkingHours(vehicle, order) {
      const params = {
        fromLat: vehicle.latitude,
        fromLng: vehicle.longitude,
        toLat: Number(order.outpointLat),
        toLng: Number(order.outpointLng),
      };
      const distanceHour = this.distanceTwoLocationHour(params);
      const currentTime = moment().format("HH:mm");
      this.vehicleArrivalTime = this.addMinutesToTime(
        currentTime,
        distanceHour
      );
      const workingHours = await this.getCustomerDetail(
        order.fillingCustomerId
      );
      if (workingHours[0] === null) return true;
      const dayOfWeek = this.getDayOfWeek();
      const dayOfWeekWorkingHours = workingHours.find(
        (item) => item.dayId === dayOfWeek
      );
      if (!dayOfWeekWorkingHours) return true;
      const result = this.isWithinWorkingHours(
        this.vehicleArrivalTime,
        dayOfWeekWorkingHours
      );
      return result;
    },
    async handleDedicatedVehicles(
      newDedicatedVehicles,
      recommendedVehicles,
      order
    ) {
      this.dedicatedVehicles = [
        ...this.dedicatedVehicles,
        ...newDedicatedVehicles,
      ];

      const groupedData = newDedicatedVehicles.reduce((acc, item) => {
        item.fleetCustomers.forEach((customer) => {
          if (!acc[customer?.customerName]) {
            acc[customer?.customerName] = [];
          }
          acc[customer?.customerName].push(item.plate);
        });
        return acc;
      }, {});

      const tableData = Object.entries(groupedData).map(
        ([customerName, vehicles]) => ({
          customerName,
          vehicles: vehicles.join(", "),
        })
      );

      const result = await this.$swal.fire({
        icon: "warning",
        title: "UYARI !",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Devam et",
        cancelButtonText: "Yeniden Öner",
        width: "800px",
        html: `
            <div>
              <div>
                <span class="block text-lg font-semibold mb-4">Önerilen araç listesinde başka müşteriye atanmış filoya ait araçlar var:</span>
              </div>
              <div class="overflow-x-auto">
                <table class="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr class="w-full bg-gray-200 text-left text-sm leading-normal text-gray-600 uppercase tracking-wider">
                      <th class="py-3 px-6 border-r border-gray-200">Müşteri</th>
                      <th class="py-3 px-6">Araçlar</th>
                    </tr>
                  </thead>
                  <tbody class="text-gray-700">
                    ${tableData
                      .map(
                        (entry, index) =>
                          `
                            <tr key="${index}" class="border-b border-gray-200 text-xs text-left">
                              <td class="py-3 px-6 border-r border-gray-200">${entry.customerName}</td>
                              <td class="py-3 px-6">${entry.vehicles}</td>
                            </tr>
                          `
                      )
                      .join("")}
                  </tbody>
                </table>
              </div>
              <div>
                <span class="block text-lg font-semibold mt-4">Devam etmek için DEVAM ET'e, yeniden araç önerisi yapmak için YENİDEN ÖNER'e basınız?</span>
              </div>
            </div>
          `,
      });

      if (result.isConfirmed) {
        this.addRecommendedVehiclesToList(recommendedVehicles, order);
      } else {
        await this.processOrderItem(this.dedicatedVehicles);
      }
    },
    async addRecommendedVehiclesToList(recommendedVehicles, order) {
      //   let fillingArrivalFailList = [];
      //   let deliveryArrivalFailList = [];
      //   for (const item of recommendedVehicles) {
      //     if (order.deliveryCustomerId) {
      //       const deliveryWillArrive =
      //         await this.checkDeliveryCustomerWorkingHours(order);
      //       if (!deliveryWillArrive) {
      //         deliveryArrivalFailList.push(item);
      //       }
      //     }

      //     if (order.fillingCustomerId) {
      //       const fillingWillArrive = await this.checkFillingCustomerWorkingHours(
      //         item,
      //         order
      //       );
      //       if (!fillingWillArrive) {
      //         fillingArrivalFailList.push(item);
      //       }
      //     }
      //   }
      //   if (fillingArrivalFailList.length || deliveryArrivalFailList.length) {
      //     const tableData = [
      //       {
      //         title: "Yüklemeye yetişmeyecek araçlar",
      //         vehicles:
      //           fillingArrivalFailList.map((item) => item.vehicle).join(", ") ||
      //           "Yok",
      //       },
      //       {
      //         title: "Boşaltmaya yetişmeyecek araçlar",
      //         vehicles:
      //           deliveryArrivalFailList.map((item) => item.vehicle).join(", ") ||
      //           "Yok",
      //       },
      //     ];

      //     await this.$swal.fire({
      //       icon: "warning",
      //       title: "UYARI !",
      //       showConfirmButton: true,
      //       confirmButtonText: "Tamam",
      //       width: "800px",
      //       html: `
      //     <div>
      //       <div>
      //         <span class="block text-lg font-semibold mb-4">Önerilen araç listesinde müşterinin çalışma saatlerine yetişemeyecek araçlar var:</span>
      //       </div>
      //       <div class="overflow-x-auto">
      //         <table class="min-w-full bg-white border border-gray-200">
      //           <thead>
      //             <tr class="w-full bg-gray-200 text-left text-sm leading-normal text-gray-600 uppercase tracking-wider">
      //               <th class="py-3 px-6 border-r border-gray-200">Durum</th>
      //               <th class="py-3 px-6">Araçlar</th>
      //             </tr>
      //           </thead>
      //           <tbody class="text-gray-700">
      //             ${tableData
      //               .map(
      //                 (entry, index) =>
      //                   `
      //                     <tr key="${index}" class="border-b border-gray-200 text-xs text-left">
      //                       <td class="py-3 px-6 border-r border-gray-200">${entry.title}</td>
      //                       <td class="py-3 px-6">${entry.vehicles}</td>
      //                     </tr>
      //                   `
      //               )
      //               .join("")}
      //           </tbody>
      //         </table>
      //       </div>
      //     </div>
      // `,
      //     });
      //   }

      this.List = [...this.List, ...recommendedVehicles];
      this.$emit("change", this.List);
    },
    async calculateVehicle() {
      if (this.isCalculationInProgress) {
        console.log("calculateVehicle: Calculation is already in progress");
        return;
      }

      console.log("calculateVehicle called");
      this.isCalculationInProgress = true;

      try {
        if (this.totalCount < 1) {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "Araç sayısı en az 1 olmalıdır",
            false,
            this.$t("general.OkayTitle")
          );
          return;
        }
        // asdasdasd
        if (this.vehicleList?.length == 0) {
          this.load = true;
          this.vehicleList = await getLocationAll(
            this.$store.state.userData.token
          );
          this.load = false;
        }

        if (this.isDeleted) {
          console.log("calculateVehicle: isDeleted is true, returning");
          return;
        }

        await this.processOrderItem();
        this.changeState = false;
      } finally {
        this.isCalculationInProgress = false;
      }
    },
    resetChange(isManuel = false) {
      // Kullanılmıyor
      this.changeState = true;
      if (!this.isManuel && !isManuel) {
        this.List = [];
      }
    },
    getCurrentVehicles() {
      const networks = {
        collective: bulkPlanning,
        ship: bulkShipPlanning,
      };
      axios
        .get(
          networks[this.network].vehiclesAll +
            "?rowId=" +
            this.rowId +
            "&isAll=true",
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          const newCurrentVehicles = res.data.data.map((item) => {
            return { ...item, plate: item.plaque };
          });

          this.currentVehicles = newCurrentVehicles.filter(
            (item) => item.isReleasedFromShip !== 1
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async save(callback) {
      try {
        const list = this.List.map((item) => {
          return {
            orderNo: item.orderNo,
            orderName: item.orderName,
            vehicle: item.vehicle,
            plate: item.vehicle,
            dorse: item.dorse,
            driverName: item.driverName,
            isCustom: item.isCustom || 0,
            driverTC: item.driverTC,
            capacity: item.capacity,
          };
        });

        if (list.length > 0) {
          await axios.post(
            bulkShipPlanning.vehicleBulkAdd,
            {
              vehicleList: JSON.stringify(list),
              shipId: this.shipId,
              isLaterAddOrder: this.isLaterAddOrder,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          );
          callback(true);
        } else {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "Devam etmek için araç eklemeniz gerekmektedir.",
            false,
            this.$t("general.OkayTitle")
          );
          callback(false);
        }
      } catch (err) {
        this.errorBox(err.response);
        callback(false);
      }
    },
  },
  computed: {
    isMultipleDay() {
      if (Array.isArray(this.recomendedList))
        return this.recomendedList.length > 0;
      else return false;
    },
  },
  async created() {
    this.changeState = this.calculateState != true;
    this.load = true;

    if (this.vehicleList.length == 0) {
      this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    }
    if (this.customers.length == 0)
      this.customers = await getCustomers(this.$store.state.userData.token);
    if (this.products.length == 0)
      this.products = await getProducts(this.$store.state.userData.token);
    if (!this.changeState) await this.calculateVehicle();
    if (this.network) {
      this.getCurrentVehicles();
    }
    this.load = false;
  },
  watch: {
    totalCount: {
      handler(newVal, oldVal) {
        if (this.isManuel || this.isCalculationInProgress) return;
        if (!this.load && newVal > 0 && !this.isDeleted) {
          this.changeState = true;
          // this.debouncedCalculateVehicle();
        } else if (!this.isDeleted) {
          this.List = [];
        }
      },
      immediate: true,
    },
    List(val) {
      this.$emit("change", val);
    },
    changeState: {
      handler(newVal, oldVal) {
        if (this.isDeleted || this.isCalculationInProgress) {
          this.changeState = false;
          return;
        }
        if (this.totalCount > 0 && newVal === true && !oldVal) {
          this.List = [];
        }
      },
    },
    center() {
      if (!this.load) {
        this.changeState = true;
      }
    },
    haveType: {
      handler(newVal, oldVal) {
        if (this.isManuel || this.isCalculationInProgress) return;
        if (!this.load && newVal > 0 && !this.isDeleted) {
          this.changeState = true;
          // this.debouncedCalculateVehicle();
        } else if (!this.isDeleted) {
          this.List = [];
        }
      },
      immediate: true,
    },
    order() {
      this.List = [];
    },
  },
};
</script>
