<template>
  <div class="flex items-center">
    <div class="w-full mr-2">
      <diffInput
        v-model="internalMonth"
        :optList="monthList"
        title="Ay Seç"
        type="select"
        :loading="loading"
        :hideContainer="true"
      />
    </div>
    <div class="w-full">
      <diffInput
        v-model="internalYear"
        :optList="yearList"
        title="Yıl Seç"
        type="select"
        :loading="loading"
        :hideContainer="true"
      />
    </div>
  </div>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "SelectMonth",
  components: {
    diffInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    modelMonth: {
      type: Number,
      required: false,
      default: null,
    },
    modelYear: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      monthList: [
        { name: "Ocak", id: 1 },
        { name: "Şubat", id: 2 },
        { name: "Mart", id: 3 },
        { name: "Nisan", id: 4 },
        { name: "Mayıs", id: 5 },
        { name: "Haziran", id: 6 },
        { name: "Temmuz", id: 7 },
        { name: "Ağustos", id: 8 },
        { name: "Eylül", id: 9 },
        { name: "Ekim", id: 10 },
        { name: "Kasım", id: 11 },
        { name: "Aralık", id: 12 },
      ],
      yearList: [],
    };
  },
  computed: {
    internalMonth: {
      get() {
        // Convert from 0-based (parent) to 1-based (UI)
        return this.modelMonth !== null && this.modelMonth !== undefined
          ? this.modelMonth + 1
          : new Date().getMonth() + 1;
      },
      set(value) {
        // Convert from 1-based (UI) to 0-based (parent)
        const numValue = Number(value) - 1;
        this.$emit("update:month", numValue, this.internalYear);
      },
    },
    internalYear: {
      get() {
        return this.modelYear !== null && this.modelYear !== undefined
          ? this.modelYear
          : new Date().getFullYear();
      },
      set(value) {
        const numValue = Number(value);
        // Convert month back to 0-based
        this.$emit("update:year", this.internalMonth - 1, numValue);
      },
    },
  },
  methods: {
    initializeYearList() {
      const currentYear = new Date().getFullYear();
      this.yearList = [];
      for (let year = 2024; year <= currentYear; year++) {
        this.yearList.push({ name: year.toString(), id: year });
      }
    },
  },
  created() {
    this.initializeYearList();
  },
};
</script>
