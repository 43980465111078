/**
 * recommendModules/helpers/optimizeVehicles.js
 * Optimal araçları seçme ve ek araç ekleme fonksiyonları
 */

const { selectOptimalVehicles } = require('../tools/selectOptimalVehicles');
const { updateVehicleAvailability } = require("../tools/updateVehicleAvailability");

/**
 * Optimal araçları seçer ve gerekirse ek araçlar ekler
 * @param {Array} sortedVehicles - Sıralanmış araçlar listesi
 * @param {Object} order - Sipariş detayları
 * @param {number} totalCount - İstenen toplam araç sayısı
 * @param {boolean} setAvailable - Araç durumunu güncelle
 * @param {Array} vehicles - Tüm araçlar listesi (durumları güncellemek için)
 * @returns {Array} Seçilen araçlar
 */
function optimizeVehicles(sortedVehicles, order, totalCount, setAvailable = true, vehicles = []) {
  // 1. Optimal araçları seç
  let topVehicles = selectOptimalVehicles(sortedVehicles, order.tonnage, totalCount);
  
  // 2. Gerekirse ek araçlar ekle
  if (topVehicles.length < totalCount) {
    const selectedIds = new Set(topVehicles.map(v => v.id));
    const additionalVehicles = sortedVehicles
      .filter(v => !selectedIds.has(v.id))
      .slice(0, totalCount - topVehicles.length);
    
    topVehicles = [...topVehicles, ...additionalVehicles];
  }
  
  // 3. Araç durumlarını güncelle (setAvailable true ise)
  if (setAvailable && vehicles.length > 0) {
    updateVehicleAvailability(topVehicles, vehicles, order);
  }
  
  return topVehicles;
}

/**
 * Yakın şubedeki araçları listeye ekler
 * @param {Array} topVehicles - Seçilen araçlar listesi 
 * @param {Array} sortedVehicles - Tüm sıralanmış araçlar
 * @returns {Array} Güncellenmiş araçlar listesi
 */
function addCloseBranchVehicles(topVehicles, sortedVehicles) {
  // Yakın şubedeki aracı bul
  const closeBranchVehicle = sortedVehicles.find(item => item.isCloseBranch);
  
  if (closeBranchVehicle) {
    // Zaten listede var mı kontrol et
    const alreadyExists = topVehicles.some(v => v.id === closeBranchVehicle.id);
    if (!alreadyExists) {
      // Listeye ekle
      return [...topVehicles, closeBranchVehicle];
    }
  }
  
  // Yakın şubede araç yoksa veya zaten listedeyse, listeyi olduğu gibi döndür
  return topVehicles;
}

module.exports = {
  optimizeVehicles,
  addCloseBranchVehicles
};