<template>
  <div class="relative">
    <button>
      <div class="flex items-center">
        <img
          @mousedown.stop="toggleStatusFilter()"
          class="w-4 h-4 mr-2"
          src="@/assets/planing/filteredit.svg"
          alt=""
        />
        <button
          v-if="isBulk ? initialStatus !== 2 : statusId !== 11"
          class="text-red-700 font-bold text-xl"
          @click="() => stopFiltering()"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </button>

    <div
      @mousedown.stop="statusFilter = true"
      :class="{
        'invisible opacity-0 transition-all ease-in duration-100 ':
          !statusFilter,
      }"
      class="w-44 h-auto overflow-y-scroll z-50 right-0 rounded mt-1 text-xs absolute bg-white top-7 border border-gray-300 text-black shadow-md"
    >
      <div>
        <div class="h-24">
          <button
            v-for="(item, index) in statusList"
            :key="index"
            @click="setStatusFilter(item.id)"
            :class="{
              'bg-red-700 text-white ': item.id === selectedStatus,
            }"
            class="w-full py-1 border-b hover:bg-red-700 hover:text-white border-gray-100 text-left px-2"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "statusPanel",
  props: ["isPlanning", "statusList", "statusId", "initialStatus", "isBulk"],
  data() {
    return {
      statusFilter: false,
      selectedStatus: null,
    };
  },
  methods: {
    stopFiltering() {
      this.selectedStatus = this.initialStatus;
      this.$emit("stopFiltering", true);
      this.toggleStatusFilter();
    },
    setStatusFilter(id) {
      this.selectedStatus = id;
      this.$emit("setStatusFilter", id);
      this.toggleStatusFilter();
    },
    toggleStatusFilter() {
      this.statusFilter = !this.statusFilter;
    },
  },
  created() {
    this.selectedStatus = this.initialStatus;
  },
  watch: {
    initialStatus(val) {
      this.selectedStatus = val;
    },
  },
};
</script>
