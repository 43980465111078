<template>
  <div class="w-full mt-2 bg-white rounded-lg shadow-sm">
    <div class="h-[calc(100vh-180px)] overflow-y-auto px-2 py-1">
      <!-- Yükleniyor Göstergesi -->
      <loading-spinner v-if="load" />
      
      <!-- Boş Durum Göstergesi -->
      <empty-state 
        v-else-if="List.length === 0" 
        message="Bu araç henüz herhangi bir sevkiyat gerçekleştirmedi"
        icon="fa-truck-loading"
      />
      
      <!-- Sevkiyat Listesi -->
      <div v-else>
        <shipment-card
          v-for="(item, index) in List"
          :key="item.id"
          :shipment="item"
          :index="index"
          :is-expanded="expandedItem === index"
          @toggle="toggleExpand(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { tracking } from "@/networking/urlmanager";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import EmptyState from "./components/EmptyState.vue";
import ShipmentCard from "./components/ShipmentCard.vue";

export default {
  name: "ShipmentHistory",
  components: {
    LoadingSpinner,
    EmptyState,
    ShipmentCard
  },
  props: ["vehicleId"],
  data() {
    return {
      List: [],
      load: false,
      expandedItem: 0 // İlk öğeyi varsayılan olarak aç
    };
  },
  created() {
    this.getAll();
    // Türkçe moment ayarı
    moment.locale('tr');
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(tracking.getLastTransition + "?vehicleId=" + this.vehicleId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          this.List = response.data.data;
        })
        .catch((err) => {
          console.error("Sevkiyat verileri alınırken hata:", err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    toggleExpand(index) {
      this.expandedItem = this.expandedItem === index ? null : index;
    }
  }
};
</script>