<template>
  <div>
    <!-- Bireysel bölge polygonları -->
    <div v-if="showIndividualPolygons">
      <div v-for="(zone, index) in zones" :key="'polygon-' + index">
        <GmapPolygon
          :paths="zone.path"
          :options="{
            fillColor: getZoneColor(zone),
            fillOpacity: 0.3,
            strokeColor: getZoneColor(zone),
            strokeWeight: 2,
            strokeOpacity: 0.8,
            clickable: true,
          }"
          @click="$emit('zoneSelected', zone)"
        />
      </div>

      <!-- Bölge etiketleri (markerlar) doğrudan burada kullanmıyoruz,
             ana bileşen bunları Google Maps API ile oluşturuyor -->
    </div>

    <!-- Seçili bölge vurgulanmış polygon -->
    <GmapPolygon
      v-if="selectedZone"
      :paths="selectedZone.path"
      :options="{
        fillColor: getZoneColor(selectedZone),
        fillOpacity: 0.5,
        strokeColor: getZoneColor(selectedZone),
        strokeWeight: 3,
        strokeOpacity: 1,
        zIndex: 100,
      }"
    />
  </div>
</template>
  
  <script>
export default {
  name: "ZonePolygons",
  props: {
    zones: {
      type: Array,
      required: true,
    },
    selectedZone: {
      type: Object,
      default: null,
    },
    showIndividualPolygons: {
      type: Boolean,
      default: true,
    },
    combinedPolygon: {
      type: Object,
      default: null,
    },
  },
  methods: {
    /**
     * Bölge için renk döndürür
     */
    getZoneColor(zone) {
      // Özelleştirilebilir renk dizisi
      const zoneColors = ["#00ffff"];

      // Bölge ID'sine göre renk seçimi
      return zoneColors[zone.id % zoneColors.length];
    },
  },
};
</script>